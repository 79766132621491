import React from 'react';
import {
  Grid,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import ItemCard from './ItemCard';
import { Action } from './ActionMenu';
import { ItemField } from './ItemTypes';

type GridListProps = {
  // fields?: Field[];
  model: any;
  defaultAction: ((item:any, navigate: any) => void) | undefined;
  actions: Action[];
  imageField: ItemField;
  titleField: ItemField;
  secondaryFields: ItemField[];
  showSelect: boolean;
};

const GridList = ({
  model,
  // fields, handleSelectAll,
  defaultAction,
  actions,
  imageField,
  titleField,
  secondaryFields,
  showSelect,
}: GridListProps) => (
  <Grid container spacing={3} className="grid-items">
    {model.items.map((item) => (
      <Grid
        item
        xl={3}
        lg={4}
        md={4}
        sm={6}
        xs={12}
        key={item.id}
      >
        <ItemCard
          item={item}
          selected={model.isSelected(item)}
          defaultAction={defaultAction}
          actions={actions}
          imageField={imageField}
          titleField={titleField}
          secondaryFields={secondaryFields}
          showSelect={showSelect}
        />
      </Grid>
    ))}

  </Grid>
);

GridList.defaultProps = {
  // fields: [
  //   { name: 'name', label: 'Name' },
  //   { name: 'description', label: 'Description' },
  //   { name: 'image', label: 'Image'}
  // ],
};

export default observer(GridList);
