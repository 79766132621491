/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import mainLogo from 'assets/images/logo.png';

const Logo = (props) => (
  <img
    alt="Logo"
    src={mainLogo}
    height="48px"
    {...props}
  />
);

export default Logo;
