import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import TextCircle from '../TextCircle';

const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: '5px !important',
  //   minWidth: 300,
  //   marginBottom: theme.spacing(0.5),
  borderRadius: theme.spacing(0.3125),
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.lightBlue[300],
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: theme.palette.success.main,
  },
}));

interface ProgressLinearType {
  value: number,
  showTitle?: boolean
}

const ProgressLinear = ({ value, showTitle }: ProgressLinearType) => (
  <Box
    sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      minHeight: 28,
    }}
  >
    {showTitle && (
      <Box sx={{ width: '160px' }}>
        <Typography variant="subtitle1" fontWeight={800} sx={{ color: 'info.light' }}>
          YOUR PROGRESS:
        </Typography>
      </Box>
    )}

    <Box sx={{
      mt: 'auto',
      mb: 'auto',
      ml: 2.5,
      width: `calc(100% - ${showTitle ? '160px' : '0px'})`,
      height: '100%',
    }}
    >
      <Box sx={{ position: 'relative' }}>
        <CustomLinearProgress variant="determinate" value={value} />
        <Box
          sx={{
            width: '100%',
            position: 'absolute',
            top: (theme) => theme.spacing(-2.5),
            transition: 'transform .4s linear',
            transform: (theme) => `translateX(calc(${value}% - ${theme.spacing(3)}))`,
          }}
        >
          <TextCircle
            innerText={`${value}%`}
            wrapperStyle={{ backgroundColor: 'success.main' }}
          />
        </Box>
      </Box>
    </Box>
  </Box>
);

ProgressLinear.defaultProps = {
  showTitle: true,
};

export default ProgressLinear;
