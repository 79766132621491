import { Auth } from 'aws-amplify';
import React from 'react';
// import { AmplifySignOut } from '@aws-amplify/ui-react';
import { styled } from '@mui/material/styles';
import {
  Grid, Stack, Menu, MenuItem, ListItemIcon,
  IconButton, Typography,
  // TextField,
  // InputAdornment,
  Badge, SvgIcon, Avatar,
} from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import Logout from '@mui/icons-material/Logout';
import BusinessIcon from '@mui/icons-material/Business';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';

import { navStore, useStore } from '@mcn-platform/models';
import EmailIcon from 'icons/EmailIcon';
import placeholderImage from 'assets/images/placeholder400.jpg';
import { sortBy } from 'lodash';

const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-dot': {
    background: '#f1501e',
    boxShadow: '0 0 0 2px #edf0f5',
  },
  '& .MuiBadge-anchorOriginTopRight': {
    top: 7,
    right: 2,
  },
}));

type AdminAppBarProps = {
  title: string
  drawerWidth?: number
  sidebarOpen?: boolean
  setSidebarOpen: (state: boolean) => void
};

const AppBar = styled(({
  open, drawerWidth, ...otherProps
}: any) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <MuiAppBar {...otherProps} />
))(({
  theme, open, drawerWidth,
}: any) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  }),
}));

const AdminAppBar = ({
  title, drawerWidth, sidebarOpen, setSidebarOpen,
}: AdminAppBarProps) => {
  const store = useStore();
  const { currentOrganization, organizations } = store;
  // console.log('List items', organizations);
  const [notifications] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleSignOut = () => {
    Auth.signOut().then(() => {
      window.location.reload();
    });
  };
  const handleSelectOrganization = async (org: any) => {
    console.log('Switching to organization', org);
    navStore.showBlockInput();
    await store.setCurrentOrganization(org);
    navStore.clearBlockInput();
    navigate('/');
  };
  return (
    <AppBar
      open={sidebarOpen}
      drawerWidth={drawerWidth}
      position="static"
      color="transparent"
      elevation={0}
      enableColorOnDark
      sx={{ px: 2.5, pt: 3, pb: 0.625 }}
    >
      <Grid container spacing={1.125} p={0}>
        <Grid item container xs={12} sm={6}>
          {!sidebarOpen && (
            <Grid item pr={1.125}>
              <IconButton
                color="inherit"
                onClick={() => setSidebarOpen(!sidebarOpen)}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
          )}
          <Grid item xs my="auto">
            <Typography component="div" variant="h2" textAlign="left">
              {title}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={6}
          spacing={1.125}
          sx={{ float: 'right' }}
        >
          <Grid item my="auto" xs>
            {/* <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ color: 'primary.main' }}>
                    <Icon name="search" />
                  </InputAdornment>
                ),
                sx: {
                  height: 28,
                  typography: 'h6',
                  backgroundColor: 'common.white',
                  borderRadius: 1,
                  fontWeight: 'fontWeightMedium',
                  py: 0.9625,
                  px: 1.7125,
                  '& input::placeholder': {
                    fontWeight: 'fontWeightBold',
                  },
                },
                disableUnderline: true,
              }}
              placeholder="Search for anything"
              variant="standard"
              sx={{ minWidth: 200, maxWidth: 334, float: 'right' }}
            /> */}
          </Grid>
          <Grid item my="auto">
            <Stack direction="row" spacing={0.125}>
              {/* <IconButton color="primary">
                <Icon name="lineGraph" />
              </IconButton> */}
              <IconButton onClick={() => {
                window.open('mailto:support@microcasting.com?subject=Support Request&body=Describe%20issue');
              }}
              >
                <StyledBadge variant="dot" invisible={notifications?.length < 1}>
                  <SvgIcon color="primary"><EmailIcon /></SvgIcon>
                </StyledBadge>
              </IconButton>
              <IconButton
                onClick={handleOpenMenu}
                id="account-button"
              >
                <Avatar src={placeholderImage} sx={{ width: '24px', height: '24px' }} />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={openMenu}
                onClose={handleCloseMenu}
                onClick={handleCloseMenu}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    overflowY: 'auto',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                {organizations?.size > 0 && sortBy([...organizations.values()], 'name').map((org: any) => (
                  <MenuItem key={org.id} onClick={() => { handleSelectOrganization(org); }}>
                    <ListItemIcon>
                      <BusinessIcon fontSize="small" />
                    </ListItemIcon>
                    {currentOrganization && currentOrganization.id === org.id && ('**')}
                    {org.name}
                  </MenuItem>
                ))}
                <MenuItem onClick={handleSignOut}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </AppBar>
  );
};

AdminAppBar.defaultProps = {
  drawerWidth: 298,
  sidebarOpen: true,
};

export default observer(AdminAppBar);
