import React, { useRef, useState, useEffect } from 'react';
import {
  Box, ButtonGroup, IconButton,
  ListItem, TextField,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import useDraggableItem from 'hooks/useDraggableItem';
import CloseIcon from 'icons/CloseIcon';
import PencilIcon from 'icons/PencilIcon';

type BulletItemInputProps = {
  id: string
  text: string
  index: number
  placeholder: string
  disabled: boolean
  selectedID: string | null
  setSelectedID: any
  onAdd: (item: string) => void
  onEdit: (item: string) => void
  onDelete: (id: string) => void
  onCancel: () => void
  moveItem: (dragIndex: number, hoverIndex: number) => void
};

const BulletItemInput = ({
  id, text, index, placeholder,
  disabled,
  selectedID, setSelectedID,
  onAdd, onEdit, onDelete, onCancel, moveItem,
}: BulletItemInputProps) => {
  const [currentValue, setCurrentValue] = useState<string>(text);

  const dragRef = useRef<HTMLDivElement>(null);
  const dropRef = useRef<HTMLDivElement>(null);

  let itemHandlerId:any = null;
  let itemOpacity:any = null;
  const selected = selectedID === id;

  const { handlerId, opacity } = useDraggableItem({
    id, index, dragRef, dropRef, moveItem,
  });

  useEffect(() => setCurrentValue(text), [text]);

  const handleAddOrEdit = () => {
    if (selectedID === '-1') {
      onAdd(currentValue);
    } else {
      onEdit(currentValue);
    }
  };

  // disable only for new input item
  if (id !== '-1') {
    itemHandlerId = handlerId;
    itemOpacity = opacity;
  }

  return (
    <ListItem
      disablePadding
      data-handler-id={itemHandlerId}
      sx={{ my: 1.5, opacity: itemOpacity }}
    >
      <TextField
        ref={dropRef}
        id={id}
        name={id}
        fullWidth
        multiline
        variant="standard"
        placeholder={placeholder}
        disabled={disabled || !selected}
        value={currentValue}
        onBlur={handleAddOrEdit}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCurrentValue(e.target.value)}
        InputProps={{
          'aria-describedby': `helper-text-${id}}`,
          startAdornment: (!disabled && id !== '-1') && (
            <Box ref={dragRef}>
              <IconButton
                disableRipple
                aria-label="dragNdrop"
                onClick={() => {}}
              >
                <DragIndicatorIcon />
              </IconButton>
            </Box>
          ),
          endAdornment: ((selectedID === id)
            ? (
              <ButtonGroup>
                <IconButton
                  disableRipple
                  aria-label="add-or-edit"
                  onClick={handleAddOrEdit}
                  disabled
                >
                  <DoneIcon />
                </IconButton>
                <IconButton
                  disableRipple
                  aria-label="cancel"
                  sx={{ '&:hover': { color: (theme) => `${theme.palette.error.main} !important` } }}
                  onClick={() => {
                    // revert input change
                    setCurrentValue(text);
                    onCancel();
                  }}
                  disabled={disabled}
                >
                  <CloseIcon />
                </IconButton>
              </ButtonGroup>
            )
            : (
              <ButtonGroup>
                <IconButton
                  disableRipple
                  aria-label="edit"
                  disabled={disabled || selectedID === '-1'}
                  onClick={() => setSelectedID(id)}
                >
                  <PencilIcon />
                </IconButton>
                <IconButton
                  disableRipple
                  aria-label="delete"
                  sx={{ '&:hover': { color: (theme) => `${theme.palette.error.main} !important` } }}
                  onClick={() => onDelete(id)}
                  disabled={disabled}
                >
                  <DeleteIcon />
                </IconButton>
              </ButtonGroup>
            )
          ),
        }}
      />
    </ListItem>
  );
};

export default BulletItemInput;
