import React, { useState } from 'react';
import { Box, Button, LinearProgress } from '@mui/material';
import { observer } from 'mobx-react-lite';
import {
  IAsset,
  IRepository,
  MimeTypeMap,
  useStore,
} from '@mcn-platform/models';
import { SignedImage } from '@mcn-platform/portal-lib';

type UploadViewImageProps = {
  repository: IRepository;
  onUploadComplete: (asset: IAsset) => void;
  onCancel: () => void;
  assetType: string | undefined;
};

enum UploadState {
  Choosing = 'Choosing',
  Confirm = 'Confirm',
  Uploading = 'Uploading',
  Processing = 'Processing',
  Display = 'Display',
}

/**
 * Child component for displaying the asset or a placeholder.
 */
const AssetDisplay = ({ asset }:any) => {
  if (!asset) {
    return (
      <Box>No file specified</Box>
    );
  }

  console.log(`Displaying asset ${asset.name} with preview url: ${asset.thumb200h}`);

  if (asset.status !== 'processed') {
    return (
      <Box>Asset is being processed</Box>
    );
  }
  return (
    <Box>
      <SignedImage
        bucket={asset.repository.bucket}
        bucketKey={`${asset.id}/thumb200h`}
        status={asset.status}
        alt={asset.name}
      />
      {/* <Box>{asset.status}</Box> */}
    </Box>
  );
};

/**
 * Widget typically passes through several states:
 * Choosing File -> Uploading -> Uploaded
 * and then once Uploaded, will poll every few seconds to see if
 * Asset is being processed, or if that is complete.
 *
 * @param Repository that is being uploaded to
 * @returns Asset
 */
const UploadViewImage = ({
  repository,
  onUploadComplete,
  onCancel,
  assetType,
}:UploadViewImageProps) => {
  const [selectedFile, setSelectedFile] = useState<File>();
  const [asset, setAsset] = useState<IAsset | undefined>();
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [uploadState, setUploadState] = useState<string>(
    asset ? UploadState.Display : UploadState.Choosing,
  );

  const mimeType = (assetType && MimeTypeMap[assetType]) || 'any';
  const store = useStore();

  const handleFileSelected = (event:any) => {
    setSelectedFile(event.target.files[0]);
    setUploadState(UploadState.Confirm);
  };

  const handleUploadProgress = (progressPercent:number, uploadAsset: IAsset) => {
    setUploadProgress(progressPercent);
    if (uploadAsset && progressPercent === 100) {
      setUploadState(UploadState.Processing);
      onUploadComplete(uploadAsset);
    }
  };

  const handleAssetUpdate = (newAsset:IAsset) => {
    console.log('Set asset', newAsset);
    setAsset(newAsset);
  };

  const handleUploadClick = () => {
    console.log('Handle upload click');
    if (!selectedFile) {
      return;
    }
    setUploadState(UploadState.Uploading);
    // setProgress is a call back - expects a number between 0 and 100.
    store.upload(selectedFile, repository, handleAssetUpdate, handleUploadProgress);
  };

  const handleCancelClick = () => {
    console.log('Handle cancel click');
    setSelectedFile(undefined);
    setUploadState(UploadState.Display);
  };

  const handleChangeFileClick = () => {
    console.log('Handle change file click');
    setSelectedFile(undefined);
    setUploadState(UploadState.Choosing);
  };

  const handleCompletedClick = () => {
    console.log('Handle completed click');
    setSelectedFile(undefined);
    setUploadState(UploadState.Display);
  };

  return (
    <Box>
      {uploadState === UploadState.Display && (
        <Box>
          <AssetDisplay asset={asset} />
          <Button onClick={handleChangeFileClick}>Change</Button>
        </Box>
      )}
      {uploadState === UploadState.Choosing && (
        <Box>
          <label htmlFor="contained-button-file">
            <input
              id="contained-button-file"
              name="contained-button-file"
              accept={mimeType}
              style={{ display: 'none' }}
              type="file"
              onChange={handleFileSelected}
            />
            <Button onClick={onCancel} color="secondary">Cancel</Button>
            <Button variant="outlined" component="span">Choose file</Button>
          </label>
        </Box>
      )}
      {uploadState === UploadState.Confirm && (
        <Box>
          {selectedFile && selectedFile.name}
          <Button onClick={handleCancelClick} disabled={!selectedFile} color="secondary">Cancel</Button>
          <Button onClick={handleUploadClick} disabled={!selectedFile} variant="contained" color="primary">Upload</Button>
        </Box>
      )}
      {uploadState === UploadState.Uploading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress variant="determinate" value={uploadProgress} />
        </Box>
      )}
      {uploadState === UploadState.Processing && (
        <Box>
          Upload complete.
          <Button onClick={handleCompletedClick} variant="contained">Ok</Button>
        </Box>
      )}
    </Box>
  );
};

export default observer(UploadViewImage);
