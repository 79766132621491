import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { IQuestionnaire, useStore } from '@mcn-platform/models';
import { QuestionnaireWidget } from '@mcn-platform/portal-lib';

const QuestionnaireList = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  organization,
  portal,
  customer,
}:any) => {
  const store = useStore();
  const [selectedQuestionnaire, setSelectedQuestionaire] = useState<IQuestionnaire | undefined>();

  const questionnaireList = store.getCachedList('questionnaire', {
    portalID: { eq: portal.id },
    email: { eq: customer.email },
  });
  questionnaireList.setRequestedCount(1000);

  return (
    <div>
      Questionnaire List
      <Grid container sx={{ height: '100%' }}>
        <Grid item xs={12} md={5} sx={{ height: '100%', overflow: 'hidden', pr: 2 }}>
          <Card sx={{ height: '100%', overflow: 'auto' }}>
            <CardContent>
              <List>
                {questionnaireList?.getItems().map((questionnaire) => (
                  <ListItem
                    key={questionnaire.id}
                    onClick={() => setSelectedQuestionaire(questionnaire)}
                  >
                    {questionnaire.name}
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={7} sx={{ height: '100%', overflow: 'hidden' }}>
          <Card sx={{ height: '100%', overflow: 'auto' }}>
            <CardContent>
              <Box sx={{ minHeight: '100%' }}>
                <QuestionnaireWidget questionnaireID={selectedQuestionnaire?.id} readonly />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default observer(QuestionnaireList);
