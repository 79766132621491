import React from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Container,
  Link,
  Typography,
} from '@mui/material';

const NotFound = () => (
  <>
    <Helmet>
      <title>Microcasting</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
      }}
    >
      <Container maxWidth="md">
        <Typography
          align="center"
          color="textPrimary"
          variant="h1"
        >
          We're sorry, an error has occurred.
        </Typography>
        <Typography
          align="center"
          color="textPrimary"
          variant="h4"
          sx={{ py: 2 }}
        >
          Please click
          {' '}
          <Link href="/">here to return to the Home Page</Link>
          .
        </Typography>
      </Container>
    </Box>
  </>
);

export default NotFound;
