import React from 'react';
import {
  FormLabel,
  Grid,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { StandardFieldProps } from './FieldTypes';
import StandardNumberField from './StandardNumberField';

/**
 * Handle Color Sets (normal, hover, disabled) - usually for buttons
 * The keys are fieldName + .color, .hover.color and .disabled.color
 * @param param
 * @returns
 */
const StandardBorderStyleField = ({
  control,
  fieldName,
  field,
  readOnly,
}:StandardFieldProps) => (
  <Controller
    name={`${fieldName}`}
    control={control}
    render={() => (
      <>
        <FormLabel>
          {field.label}
        </FormLabel>
        <Grid
          container
          spacing={1}
          padding={1}
        >
          <Grid item xs={4}>
            <StandardNumberField
              control={control}
              fieldName={fieldName.replace('.borderStyle', '.thickness')}
              field={{
                type: 'number', // mapping values used in stylesheet to standard field types
                label: 'Thickness',
              }}
              readOnly={readOnly}
            />
          </Grid>
          <Grid item xs={4}>
            <StandardNumberField
              control={control}
              fieldName={fieldName.replace('.borderStyle', '.radius')}
              field={{
                type: 'number', // mapping values used in stylesheet to standard field types
                label: 'Radius',
              }}
              readOnly={readOnly}
            />
          </Grid>
        </Grid>
      </>
    )}
  />
);

export default StandardBorderStyleField;
