/* eslint-disable react/jsx-props-no-spreading */
/**
 * Takes tags[] as the initial list of chips to display.
 * Each time a chip is added or removed onChange is called.
 * The value being typed or selected in for a new chip is internal state as well.
 * TODO: tags can be selected - this is internal state that is managed.
 */
import React, { useState } from 'react';
import {
  Autocomplete, Chip, TextField, IconButton,
} from '@mui/material';
import CloseIcon from 'icons/CloseIcon';

type TagsInputProps = {
  onChange: (tags:string[]) => void;
  value: string[];
  options?: string[];
  placeholder?: string;
  disabled: boolean;
  id: string,
  name: string,
  [other:string]: any;
};

// Turns text into zero or more tags
const parseText = (text:string) => {
  if (!text) return [];
  return text.split(',').map((tag) => tag.trim());
};

const TagsInput = ({
  onChange,
  placeholder,
  value = [],
  options = [],
  disabled,
  id,
  name,
  ...other
}:TagsInputProps) => {
  console.log('Other:', other);
  // these are the already defined tags parsed from the form field values

  // this is the text currently in the field that hasnt been turned into a chip yet
  const [freeText, setFreeText] = useState<string>('');
  // the 'value' field is the parsed array of values.

  const handleDelete = (e: any, item:string) => {
    e.preventDefault();
    const newValue = [...value];
    newValue.splice(newValue.indexOf(item), 1);
    onChange(newValue);
  };
  return (
    <Autocomplete
      fullWidth
      multiple
      freeSolo
      id={id}
      value={value}
      inputValue={freeText}
      options={options}
      renderTags={(tagsValue: readonly string[], getTagProps) => (
        tagsValue.map((option: string, index: number) => (
          <Chip
            {...getTagProps({ index })}
            label={option}
            sx={{ margin: 0.5 }}
            deleteIcon={(
              <IconButton
                disabled={disabled}
                disableRipple
              >
                <CloseIcon />
              </IconButton>
            )}
            onDelete={(e) => handleDelete(e, option)}
          />
        )))}
      onChange={() => {
        const newValue = [...value, ...parseText(freeText)];
        onChange(newValue);
        setFreeText('');
      }}
      renderInput={(params) => (
        <TextField
          variant="outlined"
          placeholder={value.length < 3 ? placeholder : ''}
          onChange={(event) => {
            console.log('TextField onChange', event);
            setFreeText(event.target.value);
          }}
          onBlur={() => {
            // if there is text that hasn't been processed into the tags yet, do it now.
            if (freeText) {
              const newValue = [...value, ...parseText(freeText)];
              setFreeText('');
              onChange(newValue);
            }
          }}
          {...params}
          disabled={disabled}
          id={id}
          name={name}
        />
      )}
      {...other}
      disabled={disabled}
    />
  );
};

TagsInput.defaultProps = {
  options: [],
  placeholder: '',
};

export default TagsInput;
