import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Box,
  Button,
  Card, CardContent,
  Grid,
} from '@mui/material';

import { ILearningBoard, useStore } from '@mcn-platform/models';
import ButtonStack from 'components/item/ButtonStack';
import SectionDetails from './SectionDetails';
import LearningBoardTree from './LearningBoardTree/LearningBoardTree';
import { TreeItem } from './LearningBoardTree/types';

export type LearningBoardEditorProps = {
  portalID: string;
  customerID: string | undefined;
};

const LearningBoardEditor = ({
  portalID, customerID,
}:LearningBoardEditorProps) => {
  const [learningBoard, setLearningBoard] = useState<ILearningBoard | undefined>();
  const [section, setSection] = useState<TreeItem | null>(null);
  const store = useStore();
  const boardID = `${customerID}#${portalID}`;
  // const learningBoard = store.getOrLoad('learningBoard', boardID);

  const saveLearningBoard = () => {
    if (!learningBoard) return;
    if (learningBoard.syncState === 'stubbed') {
      // its a new board, create it
      store.addStub('learningBoard', learningBoard);
    } else {
      store.update('learningBoard', learningBoard);
    }
  };

  useEffect(() => {
    setSection(null);
    store.load('learningBoard', boardID).then((board:ILearningBoard) => {
      if (board.syncState === 'not_found') {
        const newBoard = store.stub(
          'learningBoard',
          boardID,
          '',
          {
            customerID,
            portalID,
          },
        );
        setLearningBoard(newBoard);
      } else {
        setLearningBoard(board);
      }
    });
  }, [store, boardID, customerID, portalID]);

  if (!learningBoard) {
    return <div>Loading...</div>;
  }

  // console.log('onChange', onChange);
  return (
    <Box sx={{ flex: '1', overflowY: 'auto', width: '100%' }}>
      {/* <Typography variant="h4" component="div">
        Learning Board
        {' '}
        {learningBoard?.name}
      </Typography> */}
      <Grid container sx={{ height: '100%' }}>
        <Grid item xs={12} md={5} sx={{ height: '100%', overflow: 'hidden', pr: 2 }}>
          <Card sx={{ height: '100%', overflow: 'auto' }}>
            <CardContent>
              <LearningBoardTree
                learningBoard={learningBoard}
                currentSection={section}
                setCurrentSection={setSection}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={7} sx={{ height: '100%', overflow: 'hidden' }}>
          <Card sx={{ height: '100%', overflow: 'auto' }}>
            <CardContent>
              <Box sx={{ minHeight: '100%' }}>
                <SectionDetails
                  section={section || learningBoard}
                  key={section ? section.id : learningBoard.id}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Box sx={{ flex: 1 }}>
        <ButtonStack>
          <Button
            color="primary"
            variant="contained"
            onClick={saveLearningBoard}
            disabled={learningBoard.changeCount === 0}
          >
            Save
          </Button>
        </ButtonStack>
      </Box>
    </Box>
  );
};

export default observer(LearningBoardEditor);
