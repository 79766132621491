/**
 * Field shows the comma seperated list of values as chips inside a TextField input element.
 * The value stored in Formik is the comma seperated list.
 * The TagsInput is where all the actual work is done - it expects its input to be
 * an array of tags and when a tag is added or removed it calls onChange with the new list of tags.
 */
import React from 'react';
import { observer } from 'mobx-react-lite';
import { Controller } from 'react-hook-form';
import {
  Box, Chip, FormControlLabel,
  MenuItem, OutlinedInput,
  Select,
  SelectChangeEvent,
  IconButton,
} from '@mui/material';
import CloseIcon from 'icons/CloseIcon';
import { useStore } from '@mcn-platform/models';
import { StandardFieldProps } from './FieldTypes';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AccessGroupsTagsField = ({
  control,
  fieldName,
  field,
  readOnly,
}:StandardFieldProps) => {
  const store = useStore();
  if (!store.currentOrganization) {
    return null;
  }

  const { accessGroups } = store.currentOrganization;

  return (
    <Controller
      name={fieldName}
      control={control}
      render={({
        field: {
          onChange,
          onBlur,
          value,
          // name,
          // ref,
        },
        // fieldState: {
        //   // invalid,
        //   isTouched,
        //   // isDirty,
        //   error,
        // },
      }) => {
        const selectedGroups = value;

        const handleChange = (event: SelectChangeEvent<any>) => {
          const {
            target: { value: newValue },
          } = event;
          onChange(
            typeof value === 'string' ? value.split(',') : newValue,
          );
        };
        const handleDelete = (e: any, item:string) => {
          e.preventDefault();
          const newValue = [...value];
          newValue.splice(newValue.indexOf(item), 1);
          onChange(newValue);
        };
        const disabled = field.readOnly || readOnly;
        const select = (
          <Select
            labelId="demo-multiple-chip-label"
            id={fieldName}
            name={fieldName}
            placeholder={field.placeholder}
            multiple
            onBlur={onBlur}
            disabled={disabled}
            value={selectedGroups}
            fullWidth
            onChange={handleChange}
            input={<OutlinedInput id="select-multiple-chip" label={field.label} />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((entry) => (
                  <Chip
                    key={entry}
                    label={entry}
                    sx={{ margin: 0.5 }}
                    onMouseDown={(event) => {
                      event.stopPropagation();
                    }}
                    disabled={disabled}
                    deleteIcon={(
                      <IconButton
                        disabled={disabled}
                        disableRipple
                      >
                        <CloseIcon />
                      </IconButton>
                    )}
                    onDelete={(e) => handleDelete(e, entry)}
                  />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {accessGroups.map((accessGroup) => (
              <MenuItem
                key={accessGroup.id}
                value={accessGroup.code}
              >
                {accessGroup.code}
                :
                {accessGroup.name}
              </MenuItem>
            ))}
          </Select>
        );
        if (field.showLabel === false) {
          return select;
        }
        return (
          <FormControlLabel
            label={field.label}
            disabled={field.readOnly}
            labelPlacement="top"
            control={select}
          />
        );
      }}
    />
  );
};

AccessGroupsTagsField.defaultProps = {
  placeholder: '',
  disabled: false,
};

export default observer(AccessGroupsTagsField);
