import * as React from 'react';
import { SVGProps } from 'react';

export default (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 5H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1ZM4 3a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h16a3 3 0 0 0 3-3V6a3 3 0 0 0-3-3H4Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.232 7.36a1 1 0 0 1 1.408-.128l4.72 3.933a1 1 0 0 0 1.28 0l4.72-3.933a1 1 0 0 1 1.28 1.536l-4.72 3.933a3 3 0 0 1-3.84 0L5.36 8.768a1 1 0 0 1-.128-1.408Z"
      fill="currentColor"
    />
  </svg>
);
