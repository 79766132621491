/**
 * Field shows the comma seperated list of values as chips inside a TextField input element.
 * The value stored in Formik is the comma seperated list.
 * The TagsInput is where all the actual work is done - it expects its input to be
 * an array of tags and when a tag is added or removed it calls onChange with the new list of tags.
 */
import React from 'react';
import { FormControlLabel } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Controller } from 'react-hook-form';
import { StandardFieldProps } from './FieldTypes';
import TagsInput from './TagsInput/TagsInput';
import HelperText from './HelperText';

const StandardChipOptionsField = ({
  control,
  fieldName,
  field,
  readOnly,
}:StandardFieldProps) => (
  <Controller
    name={fieldName}
    control={control}
    render={({
      field: {
        onChange,
        onBlur,
        value,
      },
      fieldState: {
        isTouched,
        error,
      },
    }) => (
      <>
        <FormControlLabel
          label={field.label}
          disabled={field.readOnly || readOnly}
          labelPlacement="top"
          control={(
            <TagsInput
              id={fieldName}
              name={fieldName}
              placeholder={field.placeholder}
              value={value}
              onChange={onChange}
              options={field.enum || []}
              optionLabels={field.enumLabels || []}
              onBlur={onBlur}
              disabled={!!field.readOnly}
              fullWidth
            />
          )}
        />
        <HelperText
          name={fieldName}
          error={isTouched && error?.message}
          description={field.description}
        />
      </>
    )}
  />
);

StandardChipOptionsField.defaultProps = {
  placeholder: '',
  disabled: false,
  options: [],
};

export default observer(StandardChipOptionsField);
