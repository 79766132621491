/**
 * Field shows the comma seperated list of values as chips inside a TextField input element.
 * The value stored in Formik is the comma seperated list.
 * The TagsInput is where all the actual work is done - it expects its input to be
 * an array of tags and when a tag is added or removed it calls onChange with the new list of tags.
 */
import React from 'react';
import { FormControlLabel } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Controller } from 'react-hook-form';
import { StandardFieldProps } from './FieldTypes';
import ReferenceTagsInput from './TagsInput/ReferenceTagsInput';

const StandardReferenceChipsField = ({
  control,
  fieldName,
  field,
  readOnly,
}:StandardFieldProps) => (
  <Controller
    name={fieldName}
    control={control}
    render={({
      field: {
        onChange,
        onBlur,
        value,
      },
    }) => {
      const selector = (
        <ReferenceTagsInput
          id={fieldName}
          name={fieldName}
          placeholder={field.placeholder}
          value={value}
          onChange={onChange}
          modelName={field.references || ''}
          onBlur={onBlur}
          disabled={field.readOnly || readOnly || false}
          fullWidth
        />
      );
      if (field.showLabel === false) {
        return selector;
      }
      return (
        <FormControlLabel
          label={field.label}
          disabled={field.readOnly || readOnly}
          labelPlacement="top"
          control={selector}
        />
      );
    }}
  />
);

StandardReferenceChipsField.defaultProps = {
  placeholder: '',
  disabled: false,
  options: [],
};

export default observer(StandardReferenceChipsField);
