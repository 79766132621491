/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet-async';
import { v4 } from 'uuid';
import { useNavigate, useParams } from 'react-router-dom';
import usePrompt from 'hooks/usePrompt';
import { getSnapshot } from 'mobx-state-tree';

import {
  ILead,
  useStore,
} from '@mcn-platform/models';
import ItemEdit from 'components/item/ItemEdit';
import ButtonStack from 'components/item/ButtonStack';

const LeadEditor = () => {
  const store = useStore();
  const [lead, setLead] = useState<ILead>();
  const { leadID } = useParams();
  const navigate = useNavigate();

  usePrompt({ isDirty: lead ? (lead.changeCount > 0) : false });

  useEffect(() => {
    if (!leadID) {
      return;
    }
    // check if we're creating a new lead
    if (leadID === 'new') {
      if (lead) {
        console.log('All good - already loaded');
        return;
      }

      const newLead = store.create(
        'lead',
        v4(),
        'New Lead',
        {
          sections: [],
          type: 'container',
          libraries: [store.currentPortal.defaultLibrary],
        },
      );
      setLead(newLead);
    } else {
      const loadedLead = store.getOrLoad('lead', leadID);
      setLead(loadedLead);
    }
  }, [store, leadID, lead, lead?.syncState]);

  const saveLead = () => {
    if (!lead) return;
    console.log('check Lead', leadID);
    if (leadID === 'new') {
      store.addStub('lead', lead).then(() => {
        console.log('check saved');
        navigate(`/app/lead-editor/${lead.id}`);
      });
    } else {
      store.update('lead', lead);
    }
  };

  if (!lead) {
    return <div>Loading.</div>;
  }
  if (lead.syncState === 'error') {
    return <div>Error loading lead</div>;
  }

  if (lead.syncState === 'saving') {
    return <div>Saving..</div>;
  }

  if (leadID !== 'new' && lead.syncState !== 'loaded') {
    return <div>Loading..</div>;
  }
  console.log('lead editor', leadID, getSnapshot(lead));

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    }}
    >
      <Helmet>
        <title>{leadID === 'new' ? 'New Lead' : 'Edit Lead'}</title>
      </Helmet>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        minHeight: '100%',
      }}
      >
        <Box sx={{ flex: '1', overflowY: 'auto' }}>
          <ItemEdit
            item={lead}
            modelName="lead"
            handleSubmit={(newValues) => {
              console.log('Update lead', newValues);
              lead.updateValues(newValues);
            }}
            hideTitle
            autoSave
          />
        </Box>
        <Box sx={{ flex: 0 }}>
          <ButtonStack>
            <Button
              color="primary"
              variant="contained"
              onClick={saveLead}
              disabled={lead.changeCount === 0}
            >
              Save
            </Button>
          </ButtonStack>
        </Box>
      </Box>
    </Box>
  );
};

export default observer(LeadEditor);
