import React, { ReactNode } from 'react';
import { Box } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import ButtonStack from 'components/item/ButtonStack';

const EditLayout = ({
  title, buttonStack, children,
}: {
  title: string;
  buttonStack: ReactNode;
  children: ReactNode;
}) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    }}
  >
    <Helmet>
      <title>{title}</title>
    </Helmet>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
      }}
    >
      <Box sx={{ flex: '1', overflowY: 'auto' }}>
        {children}
      </Box>
      <ButtonStack>
        {buttonStack}
      </ButtonStack>
    </Box>
  </Box>
);

export default EditLayout;
