import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from '@mui/icons-material';
import { observer } from 'mobx-react-lite';
import { v4 } from 'uuid';
import {
  IDocumentProgress,
  navStore,
  Tokens,
  TokensProvider,
  useStore,
} from '@mcn-platform/models';
import { FeatureCard } from '@mcn-platform/portal-lib';
import ItemPickerDialog from 'components/forms/ItemPickerDialog';
import ButtonStack from 'components/item/ButtonStack';
import ItemEdit from 'components/item/ItemEdit';

const DocumentList = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  portal,
  customer,
}:any) => {
  const store = useStore();
  const [
    selectedDocumentProgress,
    setSelectedDocumentProgress,
  ] = useState<IDocumentProgress | undefined>();
  const [pickerOpen, setPickerOpen] = useState<boolean>(false);
  const [progressList, setProgressList] = useState<any>();

  const handleOpenSelectDialog = () => {
    setPickerOpen(true);
  };

  const handleCancelSelectDialog = () => {
    setPickerOpen(false);
  };

  const handleConfirmSelectDialog = (newDocuments:any[]) => {
    console.log('selected', newDocuments);
    setPickerOpen(false);

    const processUpdate = async () => {
      // these are the new documents that were selected - create a documentProgress for each.
      const progressPromisses = newDocuments.map(async (documentKey) => {
        // check if there is already a documentProgress for this document and customer
        const existingProgresses = store.getCachedList('documentProgress', {
          // portalID: { eq: portal.id },
          documentKey: { eq: documentKey },
          customerID: { eq: customer.id },
        });
        existingProgresses.setRequestedCount(10);
        await store.fetchCachedList(existingProgresses);
        if (existingProgresses.getItems().length > 0) {
          const existingProgress = existingProgresses.getItems()[0];
          existingProgress.updateValues({
            includeInMyDocuments: true,
          });
          await store.update('documentProgress', existingProgress, false);
          return existingProgress;
        }

        // otherwise result is cached and the item can be added multiple times
        existingProgresses.reset();

        // create new one
        const newProgress = await store.add(
          'documentProgress',
          {
            id: v4(),
            name: 'document progress',
            document: store.getOrLoad('document', documentKey),
            customer,
            organization: store.currentOrganization.id,
            // portalID: portal.id,
            status: 'assigned',
            includeInMyDocuments: true,
          },
        );
        return newProgress;
      });
      const updatedProgresses = await Promise.all(progressPromisses);
      console.log('updated progresses', updatedProgresses);
      await store.reloadCachedList(progressList);
    };

    navStore.showBlockInput();
    processUpdate().finally(() => {
      navStore.clearBlockInput();
    });
  };

  const deleteProgress = async (documentProgress:IDocumentProgress) => {
    await store.remove('documentProgress', documentProgress);
    await store.reloadCachedList(progressList);
  };

  const saveSelectedDocumentProgress = () => {
    store.update('documentProgress', selectedDocumentProgress);
  };

  useEffect(() => {
    const documentProgressList = store.getCachedList('documentProgress', {
      // portalID: { eq: portal.id },
      customerID: { eq: customer.id },
    });
    documentProgressList.setRequestedCount(1000);
    setProgressList(documentProgressList);
  }, [store, customer.id, progressList?.syncState]);

  return (
    <div>
      <Grid container sx={{ height: '100%' }}>
        <Grid item xs={12} md={5} sx={{ height: '100%', overflow: 'hidden', pr: 2 }}>
          <Card sx={{ height: '100%', overflow: 'auto' }}>

            <CardContent>
              <Typography variant="h4">
                Customer Documents
              </Typography>
              <List>
                {progressList?.getItems().map((documentProgress) => (
                  <ListItem
                    key={documentProgress.id}
                    onClick={() => setSelectedDocumentProgress(documentProgress)}
                    secondaryAction={(
                      <IconButton
                        aria-label="delete"
                        onClick={() => deleteProgress(documentProgress)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  >
                    <ListItemIcon>
                      {documentProgress.includeInMyDocuments ? (
                        <CheckBoxIcon />
                      ) : (
                        <CheckBoxOutlineBlankIcon />
                      )}
                    </ListItemIcon>
                    <ListItemText primary={documentProgress?.document?.name} />
                  </ListItem>
                ))}
              </List>
              <Button onClick={handleOpenSelectDialog} variant="outlined">
                Add Document
              </Button>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={7} sx={{ height: '100%', overflow: 'hidden' }}>
          {selectedDocumentProgress?.document?.key ? (
            <>
              <Card sx={{ height: '100%', overflow: 'auto' }}>
                <CardContent>
                  <Typography variant="h4">
                    Details
                  </Typography>
                  <Box sx={{ minHeight: '100%', my: 2 }}>
                    <ItemEdit
                      key={selectedDocumentProgress?.id}
                      item={selectedDocumentProgress}
                      modelName="documentProgress"
                      handleSubmit={() => {}}
                      hideTitle
                      autoSave
                      formConfig={['includeInMyDocuments', 'lastActiveDate', 'viewCount']}
                    />
                    <ButtonStack>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={saveSelectedDocumentProgress}
                        disabled={selectedDocumentProgress.changeCount === 0}
                      >
                        Save
                      </Button>
                    </ButtonStack>
                  </Box>
                </CardContent>
              </Card>
              <Card sx={{ height: '100%', overflow: 'auto' }}>
                <CardContent>
                  <Typography variant="h4">
                    Document Info
                  </Typography>
                  <Box sx={{ minHeight: '100%', my: 2 }}>
                    <TokensProvider value={new Tokens(store.currentPortal)}>
                      <FeatureCard
                        item={{
                          type: 'document',
                          itemID: selectedDocumentProgress?.document?.key,
                        }}
                        settings={{
                          showShareBtns: false,
                          showVideo: true,
                          showRatings: false,
                          showCourseInfo: true,
                          showCourseHeadline: true,
                          showOrderBy: false,
                          showInstructor: true,
                          showPrice: false,
                          showEnrollButton: false,
                        }}
                        configId={undefined}
                        limitToPageWidth
                      />
                    </TokensProvider>
                  </Box>
                </CardContent>
              </Card>
            </>
          ) : (
            <Typography variant="body1">
              Select a document to view more information.
            </Typography>
          )}
        </Grid>
      </Grid>
      <ItemPickerDialog
        open={pickerOpen}
        modelName="document"
        selectedKeysByModel={{}}
        onCancel={handleCancelSelectDialog}
        onConfirm={handleConfirmSelectDialog}
      />
    </div>
  );
};

export default observer(DocumentList);
