// import { colors } from '@mui/material';

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    subNav: {
      topColor: React.CSSProperties['color']
      bottomColor: React.CSSProperties['color']
      text: {
        primary: React.CSSProperties['color']
        secondary: React.CSSProperties['color']
      }
    }
    courseBar: {
      main: React.CSSProperties['color']
      text: {
        primary: React.CSSProperties['color']
        secondary: React.CSSProperties['color']
      }
    }
    contract: {
      main: React.CSSProperties['color']
    }
    tertiary: {
      main: React.CSSProperties['color']
    }
    lightBlue: {
      300: React.CSSProperties['color']
      800: React.CSSProperties['color']
    }
    greyCustom: {
      200: React.CSSProperties['color']
      300: React.CSSProperties['color']
      600: React.CSSProperties['color']
      800: React.CSSProperties['color']
      A200: React.CSSProperties['color']
    }
    indigoCustom: {
      700: React.CSSProperties['color']
      900: React.CSSProperties['color']
    }
    backgroundSecondary: React.CSSProperties['color']
    backgroundTertiary: React.CSSProperties['color']
    backgroundDark: React.CSSProperties['color']
    backgroundDarkHover: React.CSSProperties['color']
    backgroundDarkSelected: React.CSSProperties['color']

    dividerSecondary: React.CSSProperties['color']
  }

  interface PaletteOptions {
    subNav: {
      main: React.CSSProperties['color']
      topColor: React.CSSProperties['color']
      bottomColor: React.CSSProperties['color']
      text: {
        primary: React.CSSProperties['color']
        secondary: React.CSSProperties['color']
      }
    }
    courseBar: {
      main: React.CSSProperties['color']
      text: {
        primary: React.CSSProperties['color']
        secondary: React.CSSProperties['color']
      }
    }
    contract: {
      main: React.CSSProperties['color']
    }
    tertiary: {
      main: React.CSSProperties['color']
    }
    lightBlue: {
      300: React.CSSProperties['color']
      800: React.CSSProperties['color']
    }
    greyCustom: {
      200: React.CSSProperties['color']
      300: React.CSSProperties['color']
      600: React.CSSProperties['color']
      800: React.CSSProperties['color']
      A200: React.CSSProperties['color']
    }
    indigoCustom: {
      700: React.CSSProperties['color']
      900: React.CSSProperties['color']
    }
    backgroundSecondary: React.CSSProperties['color']
    backgroundTertiary: React.CSSProperties['color']
    backgroundDark: React.CSSProperties['color']
    backgroundDarkHover: React.CSSProperties['color']
    backgroundDarkSelected: React.CSSProperties['color']

    dividerSecondary: React.CSSProperties['color']
  }
}

const palette = {
  common: {
    black: '#000',
    white: '#fff',
  },
  contract: {
    main: '#007bad',
  },
  tertiary: {
    main: '#fcbe00',
  },
  // custom colors from design
  lightBlue: {
    300: '#1e98c9', // we may have others so should set 200 for lighter or 400 for darker
    800: '#037bad',
  },
  greyCustom: {
    200: '#f0f0f0',
    300: '#edf0f5',
    600: '#7f7f7f',
    800: '#40484f',
    A200: '#ebebeb',
  },
  indigoCustom: {
    700: '#004789',
    900: '#142261',
  },
  // used by admin
  backgroundSecondary: '#f7f8fa',
  backgroundTertiary: 'rgba(0, 0, 0, 0.5)',
  // // // // //

  // used by Portal
  backgroundDark: '#0a1d4a',
  // lighten backgroundDark by 5% => Tool: https://www.cssfontstack.com/oldsites/hexcolortool/
  backgroundDarkHover: '#172A57',
  backgroundDarkSelected: '#0a1d4a', // for now same as backgroundDark
  // // // // //

  dividerSecondary: '#91bfec',
};

export default palette;
