import * as React from 'react';
import { SVGProps } from 'react';

export default (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={6}
    height={10}
    fill="none"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.207 9.207a1 1 0 0 0 0-1.414L2.414 5l2.793-2.793A1 1 0 0 0 3.793.793l-3.5 3.5a1 1 0 0 0 0 1.414l3.5 3.5a1 1 0 0 0 1.414 0Z"
      fill="currentColor"
    />
  </svg>
);
