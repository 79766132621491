import React from 'react';
import {
  FormControl,
  FormControlLabel,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { StandardFieldProps } from './FieldTypes';
import HelperText from './HelperText';
import ColorControl from '../../publisher/editor/controls/ColorControl';

const StandardColorField = ({
  control,
  fieldName,
  field,
  readOnly,
}:StandardFieldProps) => (
  <Controller
    name={fieldName}
    control={control}
    render={({
      field: {
        onChange,
        // onBlur,
        value,
        // name,
        // ref,
      },
      fieldState: {
        // invalid,
        isTouched,
        // isDirty,
        error,
      },
    }) => (
      <FormControl
        sx={{ width: '100%' }}
      >
        <FormControlLabel
          label={field.label || 'No Label'}
          disabled={field.readOnly || readOnly}
          labelPlacement="top"
          control={(
            <ColorControl
              label="Color"
              value={value}
              onChange={onChange}
              disabled={false}
              showGlobalColors={!fieldName.startsWith('global')}
            />
          )}
        />
        <HelperText
          name={fieldName}
          error={isTouched && error?.message}
          description={field.description}
        />
      </FormControl>
    )}
  />
);

StandardColorField.defaultProps = {
  description: undefined,
};

export default StandardColorField;
