import React from 'react';
import { observer } from 'mobx-react-lite';
import {
  NavLink as RouterLink,
  matchPath,
  useLocation,
} from 'react-router-dom';
import {
  Collapse, IconButton,
  List, ListItem,
  ListItemIcon, ListItemText,
  SvgIcon,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { INavItem } from '@mcn-platform/models';

type NavListItemProps = {
  item: INavItem,
  depth: number,
};
type WrapperProps = {
  item: INavItem,
  active: boolean,
  depth: number,
  children: any,
};

type ExpanderProps = {
  item: INavItem,
  doToggle?: boolean,
};

const Expander = observer(({ item, doToggle = true }:ExpanderProps) => {
  if (item.children && item.children.length > 0) {
    return (
      <IconButton
        id={`admin-nav-${item.id}-toggle`}
        edge="end"
        aria-label="Expand/Collapse"
        onClick={(event) => {
          event.preventDefault();
          if (doToggle) {
            item.toggle();
          }
        }}
        disableFocusRipple
        sx={{
          borderRadius: 1,
          '.MuiTouchRipple-ripple .MuiTouchRipple-child': {
            borderRadius: 1,
            backgroundColor: 'primary.main',
          },
        }}
      >
        {item.expanded ? <ExpandLess /> : <ExpandMore />}
      </IconButton>
    );
  }
  return null;
});

const ListItemWrapper = observer(({
  item, depth, active, children,
}:WrapperProps) => {
  if (item.path) {
    return (
      <ListItem
        component={RouterLink}
        to={item.path}
        selected={active}
        sx={{ pl: depth * 4, pr: 1, my: 0.5 }}
        secondaryAction={<Expander item={item} />}
      >
        {children}
      </ListItem>
    );
  }

  return (
    <ListItem
      onClick={() => { item.toggle(); }}
      secondaryAction={<Expander item={item} doToggle={false} />}
      sx={{ pl: depth * 4, pr: 1, my: 0.5 }}
    >
      {children}
    </ListItem>
  );
});

const NavListItem = ({ item, depth }: NavListItemProps) => {
  const location = useLocation();
  const active = item.path ? !!matchPath({
    path: item.path,
    end: false,
  }, location.pathname) : false;
  return (
    <>
      <ListItemWrapper item={item} active={active} depth={depth}>
        <ListItemIcon sx={{ minWidth: 38 }}>
          {item.icon && (
            <SvgIcon color={active ? 'primary' : 'action'}>
              {item.icon}
            </SvgIcon>
          )}
        </ListItemIcon>
        <ListItemText
          primary={item.name}
          primaryTypographyProps={{
            sx: { typography: 'h6', color: active ? 'primary.main' : 'text.secondary' },
          }}
        />
      </ListItemWrapper>
      <Collapse in={item.expanded} timeout="auto" unmountOnExit>
        <List component="div" disablePadding dense>
          {item.children && item.children.map((subItem:INavItem) => (
            <NavListItem
              key={subItem.id}
              item={subItem}
              depth={depth + 1}
            />
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default observer(NavListItem);
