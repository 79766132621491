import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import {
  Box,
  Stack,
  Divider,
  Drawer,
  // SwipeableDrawer,
  List,
  IconButton,
  SvgIcon,
  Button,
} from '@mui/material';
import { styled, Theme } from '@mui/material/styles';
import { getSnapshot } from 'mobx-state-tree';
import {
  INavItem, IOrganization, IPortal,
  navStore, useStore,
} from '@mcn-platform/models';
import CustomSelect from 'components/CustomSelect';
import LogoSVG from 'icons/LogoSVG';
import ArrowLeftIcon from 'icons/ArrowLeftIcon';
import BankIcon from 'icons/BankIcon';
import NavListItem from './NavListItem';
import AdminAvatar from './AdminAvatar';

export const viewPortal = (
  organization: IOrganization | undefined,
  portal: IPortal | undefined,
  newWindow = true,
) => {
  console.log('Open portal', portal);
  if (organization && portal) {
    window.open(`/portal/${organization.id}/${portal.id}/home`, newWindow ? '_blank' : '_self');
  }
};

const StyledDivider = styled(Divider)(({ theme }: { theme: Theme }) => ({
  borderColor: theme.palette.divider,
  borderBottomWidth: 1,
  margin: '4px 0',
}));

// type PortalOptionProp = {
//   label: string,
//   value: number,
// };

type AdminSideBarProps = {
  drawerWidth?: number;
  overlay: boolean; // if true sites over top of the content - good for smaller screens
  sidebarOpen?: any;
  setSidebarOpen: (state: boolean) => void;
};

const checkHideRules = (
  item:INavItem,
  organization: IOrganization,
  user: any,
  isSuperUser: boolean,
) => {
  if (!item.hide || !organization) {
    return false;
  }
  const hide:any = getSnapshot(item.hide);
  // console.log('check hide rules', hide);
  if (hide.organization) {
    const orgRules = Object.keys(hide.organization).every((key) => (
      organization[key] === hide.organization[key]
    ));
    if (orgRules) {
      return true;
    }
  }

  if (hide.user) {
    const userRules = Object.keys(hide.user).every((key) => (
      user[key] === hide.user[key]
    ));
    if (userRules) {
      return true;
    }
  }

  if (hide.isSuperUser === false && !isSuperUser) {
    return true;
  }
  return false;
};

const AdminSideBar = ({
  drawerWidth, overlay, sidebarOpen, setSidebarOpen,
}:AdminSideBarProps) => {
  // const location = useLocation();
  const store = useStore();
  const { currentOrganization, currentPortal } = useStore();
  const availablePortals = store.getCachedList('portal').getItems();
  // console.log('available portals', availablePortals);
  // closes sidebar after navigation
  // useEffect(() => {
  //   if (sidebarOpen && setSidebarOpen) {
  //     setSidebarOpen(false);
  //   }
  // }, [location.pathname]);

  const setCurrentPortal = (portal:IPortal) => {
    store.setCurrentPortal(portal);
  };

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Stack direction="row" justifyContent="space-between" py={3.5} pl={5} pr={2.5}>
        <RouterLink to="/">
          <LogoSVG />
        </RouterLink>
        <IconButton
          sx={{
            width: 24,
            height: 24,
            my: 'auto',
            borderRadius: 0.75,
            color: 'secondary', // for arrow icon
            backgroundColor: (theme) => theme.palette.primary.light,
          }}
          onClick={() => setSidebarOpen(!sidebarOpen)}
        >
          <ArrowLeftIcon />
        </IconButton>
      </Stack>
      <Box px={2.5} pt={0.25}>
        <CustomSelect
          option={currentPortal}
          options={availablePortals}
          setOption={setCurrentPortal}
          startIcon={<SvgIcon color="primary"><BankIcon /></SvgIcon>}
          iconStyle={{ color: (theme: any) => theme.palette.primary }}
          customStyle={{
            width: '100%',
            color: (theme:any) => theme.palette.primary.main,
            '& .MuiSvgIcon-root': {
              color: (theme:any) => theme.palette.primary.main,
            },
            borderWidth: '1px',
            typography: 'h6',
          }}
          id="select-portal"
        />
      </Box>
      <Box sx={{ width: '100%' }}>
        <Button
          fullWidth
          variant="text"
          size="small"
          onClick={() => { viewPortal(currentOrganization, currentPortal, true); }}
          id="view-portal"
        >
          View
        </Button>
      </Box>
      <StyledDivider />
      <Box sx={{ px: 2.5 }}>
        <List component="nav">
          {navStore.items.map((item:INavItem) => {
            if (!(checkHideRules(
              item,
              currentOrganization,
              store.currentUser,
              store.isSuperUser,
            ))) {
              return (
                <Box key={item.id}>
                  {item.dividerBefore && <StyledDivider sx={{ mx: -2.5 }} />}
                  <NavListItem item={item} depth={0.565} />
                  {item.dividerAfter && <StyledDivider sx={{ mx: -2.5 }} />}
                </Box>
              );
            }
            // user doesnt have permission for this
            return null;
          })}
        </List>
      </Box>
      <AdminAvatar />
    </Box>
  );

  return (
    <Drawer
      anchor="left"
      open={sidebarOpen}
      variant={overlay ? 'temporary' : 'persistent'}
      sx={{
        width: drawerWidth,
        border: 'none',
        '& .MuiDrawer-paper': {
          width: drawerWidth,
        },
      }}
    >
      {content}
    </Drawer>
  );
};

AdminSideBar.defaultProps = {
  drawerWidth: 320,
  sidebarOpen: true,
};

export default observer(AdminSideBar);
