import {
  Box,
  Grid,
  Tab,
  Tabs,
} from '@mui/material';
import React, { useState } from 'react';

/**
 * Helper component for doing tabs
 */
type TabDefinition = {
  key: string;
  label: string;
  content: React.ReactElement;
};

interface TabPanelProps {
  children: React.ReactNode;
  index: number;
  value: number;
}

const a11yProps = (index: number) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

const TabPanel = (props: TabPanelProps) => {
  const {
    children,
    value,
    index,
    ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {value === index && (
        <Box sx={{ height: '100%', overflow: 'auto' }}>
          { children }
        </Box>
      )}
    </div>
  );
};

const VerticalTabs = ({ tabs }: { tabs: TabDefinition[] }) => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Grid container sx={{ height: '100%' }}>
      <Grid item xs={12} md={3} sx={{ height: '100%', overflow: 'hidden', pr: 2 }}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={tabValue}
          onChange={handleTabChange}
          aria-label="vertical tabs"
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
          {tabs.map((tab: TabDefinition, index: number) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <Tab
              key={tab.key}
              label={tab.label}
              value={index}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </Grid>
      <Grid item xs={12} md={9} sx={{ height: '100%', overflow: 'hidden', pr: 2 }}>
        {tabs.map((tab: TabDefinition, index: number) => (
          <TabPanel key={tab.key} value={tabValue} index={index}>
            {tab.content}
          </TabPanel>
        ))}
      </Grid>
    </Grid>
  );
};

export default VerticalTabs;
