import React from 'react';
import { Box, Collapse } from '@mui/material';
import { useDrop } from 'react-dnd';

const getStyle = (canDrop:boolean, isOver:boolean) => {
  if (!canDrop) {
    return {
      borderColor: 'red',
      backgroundColor: 'red',
      opacity: '.5',
    };
  }
  if (isOver) {
    return {
      borderColor: 'green',
      backgroundColor: 'green',
      opacity: '.5',
    };
  }
  return {
    backgroundColor: 'white',
    borderColor: '#aaa',
  };
};

const DropTarget = ({
  index,
  handleDrop,
  dndType,
}:{
  index: number,
  handleDrop: any,
  dndType: string,
}) => {
  const [{ canDrop, isOver }, dropRef] = useDrop({
    accept: [dndType],
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      };
    },
    drop(item: any) {
      handleDrop(item, index);
    },
    canDrop() {
      return true;
    },
  });

  // timeout on collapse doesnt work with absolute positioning
  // zIndex is based on depth so deeper nestings appear over top of the shallow ones
  // making them easier to select and it looks better
  return (
    <Collapse in={canDrop} ref={dropRef} timeout={10}>
      <Box sx={{ position: 'relative' }}>
        <Box sx={{
          position: 'absolute',
          width: '100%',
          top: -9,
          height: 12,
          border: 1,
          borderRadius: 1,
          borderStyle: 'dashed',
          ...getStyle(canDrop, isOver),
        }}
        />
      </Box>
    </Collapse>
  );
};

export default DropTarget;
