import React from 'react';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { StandardFieldProps } from './FieldTypes';
import HelperText from './HelperText';
import ColorControl from '../../publisher/editor/controls/ColorControl';

const ColorItem = ({
  columns,
  label,
  color,
  onChange,
  showGlobalColors,
  rounded,
}:{
  columns: number,
  label: string,
  color: string,
  onChange: (newColor:string) => void,
  showGlobalColors: boolean,
  rounded: 'none' | 'all' | 'left' | 'right',
}) => (
  <Grid container item xs={columns} direction="column">
    <Grid item>
      <FormLabel>
        {label}
      </FormLabel>
    </Grid>
    <Grid flexGrow={1}>
      <ColorControl
        label="Color"
        value={color}
        onChange={onChange}
        disabled={false}
        showGlobalColors={showGlobalColors}
        rounded={rounded}
      />
    </Grid>
  </Grid>
);
/**
 * Handle Color Sets (normal, hover, disabled) - usually for buttons
 * The keys are fieldName + .color, .hover.color and .disabled.color
 * @param param
 * @returns
 */
const StandardColorSetField = ({
  control,
  fieldName,
  field,
  readOnly,
}:StandardFieldProps) => (
  <Controller
    name={`${fieldName.replace('.colorSet', '')}`}
    control={control}
    render={({
      field: {
        onChange,
        // onBlur,
        value,
        // name,
        // ref,
      },
      fieldState: {
        // invalid,
        isTouched,
        // isDirty,
        error,
      },
    }) => (
      <FormControl
        sx={{ width: 1 }}
      >
        <FormControlLabel
          label={field.label || 'No Label'}
          disabled={field.readOnly || readOnly}
          labelPlacement="top"
          // sx={{ width: '100%' }}
          control={(
            <Grid
              container
              spacing={0}
              padding={1}
            >
              <ColorItem
                columns={6}
                label="Default"
                color={value?.color}
                onChange={(newValue) => onChange({ ...value, color: newValue })}
                showGlobalColors={!fieldName.startsWith('global')}
                rounded="left"
              />
              <ColorItem
                columns={3}
                label="Hover"
                color={value?.hover?.color}
                onChange={(newValue) => onChange({
                  ...value,
                  hover: { ...value?.hover, color: newValue },
                })}
                showGlobalColors={!fieldName.startsWith('global')}
                rounded="none"
              />
              <ColorItem
                columns={3}
                label="Disabled"
                color={value?.disabled?.color}
                onChange={(newValue) => onChange({
                  ...value,
                  disabled: { ...value?.disabled, color: newValue },
                })}
                showGlobalColors={!fieldName.startsWith('global')}
                rounded="right"
              />
            </Grid>
          )}
        />
        <HelperText
          name={fieldName}
          error={isTouched && error?.message}
          description={field.description}
        />
      </FormControl>
    )}
  />
);

StandardColorSetField.defaultProps = {
  description: undefined,
};

export default StandardColorSetField;
