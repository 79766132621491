import React from 'react';
import { observer } from 'mobx-react-lite';

import ItemEdit from 'components/item/ItemEdit';
import { ILearningBoardSection } from '@mcn-platform/models';
import { Box, Typography } from '@mui/material';

const fields = [
  'category', 'name',
];

const updateSection = (section:ILearningBoardSection, formData:any) => {
  console.log('Update section', formData);
  formData.name = formData.category.name;
  section.updateFields(fields, formData);
};

type SectionDetailProps = {
  section: ILearningBoardSection;
};

const CategoryDetail = ({ section }:SectionDetailProps) => {
  console.log('Category DETAIL: ', section);
  return (
    <Box>
      <Typography variant="h4">
        Category:
        {' '}
        {section.name}
      </Typography>
      <ItemEdit
        key={section.id}
        item={section}
        modelName="learningBoardSection"
        handleSubmit={(newValues) => updateSection(section, newValues)}
        formConfig={fields}
        hideTitle
        autoSave
      />
    </Box>
  );
};

export default observer(CategoryDetail);
