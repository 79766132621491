/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
} from '@mui/material';

import Logo from './Logo';

const MainNavbar = () => (
  <AppBar>
    <Toolbar sx={{ height: 64, backgroundColor: '#ffffff' }}>
      <RouterLink to="/">
        <Logo />
      </RouterLink>
    </Toolbar>
  </AppBar>
);

export default MainNavbar;
