import React, { useEffect, useState } from 'react';
import {
  DataGrid,
  GridColumns,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import {
  Box,
  LinearProgress,
  Typography,
  Button,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { ICachedList, navStore, useStore } from '@mcn-platform/models';
import ItemNameFormatter from 'components/reporting/ItemNameFormatter';
import QuizResultDialog from './QuizResultDialog';

export type CustomerTrackingItems = {
  progressList : ICachedList | undefined;
  isCertification?: Boolean;
  customer: any,
};

const LinearProgressWithLabel = ({ value }: { value:number }) => (
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <Box sx={{ width: '100%', mr: 1 }}>
      <LinearProgress variant="determinate" sx={{ height: 12, width: 32 }} value={value} />
    </Box>
    <Box sx={{ minWidth: 35 }}>
      <Typography variant="body2" color="text.primary">
        {`${Math.round(
          value,
        )}%`}
      </Typography>
    </Box>
  </Box>
);

const QuizResultCell = ({ item, handleItemSelected }:any) => {
  if (item?.quizResults && Object.keys(item.quizResults).length > 0) {
    return (
      <Button
        onClick={() => handleItemSelected(item)}
        variant="outlined"
        size="small"
        color="primary"
        sx={{
          paddingY: '2px',
          height: '32px',
          width: '48px',
          minWidth: '32px',
        }}
      >
        {Object.keys(item.quizResults).length}
      </Button>
    );
  }
  return null;
};

const CustomerTrackingData = ({
  customer,
  progressList,
  isCertification,
}:CustomerTrackingItems) => {
  const [quizDialogOpen, setQuizDialogOpen] = useState<boolean>(false);
  const [item, setItem] = useState<any>();
  const [items, setItems] = useState<any[]>([]);
  const { getSignedCertificateUrl } = useStore();
  console.log('rendering CustomerTrackingData', items);
  useEffect(() => {
    if (progressList?.syncState === 'loaded') {
      setItems(progressList?.items);
    } else {
      setItems([]);
    }
  }, [progressList, progressList?.syncState]);

  const DateFormatter = ({ value } : any) => (value && value !== '' ? new Date(value).toDateString() : '');
  const handleClose = () => {
    setQuizDialogOpen(false);
  };

  const handleQuizSelected = (newItem: any) => {
    console.log('Viewing quizResults', newItem.quizResults);
    setItem(newItem);
    setQuizDialogOpen(true);
  };

  const downloadCertificate = async (progress:any) => {
    // get the link and then open in a new window
    const certificate = progress.certificates[0];
    console.log('Download', certificate);
    try {
      navStore.showBlockInput();
      const url = await getSignedCertificateUrl(certificate.id);
      window.open(url, '_blank');
    } catch (exception: any) {
      console.log('error downloading certificate', exception);
      navStore.showConfirmDialog(
        'Error downloading certificate',
        'There was an error generating and downloading the certicate.',
        null,
        'OK',
        null,
      );
    } finally {
      navStore.clearBlockInput();
    }
  };

  const columns: GridColumns = [
    {
      field: 'type',
      headerName: 'Type',
      width: 150,
      valueGetter: ({ row }) => {
        if (row.program) {
          return 'Program';
        }
        if (row.microcast) {
          return 'Microcast';
        }
        if (row.course) {
          return 'Course';
        }
        return null;
      },
    },
    {
      field: 'name',
      headerName: 'Item Name',
      width: 250,
      renderCell: (params:GridRenderCellParams) => (
        <ItemNameFormatter item={params.row} />
      ),
    },
    {
      field: 'startedDate',
      headerName: 'Date Started',
      width: 150,
      valueFormatter: (DateFormatter),
    },
    {
      field: 'lastActiveDate',
      headerName: 'Last Active Date',
      width: 150,
      valueFormatter: (DateFormatter),
    },
    {
      field: 'completeDate',
      headerName: 'Date Completed',
      width: 200,
      valueFormatter: (DateFormatter),
    },
    {
      field: 'progress',
      headerName: 'Progress',
      renderCell: (params) => (
        <LinearProgressWithLabel
          value={typeof params.value === 'number' ? params.value : 0}
        />
      ),
    },
    {
      field: 'quizResults',
      headerName: 'Quiz Results',
      renderCell: (params:GridRenderCellParams) => (
        <QuizResultCell item={params.row} handleItemSelected={handleQuizSelected} />
      ),
    },
  ];
  if (isCertification) {
    columns.push({
      field: 'certificateScore',
      headerName: 'Score',
      width: 150,
      // valueGetter: ({ row }) => {
      //   const { certificates } = row;
      //   return certificates.length > 0 ? 'YES' : 'NO';
      // },
    });
    columns.push({
      field: 'certification',
      headerName: 'Certificate Earned',
      width: 150,
      valueGetter: ({ row }) => {
        const { certificates } = row;
        return certificates.length > 0 ? 'YES' : 'NO';
      },
    });
    columns.push({
      field: 'download',
      headerName: 'Download',
      width: 150,
      // valueGetter: ({ row }) => {
      //   const { certificates } = row;
      //   return certificates.length >= 1
      //   ? (
      //     <Button
      //       color="error"
      //       variant="outlined"
      //       size="small"
      //       sx={{ fontSize: '10px' }}
      //       key="download"
      //       onClick={() => {
      //         console.log('Download TO DO', row.certificates ? row.certificates[0] : '');
      //       }}
      //     >
      //       DOWNLOAD
      //     </Button>
      //   ) : '-';
      // },
      renderCell: (params) => {
        const { certificates } = params.row;
        if (certificates.length > 0) {
          return (
            <Button
              color="error"
              variant="outlined"
              size="small"
              sx={{ fontSize: '10px' }}
              key="download"
              onClick={() => {
                console.log('Download', params);
                downloadCertificate(params.row);
               }}
            >
              DOWNLOAD
            </Button>
          );
        }
        return null;
      },
    });
  }
  return (
    <Box>
      <DataGrid
        autoHeight
        pageSize={25}
        rows={items}
        columns={columns}
        editMode="row"
        hideFooterSelectedRowCount
      />
      <QuizResultDialog
        open={quizDialogOpen}
        item={item}
        customer={customer}
        handleClose={handleClose}
      />
    </Box>
  );
};

CustomerTrackingData.defaultProps = {
  isCertification: false,
};

export default observer(CustomerTrackingData);
