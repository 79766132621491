import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box } from '@mui/material';
import CertificationsProgressFilters from './CertificationsProgressFilters';
import CertificationProgressTable from './CertificationProgressTable';

const CertificationProgressReport = () => {
  const [allItems, setAllItems] = useState<boolean>(true);
  const [courseFilter, setCourseFilter] = useState<string[]>([]);
  const [microcastFilter, setMicrocastFilter] = useState<string[]>([]);
  const [programFilter, setProgramFilter] = useState<string[]>([]);
  const [allCertifications, setAllCertifications] = useState<boolean>(true);
  const [certificationFilter, setCertificationFilter] = useState<string[]>([]);
  const [complianceStatus, setComplianceStatus] = useState<string>('all');
  const [accessedAfter, setAccessedAfter] = useState<any>(null);
  const [accessedBefore, setAccessedBefore] = useState<any>(null);
  const [completedAfter, setCompletedAfter] = useState<any>(null);
  const [completedBefore, setCompletedBefore] = useState<any>(null);
  const [lastActiveAfter, setLastActiveAfter] = useState<any>(null);
  const [lastActiveBefore, setLastActiveBefore] = useState<any>(null);
  const [progressMin, setProgressMin] = useState<number>(0);
  const [progressMax, setProgressMax] = useState<number>(100);
  const [sortField, setSortField] = useState<string>('lastActiveDate');
  const [sortDirection, setSortDirection] = useState<string>('desc');

  return (
    <Box>
      <Helmet>
        <title>
          Compliance Report
        </title>
      </Helmet>
      <CertificationsProgressFilters
        allItems={allItems}
        setAllItems={setAllItems}
        courseFilter={courseFilter}
        setCourseFilter={setCourseFilter}
        microcastFilter={microcastFilter}
        setMicrocastFilter={setMicrocastFilter}
        programFilter={programFilter}
        setProgramFilter={setProgramFilter}
        allCertifications={allCertifications}
        setAllCertifications={setAllCertifications}
        certificationFilter={certificationFilter}
        setCertificationFilter={setCertificationFilter}
        complianceStatus={complianceStatus}
        setComplianceStatus={setComplianceStatus}
        accessedAfter={accessedAfter}
        setAccessedAfter={setAccessedAfter}
        accessedBefore={accessedBefore}
        setAccessedBefore={setAccessedBefore}
        completedAfter={completedAfter}
        setCompletedAfter={setCompletedAfter}
        completedBefore={completedBefore}
        setCompletedBefore={setCompletedBefore}
        lastActiveAfter={lastActiveAfter}
        setLastActiveAfter={setLastActiveAfter}
        lastActiveBefore={lastActiveBefore}
        setLastActiveBefore={setLastActiveBefore}
        progressMin={progressMin}
        setProgressMin={setProgressMin}
        progressMax={progressMax}
        setProgressMax={setProgressMax}
        sortField={sortField}
        setSortField={setSortField}
        sortDirection={sortDirection}
        setSortDirection={setSortDirection}
      />
      <CertificationProgressTable
        allItems={allItems}
        courseFilter={courseFilter}
        microcastFilter={microcastFilter}
        programFilter={programFilter}
        allCertifications={allCertifications}
        certificationFilter={certificationFilter}
        complianceStatus={complianceStatus}
        progressMin={progressMin}
        progressMax={progressMax}
        startedAfter={accessedAfter}
        startedBefore={accessedBefore}
        completedAfter={completedAfter}
        completedBefore={completedBefore}
        lastActiveAfter={lastActiveAfter}
        lastActiveBefore={lastActiveBefore}
        sortField={sortField}
        sortDirection={sortDirection}
      />
    </Box>
  );
};

export default CertificationProgressReport;
