import React from 'react';
import { Box } from '@mui/material';
import CertificationProgressReport from 'components/reporting/compliance/CertificationProgressReport';

const Compliance = () => (
  <Box>
    <CertificationProgressReport />
  </Box>
);

export default Compliance;
