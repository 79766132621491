import { Stack } from '@mui/material';
import React, { ReactNode } from 'react';

const ButtonStack = ({ children }:{ children: ReactNode }) => (
  <Stack
    direction="row"
    spacing={2}
    justifyContent="right"
    alignItems="center"
    sx={{
      padding: 1,
      textAlign: 'center',
      height: '64px',
      boxShadow: '0 -5px 3px -3px #00000030',
    }}
  >
    {children}
  </Stack>
);

export default ButtonStack;
