import React from 'react';
import { GlobalStyles } from '@mui/material';
import '@fontsource/montserrat';

const AdminGlobalStyles = () => (
  <GlobalStyles styles={{
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      webkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
      height: '100%',
      width: '100%',
    },
    body: {
      margin: 0,
      backgroundColor: '#ffffff', // '#f4f6f8',
      height: '100%',
      width: '100%',
      fontFamily: 'Montserrat, "-apple-system", BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", Helvetica Neue, "sans-serif"',
    },
    a: {
      textDecoration: 'none',
    },
    '#root': {
      height: '100%',
      width: '100%',
    },
    code: {
      fontFamily: 'source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace',
    },
    '.fullHeight': {
      height: '100%',
    },
  }}
  />
);

export default AdminGlobalStyles;
