/* eslint-disable @typescript-eslint/dot-notation */
import React from 'react';
import { observer } from 'mobx-react-lite';
import {
  FormControlLabel,
  TextField,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { StandardFieldProps } from './FieldTypes';
import HelperText from './HelperText';

interface CustomProps {
  onChange: any; // (event: { target: { value: string } }) => void;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  (props, ref) => {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...other}
        getInputRef={ref}
        onValueChange={(values) => onChange(values.value)}
        thousandSeparator
        valueIsNumericString
        prefix="$"
      />
    );
  },
);

/**
 * This field takes a currency amount represented as a string
 * in dollars and cents (1234.56) and presents it to the user
 * with dollars and cents with the comma 1,234.56.
 * The value is saved without the comma so it can be parsed as a float
 * as needed.
 *
 * Spaces or empty strings will be stored as null.
 *
 * Only numbers, comma and period or accepted into the field.
 *
 * @param
 * @returns
 */
const StandardCurrencyField = (
  {
    control,
    fieldName,
    field,
    readOnly,
  }:StandardFieldProps,
) => (
  <Controller
    name={fieldName}
    control={control}
    render={({
      field: {
        onChange,
        onBlur,
        value,
        // name,
        // ref,
      },
      fieldState: {
        // invalid,
        isTouched,
        // isDirty,
        error,
      },
    }) => (
      <>
        <FormControlLabel
          label={field.label}
          disabled={field.readOnly || readOnly}
          labelPlacement="top"
          sx={{ alignItems: 'flex-start' }}
          control={(
            <TextField
              id={fieldName}
              name={fieldName}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              disabled={field.readOnly || readOnly}
              error={isTouched && Boolean(error)}
              InputProps={{
                inputComponent: NumericFormatCustom as any,
              }}
            />
          )}
        />
        <HelperText
          name={fieldName}
          error={isTouched && error?.message}
          description={field.description}
        />
      </>
    )}
  />
);

StandardCurrencyField.defaultProps = {
  // placeholder: '',
  description: '',
  disabled: false,
};

export default observer(StandardCurrencyField);
