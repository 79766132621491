/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Grid, Button } from '@mui/material';
import { useStore } from '@mcn-platform/models';
import PaginatedItemList from 'components/item/PaginatedItemList';
import Pagination from 'components/pagination/Pagination';
import { SortType } from '../types';
import { certificationStatusOptions } from './CertificationsProgressFilters';
import CertificateNameFormatter from './CertificateNameFormatter';
import ItemNameFormatter from '../ItemNameFormatter';

const CertificateStatusFormatter = ({ value, item }: any) => {
  const sortEntry = certificationStatusOptions.find((sort:SortType) => (sort.id === value));
  return sortEntry?.label || '-';
};

const CertificationProgressTable = ({
  allItems,
  courseFilter,
  microcastFilter,
  programFilter,
  allCertifications,
  certificationFilter,
  complianceStatus,
  progressMin,
  progressMax,
  startedAfter,
  startedBefore,
  completedAfter,
  completedBefore,
  lastActiveAfter,
  lastActiveBefore,
  sortField,
  sortDirection,
}:any) => {
  const store = useStore();
  const [page, setPage] = useState<number>(0);
  const itemsPerPage = 20;

  useEffect(
    () => {
      setPage(0);
    },
    [
      allItems,
      courseFilter,
      microcastFilter,
      programFilter,
      allCertifications,
      certificationFilter,
      complianceStatus,
      progressMin,
      progressMax,
      startedAfter,
      startedBefore,
      completedAfter,
      completedBefore,
      lastActiveAfter,
      lastActiveBefore,
    ],
  );
  const combinedFilter:{
    and: any[]
  } = {
    and: [],
  };
  combinedFilter.and.push({
    hasCertification: {
      eq: true,
    },
  });
  if (progressMin > 0 || progressMax < 100) {
    const progress:any = {};
    if (progressMin > 0) {
      progress.gt = progressMin;
    }
    if (progressMax < 100) {
      progress.lt = progressMax;
    }
    combinedFilter.and.push({
      progress,
    });
  }
  if (!allItems && (
    courseFilter?.length > 0 || microcastFilter?.length > 0 || programFilter?.length > 0
  )) {
    const itemFilter:any = { or: [] };
    courseFilter?.forEach((id:string) => {
      itemFilter.or.push({ courseID: { matchPhrase: id } });
    });
    microcastFilter?.forEach((id:string) => {
      itemFilter.or.push({ microcastID: { matchPhrase: id } });
    });
    programFilter?.forEach((id:string) => {
      itemFilter.or.push({ programID: { matchPhrase: id } });
    });
    combinedFilter.and.push(
      itemFilter,
    );
  }
  if (!allCertifications && (
    certificationFilter?.length > 0
  )) {
    const certFilter:any = { or: [] };
    certificationFilter?.forEach((id:string) => {
      certFilter.or.push({ certifications: { matchPhrase: id } });
    });
    combinedFilter.and.push(
      certFilter,
    );
  }
  if (!allItems && (
    complianceStatus !== 'all'
  )) {
    const itemFilter:any = { or: [] };
    itemFilter.or.push({ certificateStatus: { matchPhrase: complianceStatus } });
    combinedFilter.and.push(
      itemFilter,
    );
  }
  if (startedAfter || startedBefore) {
    const startedDate:any = {};
    if (startedAfter) {
      startedDate.gt = startedAfter.toJSON();
    }
    if (startedBefore) {
      startedDate.lt = startedBefore.toJSON();
    }
    combinedFilter.and.push({
      startedDate,
    });
  }
  if (completedAfter || completedBefore) {
    const completeDate:any = {};
    if (completedAfter) {
      completeDate.gt = completedAfter.toJSON();
    }
    if (completedBefore) {
      completeDate.lt = completedBefore.toJSON();
    }
    combinedFilter.and.push({
      completeDate,
    });
  }
  if (lastActiveAfter || lastActiveBefore) {
    const lastActiveDate:any = {};
    if (lastActiveAfter) {
      lastActiveDate.gt = lastActiveAfter.toJSON();
    }
    if (lastActiveBefore) {
      lastActiveDate.lt = lastActiveBefore.toJSON();
    }
    combinedFilter.and.push({
      lastActiveDate,
    });
  }

  const itemProgressList = store.getCachedList(
    'itemProgress',
    combinedFilter.and.length > 0 ? combinedFilter : undefined,
    {
      field: sortField,
      direction: sortDirection,
    },
  );
  const hasMorePages = itemProgressList && itemProgressList.hasMorePages(itemsPerPage, page + 1);
  // console.log(`Has more pages on ${page}`, hasMorePages);

  const handleNext = () => {
    if (hasMorePages) {
      setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Pagination
            currentPage={page + 1}
            hasNext={hasMorePages}
            hasPrevious={page > 0}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
          />
        </Grid>
        <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
          <Button
            color="error"
            variant="outlined"
            size="small"
            sx={{ fontSize: '10px' }}
            key="export"
            onClick={() => { console.log('EXPORT CALLED'); }}
          >
            EXPORT
          </Button>
        </Grid>
      </Grid>
      <PaginatedItemList
        modelName="itemProgress"
        selectedKeys={[]}
        // listStyle="list"
        // updateSelectedItems={setselectedIDs}
        // defaultAction={handleChange}
        cachedList={itemProgressList}
        multiSelect={false}
        showSelect={false}
        enableDeselect={false}
        listStyle="list"
        page={page}
        itemsPerPage={itemsPerPage}
        defaultAction={undefined}
        titleField={{
          name: 'customer.name',
          label: 'Learner Name',
        }}
        secondaryFields={[
          {
            name: 'customer.email',
            label: 'Learner Email',
          },
          {
            name: 'item',
            label: 'Course/Program',
            formatter: ItemNameFormatter,
          },
          {
            name: 'certifications',
            label: 'Certification',
            formatter: CertificateNameFormatter,
          },
          {
            name: 'startedDate',
            label: 'Started Date',
          },
          {
            name: 'lastActiveDate',
            label: 'Assigned / Accessed Date',
          },
          {
            name: 'certificateAwardedAt',
            label: 'Completed Date',
          },
          {
            name: 'certificateStatus',
            label: 'Certification Status',
            formatter: CertificateStatusFormatter,
          },
          {
            name: 'certificateScore',
            label: 'Score',
          },
        ]}
      />
    </Box>
  );
};

export default observer(CertificationProgressTable);
