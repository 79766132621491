import React, { ReactElement } from 'react';
import loadable from '@loadable/component';
import { v4 } from 'uuid';
import { Navigate } from 'react-router-dom';
import {
  Box,
  Chip,
  Stack,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { ErrorBoundary } from 'react-error-boundary';

import {
  IOrganization, IPortal,
  adminStore, useStore, navStore, Schema,
} from '@mcn-platform/models';
import {
  capitalizeFirstLetter,
  ClassPage,
  CourseListPage,
  getTimeZone,
  InstructorPage,
  MicrocastPage,
  PortalLoadGuard,
  PortalRouteMapper,
  PortalFontLoader,
} from '@mcn-platform/portal-lib'; // todo - lazy load
import AdminLayout from 'components/adminLayout/AdminLayout';
import AssetEditor from 'pages/AssetEditor';
import Compliance from 'pages/Compliance';
import CustomerEditor from 'pages/CustomerEditor';
import DiscussionEditor from 'pages/DiscussionEditor';
import DocumentEditor from 'pages/DocumentEditor';
import EmailTemplateEditor from 'pages/EmailTemplateEditor';
import EngagementFlowEditor from 'pages/EngagementFlowEditor';
import GeneralError from 'pages/GeneralError';
import ItemPage from 'pages/ItemPage';
import KickerEditor from 'pages/KickerEditor';
import LeadEditor from 'pages/LeadEditor';
import MainLayout from 'components/MainLayout';
import MemberEditor from 'pages/MemberEditor';
import MicrocastEditor from 'pages/MicrocastEditor';
import { observer } from 'mobx-react-lite';
import OrganizationEditor from 'pages/OrganizationEditor';
import PortalCss from 'publisher/PortalCss';
import PortalTokens from 'pages/PortalTokens';
import RepositoryEditor from 'pages/RepositoryEditor';
import UserEditor from 'pages/UserEditor';
import { viewPortal } from 'components/adminLayout/AdminSideBar';

const Account = loadable(() => import('pages/Account'));
// const ItemPage = loadable(() => import('pages/ItemPage'));
const CertificationEditor = loadable(() => import('pages/CertificationEditor'));
const CourseEditor = loadable(() => import('pages/CourseEditor'));
const EnrollCustomers = loadable(() => import('pages/EnrollCustomers'));
const HubEditor = loadable(() => import('pages/HubEditor'));
const InstructorEditor = loadable(() => import('pages/InstructorEditor'));
const ItemEdit = loadable(() => import('components/item/ItemEdit'));
const LibraryEditor = loadable(() => import('pages/LibraryEditor'));
const LibraryShareEditor = loadable(() => import('pages/LibraryShareEditor'));
const LibraryShareViewer = loadable(() => import('pages/LibraryShareViewer'));
const MeetingEditor = loadable(() => import('pages/MeetingEditor'));
const MembershipEditor = loadable(() => import('pages/MembershipEditor'));
// const MyFiles = loadable(() => import('pages/MyFiles'));
const NotFound = loadable(() => import('pages/NotFound'));
const ProgramEditor = loadable(() => import('pages/ProgramEditor'));
const PortalCategories = loadable(() => import('pages/PortalCategories'));
const PortalDetails = loadable(() => import('pages/PortalDetails'));
const PortalStylesheet = loadable(() => import('pages/PortalStylesheet'));
// const ComponentViewer = loadable(() => import('publisher/editor/ComponentViewer'));
const OrganizationSummary = loadable(() => import('pages/OrganizationSummary'));
const PlaceholderPage = loadable(() => import('pages/PlaceholderPage'));
const PortalEditor = loadable(() => import('publisher/editor/PortalEditor'));
const QuestionEditor = loadable(() => import('pages/QuestionEditor'));
const QuestionnaireEditor = loadable(() => import('pages/QuestionnaireEditor'));
const Reporting = loadable(() => import('pages/Reporting'));
const ScormPlayer = loadable(() => import('pages/ScormPlayer'));
const Settings = loadable(() => import('pages/Settings'));
const UserPage = loadable(() => import('pages/UserPage'));

const ErrorFallback = () => {
  navStore.errorDialog.showError('', 'An Error occurred. Please reload the page and try again.');
  return null;
};

export const editModel = (editorPath:string, queryString?:string) => (({ item }: any) => {
  console.log('Going to', item);
  const key = item?.key || 'new';
  const qs = queryString ? `?${queryString}` : '';
  return (
    <Navigate to={`../${editorPath}/${key}${qs}`} />
  );
});

const EditPortal = ({ item, setOpen }: any) => {
  console.log(`Going to portal ${item}`);

  const store = useStore();

  if (!item.id) {
    // its a new item
    return (
      <ItemEdit
        modelName="portal"
        item={item}
        formConfig={['name', 'publicName', 'description', 'type']}
        handleSubmit={(newValues: any) => {
          console.log('New values', newValues);
          store.add('library', {
            name: `${newValues.name} Portal Library`,
            id: v4(),
            organization: store.currentOrganization?.id,
          })
            .then((library) => {
              store.add('portal', {
                ...newValues,
                defaultLibrary: library,
                id: v4(),
                organization: store.currentOrganization?.id,
              });
            })
            .then((portal: IPortal) => {
              console.log('New portal, and library', portal);
            }).then(() => {
              store.clearCachedList('portal');
              setOpen(false);
            });
        }}
      />
    );
  }
  console.log('Edit existing');
  // edit existing portal - note it also makes it the active portal
  store.setCurrentPortal(item);
  return (
    <Navigate to="../details" />
  );
};

// const EditRepository = ({ item, setOpen }: any) => {
//   console.log('Going to repository', item);

//   const store = useStore();

//   return (
//     <ItemEdit
//       modelName="repository"
//       item={item}
//       formConfig={['name', 'description', 'status']}
//       handleSubmit={(newValues: any) => {
//         console.log('New values', newValues);
//         const id = v4();
//         store.add(
//           'repository',
//           {
//             ...newValues,
//             id,
//             bucket: `mcn-repo-${store.amplifyEnvironment}-${id}`,
//             organization: store.currentOrganization?.id,
//           },
//         ).then(() => {
//             store.clearCachedLists();
//             setOpen(false);
//           });
//       }}
//     />
//   );
// };

const clonePortal = (portalToClone: IPortal, navigate: any, store: any) => {
  console.log(`Cloning portal ${portalToClone}`);
  navStore.showConfirmDialog(
    'Clone Portal',
    'Are you sure you want to make a copy of this portal?',
    () => {
      console.log('Im copying', portalToClone);
      const newSnap = portalToClone.clone();
      const newPortal = store.create('portal', null, null, newSnap);
      store.addStub('portal', newPortal).then((result: any) => {
        store.setCurrentPortal(result.id);
        navigate('../details');
      });
    },
    'Make Copy',
    undefined,
    'Cancel',
  );
};

const deployPortal = (portal: IPortal, navigate: any, store: any) => {
  console.log(`Deploy portal ${portal}`);
  navStore.showConfirmDialog(
    'Deploy Portal',
    'Are you sure you want to deploy portal to domain {domain}?',
    async () => {
      console.log('Deploying', portal);
      await store.deployPortal(portal);
    },
    'Deploy Portal',
    undefined,
    'Cancel',
  );
};

const deployOrganization = (organization: IOrganization, navigate: any, store: any) => {
  console.log(`Deploy organization ${organization}`);
  navStore.showConfirmDialog(
    'Deploy Organization',
    `Are you sure you want to deploy organization ${organization.name}?`,
    async () => {
      console.log('Deploying', organization);
      await store.deployOrganization(organization);
    },
    'Deploy Organization',
    undefined,
    'Cancel',
  );
};

const deployRepository = (repository: IPortal, navigate: any, store: any) => {
  console.log(`Deploy repository ${repository}`);
  navStore.showConfirmDialog(
    'Deploy Repository',
    'Are you sure you want to deploy the repository?',
    async () => {
      console.log('Deploying', repository);
      await store.deployRepository(repository);
    },
    'Deploy Repository',
    undefined,
    'Cancel',
  );
};

export const portalRoutes = [
  {
    path: 'portal/:organizationId/:portalId/*',
    element: (
      <PortalCss>
        <PortalLoadGuard>
          <PortalRouteMapper isServerSide={false} />
        </PortalLoadGuard>
      </PortalCss>
    ),
  },
];

/** Returns a function that maps the status values to their labels */
const enumLabeller = (modelName, fieldName) => {
  const field = Schema.getProperty(modelName, fieldName);
  return ({ value }: { value: string }) => {
    const label = field.enumLabels[field.enum.indexOf(value)];
    return (
      <span>
        {label}
      </span>
    );
  };
};

const CustomerMembershipCount = ({ value }: any) => {
  if (!value) {
    return '';
  }
  return (
    <Box>
      {['active', 'trial', 'overdue', 'expired'].map((key) => {
        if (value[key]) {
          return (
            <Typography variant="body1" sx={{ fontSize: '12px' }} key={key}>
              {capitalizeFirstLetter(key)}
              {' '}
              :
              {' '}
              {value[key]}
            </Typography>
          );
        }
        return null;
      })}
    </Box>
  );
};

const CustomerEmailFormatter = ({ value, item }: any) => {
  const store = useStore();
  return (
    <>
      {value}
      {item.id.startsWith('admin') && (
        <Chip
          size="small"
          sx={{ ml: '5px', fontSize: '11px' }}
          label={(store.currentCustomer?.id === item?.id) ? 'Me' : 'Admin'}
        />
      )}
      {' '}
    </>
  );
};

export const DateFormatter = ({ value }: any) => (value ? new Date(value).toDateString() : '-');
export const TimestampFormatter = ({ value }: any) => (
  value
  ? `${dayjs(value).format('lll')} ${getTimeZone()}`
  : 'Not Scheduled'
);

const LearningBoardFormatter = observer(({ value }: any): ReactElement => {
  // console.log(`LearningBoards value ${typeof value}`, value);
  const { currentPortal } = useStore();
  if (!value || value.length === 0) {
    return <span>No Boards</span>;
  }
  const portalBoard = value.find((lb) => (lb?.portal?.id === currentPortal?.id));
  return (
    <span>
      {portalBoard ? 'Yes' : 'Other Portal(s)'}
    </span>
  );
});

const assetAdditionalActions = [
  {
    name: 'process',
    label: 'Process',
    action: ((asset: any) => {
      console.log('Process asset', asset);
      adminStore.processAsset(asset);
    }),
  },
  {
    name: 'toDocument',
    label: 'Create Document',
    action: ((asset: any) => {
      console.log('Create document from asset', asset);
      adminStore.documentFromAsset(asset);
    }),
  },
];
// const LibrariesFormatter = observer(({ value }: any): ReactElement => {
//   if (!value || value.length === 0) {
//     return <span>None</span>;
//   }
//   return value.map((library) => (library.name)).join(', ');
// });

export const adminRoutes = [
  {
    path: 'app',
    element: <AdminLayout />,
    ErrorBoundary: GeneralError,
    children: [
      // side bar nav items //
      // Dashboard
      // { path: 'dashboard', element: <Dashboard /> },

      // Create item
      { path: 'course-editor/:key/clone', element: <CourseEditor clone /> },
      { path: 'course-editor/:key', element: <CourseEditor /> },
      { path: 'microcast-editor/:microcastID', element: <MicrocastEditor /> },
      { path: 'document-editor/:key', element: <DocumentEditor /> },
      { path: 'learning-builder', element: <PlaceholderPage title="Learning Builder" /> },
      { path: 'program-editor/:key', element: <ProgramEditor /> },
      // Microcast
      {
        path: 'microcasts',
        element: <ItemPage
          modelName="microcast"
          EditScreen={editModel('microcast-editor')}
          additionalActions={[
            {
              name: 'view',
              label: 'View',
              action: ((item: any) => {
                window.open(`/microcast/${item.organization.id}/${item.slug || item.id}`, '_blank');
              }),
            },
            {
              name: 'clone',
              label: 'Clone',
              action: ((item: any, navigate: any) => {
                navigate(`../microcast-editor/${item.key}/clone`);
              }),
            },
          ]}
          imageField={{ name: 'microcastImage', label: 'Poster' }}
          secondaryFields={[
          ]}
          filters={['searchText', 'combinedLibrary']}
        />,
      },
      // Courses
      {
        path: 'courses',
        element: <ItemPage
          modelName="course"
          EditScreen={editModel('course-editor')}
          additionalActions={[
            {
              name: 'view',
              label: 'View',
              action: ((course: any) => {
                window.open(`/class/${course.organization.id}/${course.slug || course.key}`, '_blank');
              }),
            },
            {
              name: 'clone',
              label: 'Clone',
              action: ((course: any, navigate: any) => {
                navigate(`../course-editor/${course.key}/clone`);
              }),
            },
          ]}
          imageField={{ name: 'courseImage', label: 'Poster' }}
          secondaryFields={[
          ]}
          filters={['searchText', 'combinedLibrary']}
        />,
      },
      // Programs
      {
        path: 'programs',
        element: <ItemPage
          modelName="program"
          EditScreen={editModel('program-editor')}
          imageField={{ name: 'programImage', label: 'Poster' }}
          secondaryFields={[
          ]}
          filters={['searchText', 'combinedLibrary']}
        />,
      },
      // Documents
      {
        path: 'documents',
        element: <ItemPage
          modelName="document"
          EditScreen={editModel('document-editor')}
          additionalActions={[
            {
              name: 'view',
              label: 'View',
              action: ((item: any) => {
                window.open(`/document/${item.organization.id}/${item.slug || item.id}`, '_blank');
              }),
            },
            {
              name: 'clone',
              label: 'Clone',
              action: ((item: any, navigate: any) => {
                navigate(`../document-editor/${item.key}/clone`);
              }),
            },
          ]}
          imageField={{ name: 'itemImage', label: 'Poster' }}
          secondaryFields={[
          ]}
          filters={['searchText', 'combinedLibrary']}
        />,
      },
      {
        path: 'assets/video',
        element: <ItemPage
          modelName="asset"
          EditScreen={editModel('asset/video')}
          secondaryFields={[
            { name: 'status', label: 'Status', formatter: enumLabeller('asset', 'status') },
          ]}
          additionalActions={assetAdditionalActions}
          filter={{ type: { eq: 'video' } }}
        />,
      },
      {
        path: 'assets/image',
        element: <ItemPage
          modelName="asset"
          EditScreen={editModel('asset/image')}
          additionalActions={assetAdditionalActions}
          secondaryFields={[
            { name: 'status', label: 'Status', formatter: enumLabeller('asset', 'status') },
          ]}
          filter={{ type: { eq: 'image' } }}
        />,
      },
      {
        path: 'assets/pdf',
        element: <ItemPage
          modelName="asset"
          EditScreen={editModel('asset/pdf')}
          additionalActions={assetAdditionalActions}
          secondaryFields={[
            { name: 'status', label: 'Status', formatter: enumLabeller('asset', 'status') },
          ]}
          filter={{ type: { eq: 'pdf' } }}
        />,
      },
      {
        path: 'assets/other',
        element: <ItemPage
          modelName="asset"
          EditScreen={editModel('asset/other')}
          additionalActions={assetAdditionalActions}
          secondaryFields={[
            { name: 'status', label: 'Status', formatter: enumLabeller('asset', 'status') },
          ]}
          filter={{
            and: [
              { type: { ne: 'image' } },
              { type: { ne: 'video' } },
              { type: { ne: 'pdf' } },
            ],
          }}
        />,
      },
      { path: 'asset/:assetID', element: <AssetEditor /> },
      { path: 'asset/:type/:assetID', element: <AssetEditor /> },
      // { path: 'my-files', element: <MyFiles /> },
      { path: 'badges', element: <PlaceholderPage title="Badges!" /> },
      { path: 'reviews', element: <PlaceholderPage title="Reviews!" /> },
      {
        path: 'instructors',
        element: <ItemPage
          modelName="instructor"
          EditScreen={editModel('instructor')}
          imageField={{ name: 'profileImage', label: 'Profile Image' }}
          secondaryFields={[
          ]}
        />,
      },
      {
        path: 'certifications',
        element: <ItemPage
          modelName="certification"
          EditScreen={editModel('certification')}
          imageField={{ name: 'poster', label: 'Sample Certificate' }}
          secondaryFields={[
          ]}
        />,
      },
      { path: 'instructor/:instructorId', element: <InstructorEditor /> },
      { path: 'certification/:certificationId', element: <CertificationEditor /> },
      { path: 'messaging', element: <PlaceholderPage title="Messaging!" /> },

      // Portal
      {
        path: 'portals',
        element: <ItemPage
          modelName="portal"
          EditScreen={EditPortal}
          additionalActions={[
            {
              name: 'clone',
              label: 'Clone',
              action: ((portal: any, navigate: any, store: any) => {
                clonePortal(portal, navigate, store);
              }),
            },
            {
              name: 'deploy',
              label: 'Deploy',
              action: ((portal: any, navigate: any, store: any) => {
                deployPortal(portal, navigate, store);
              }),
            },
            {
              name: 'view',
              label: 'View',
              action: (
                organization: IOrganization,
                portal: IPortal,
              ) => { viewPortal(organization, portal, true); },
            },
          ]}
        />,
      },
      {
        path: 'portals/:isNewPortal',
        element: <ItemPage
          modelName="portal"
          EditScreen={EditPortal}
          additionalActions={[
            {
              name: 'view',
              label: 'View',
              action: (
                organization: IOrganization,
                portal: IPortal,
              ) => { viewPortal(organization, portal, true); },
            },
          ]}
        />,
      },
      { path: 'details', element: <PortalDetails /> },
      { path: 'categories', element: <PortalCategories /> },
      { path: 'portal-tokens', element: <PortalTokens /> },
      {
        path: 'design',
        element: (
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
          >
            <PortalEditor />
          </ErrorBoundary>
        ),
      },
      { path: 'stylesheet', element: <PortalStylesheet /> },
      {
        path: 'portal-memberships',
        element: <ItemPage
          modelName="membership"
          EditScreen={editModel('membership-editor')}
          // imageField={{ name: 'courseImage', label: 'Poster' }}
          secondaryFields={[
            { name: 'summary', label: 'Summary' },
            { name: 'accessGroups', label: 'Access Groups' },
          ]}
          filter="currentPortal"
        />,
      },
      { path: 'membership-editor/:membershipID', element: <MembershipEditor /> },
      {
        path: 'portal-members',
        element: <ItemPage
          modelName="member"
          EditScreen={editModel('member-editor')}
          // imageField={{ name: 'courseImage', label: 'Poster' }}
          secondaryFields={[
            { name: 'customer.email', label: 'Email' },
            { name: 'membership.name', label: 'Membership' },
            { name: 'status', label: 'Status' },
          ]}
          titleField={{ name: 'customer.name', label: 'Name' }}
          filter="currentPortal"
          filters={['searchText', 'membership', 'activeStatusOnly']}
          additionalActions={[
            {
              name: 'upgradeMember',
              label: 'Upgrade',
              action: (member: any, navigate: any, store: any) => {
                console.log('Upgrade member', member);
                store.swapMembership(member, member?.membership?.upgradeMembership);
              },
              disabled: (member: any) => (!member?.membership?.upgradeMembership),
            },
            {
              name: 'downgradeMember',
              label: 'Downgrade',
              action: (member: any, navigate: any, store: any) => {
                console.log('Downgrade member', member);
                store.swapMembership(member, member?.membership?.downgradeMembership);
              },
              disabled: (member: any) => (!member?.membership?.downgradeMembership),
            },
          ]}
          availableListStyles={['list']}
        />,
      },
      { path: 'member-editor/:memberID', element: <MemberEditor /> },

      {
        path: 'portal-kickers',
        element: <ItemPage
          modelName="kicker"
          EditScreen={editModel('kicker-editor')}
          imageField={{ name: 'asset', label: 'Poster' }}
          secondaryFields={[
          ]}
        />,
      },
      { path: 'kicker-editor/:kickerID', element: <KickerEditor /> },
      {
        path: 'portal-questionnaires',
        element: <ItemPage
          modelName="questionnaire"
          EditScreen={editModel('questionnaire-editor')}
          secondaryFields={[
            { name: 'email', label: 'Email' },
            { name: 'status', label: 'Status' },
          ]}
          availableListStyles={['list']}
          additionalActions={[
            {
              name: 'clone',
              label: 'Clone',
              action: ((item: any, navigate: any) => {
                navigate(`../questionnaire-editor/${item.id}/clone`);
              }),
            },
          ]}
        />,
      },
      { path: 'questionnaire-editor/:questionnaireID/clone', element: <QuestionnaireEditor clone /> },
      { path: 'questionnaire-editor/:questionnaireID', element: <QuestionnaireEditor /> },
      {
        path: 'portal-questions',
        element: <ItemPage
          modelName="question"
          EditScreen={editModel('question-editor')}
          secondaryFields={[
            { name: 'email', label: 'Email' },
            { name: 'status', label: 'Status' },
          ]}
          availableListStyles={['list']}
        />,
      },
      { path: 'question-editor/:questionID', element: <QuestionEditor /> },

      { path: 'reporting/engagement', element: <Reporting /> },
      { path: 'reporting/compliance', element: <Compliance /> },

      { path: 'faqs', element: <PlaceholderPage title="Portal FAQs!" /> },

      // Meetings
      {
        path: 'meetings',
        element: <ItemPage
          EditScreen={editModel('meeting')}
          modelName="meeting"
          availableListStyles={['list']}
          filters={['searchText', 'pastFuture']}
          secondaryFields={[
            { name: 'startsAt', label: 'Time', formatter: TimestampFormatter },
            { name: 'status', label: 'Status' },
          ]}
          handleNewLabel="Schedule Meeting"
          listBarActions={[
            {
              name: 'meetNow',
              label: 'Meet Now',
              action: ((item: any, navigate: any) => {
                navigate('../meeting/meetNow');
              }),
            },
          ]}
        />,
      },
      { path: 'meeting/:key', element: <MeetingEditor /> },
      { path: 'meeting/:key/:tab/*', element: <MeetingEditor /> },

      // Hubs
      {
        path: 'hubs',
        element: <ItemPage
          EditScreen={editModel('hub')}
          modelName="hub"
          availableListStyles={['list']}
          filters={['searchText']}
          secondaryFields={[
            { name: 'status', label: 'Status' },
          ]}
          listBarActions={[
          ]}
        />,
      },
      { path: 'hub/:key', element: <HubEditor /> },
      { path: 'hub/:key/:tab/*', element: <HubEditor /> },

      // Libraries
      {
        path: 'libraries',
        element: <ItemPage
          EditScreen={editModel('library')}
          modelName="library"
        />,
      },
      { path: 'library/:libraryId', element: <LibraryEditor /> },
      { path: 'library/:libraryId/:tab', element: <LibraryEditor /> },
      {
        path: 'libraryShares',
        element: <ItemPage
          pageHead={(item, store) => (
            <Stack>
              <Typography
                variant="body2"
                component="div"
              >
                To use a library from a different organization provide them with
                this share code and they can authorize you to use one or more of
                their libraries:
              </Typography>
              <Typography variant="h4">
                {store.currentOrganization.id}
              </Typography>
            </Stack>
          )}
          modelName="libraryShare"
          readOnly
          filter="targetOrganization"
        />,
      },
      { path: 'libraryShare-viewer/:libraryID', element: <LibraryShareViewer /> },
      { path: 'libraryShare-editor/:libraryID/:libraryShareID', element: <LibraryShareEditor /> },

      // Repositories
      {
        path: 'repositories',
        element: <ItemPage
          modelName="repository"
          EditScreen={editModel('repository-editor')}
          additionalActions={[
            {
              name: 'deploy',
              label: 'Deploy',
              action: ((repository: any, navigate: any, store: any) => {
                deployRepository(repository, navigate, store);
              }),
            },
          ]}
        />,
      },
      { path: 'repository-editor/:repositoryID', element: <RepositoryEditor /> },

      // Company
      { path: 'organization-summary', element: <OrganizationSummary /> },
      {
        path: 'organizations',
        element: <ItemPage
          modelName="organization"
          EditScreen={editModel('organization')}
          additionalActions={[
            {
              name: 'deploy',
              label: 'Deploy',
              action: ((organization: any, navigate: any, store: any) => {
                deployOrganization(organization, navigate, store);
              }),
            },
          ]}
        />,
      },
      // Customers
      {
        path: 'customers',
        element: <ItemPage
          modelName="customer"
          EditScreen={editModel('customer')}
          titleField={{ name: 'email', label: 'Email', formatter: CustomerEmailFormatter }}
          imageField={{ name: 'profileImage', label: 'Profile Image' }}
          // additionalActions={[
          //   {
          //     name: 'learningBoard',
          //     label: 'Learning Board',
          //     action: ((item:any) => {
          //       window.open(`/`, '_blank');
          //     }),
          //   },
          // ]}
          secondaryFields={[
            { name: 'name', label: 'Name' },
            { name: 'createdAt', label: 'Start Date', formatter: DateFormatter },
            { name: 'membershipCounts', label: 'Memberships', formatter: CustomerMembershipCount },
            { name: 'lastPaymentAt', label: 'Last Payment', formatter: DateFormatter },
            { name: 'nextPaymentAt', label: 'Next Payment', formatter: DateFormatter },
            { name: 'learningBoards', label: 'Learning Board', formatter: LearningBoardFormatter },
          ]}
          actionsStyle="ellipses"
          availableListStyles={['list']}
        // newItemHandler={newCustomerHandler}
        />,
      },
      // Enroll Customers
      {
        path: 'enroll-customers',
        element: <EnrollCustomers />,
      },
      { path: 'customer/:key', element: <CustomerEditor /> },
      { path: 'customer/:key/:tab', element: <CustomerEditor /> },
      // Discussion
      {
        path: 'discussion',
        element: <ItemPage
          modelName="discussionTopic"
          EditScreen={editModel('discussion')}
          titleField={{ name: 'name', label: 'Name' }}
          secondaryFields={[
            { name: 'description', label: 'Description' },
          ]}
          actionsStyle="ellipses"
          availableListStyles={['list']}
        // newItemHandler={newCustomerHandler}
        />,
      },
      { path: 'discussion/:key', element: <DiscussionEditor /> },
      { path: 'discussion/:key/:tab', element: <DiscussionEditor /> },
      // Lead
      {
        path: 'leads',
        element: <ItemPage
          modelName="lead"
          EditScreen={editModel('lead-editor')}
          titleField={{ name: 'email', label: 'Email', formatter: CustomerEmailFormatter }}
          secondaryFields={[
            { name: 'firstName', label: 'First Name' },
            { name: 'lastName', label: 'Last Name' },
            { name: 'createdAt', label: 'Date Captured', formatter: DateFormatter },
            { name: 'company', label: 'Company' },
            { name: 'phoneNumber', label: 'Phone Number' },
          ]}
          availableListStyles={['list']}
        // newItemHandler={newCustomerHandler}
        />,
      },
      { path: 'lead-editor/:leadID', element: <LeadEditor /> },

      // Users
      {
        path: 'users',
        element: <UserPage />,
      },
      { path: 'userOrg/:userOrgID', element: <UserEditor /> },

      { path: 'organization/:organizationID', element: <OrganizationEditor /> },

      // System
      { path: 'notes', element: <ItemPage modelName="note" /> },
      { path: 'scorm-player', element: <ScormPlayer /> },
      { path: 'account', element: <Account /> },

      // Other
      { path: 'settings', element: <Settings /> },
      { path: '*', element: <Navigate to="/404" /> },
      {
        path: 'engagement-flows',
        element: <ItemPage
          modelName="engagementFlow"
          EditScreen={editModel('engagement-flow-editor')}
          secondaryFields={[
          ]}
        />,
      },
      { path: 'engagement-flow-editor/:flowID', element: <EngagementFlowEditor /> },
      {
        path: 'email-templates',
        element: <ItemPage
          modelName="emailTemplate"
          EditScreen={editModel('email-template-editor')}
          secondaryFields={[
          ]}
        />,
      },
      { path: 'email-template-editor/:emailTemplateID', element: <EmailTemplateEditor /> },
    ],
  },
  {
    path: 'class/:organizationId/:keyOrID',
    element: <ClassPage />,
  },
  {
    path: 'courses',
    element: <CourseListPage />,
  },
  // for hub demo
  {
    path: 'portal/:organizationId/:portalId/hub/course/:key/*',
    element: (
      <PortalCss>
        <PortalLoadGuard>
          <CourseEditor />
        </PortalLoadGuard>
      </PortalCss>
    ),
  },
  {
    path: 'portal/:organizationId/:portalId/hub/document/:key/*',
    element: (
      <PortalCss>
        <PortalLoadGuard>
          <DocumentEditor />
        </PortalLoadGuard>
      </PortalCss>
    ),
  },
  {
    path: 'portal/:organizationId/:portalId/hub/meeting/:key/*',
    element: (
      <PortalCss>
        <PortalLoadGuard>
          <MeetingEditor />
        </PortalLoadGuard>
      </PortalCss>
    ),
  },
  {
    path: 'portal/:organizationId/:portalId/hub/microcast/:microcastID/*',
    element: (
      <PortalCss>
        <PortalLoadGuard>
          <MicrocastEditor />
        </PortalLoadGuard>
      </PortalCss>
    ),
  },
  // end of hub demo
  {
    path: 'portal/:organizationId/:portalId/*',
    element: (
      <PortalCss>
        <PortalLoadGuard>
          <PortalFontLoader>
            <PortalRouteMapper isServerSide={false} />
          </PortalFontLoader>
        </PortalLoadGuard>
      </PortalCss>
      // <PortalCss>
      //   <PortalLoadGuard />
      // </PortalCss>
    ),
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/app/courses" /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
  {
    path: 'microcast/:organizationId/:keyOrID',
    element: <MicrocastPage />,
  },
  {
    path: 'instructor/:organizationId/:instructorID',
    element: <InstructorPage />,
  },
];
