import React from 'react';
import {
  List,
  ListItemText,
  ListItemButton,
  Box,
  Typography,
} from '@mui/material';
import {
  ConditionTypes,
} from '@mcn-platform/models';

const ConditionSelector = ({ currentValue, setValue }: any) => {
  const selectedStyle = {
    color: '#1276dd',
    fontWeight: 600,
    fontSize: '0.875rem',
    backgroundColor: '#f4f6fa',
  };
  return (
    <Box sx={{ p: 2 }}>
      <Typography sx={{ fontWeight: 'bold', width: '100%' }}>Condition Type</Typography>
      <List
        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        {ConditionTypes.map((conditionType) => {
          if (conditionType.subTypes) {
            return (
              <Box key={`${conditionType.key}box`}>
                <ListItemButton
                  key={conditionType.key}
                  disabled={!conditionType.isSelectable}
                  onClick={() => setValue({
                    ...currentValue,
                    conditionType: conditionType.key,
                  })}
                  sx={{
                    ...(
                      conditionType.key === currentValue.conditionType ? selectedStyle : {}
                    ),
                  }}
                >
                  <ListItemText primary={conditionType.label} />
                </ListItemButton>
                { conditionType?.subTypes.map((subType) => (
                  <ListItemButton
                    disabled={!subType.isSelectable}
                    key={subType.key}
                    onClick={() => setValue({
                      ...currentValue,
                      conditionType: subType.key,
                    })}
                    sx={{
                      ml: 2,
                      ...(subType.key === currentValue.conditionType ? selectedStyle : {}),
                    }}
                  >
                    <ListItemText primary={subType.label} />
                  </ListItemButton>
                )) }
              </Box>
            );
          }
          return (
            <ListItemButton
              disabled={!conditionType.isSelectable}
              key={conditionType.key}
              onClick={() => setValue({
                ...currentValue,
                conditionType: conditionType.key,
              })}
              sx={{
                ...(conditionType.key === currentValue.conditionType ? selectedStyle : {}),
              }}
            >
              <ListItemText primary={conditionType.label} />
            </ListItemButton>
          );
        })}
      </List>
    </Box>
  );
};

export default ConditionSelector;
