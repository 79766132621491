import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet-async';
import { v4 } from 'uuid';
import { useNavigate, useParams } from 'react-router-dom';
import { IMember, useStore } from '@mcn-platform/models';

import usePrompt from 'hooks/usePrompt';
import ItemEdit from 'components/item/ItemEdit';
import ButtonStack from 'components/item/ButtonStack';
// import MemberTypeField from 'components/member/MemberTypeField';

const MemberEditor = () => {
  const store = useStore();
  const [member, setMember] = useState<IMember | null>();
  const { memberID } = useParams();
  const navigate = useNavigate();

  usePrompt({
    isDirty: member ? (member.changeCount > 0) : false,
  });

  useEffect(() => {
    if (!memberID) {
      return;
    }

    // check if we're creating a new member
    if (memberID === 'new') {
      const newMember = store.create(
        'member',
        v4(),
        'New Member',
        {
          portalID: store.currentPortal ? store.currentPortal.id : '',
        },
      );
      setMember(newMember);
    } else {
      const stub = store.getOrLoad('member', memberID);
      console.log('syncstub: ', stub.syncState, { ...stub });
      setMember(stub);
    }
  }, [store, memberID, member?.syncState]);

  /**
   * Takes the full member and saves it to the member stub.  Sections are serialized to JSON
   */
  const saveMember = async () => {
    if (!member) return;

    if (memberID === 'new') {
      await store.addStub('member', member).then(() => {
        console.log('saved');
      });
      if (member.customer.id) {
        await store.refreshCustomerStats(member.customer.id);
      }
      navigate(`/app/member-editor/${member.id}`);
    } else {
      await store.update('member', member);
      if (member.customer.id) {
        await store.refreshCustomerStats(member.customer.id);
      }
    }
  };

  if (!member) {
    return <div>Loading.</div>;
  }
  if (member.syncState === 'error') {
    return <div>Error loading member</div>;
  }

  if (member.syncState === 'saving') {
    return <div>Saving..</div>;
  }

  if (memberID !== 'new' && member.syncState !== 'loaded') {
    return <div>Loading..</div>;
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    }}
    >
      <Helmet>
        <title>{memberID === 'new' ? 'Member Builder' : 'Member Editor'}</title>
      </Helmet>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        minHeight: '100%',
      }}
      >
        <Box sx={{ flex: '1', overflowY: 'auto' }}>
          <ItemEdit
            item={member}
            modelName="member"
            handleSubmit={(newValues:any) => {
              console.log('Update member', newValues);
              member.updateValues(newValues);
            }}
            hideTitle
            autoSave
          />
        </Box>
        <ButtonStack>
          <Button
            color="primary"
            variant="contained"
            onClick={saveMember}
            disabled={member.changeCount === 0}
          >
            Save
          </Button>
        </ButtonStack>
      </Box>
    </Box>
  );
};

export default observer(MemberEditor);
