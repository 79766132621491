/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { observer } from 'mobx-react-lite';
import { Controller } from 'react-hook-form';
import {
  Box,
} from '@mui/material';
import Conditions from './Condition';
import { StandardFieldProps } from '../FieldTypes';

const WrappedField = ({
  control,
  fieldName,
}:StandardFieldProps) => (
  <Controller
    name={fieldName}
    control={control}
    render={({
      field: {
        onChange,
        value,
      },
    }) => (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
      }}
      >
        <Conditions currentValue={value || []} setValue={onChange} />
      </Box>
    )}
  />
);

export default observer(WrappedField);
