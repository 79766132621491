import React from 'react';
import { observer } from 'mobx-react-lite';
import { Control, Controller } from 'react-hook-form';
import {
  Box,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { ILearningBoardSection, useStore } from '@mcn-platform/models';
import ItemEdit from 'components/item/ItemEdit';
import { FieldType } from 'components/forms/FieldTypes';

const fields = [
  'name', 'items',
];

const LearningBoardGroupNameField = (
  control: Control,
  fieldName: string,
  field:FieldType,
  readOnly: boolean,
  itemType:string,
) => {
  const store = useStore();
  if (!store.currentPortal) {
    return (<div />);
  }
  const groupNames = store.currentPortal.learningBoardGroupNames.split('&#8226;');
  console.log('Called LearningBoardsField with', {
    control,
    fieldName,
    field,
    itemType,
  });
  return (
    <Controller
      name={fieldName}
      control={control}
      render={({
        field: {
          onChange,
          // onBlur,
          value,
          // ref,
        },
      }) => (
        <FormControl
          id={`label-${fieldName}`}
          disabled={Boolean(readOnly || field.readOnly)}
          fullWidth
        >
          <FormLabel id={`label-${fieldName}`}>{field.label}</FormLabel>
          <Select
            id={fieldName}
            onChange={onChange}
            value={value}
            name={fieldName}
          >
            {groupNames.map((name:string) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
};

const updateSection = (section:ILearningBoardSection, formData:any) => {
  console.log('Update section', formData);
  section.updateFields(fields, formData);
};

type SectionDetailProps = {
  section: ILearningBoardSection;
};

const CategoryDetail = ({ section }:SectionDetailProps) => (
  <Box>
    <Typography variant="h4">
      Learning Board Group
      {/* {section.name} */}
    </Typography>
    <ItemEdit
      item={section}
      modelName="learningBoardSection"
      handleSubmit={(newValues) => updateSection(section, newValues)}
      formConfig={fields}
      overrides={{
        name: LearningBoardGroupNameField,
      }}
      hideTitle
      autoSave
    />
  </Box>
);

export default observer(CategoryDetail);
