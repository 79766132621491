/**
 * This is to encapsulate the selection logic in one place so we
 * can have different styles for how the data is managed.
 */
class SelectionModel {
  multiselect:boolean;

  items:any[];

  selectedKeys:string[];

  preSelectedKeys: string[];

  disabledKeys:string[];

  constructor(
    multiselect: boolean,
    items:any[],
    preSelectedKeys:string[],
    disabledKeys:string[],
    selectedKeys:string[],
  ) {
    this.multiselect = multiselect;
    this.items = items;
    this.preSelectedKeys = preSelectedKeys;
    this.disabledKeys = disabledKeys;
    this.selectedKeys = selectedKeys;
  }

  selectedCount() {
    return this.selectedKeys.length;
  }

  isDisabled(item:any) {
    return this.disabledKeys.includes(item.key);
  }

  isPreSelected(item:any) {
    return this.preSelectedKeys.includes(item.key);
  }

  isSelected(item:any) {
    return this.selectedKeys.includes(item.key);
  }

  toggleItem(item:any) {
    if (this.isDisabled(item)) {
      return this.selectedKeys;
    }

    let newSelectedKeys: Array<string> = [];

    if (this.multiselect) {
      // check if its already selected
      const selectedIndex = this.selectedKeys.indexOf(item.key);

      if (selectedIndex === -1) {
        // not found, we want to add
        newSelectedKeys = newSelectedKeys.concat(this.selectedKeys, item.key);
      } else {
        // remove it
        newSelectedKeys = this.selectedKeys.slice();
        newSelectedKeys.splice(selectedIndex, 1);
      }
      this.selectedKeys = newSelectedKeys;
    } else {
      // can only select one at a time
      this.selectedKeys = [item.key];
    }
    return this.selectedKeys;
  }

  selectAll() {
    // @todo - fix this so it just deselects the current page's items
    this.selectedKeys = this.items.map((item:any) => item.key && !this.isDisabled(item));
    return this.selectedKeys;
  }

  deselectAll() {
    // @todo - fix this so it just deselects the current page's items

    this.selectedKeys = [];
    return this.selectedKeys;
  }
}

export default SelectionModel;
