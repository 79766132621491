import React from 'react';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  // Stack,
  Typography,
} from '@mui/material';

const ShowQuestionWithAnswers = ({ question, index, isCorrect }:any) => (
  <>
    <Box
      key={question.id}
      sx={{
        m: 2,
      }}
    >
      {index}
      .
      {isCorrect ? ' ✓ ' : ' ✗ '}
      {question.questionText}
    </Box>
    {/* {question.type === 'multipleChoiceQuestion' && (
      <Stack spacing={1} direction="row">
        <span>
          Answers:
        </span>
        {question.answers.map((answer) => (
          <span key={answer.id}>
            {answer.answerText}
          </span>
        ))}
      </Stack>
    )}
    {question.type === 'multipleResponseQuestion' && (
      <Stack spacing={1} direction="row">
        <span>
          Answers:
        </span>
        {question.answers.map((answer) => (
          <span key={answer.id}>
            {answer.answerText}
          </span>
        ))}
      </Stack>
    )}
    {question.type === 'fillInTheBlank' && (
      <Stack spacing={1} direction="row">
        <span>
          Answers:
        </span>
        {question.answers.map((answer) => (
          <span key={answer.id}>
            {answer.answerText}
          </span>
        ))}
      </Stack>
    )} */}
  </>
);

const ShowResults = ({ answers }:any) => (
  <>
    <Typography
      variant="h4"
      sx={{
        my: 1,
      }}
    >
      Correct Answers
    </Typography>
    {answers.correct?.map((question, index) => (
      <ShowQuestionWithAnswers
        key={question.id}
        question={question}
        index={index + 1}
        isCorrect
      />
    ))}
    <Typography
      variant="h4"
      sx={{
        my: 1,
      }}
    >
      Incorrect Answers
    </Typography>
    {answers.incorrect?.map((question, index) => (
      <ShowQuestionWithAnswers
        key={question.id}
        question={question}
        index={index + 1}
        isCorrect={false}
      />
    ))}
  </>
);

const QuizResultDialog = ({
  open,
  handleClose,
  customer,
  item,
}:any) => (
  <Dialog
    open={open}
    // onClose={handleCancel}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    sx={{
      width: '100%',
      maxWidth: '100%',
    }}
    PaperProps={{
      sx: {
        width: '100%',
        maxWidth: '100%',
        height: '100%',
        margin: 8,
      },
    }}
  >
    <DialogTitle id="alert-dialog-title">
      Quiz Results for
      {' '}
      {customer.name}
    </DialogTitle>
    <DialogContent>
      {item?.quizResults && Object.entries(item.quizResults).map(([key, quizResult]:any) => (
        <React.Fragment key={key}>
          {quizResult.isCompleted ? (
            <>
              <Typography variant="h2">
                Completed At:
                {' '}
                {quizResult.completedAt}
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  my: 2,
                }}
              >
                Score:
                {' '}
                {quizResult.score}
                %
                {' '}
                {quizResult.isPassed ? (
                  <Chip
                    label="Passed"
                    sx={{
                      backgroundColor: 'green',
                    }}
                  />
                ) : (
                  <Chip
                    label="Failed"
                    sx={{
                      backgroundColor: 'red',
                    }}
                  />
                )}
              </Typography>
            </>
          ) : (
            <>
              <Typography
                variant="h2"
                sx={{
                  my: 2,
                }}
              >
                Started But Not Completed
                {' '}
                {quizResult.startedAt}
              </Typography>
              <Chip
                label="Not Completed"
                sx={{
                  backgroundColor: 'orange',
                }}
              />
            </>
          )}
          <ShowResults answers={quizResult.answers} />
        </React.Fragment>
      ))}
    </DialogContent>
    <DialogActions>
      <Button onClick={() => handleClose()}>
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

export default QuizResultDialog;
