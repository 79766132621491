import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Auth } from 'aws-amplify';
import Logo from 'components/Logo';

const handleSignOut = () => {
  Auth.signOut().then(() => {
    window.location.reload();
  });
};

const LoadGuard = ({ message, showLogout }:any) => (
  <Dialog open>
    <DialogTitle>
      <Logo />
    </DialogTitle>
    <DialogContentText sx={{ p: 2, textAlign: 'center' }}>
      { message || 'Loading...' }
    </DialogContentText>
    {showLogout && (
      <DialogActions>
        <Button
          onClick={handleSignOut}
          sx={{ flexGrow: 0, minWidth: 110, mt: 2 }}
        >
          Logout
        </Button>

      </DialogActions>
    )}
  </Dialog>
);

export default LoadGuard;
