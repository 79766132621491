import React, {
  useEffect,
  useMemo,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { Control, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getSnapshot, isStateTreeNode, onPatch } from 'mobx-state-tree';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormLabel,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { omit } from 'lodash';

import {
  Schema,
  useStore,
  Tokens,
  TokensProvider,
  YupAdapter,
} from '@mcn-platform/models';
import Tabs from 'components/Tabs';
import VerticalTabs from 'components/VerticalTabs';
import StandardField from '../forms/StandardField';
import StandardCheckboxField from '../forms/StandardCheckboxField';
import AutoUpdate from './AutoUpdate';

let FormElement:any;

type FieldProps = {
  control: Control;
  modelName: string;
  fieldName: string,
  overrides?: Record<string, any>;
  itemType: string;
  readOnly: boolean;
};

export const Field = ({
  control,
  fieldName,
  modelName,
  overrides,
  itemType,
  readOnly,
}:FieldProps) => {
  const field = Schema.getProperty(modelName, fieldName);
  const override = (overrides && overrides[fieldName]);
  if (override) {
    return override(control, fieldName, field, readOnly, itemType);
  }
  return (
    <StandardField
      control={control}
      fieldName={fieldName}
      field={field}
      itemType={itemType}
      readOnly={readOnly}
    />
  );
};

Field.defaultProps = {
  overrides: undefined,
};

/**
 * Sample config:
 *  {
 *    type: 'twoColumn',
 *    elements: ['registrationMax', 'registrationCount'],
 *  },
 * @param param0
 * @returns
 */
export const TwoColumn = ({
  formConfig,
  modelName,
  control,
  itemType,
  components,
  overrides,
  readOnly,
}: any) => {
  const elements = formConfig.elements // defined directly in formConfig
    || Schema.getPropertyGroups(modelName)[formConfig.name] // or pulled from group property
    || []; // or empty
  return (
    <Grid
      container
      display="flex"
      flexWrap="nowrap"
      spacing={3}
    >
      {elements.map((element) => (
        <Grid
          item
          key={element.name || element}
          xs={12}
          md={6}
        >
          <FormElement
            control={control}
            formElement={element}
            itemType={itemType}
            overrides={overrides}
            components={components}
            modelName={modelName}
            readOnly={readOnly}
          />
        </Grid>
      ))}
    </Grid>
  );
};

/**
 * Sample config:
 * {
 *   type: 'guardedOption',
 *   controlledBy: 'registrationRequired',
 *   controlledField: 'registerBy',
 *   label: 'Registration Required',
 * },
 * @param param0
 * @returns
 */
export const GuardedOption = observer(({
  formConfig,
  modelName,
  control,
  itemType,
  // overrides,
  readOnly,
}: any) => {
  const controlChecked = useWatch({ control, name: formConfig.controlledBy });
  const controlledFieldReadOnly = readOnly || !controlChecked;
  const controlledField = Schema.getProperty(modelName, formConfig.controlledField);
  return (
    <Grid
      container
      direction="row"
      spacing={3}
    >
      <Grid
        key={`label-${formConfig.name}-guard`}
        item
        justifyContent="space-between"
        xs={3}
      >
        <Stack direction="column" spacing={1}>
          <FormLabel id={`label-${formConfig.name}`}>{formConfig.label}</FormLabel>
          <StandardCheckboxField
            control={control}
            fieldName={formConfig.controlledBy}
            field={{ ...formConfig, showLabel: false }}
            readOnly={readOnly}
          />
        </Stack>
      </Grid>
      <Grid
        key={`label-${formConfig.name}-field`}
        item
        justifyContent="flex-start"
        flexGrow={1}
        xs={9}
      >
        <StandardField
          control={control}
          fieldName={formConfig.controlledField}
          field={controlledField}
          // field={{ ...controlledField, showLabel: false }}
          itemType={itemType}
          readOnly={controlledFieldReadOnly}
        />
      </Grid>
    </Grid>
  );
});

export const FormSubGroup = ({
  modelName,
  control,
  formConfig,
  itemType,
  overrides,
  components,
  readOnly,
}: any) => {
  // console.log('subGroup name', formConfig.name);
  const elements = formConfig.elements // defined directly in formConfig
    || Schema.getPropertyGroups(modelName)[formConfig.name] // or pulled from group property
    || []; // or empty

  return (
    <Grid
      container
      direction="row"
      spacing={1}
    >
      <Grid
        key={`label-${formConfig.name}-label`}
        item
        xs={12}
        justifyContent="space-between"
      >
        <Typography variant="h4" component="div">
          {formConfig.label}
        </Typography>
      </Grid>
      <Grid
        key={`label-${formConfig.name}-field`}
        item
        xs={12}
        justifyContent="space-between"
      >
        <Box
          sx={{
            width: '100%',
            border: '1px solid',
            borderColor: '#7f7f7f',
            borderRadius: 1,
            padding: 2,
          }}
        >
          <Grid
            container
            direction="row"
            spacing={3}
          >
            {elements.map((element) => (
              <Grid
                key={element.name || element}
                item
                xs={12}
                justifyContent="space-between"
              >
                <FormElement
                  control={control}
                  formElement={element}
                  itemType={itemType}
                  overrides={overrides}
                  components={components}
                  modelName={modelName}
                  readOnly={readOnly}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export const FormGroup = ({
  control,
  formConfig,
  modelName,
  itemType,
  overrides,
  components,
  readOnly,
}:any) => {
  const elements = formConfig.elements // defined directly in formConfig
    || Schema.getPropertyGroups(modelName)[formConfig.name] // or pulled from group property
    || []; // or empty
  return (
    <Grid
      container
      display="flex"
      flexDirection="column"
      spacing={3}
      // sx={{ height: '100%' }}
      // sx={{ border: '1px solid black' }}
    >
      {elements.map((element) => (
        <Grid item key={element.name || element} flexGrow={1}>
          <FormElement
            control={control}
            formElement={element}
            itemType={itemType}
            overrides={overrides}
            components={components}
            modelName={modelName}
            readOnly={readOnly}
          />
        </Grid>
      ))}
    </Grid>
  );
};

const TabsEditor = ({
  control,
  formElement,
  modelName,
  itemType,
  overrides,
  components,
  readOnly,
}:any) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tabName = formElement.name || 'tab';

  const elements = formElement.elements // defined directly in formConfig
    || Schema.getPropertyGroups(modelName)[formElement.name] // or pulled from group property
    || []; // or empty

  const setSelectedTab = (newTab:string) => {
    searchParams.set(tabName, newTab);
    setSearchParams(searchParams);
  };

  const tabDefinitions = elements.map((tab) => ({
    key: tab.name,
    label: tab.label,
    content: (
      <FormElement
        control={control}
        formElement={tab}
        itemType={itemType}
        overrides={overrides}
        components={components}
        modelName={modelName}
        readOnly={readOnly}
      />
    ),
  }));
  if (formElement.subtype === 'vertical') {
    return (<VerticalTabs tabs={tabDefinitions} />);
  }

  return (
    <Tabs
      tabs={tabDefinitions}
      selected={searchParams.get(tabName) || ''}
      setSelected={setSelectedTab}
    />
  );
};

FormElement = ({
  formElement,
  control,
  itemType,
  overrides,
  components,
  modelName,
  readOnly,
}:any /* {formElement: FormGroup | FormSubGroup | string } */) => {
  // console.log('FormElement', formElement);
  if (typeof formElement === 'string') {
    return (
      <Field
        control={control}
        fieldName={formElement}
        itemType={itemType}
        modelName={modelName}
        overrides={overrides}
        readOnly={readOnly}
      />
    );
  }

  if (['string', 'number', 'fontFamily'].includes(formElement.type)) {
    return (
      <StandardField
        control={control}
        fieldName={formElement.name}
        field={formElement}
        itemType={itemType}
        readOnly={readOnly}
      />
    );
  }

  if (formElement.type === 'tabs') {
    return (
      <TabsEditor
        control={control}
        itemType={itemType}
        formElement={formElement}
        modelName={modelName}
        overrides={overrides}
        components={components}
        readOnly={readOnly}
      />
    );
  }

  if (formElement.type === 'group') {
    return (
      <FormGroup
        control={control}
        formConfig={formElement}
        itemType={itemType}
        overrides={overrides}
        components={components}
        modelName={modelName}
        readOnly={readOnly}
      />
    );
  }

  if (formElement.type === 'subGroup') {
    return (
      <FormSubGroup
        control={control}
        formConfig={formElement}
        itemType={itemType}
        overrides={overrides}
        components={components}
        modelName={modelName}
        readOnly={readOnly}
      />
    );
  }

  if (formElement.type === 'component') {
    const component = components[formElement.name];
    if (component) {
      return component;
    }
    console.warn('No component with name found', formElement.name);
    return null;
  }

  if (formElement.type === 'guardedOption') {
    return (
      <GuardedOption
        control={control}
        formConfig={formElement}
        itemType={itemType}
        overrides={overrides}
        components={components}
        modelName={modelName}
        readOnly={readOnly}
      />
    );
  }
  if (formElement.type === 'twoColumn') {
    return (
      <TwoColumn
        control={control}
        formConfig={formElement}
        itemType={itemType}
        overrides={overrides}
        components={components}
        modelName={modelName}
        readOnly={readOnly}
      />
    );
  }

  console.log('Dont know what to do with', formElement);
  return null;
};
/**
 * If item.id is null then its a new item, otherwise updating.
 * @param
 * @returns
 */
const HookedForm = observer(({
  modelName,
  handleSubmit,
  formConfig,
  overrides,
  components,
  hideTitle,
  autoSave,
  itemType,
  isNew,
  item,
  validationSchema,
  readOnly,
}:{
  modelName: string,
  formConfig: any,
  handleSubmit: any,
  overrides: Record<string, any>,
  components: Record<string, any>,
  hideTitle: boolean,
  autoSave: boolean,
  itemType: string,
  isNew: boolean,
  item: any,
  validationSchema: any,
  readOnly: boolean,
}) => {
  const model = Schema.getModel(modelName);
  const store = useStore();

  const initialValues = useMemo(() => omit(
    isStateTreeNode(item) ? getSnapshot(item) : item,
    ['changeCount', 'syncState', 'updatedAt'],
  ), [item]);

  // console.log('formConfig: ', formConfig);
  console.log('initialValues: ', initialValues);
  // console.log('initial UpdatedAt', item.updatedAt);

  const {
    control, handleSubmit: innerHandleSubmit, watch,
    reset, setValue,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: initialValues,
    // @ts-expect-error resolver mismatch
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    console.log('hooked form useEffect');

    // onPatch is triggered when item is updated, for example
    // from AI wizard.  This will update the form fields.
    if (isStateTreeNode(item)) {
      onPatch(item, (patch) => {
        if (typeof patch.value === 'string') {
          // console.info('------> Got change: ', patch);
          setValue(patch.path.substring(1), patch.value);
        }
      });
    }

    // if the item changes then update the fields.
    reset(omit(
      isStateTreeNode(item) ? getSnapshot(item) : item,
      ['changeCount', 'syncState', 'updatedAt'],
    ));

    const subscription = watch((value, { name }) => {
      console.log('autoSave:', {
        item, value, name, autoSave,
      });

      // update the name of the section to match the category
      if (modelName === 'learningBoardSection' && name === 'category') {
        const selectedCategory = store.currentPortal?.getCategory(value.category);
        setValue('name', selectedCategory?.name || '');
      }

      if (autoSave) {
        item.updateFields([name], value, store);
        if (name === 'item.course' || name === 'item.microcast') {
          reset(item);
        }
      }
      // console.log('Updated value', item);
    });

    return () => subscription.unsubscribe();
  }, [store, setValue, reset, watch, item, item?.updatedAt, autoSave, modelName]);
  return (
    <Card sx={{ height: '100%', overflow: 'auto' }}>
      {!hideTitle && (
        <>
          <CardHeader
            title={model.name}
          />
          <Divider />
        </>
      )}
      <CardContent sx={{ height: '100%' }}>
        <form
          style={{ height: '100%' }}
          onSubmit={(event) => {
            event.preventDefault();
            console.log('onSubmit', event);
            // innerHandleSubmit(handleSubmit)();
            innerHandleSubmit(handleSubmit, (errors) => console.log('ERRORS', errors))();
          }}
          // onChange={(event) => handleChange(event, getValues())}
        >
          <Grid
            container
            display="flex"
            flexDirection="column"
            spacing={3}
            // sx={{ height: '100%' }}
          >
            {formConfig.map((element:any) => (
              <Grid key={element.name || element} item flexGrow={1}>
                <FormElement
                  key={element.name || element}
                  control={control}
                  formElement={element}
                  modelName={modelName}
                  itemType={itemType}
                  overrides={overrides}
                  components={components}
                  item={item}
                  readOnly={readOnly}
                />
              </Grid>
            ))}
          </Grid>
          {autoSave
            ? <AutoUpdate control={control} />
            : (
              <>
                <Divider />
                <Stack
                  alignContent="center"
                  justifyContent="right"
                  direction="row"
                  spacing={2}
                  sx={{
                    padding: 1,
                    textAlign: 'center',
                    height: '64px',
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={item.changeCount === 0}
                  >
                    { isNew ? 'Create' : 'Save' }
                  </Button>
                </Stack>
              </>
            )}
        </form>
      </CardContent>
    </Card>
  );
});

interface ItemEditProps {
  modelName: string;
  item: any;
  handleSubmit: (values:any) => void;
  formConfig?: any | undefined;
  overrides?: Record<string, any>;
  components?: Record<string, any>;
  hideTitle?: boolean;
  autoSave?: boolean;
  readOnly?: boolean;
}

/**
 * If item.id is null then its a new item, otherwise updating.
 * @param
 * @returns
 */
const ItemEdit = ({
  modelName,
  item,
  handleSubmit,
  formConfig,
  components = {},
  overrides = {},
  hideTitle,
  autoSave,
  readOnly,
}:ItemEditProps) => {
  // if (initialValues === undefined) {
  //   return <div>Loading...</div>;
  // }
  const validator = useMemo(() => YupAdapter(modelName), [modelName]);
  const { currentPortal } = useStore();
  // console.log('Model validator is', validator);
  return (
    <TokensProvider value={new Tokens(currentPortal)}>
      <HookedForm
        modelName={modelName}
        handleSubmit={handleSubmit}
        formConfig={formConfig || Schema.getFormConfig(modelName)}
        overrides={overrides}
        components={components}
        hideTitle={hideTitle || false}
        autoSave={autoSave || false}
        itemType={item.type}
        isNew={!item.id}
        item={item}
        validationSchema={validator}
        readOnly={!!readOnly}
      />
    </TokensProvider>
  );
};

ItemEdit.defaultProps = {
  formConfig: undefined,
  overrides: {},
  components: {},
  hideTitle: false,
  autoSave: false,
  readOnly: false,
};

export default ItemEdit;
