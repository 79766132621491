/* eslint-disable react/jsx-props-no-spreading */
/**
 * Takes tags[] as the initial list of chips to display.
 * Each time a chip is added or removed onChange is called.
 * The value being typed or selected in for a new chip is internal state as well.
 * TODO: tags can be selected - this is internal state that is managed.
 */
import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore, Schema } from '@mcn-platform/models';
import {
  Autocomplete, Chip, TextField, IconButton,
} from '@mui/material';
import CloseIcon from 'icons/CloseIcon';

type TagsInputProps = {
  onChange: (tags:string[]) => void;
  value: any;
  placeholder?: string;
  portalScoped?: boolean;
  modelName: string;
  disabled: boolean;
  [other:string]: any;
};

const ReferenceTagsInput = ({
  onChange,
  placeholder,
  value = [],
  modelName,
  portalScoped = false,
  disabled,
  ...other
}:TagsInputProps) => {
  const store = useStore();
  const getLabel = (id:string) => {
    if (modelName === 'combinedLibrary' || modelName === 'portalLibraries') {
      return store.combinedLibraries.find((i) => (id === i.getLibraryID()))?.name;
    }
    if (modelName === 'receivedLibrary') {
      return store.receivedLibraries.find((i) => (id === i.key))?.name;
    }
    const m = store.getOrLoad(modelName, id);
    return m.name;
  };

  const getItems = (searchTerm: string) => {
    // both my libraries, and libraries shared with me
    if (modelName === 'combinedLibrary') {
      console.log('Returning', store.combinedLibraryIDs());
      return store.combinedLibraryIDs();
    }

    // both my libraries, and libraries shared with me
    if (modelName === 'receivedLibrary') {
      console.log('Returning', store.receivedLibraryShareKeys());
      return store.receivedLibraryShareKeys();
    }

    // libraries for current portal
    if (modelName === 'portalLibraries') {
      console.log('Returning', store.currentPortal.getLibraryIDs());
      return store.currentPortal.getLibraryIDs();
    }

    const searchableList = Schema.getSearchablePropertyList(modelName);
    const emptyArr : any[] = [];
    const searchFilter = {
      or: emptyArr,
    };

    let combinedFilter:any = {
    };
    if (searchTerm?.length) {
      searchableList.forEach((searchField:any) => {
        searchFilter.or.push({ [searchField]: { matchPhrase: `${searchTerm?.toLowerCase()}` } });
      });
      combinedFilter = {
        ...searchFilter,
        searchable: { eq: true },
      };
    }
    if (portalScoped) {
      console.log('Limit to current portal');
      combinedFilter.portalID = {
          eq: store.currentPortal?.id,
      };
    }
    const cachedList = store.getCachedList(modelName, combinedFilter).getItems();
    return cachedList.map((c) => (c.id));
  };
  // these are the already defi ned tags parsed from the form field values

  // this is the text currently in the field that hasnt been turned into a chip yet
  const [freeText, setFreeText] = useState<string>('');
  // the 'value' field is the parsed array of values.

  const handleDelete = (e: any, item:string) => {
    e.preventDefault();
    const newValue = [...value];
    newValue.splice(newValue.indexOf(item), 1);
    onChange(newValue);
  };
  return (
    <Autocomplete
      fullWidth
      multiple
      id="tags-outlined"
      value={value}
      inputValue={freeText}
      options={getItems('')}
      getOptionLabel={(option:any) => getLabel(option)}
      renderTags={(tagsValue: readonly string[], getTagProps) => (
        tagsValue.map((option: string, index: number) => (
          <Chip
            {...getTagProps({ index })}
            label={getLabel(option)}
            sx={{ margin: 0.5 }}
            deleteIcon={(
              <IconButton
                disableRipple
                disabled={disabled}
              >
                <CloseIcon />
              </IconButton>
            )}
            disabled={disabled}
            onDelete={(e) => handleDelete(e, option)}
          />
        )))}
      onChange={(event: any, newValue:any) => {
        onChange(newValue);
        setFreeText('');
      }}
      renderInput={(params) => (
        <TextField
          variant="outlined"
          placeholder={value.length < 3 ? placeholder : ''}
          {...params}
          onChange={(event) => {
            console.log('TextField onChange', event);
            setFreeText(event.target.value);
          }}
          disabled={disabled}
        />
      )}
      {...other}
      disabled={disabled}
    />
  );
};

ReferenceTagsInput.defaultProps = {
  placeholder: '',
  portalScoped: false,
};

export default observer(ReferenceTagsInput);
