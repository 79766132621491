import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import {
  Box, Button,
  Checkbox,
  FormLabel,
  IconButton,
  MenuItem,
  Select,
  TextField, ToggleButton, ToggleButtonGroup, Toolbar,
  Typography,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import ListIcon from '@mui/icons-material/List';
import GridIcon from '@mui/icons-material/GridView';
import { Refresh as ReloadIcon } from '@mui/icons-material';
import {
  ICachedList,
  Schema,
  useStore,
} from '@mcn-platform/models';
import { ItemField } from 'components/item/ItemTypes';
import PaginatedItemList from 'components/item/PaginatedItemList';
import Pagination from 'components/pagination/Pagination';
import ReferenceTagsInput from 'components/forms/TagsInput/ReferenceTagsInput';
import { useNavigate } from 'react-router';

type ActionHandler = (item:any, navigate: any) => void;
type Action = {
  label: string;
  handler: ActionHandler;
  hideWhen?: (item:any) => boolean;
  disableWhen?: (item:any) => boolean;
};
interface ItemNavigatorProps {
  modelName: string;
  imageField?: ItemField;
  titleField?: ItemField;
  secondaryFields?: ItemField[];
  defaultAction?: ActionHandler | undefined;
  actions?: Action[];
  filter?: any;
  handleNew?: any;
  handleNewLabel?: string,
  listBarActions?: any;
  multiSelect?: boolean;
  selectedKeys?: string[];
  preSelectedKeys?: string[];
  // eslint-disable-next-line react/require-default-props
  updateSelectedItems?: any;
  augmentData?: any;
  showSelect: boolean;
  enableDeselect: boolean;
  availableListStyles?: any[];
  actionsStyle?: 'menu' | 'ellipses';
  useLibraryModel?: boolean;
  libraryID?: string | undefined;
  filters?: string[];
  readOnly?: boolean;
}

const ItemNavigator = ({
  modelName,
  imageField = undefined,
  titleField = undefined,
  secondaryFields = undefined,
  defaultAction,
  actions = [],
  filter = {},
  handleNew = undefined,
  handleNewLabel = undefined,
  listBarActions = undefined,
  multiSelect = false,
  selectedKeys = [],
  preSelectedKeys = [],
  actionsStyle,
  availableListStyles = [],
  // gets called each time the list of selected items is changed
  // it is the list of IDs of the items. its optional.
  updateSelectedItems = undefined,
  augmentData,
  showSelect,
  enableDeselect,
  useLibraryModel = false,
  libraryID,
  filters = ['searchText'],
  readOnly = false,
}:ItemNavigatorProps) => {
  const [cachedList, setCachedList] = useState<ICachedList | undefined>();
  const [page, setPage] = useState<number>(0);
  const [listStyle, setListStyle] = useState<'list' | 'grid'>(multiSelect ? 'list' : 'grid');
  const [preferredListStyle, setPreferredListStyle] = useState<'list' | 'grid'>(multiSelect ? 'list' : 'grid');
  const [searchText, setSearchText] = useState<string>('');
  const [searchLibraries, setSearchLibraries] = useState<string[]>([]);
  const [searchMemberships, setSearchMemberships] = useState<string[]>([]);
  const [searchPastFuture, setSearchPastFuture] = useState<string>('Upcoming');
  const [activeStatusOnly, setActiveStatusOnly] = useState<boolean>(true);
  const store = useStore();
  const navigate = useNavigate();
  const itemsPerPage = 20;

  useEffect(() => {
    // if listStyle is not in availableListStyle, switch the style.
    if (!availableListStyles.includes(listStyle)) {
      setListStyle(availableListStyles[0]);
    }
  }, [availableListStyles, listStyle]);

  // when switching between models - go back to preferred style if there is one
  useEffect(() => {
    if (listStyle !== preferredListStyle && availableListStyles.includes(preferredListStyle)) {
      setListStyle(preferredListStyle);
    }
  }, [availableListStyles, listStyle, modelName, preferredListStyle]);

  useEffect(
    () => {
      const combinedFilter:{
        and: any[]
      } = {
        and: [],
      };
      let crossOrganization = false;
      const sortValues = Schema.getSortValues(modelName);
      if (filters.includes('searchText') && searchText?.length) {
        const searchableList = Schema.getSearchablePropertyList(modelName);
        const emptyOr : any[] = [];
        const searchFilter = {
          or: emptyOr,
        };
        searchableList.forEach((searchField:any) => {
          searchFilter.or.push({ [searchField]: { matchPhrasePrefix: `${searchText.toLowerCase()}` } });
        });
        combinedFilter.and.push(searchFilter);
      }

      // means both Libraries and LibraryShares for this Organization or this portal
      // if no libraries selected - the base filter will already have the right library filters
      if (
        (
          filters.includes('library')
          || filters.includes('combinedLibrary')
          || filters.includes('portalLibraries')
        ) && searchLibraries.length > 0
      ) {
        const libraryFilter = {
          or: searchLibraries.map((id:string) => ({ libraries: { matchPhrase: id } })),
        };
        combinedFilter.and.push(libraryFilter);
        crossOrganization = true;
      }
      else if (filters.includes('combinedLibrary'))
      {
        // Add the shared libraries for the Org or Courses in the Org
        const organizationFilter = store.currentOrganization?.id
          ? { organizationID: { eq: store.currentOrganization.id } }
          : null;

        const librariesToSearch = [store.currentPortal?.defaultLibrary?.id];
        store.currentPortal?.libraryShares.forEach(({libraryID}) => {
          librariesToSearch.push(libraryID);
        });
        const libraryFilter = {
          or: [
            ...librariesToSearch.map((id: string) => ({ libraries: { matchPhrase: id } })),
            ...(organizationFilter ? [organizationFilter] : []),
          ],
        };
        combinedFilter.and.push(libraryFilter);
        crossOrganization = true;
      }

      if (filters.includes('membership') && searchMemberships.length > 0) {
        const membershipFilter:{ or: any[] } = {
          or: [],
        };
        searchMemberships.forEach((id) => {
          membershipFilter.or.push({ membershipID: { eq: id } });
        });
        combinedFilter.and.push(membershipFilter);
      }

      if (filters.includes('pastFuture')) {
        if (searchPastFuture === 'Past') {
          const statusFilter = {
            or: [
              { status: { eq: 'POST_MEETING' } },
              { status: { eq: 'CANCELLED' } },
            ],
          };
          combinedFilter.and.push(statusFilter);
        } else if (searchPastFuture === 'Upcoming') {
          const statusFilter = {
            or: [
              { status: { eq: 'PRE_MEETING' } },
              { status: { eq: 'OPEN' } },
              { status: { eq: 'ACTIVE' } },
            ],
          };
          combinedFilter.and.push(statusFilter);
        }
      }

      if (filters.includes('activeStatusOnly') && activeStatusOnly) {
        const statusFilter = { status: { eq: 'ACTIVE' } };
        combinedFilter.and.push(statusFilter);
      }

      if (Object.keys(filter).length > 0) {
        if (filter === 'currentPortal') {
          console.log('Limit to current portal');
          combinedFilter.and.push({
            or: [
              {
                portalID: {
                 eq: store.currentPortal?.id,
                },
              },
              {
                portalID: {
                 eq: store.currentPortal?.key,
                },
              },
            ],
          });
        } else if (filter === 'targetOrganization') {
          // its a function that resolves to a filter
          combinedFilter.and.push(
            {
              targetOrganizationID: { eq: store.currentOrganization?.id },
            },
          );
          crossOrganization = true;
        } else {
          // its a static filter
          combinedFilter.and.push(filter);
        }
      }

      setCachedList(
        store.getCachedList(
          modelName,
          combinedFilter.and.length > 0 ? combinedFilter : {},
          sortValues,
          crossOrganization,
        ),
      );
    },
    [
      store,
      store.currentOrganization,
      store.currentPortal,
      modelName,
      filter,
      filters,
      activeStatusOnly,
      searchLibraries,
      searchMemberships,
      searchPastFuture,
      searchText,
    ],
  );

  useEffect(
    () => {
      // if search criteria have changed, go back to the first page
      setPage(0);
    },
    [
      activeStatusOnly,
      searchLibraries,
      searchMemberships,
      searchText,
    ],
  );

  // todo - add filter and sorting here too
  const refresh = async () => {
    store.clearCachedList(modelName);
    await store.fetchCachedList(cachedList);
    setPage(0);
  };

  const handleNext = () => {
    if (cachedList && cachedList.hasMorePages(itemsPerPage, page + 1)) {
      setPage(page + 1);
    }
  };

  const handlePrevious = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  return (
    <>
      <Toolbar disableGutters>
        {filters.includes('pastFuture') && (
          <Select
            id="pastFutureSelect"
            onChange={(event) => {
              setSearchPastFuture(event.target.value);
            }}
            value={searchPastFuture || 'Future'}
            name="Filter By"
            size="small"
            sx={{ mx: 1 }}
          >
            {['Past', 'Upcoming', 'All'].map((option:string) => (
              <MenuItem
                key={option}
                value={option}
              >
                {option}
              </MenuItem>
            ))}
          </Select>
        )}
        {filters.includes('searchText') && cachedList?.hasSearch() && (
          <TextField
            size="small"
            placeholder="Search..."
            value={searchText}
            onChange={(event) => { setSearchText(event.target.value); }}
            InputProps={{
              endAdornment: (
                <IconButton onClick={() => { setSearchText(''); }} disabled={searchText === ''}>
                  <ClearIcon fontSize="small" />
                </IconButton>
              ),
            }}
          />
        )}
        {filters.includes('library') && (
          <Box sx={{ minWidth: '300px', maxWidth: '500px', margin: 1 }}>
            <ReferenceTagsInput
              modelName="library"
              size="small"
              placeholder="Filter by library..."
              value={searchLibraries}
              onChange={(newValue) => {
                setSearchLibraries(
                  newValue,
                  // newValue.map(
                  //   (id) => store.getOrLoad('library', id),
                  // ),
                );
              }}
              disabled={false}
            />
          </Box>
        )}
        {filters.includes('combinedLibrary') && (
          <Box sx={{ minWidth: '300px', maxWidth: '500px', margin: 1 }}>
            <ReferenceTagsInput
              modelName="combinedLibrary"
              size="small"
              placeholder="Filter by library/shared library..."
              value={searchLibraries}
              onChange={(newValue) => {
                setSearchLibraries(
                  newValue,
                  // newValue.map(
                  //   (id) => store.getOrLoad('library', id),
                  // ),
                );
              }}
              disabled={false}
            />
          </Box>
        )}
        {filters.includes('portalLibraries') && (
          <Box sx={{ minWidth: '300px', maxWidth: '500px', margin: 1 }}>
            <ReferenceTagsInput
              modelName="portalLibraries"
              size="small"
              placeholder="Filter by portal library..."
              value={searchLibraries}
              onChange={(newValue) => {
                setSearchLibraries(
                  newValue,
                  // newValue.map(
                  //   (id) => store.getOrLoad('library', id),
                  // ),
                );
              }}
              disabled={false}
            />
          </Box>
        )}
        {filters.includes('membership') && (
          <Box sx={{ minWidth: '200px', maxWidth: '500px', margin: 1 }}>
            <ReferenceTagsInput
              modelName="membership"
              size="small"
              placeholder="Filter by membership..."
              value={searchMemberships}
              portalScoped
              onChange={(newValue) => {
                setSearchMemberships(
                  newValue,
                  // newValue.map(
                  //   (id) => store.getOrLoad('library', id),
                  // ),
                );
              }}
              disabled={false}
            />
          </Box>
        )}
        {filters.includes('activeStatusOnly') && (
          <Box sx={{ minWidth: '200px', maxWidth: '300px', margin: 1 }}>
            <Checkbox
              checked={activeStatusOnly}
              onChange={(event) => {
                setActiveStatusOnly(event.target.checked);
              }}

            />
            <FormLabel>Active Only</FormLabel>
          </Box>
        )}
        <Box sx={{ flexGrow: 1 }} />
        {cachedList?.totalCount !== -1 && (
          <Typography variant="h5">
            {cachedList?.totalCount}
            {' '}
            items
          </Typography>
        )}
        <Box sx={{ flexGrow: 1 }} />
        {!readOnly && handleNew && (
          <Button
            color="primary"
            variant="contained"
            onClick={handleNew}
            sx={{ marginX: 1 }}
          >
            {handleNewLabel || `New ${Schema.getModel(modelName).name}`}
          </Button>
        )}
        {listBarActions?.map((action) => (
          <Button
            key={action.label}
            color="primary"
            variant="contained"
            onClick={() => action.action(undefined, navigate)}
            sx={{ marginX: 1 }}
          >
            {action.label}
          </Button>
        ))}
        {availableListStyles?.length > 1 && (
          <ToggleButtonGroup
            sx={{ marginX: 2 }}
            exclusive
            value={listStyle}
            onChange={(event, newValue) => {
              setListStyle(newValue);
              // its a choice the user made so remember it
              // and use except when it is not an available choice.
              setPreferredListStyle(newValue);
            }}
          >
            {availableListStyles?.includes('list') && <ToggleButton value="list"><ListIcon /></ToggleButton>}
            {availableListStyles?.includes('grid') && <ToggleButton value="grid"><GridIcon /></ToggleButton>}
          </ToggleButtonGroup>
        )}
        <IconButton
          sx={{ margingX: 4 }}
          onClick={refresh}
          id="reload-items"
        >
          <ReloadIcon />
        </IconButton>
        <Pagination
          currentPage={page + 1}
          hasNext={cachedList ? cachedList.hasMorePages(itemsPerPage, page + 1) : false}
          hasPrevious={page > 0}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
        />
      </Toolbar>
      <PaginatedItemList
        key={cachedList?.id}
        modelName={modelName}
        listStyle={listStyle}
        cachedList={cachedList}
        page={page}
        itemsPerPage={itemsPerPage}
        defaultAction={defaultAction}
        actions={actions}
        imageField={imageField}
        titleField={titleField}
        secondaryFields={secondaryFields}
        multiSelect={multiSelect}
        selectedKeys={selectedKeys}
        preSelectedKeys={preSelectedKeys}
        augmentData={augmentData}
        updateSelectedItems={updateSelectedItems}
        showSelect={showSelect}
        enableDeselect={enableDeselect}
        actionsStyle={actionsStyle}
        useLibraryModel={useLibraryModel}
        libraryID={libraryID}
      />
    </>
  );
};

ItemNavigator.defaultProps = {
  imageField: undefined,
  titleField: undefined,
  secondaryFields: undefined,
  defaultAction: undefined,
  actions: [],
  listBarActions: [],
  filter: {},
  handleNew: undefined,
  handleNewLabel: undefined,
  multiSelect: false,
  selectedKeys: [],
  preSelectedKeys: [],
  // eslint-disable-next-line react/default-props-match-prop-types
  disableKeys: [],
  updateSelectedItems: undefined,
  augmentData: undefined,
  actionsStyle: 'menu',
  availableListStyles: ['list', 'grid'],
  useLibraryModel: false,
  libraryID: undefined,
  filters: ['searchText'],
  readOnly: false,
};

export default observer(ItemNavigator);
