import React from 'react';
import { FormHelperText, SvgIcon, Typography } from '@mui/material';
import { Info as InfoIcon } from '@mui/icons-material';

type HelperTextProps = {
  name: string,
  error?: string | false | undefined,
  description?: string | undefined,
};

const HelperText = ({ name, error, description }:HelperTextProps) => (
  <>
    {error && (
      <FormHelperText error component="div" id={`helper-text-${name}`}>
        <SvgIcon>
          <InfoIcon />
        </SvgIcon>
        <Typography variant="body2" component="div">
          {error}
        </Typography>
      </FormHelperText>
    )}
    {description && (
      <FormHelperText component="div" id={`description-text-${name}`}>
        <Typography variant="body2" component="div">
          {description}
        </Typography>
      </FormHelperText>
    )}
  </>
);

HelperText.defaultProps = {
  error: undefined,
  description: undefined,
};

export default HelperText;
