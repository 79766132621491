/**
 * Field shows the comma seperated list of values as chips inside a TextField input element.
 * The value stored in Formik is the comma seperated list.
 * The TagsInput is where all the actual work is done - it expects its input to be
 * an array of tags and when a tag is added or removed it calls onChange with the new list of tags.
 */
import React from 'react';
import {
  FormControlLabel, FormLabel,
  Stack, TextField,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { parseDuration } from '@mcn-platform/models';
import { Controller } from 'react-hook-form';
import { StandardFieldProps } from './FieldTypes';

const StandardDurationField = ({
  control,
  fieldName,
  field,
  readOnly,
}:StandardFieldProps) => (
  <Controller
    name={fieldName}
    control={control}
    render={({
      field: {
        onChange,
        onBlur,
        value,
        // name,
        // ref,
      },
      fieldState: {
        // invalid,
        isTouched,
        // isDirty,
        error,
      },
    }) => {
      const updateTime = (h:number, m:number) => {
        console.log('Update to ', { h, m });
        if (h < 0 || m < 0) {
          // don't allow it
          return;
        }

        if (m > 59) {
          onChange(`${h + Math.floor(m / 60)}:${m % 60}`);
        } else {
          onChange(`${h}:${m}`);
        }
      };

      const { hours, minutes } = parseDuration(value);
      const disabled = field.readOnly || readOnly;
      return (
        <FormControlLabel
          label={field.label}
          disabled={field.readOnly || readOnly}
          labelPlacement="top"
          sx={{ alignItems: 'flex-start' }}
          control={(
            <Stack
              direction="row"
              spacing={1}
              justifyContent="flex-start"
              alignItems="center"
            >
              <TextField
                id={`${fieldName}.hours`}
                name={`${fieldName}.hours`}
                type="number"
                placeholder="Hours"
                value={hours}
                onChange={(e:any) => updateTime(
                  e.target.value,
                  minutes,
                )}
                onBlur={onBlur}
                // disabled={disabled}
                error={isTouched && Boolean(error)}
                inputProps={{
                  'aria-describedby': `helper-text-${fieldName}`,
                }}
                disabled={disabled}
              />
              <FormLabel disabled={disabled}>
                hours
              </FormLabel>
              <TextField
                id={`${fieldName}.minutes`}
                name={`${fieldName}.minutes`}
                type="number"
                placeholder="Minutes"
                value={minutes}
                onChange={(e:any) => updateTime(
                  hours,
                  e.target.value,
                )}
                onBlur={onBlur}
                // disabled={disabled}
                error={isTouched && Boolean(error)}
                inputProps={{
                  'aria-describedby': `helper-text-${fieldName}`,
                }}
                disabled={disabled}
              />
              <FormLabel disabled={disabled}>
                minutes
              </FormLabel>
            </Stack>
          )}
        />
      );
    }}
  />
);

export default observer(StandardDurationField);
