import React from 'react';
import { useNavigate } from 'react-router';
import {
  IconButton,
  Menu,
  MenuItem,
  Stack,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useStore } from '@mcn-platform/models';
import { CheckCirclePrimary } from 'icons/CheckCircleIcon';
import CircleIcon from 'icons/CircleIcon';

export type Action = {
  label: string;
  default?: boolean;
  action: (item: any, navigate: any, store: any) => void;
  disabled: (item: any, store: any) => boolean | undefined;
};

const ActionMenu = ({
  item,
  showSelect,
  actions,
  selected,
  defaultAction,
}: {
  item: any;
  showSelect: boolean;
  defaultAction?: (item: any, navigate: any, store: any) => void;
  actions: Action[];
  selected: boolean;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const store = useStore();
  const navigate = useNavigate();
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack direction="row" alignItems="baseline">
      {actions.length > 0 && (
        <>
          <IconButton aria-label="settings" onClick={handleMenuClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            open={open}
            anchorEl={anchorEl}
            onClick={handleMenuClose}
            onClose={handleMenuClose}
          >
            {actions.map((action) => (
              <MenuItem
                key={action.label}
                onClick={() => {
                  handleMenuClose();
                  action.action(item, navigate, store);
                }}
                disabled={action.disabled && action.disabled(item, store)}
              >
                {action.label}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
      {showSelect && (
        <IconButton
          data-testid="chooseButton"
          sx={{ color: 'success.main' }}
          onClick={() => {
            if (defaultAction) {
              defaultAction(item, navigate, store);
            }
          }}
        >
          {selected ? <CheckCirclePrimary /> : <CircleIcon />}
        </IconButton>
      )}
    </Stack>
  );
};

ActionMenu.defaultProps = {
  defaultAction: undefined,
};

export default ActionMenu;
