import React, { ReactElement } from 'react';
import { observer } from 'mobx-react-lite';
import { ShowMoreText } from '@mcn-platform/portal-lib';
import { get } from 'lodash';
import { ItemField } from './ItemTypes';

const FieldValue = ({ item, field }:{ item:any, field: ItemField }):ReactElement => {
  const value = typeof item[field.name] === 'function' ? item[field.name]() : get(item, field.name);
  const Formatter = field.formatter;
  if (Formatter) {
    return (
      <Formatter value={value} item={item} field={field} />
    );
  }

  if (value === undefined || value === null) {
    return (<>-</>);
  }

  if (value instanceof Date) {
    return (
      <>
        {value.toDateString()}
      </>
    );
  }
  if (field.showMore) {
    return (
      <ShowMoreText text={value.toString()} cutOff={200} />
    );
  }
  return (
    <>
      {value?.toString()}
    </>
  );
};

export default observer(FieldValue);
