import React from 'react';
import { IDocument, useStore } from '@mcn-platform/models';
import { observer } from 'mobx-react-lite';
import { baseUrl } from '@mcn-platform/portal-lib';
import CopyLinkButton from './CopyLinkButton';

const DocumentPageLinkButton = ({ document }:{ document:IDocument }) => {
  const store = useStore();
  if (!(store.currentOrganization && store.currentPortal)) {
    return null;
  }

  const url = `${baseUrl(store, store.currentOrganization, store.currentPortal)}/document/${document?.slug || document?.key}`;

  return (
    <CopyLinkButton label="Document Page URL" url={url} />
  );
};

export default observer(DocumentPageLinkButton);
