import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';

import awsExports from 'aws-exports';
import { AwsRum, AwsRumConfig } from 'aws-rum-web';

import {
  configureAdminClient,
  StoreProvider,
  adminStore,
} from '@mcn-platform/models';
import { parseAdminRequestPath } from '@mcn-platform/portal-lib';
import App from './App';
import reportWebVitals from './reportWebVitals';

declare global {
  interface Window { store: any; }
}

if (window.location.hostname !== 'localhost') {
  try {
    const config: AwsRumConfig = {
      sessionSampleRate: 1,
      guestRoleArn: 'arn:aws:iam::558286451067:role/RUM-Monitor-us-east-1-558286451067-7095850338861-Unauth',
      identityPoolId: 'us-east-1:a56c125b-4bde-4901-88d5-fcaea348ea4d',
      endpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
      telemetries: ['performance', 'errors', 'http'],
      allowCookies: true,
      enableXRay: true,
    };

    const APPLICATION_ID = '70107128-6a14-471c-a5b9-51d73764216f';
    const APPLICATION_VERSION = '1.0.0';
    const APPLICATION_REGION = 'us-east-1';

    const awsRum: AwsRum = new AwsRum(
      APPLICATION_ID,
      APPLICATION_VERSION,
      APPLICATION_REGION,
      config,
    );
    console.log('Rum is', awsRum);
  } catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
  }
} else {
  console.log('Skipping AWS RUM on localhost');
}

const url = new URL(window.location.href);
let preferredOrganizationID:string | undefined;
if (url.pathname.startsWith('/portal')) {
  preferredOrganizationID = parseAdminRequestPath(url.pathname).organizationId;
}
configureAdminClient(awsExports, preferredOrganizationID);

const rootNode = document.getElementById('root');
if (rootNode) {
  const root = ReactDOM.createRoot(rootNode);
  root.render(
    <HelmetProvider>
      <StoreProvider value={adminStore}>
        <React.StrictMode>
          {/* <BrowserRouter> */}
          <App />
          {/* </BrowserRouter> */}
        </React.StrictMode>
      </StoreProvider>
    </HelmetProvider>,
  );
  window.store = adminStore;
} else {
  console.log('Error: Root node not found');
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
