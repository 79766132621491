import { observer } from 'mobx-react-lite';

const CertificateNameFormatter = observer(({ item }: any) => {
  // console.log('AJ item.certification', item.certifications.length);
  if (item.certifications) {
    // console.log(`AJ ${item?.certifications[0]?.name}`);
    return item?.certifications[0]?.name || null;
  }
  return '-';
});

export default CertificateNameFormatter;
