import React from 'react';

import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Box, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { SnackbarProvider } from 'notistack';
import '@aws-amplify/ui-react/styles.css';
import adminTheme from 'theme/admin/adminTheme';
import 'App.css';
import { adminRoutes } from 'routes';
import Logo from 'components/Logo';
import AdminGlobalStyles from 'components/AdminGlobalStyles';

const router = createBrowserRouter(adminRoutes);

const BasePage = () => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <ThemeProvider theme={adminTheme}>
      <AdminGlobalStyles />
      <SnackbarProvider>
        <RouterProvider router={router} />
      </SnackbarProvider>
    </ThemeProvider>
  </LocalizationProvider>
);

const AdminPage = (/* { isPassedToWithAuthenticator, signOut, user }:any */) => (
  <BasePage />
);

const App = withAuthenticator(
  AdminPage,
  {
    components: {
      Header: () => (
        <Box margin={5} display="flex" justifyContent="center" alignItems="center">
          <Logo />
        </Box>
      ),
    },
  },
);

export default App;
