/* eslint-disable no-plusplus */
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useDrag } from 'react-dnd';
import {
  Box,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

type DraggableItemProps = {
  item: any;
  handleDelete: (item: any) => void;
  dndType: string;
};
const DraggableItem = ({
  item,
  handleDelete,
  dndType,
}:DraggableItemProps) => {
  const [{ isDragging }, dragRef, previewRef] = useDrag({
    type: dndType,
    item,
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <Box
      sx={{
        opacity: isDragging ? 0.5 : 1,
        backgroundColor: 'inherit',
      }}
      ref={previewRef}
    >
      <ListItem
        disableGutters
        dense
        sx={{ marginBottom: 0 }}
        secondaryAction={(
          <DeleteIcon onClick={() => handleDelete(item)} />
        )}
      >
        <ListItemIcon ref={dragRef} sx={{ cursor: 'grab' }}>
          <DragIndicatorIcon />
        </ListItemIcon>
        <ListItemText>
          {item.name}
        </ListItemText>
      </ListItem>
    </Box>
  );
};

export default observer(DraggableItem);
