import { useCallback, useEffect } from 'react';
import { useBlocker } from 'react-router-dom';
import { navStore } from '@mcn-platform/models';

const usePrompt = ({
  isDirty = false,
  title = 'Save Changes',
  message = 'You have unsaved changes. Are you sure you want to leave?',
  confirmButtonLabel = 'Leave',
  cancelButtonLabel = 'Cancel',
  onConfirm,
  onCancel,
}: {
  title?: string,
  message?: string,
  confirmButtonLabel?: string,
  cancelButtonLabel?: string,
  onConfirm?: () => void,
  onCancel?: () => void,
  isDirty?: boolean 
}) => {
  const blocker = useBlocker(({ currentLocation, nextLocation }) => (
    isDirty &&
    currentLocation.pathname !== nextLocation.pathname
  ));

  const confirm = useCallback(() => {
    if (!isDirty) return Promise.resolve(true);

    return new Promise<boolean>((resolve) => {
      navStore.showConfirmDialog(
        title,
        message,
        () => {
          resolve(true);
          onConfirm?.();
        },
        confirmButtonLabel,
        () => {
          resolve(false);
          onCancel?.();
        },
        cancelButtonLabel,
      );
    });
  }, [
    cancelButtonLabel,
    confirmButtonLabel,
    isDirty,
    onCancel,
    onConfirm,
    message,
    title,
  ]);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      confirm().then((result) => {
        if (result) blocker.proceed();
        else blocker.reset();
      });
    }
  }, [blocker, confirm]);

  useEffect(() => {
    if (isDirty) {
      window.onbeforeunload = () => message;
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [isDirty, message]);

  return {
    confirm,
  };
};

export default usePrompt;
