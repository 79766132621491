import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

const FilterAccordion = ({
  children,
}: {
  children: React.ReactNode,
}) => {
  const [open, setOpen] = useState<boolean>(true);
  return (
    <Accordion
      expanded={open}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        sx={{
          flexDirection: 'row-reverse',
        }}
        onClick={() => setOpen(!open)}
      >
        Filter and Sort
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default FilterAccordion;
