/**
 * Field shows the comma seperated list of values as chips inside a TextField input element.
 * The value stored in Formik is the comma seperated list.
 * The TagsInput is where all the actual work is done - it expects its input to be
 * an array of tags and when a tag is added or removed it calls onChange with the new list of tags.
 */
import React from 'react';
import { FormControlLabel } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Controller } from 'react-hook-form';
import { StandardFieldProps } from './FieldTypes';
import TagsInput from './TagsInput';

const StandardTagsField = ({
  control,
  fieldName,
  field,
  readOnly,
}:StandardFieldProps) => (
  <Controller
    name={fieldName}
    control={control}
    render={({
      field: {
        onChange,
        onBlur,
        value,
        // name,
        // ref,
      },
      fieldState: {
        // invalid,
        isTouched,
        // isDirty,
        error,
      },
    }) => (
      <FormControlLabel
        label={field.label}
        disabled={field.readOnly}
        labelPlacement="top"
        control={(
          <TagsInput
            id={fieldName}
            name={fieldName}
            placeholder={field.placeholder}
            options={field.enumLabels}
            value={value ? value.split(',') : []}
            onChange={(tags) => { onChange(tags.join(',')); }}
            onBlur={onBlur}
            disabled={Boolean(field.readOnly || readOnly)}
            // error={isTouched && !!error}
            // helperText={meta.touched && meta.error}
            fullWidth
          />
        )}
      />
    )}
  />
);

StandardTagsField.defaultProps = {
  placeholder: '',
  disabled: false,
  options: [],
};

export default observer(StandardTagsField);
