import React from 'react';
import { FormControlLabel } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { observer } from 'mobx-react-lite';
import { Controller } from 'react-hook-form';
import dayjs from 'dayjs';
import { StandardFieldProps } from './FieldTypes';

const StandardDateTimeField = ({
  control,
  fieldName,
  field,
  readOnly,
}:StandardFieldProps) => (
  <Controller
    name={fieldName}
    control={control}
    render={({
      field: {
        onChange,
        onBlur,
        value,
        name,
      },
      fieldState: {
        // invalid,
        isTouched,
        // isDirty,
        error,
      },
    }) => (
      <FormControlLabel
        label={field.label}
        disabled={field.readOnly}
        labelPlacement="top"
        sx={{ alignItems: 'flex-start' }}
        control={(
          <DateTimePicker
            slotProps={{
              textField: {
                name: fieldName,
                id: fieldName,
                onBlur,
                error: isTouched && !!error,
              },
            }}
            name={name}
            value={value ? dayjs(value) : null}
            onChange={(date) => {
              console.log('New timestamp', date);
              onChange(date ? date.toDate() : null);
            }}
            disabled={field.readOnly || readOnly}
          />
        )}
      />
    )}
  />
);

export default observer(StandardDateTimeField);
