import React from 'react';
import { observer } from 'mobx-react-lite';
import {
  Box,
  FormControl,
  FormLabel,
  Typography,
} from '@mui/material';
import {
  Tokens,
  TokensProvider,
  useStore,
  useTokens,
} from '@mcn-platform/models';
import { Controller } from 'react-hook-form';
import { WysiwygEditor, WysiwygViewer, serialize } from '@mcn-platform/portal-lib';
import { StandardFieldProps } from './FieldTypes';
import GenerateAIDialog from './GenerateAIDialog';
import GenerateAiFormLabel from './GenerateAiFormLabel';

const promptList = [
  // { id: '0', value: 'Select Prompt', prompt: '' },
  'Summarize the lesson',
  'Summarize key points in this lesson',
  'Create a summary of this lesson using bullet points',
];

const StandardRichTextField = ({
  control,
  fieldName,
  field,
  readOnly,
}: StandardFieldProps) => {
  const { currentPortal } = useStore();
  const tokens = useTokens();
  const [aiDialogOpen, setAiDialogOpen] = React.useState(false);
  const [wysiwygID, setWysiwygID] = React.useState<string>(fieldName);
  return (
    <TokensProvider value={new Tokens(currentPortal)}>
      <Controller
        name={fieldName}
        control={control}
        render={({
          field: {
            onChange,
            onBlur,
            name,
            value,
          },
          fieldState: {
            isTouched,
            error,
          },
        }) => {
          const updatedValue = (slateValue:any) => {
            // console.log('slate value', slateValue);
            const newValue = {
              slate: slateValue,
              html: serialize(slateValue),
            };
            console.log('New rich text value', newValue);
            return newValue;
          };

          const handleGenerateFieldCancel = () => {
            setAiDialogOpen(false);
          };

          const handleGenerateFieldConfirm = (newValue: string) => {
            setAiDialogOpen(false);
            onChange(updatedValue(newValue));
            setWysiwygID(`${fieldName}.${Date.now()}`);
          };

          /**
             * Takes the value output by Plate/Slate and converts to mixed format of:
             * {
             *  slate: []
             *  html: string
             * }
             * @param slateValue
             */
          // eslint-disable-next-line no-underscore-dangle
          const itemKey = field.aiTarget === 'asset' ? control._formValues.item?.asset : control._formValues.key;

          const label = field.tokenKey ? tokens.getToken(field.tokenKey) : field.label;
          return (
            <FormControl fullWidth id={fieldName}>
              <FormLabel className="WysiwygEditor-formlabel" htmlFor={fieldName}>
                {!readOnly && field.aiTarget ? (
                  <GenerateAiFormLabel label={label} onClick={() => setAiDialogOpen(true)} />
                ) : (
                  <Typography color="inherit" fontWeight="inherit" component="div">
                    {label}
                  </Typography>
                )}
              </FormLabel>
              <Box
                sx={[{
                  width: '100%',
                  border: '1px solid',
                  borderColor: '#e0e0eb',
                  borderRadius: 1,
                  padding: 2,
                },
                {
                  '&:hover': {
                    borderColor: '#111111',
                  },
                },
                {
                  '&:focus': {
                    border: '2px solid #1276dd',
                  },
                },
                ]}
              >
                {readOnly ? (
                  <WysiwygViewer
                    key={wysiwygID}
                    id={wysiwygID}
                    value={value?.slate || undefined}
                  />
                ) : (
                  <WysiwygEditor
                    key={wysiwygID}
                    id={wysiwygID}
                    value={value?.slate || undefined}
                    setValue={(newValue:any) => { onChange(updatedValue(newValue)); }}
                    onBlur={onBlur}
                    name={name}
                    error={isTouched && !!error}
                  />
                )}
              </Box>
              { !readOnly && field?.aiTarget && (
                <GenerateAIDialog
                  open={aiDialogOpen}
                  title="Generate AI"
                  promptList={field.promptList || promptList}
                  itemType={field.aiTarget}
                  itemKey={itemKey}
                  onConfirm={handleGenerateFieldConfirm}
                  onCancel={handleGenerateFieldCancel}
                  onClose={() => { setAiDialogOpen(false); }}
                  isRichText
                />
              )}
            </FormControl>
          );
        }}
      />
    </TokensProvider>
  );
};

export default observer(StandardRichTextField);
