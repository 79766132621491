/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box, Typography } from '@mui/material';
import { SxProps } from '@mui/material/styles';

const TextCircle = (
  {
    outlined, innerText, wrapperStyle, textStyle,
  }:
  { outlined?: boolean, innerText: string, wrapperStyle?: SxProps, textStyle?: SxProps },
) => (
  <Box
    sx={{
      textAlign: 'center',
      borderRadius: '50%',
      backgroundColor: outlined ? 'transparent' : 'primary.main',
      width: outlined ? 36 : 40,
      minWidth: outlined ? 36 : 40,
      height: outlined ? 36 : 40,
      border: outlined ? 2 : 'none',
      borderColor: outlined ? 'primary.main' : 'none',
      ...wrapperStyle,
    }}
  >
    <Typography
      component="span"
      sx={{
        height: outlined ? 36 : 40,
        lineHeight: outlined ? '34px' : '40px',
        fontSize: (theme) => theme.typography.subtitle1.fontSize,
        fontWeight: 'fontWeightBold',
        color: outlined ? 'primary.main' : 'text.secondary',
        letterSpacing: 'normal',
        ...textStyle,
      }}
    >
      {innerText || '--'}
    </Typography>
  </Box>
);

TextCircle.defaultProps = {
  outlined: false,
  wrapperStyle: {},
  textStyle: {},
};

export default TextCircle;
