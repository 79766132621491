import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Stack, Typography } from '@mui/material';
import { useStore } from '@mcn-platform/models';
import { AssetImage } from '@mcn-platform/portal-lib';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const AdminAvatar = () => {
  const store = useStore();
  const user = store.currentUser;
  if (!user) {
    return null;
  }
  return (
    <Stack
      sx={{
        py: 3.75,
        pl: 4.5,
        pr: 5.125,
        flexGrow: 1,
        background: (theme) => theme.palette.backgroundSecondary,
        cursor: 'pointer',
        '&:hover': {
          background: (theme) => theme.palette.action.hover,
        },
      }}
      direction="row"
      justifyContent="space-between"
    >
      <Stack spacing={1.5} direction="row">
        <RouterLink to="/app/account">
          <AssetImage
            customStyle={{
              marginRight: '14px',
              width: 40,
              height: 40,
              borderRadius: 20,
            }}
            asset={user.profileImage}
            size="thumb400w"
          />
        </RouterLink>
        <Stack spacing={0.5}>
          <Typography
            variant="h6"
            fontWeight="fontWeightBold"
            color="textPrimary"
            component="div"
          >
            {user.name}
          </Typography>
          <Typography
            variant="body2"
            color="secondary"
            component="div"
          >
            {user.title}
          </Typography>
        </Stack>
      </Stack>
      <KeyboardArrowDownIcon fontSize="small" />
    </Stack>
  );
};

export default observer(AdminAvatar);
