import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { IRegistration, useStore } from '@mcn-platform/models';
// import { RegistrationWidget } from '@mcn-platform/portal-lib';

const RegistrationList = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  organization,
  portal,
  customer,
}:any) => {
  const store = useStore();
  const [selectedRegistration, setSelectedRegistration] = useState<IRegistration | undefined>();

  const registrationList = store.getCachedList('meetingRegistration', {
    portalID: { eq: portal.id },
    customerID: { eq: customer.id },
  });
  registrationList.setRequestedCount(1000);

  return (
    <div>
      Meeting Registration List
      <Grid container sx={{ height: '100%' }}>
        <Grid item xs={12} md={5} sx={{ height: '100%', overflow: 'hidden', pr: 2 }}>
          <Card sx={{ height: '100%', overflow: 'auto' }}>
            <CardContent>
              <List>
                {registrationList?.getItems().map((registration) => (
                  <ListItem
                    key={registration.id}
                    onClick={() => setSelectedRegistration(registration)}
                  >
                    {registration.id}
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={7} sx={{ height: '100%', overflow: 'hidden' }}>
          <Card sx={{ height: '100%', overflow: 'auto' }}>
            <CardContent>
              <Box sx={{ minHeight: '100%' }}>
                {selectedRegistration?.id}
                {/* <RegistrationWidget registrationID={selectedRegistration?.id} readonly /> */}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default observer(RegistrationList);
