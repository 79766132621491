import React from 'react';
import { Box, Collapse } from '@mui/material';
import { useDrop } from 'react-dnd';
import { ILearningBoard } from '@mcn-platform/models';
import { ItemTypes } from 'publisher/editor/DragConstants';

const getStyle = (canDrop:boolean, isOver:boolean) => {
  if (!canDrop) {
    return {
      borderColor: 'red',
      backgroundColor: 'red',
      opacity: '.5',
    };
  }
  if (isOver) {
    return {
      borderColor: 'green',
      backgroundColor: 'green',
      opacity: '.5',
    };
  }
  return {
    backgroundColor: 'white',
    borderColor: '#aaa',
  };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DropTarget = ({
  index,
  depth,
  learningBoard,
}:{
  index: number,
  depth: number,
  learningBoard: ILearningBoard
}) => {
  const [{ canDrop, isOver }, dropRef] = useDrop({
    accept: [ItemTypes.LEARNING_BOARD_SECTION],
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      };
    },
    drop(item: any /* monitor */) {
      console.log(`Move to index ${index}`, item);
      learningBoard.moveSection(item, index);
    },
    // canDrop(item: any /* , monitor: any */) {
    //   // console.log('checking can drop', { item, monitor });
    //   return learningBoard.canReceive(item, section, index);
    // },
  });

  // timeout on collapse doesnt work with absolute positioning
  // zIndex is based on depth so deeper nestings appear over top of the shallow ones
  // making them easier to select and it looks better
  return (
    <Collapse in={canDrop} ref={dropRef} timeout={10}>
      <Box sx={{ position: 'relative', zIndex: 10 + depth, pl: depth * 4 }}>
        <Box sx={{
          position: 'absolute',
          width: '100%',
          top: -9,
          height: 12,
          border: 1,
          borderRadius: 1,
          borderStyle: 'dashed',
          ...getStyle(canDrop, isOver),
        }}
        />
      </Box>
    </Collapse>
  );
};

export default DropTarget;
