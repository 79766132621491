import React from 'react';
import { useNavigate } from 'react-router';
import {
  Box,
  Card,
  CardHeader,
  CardActionArea,
  Divider,
  Grid,
  Typography,
  Stack,
} from '@mui/material';
import { AssetImage } from '@mcn-platform/portal-lib';
import placeholderImage from 'assets/images/placeholder400.jpg';
import { ItemField } from './ItemTypes';
import ActionMenu, { Action } from './ActionMenu';
import FieldValue from './FieldValue';

export type ItemCardProps = {
  item: any;
  selected: boolean;
  defaultAction: any;
  actions: Action[];
  imageField: ItemField;
  titleField: ItemField;
  secondaryFields: ItemField[];
  showSelect: boolean;
};

const CardImage = ({ item, imageField }:any) => {
  const targetField = imageField && imageField.name === '' ? item : item[imageField.name];
  const target = (targetField instanceof Function) ? targetField() : targetField;
  // console.log('Target', target);
  return (
    <AssetImage
      asset={target}
      customStyle={{ width: '100%', height: '100%', objectFit: 'cover' }}
      fallbackSrc={placeholderImage}
      size={target?.type === 'video' ? 'videoThumb' : 'thumb400w'}
    />
  );
};

const ItemCard = ({
  item,
  selected,
  defaultAction,
  actions,
  imageField,
  titleField,
  secondaryFields,
  showSelect,
}:ItemCardProps) => {
  const navigate = useNavigate();
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      {imageField && (
        <CardActionArea
          sx={{ height: '200px' }}
          // onClick={
          //   () => actions.filter((ac:any) => ac.name === 'edit')?.[0]?.action(item, navigate)}
          onClick={() => {
            if (defaultAction) {
              defaultAction(item, navigate);
            }
          }}
        >
          <CardImage item={item} imageField={imageField} />
        </CardActionArea>
      )}
      <CardHeader
        title={item[titleField.name]}
        action={(
          <ActionMenu
            item={item}
            defaultAction={defaultAction}
            actions={actions}
            selected={selected}
            showSelect={showSelect}
          />
        )}
      />
      <Box sx={{ flexGrow: 1 }} />
      <Divider />
      <Box sx={{ p: 2 }}>
        <Grid
          container
          spacing={2}
          sx={{ justifyContent: 'space-between' }}
        >
          {secondaryFields && secondaryFields.map((field:ItemField) => (
            <Grid
              key={field.name}
              item
              sx={{
                alignItems: 'left',
                display: 'flex',
              }}
              xs={12}
            >
              <Typography
                component="div"
                color="textSecondary"
                display="inline"
                sx={{ pl: 1 }}
                variant="body2"
              >
                <Stack direction="row">
                  <Box>
                    {field.label}
                    {': '}
                  </Box>
                  <FieldValue item={item} field={field} />
                </Stack>
              </Typography>
            </Grid>
          ))}
          <Grid
            item
            sx={{
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <Typography
              color="textSecondary"
              display="inline"
              sx={{ pl: 1 }}
              variant="body2"
            >
              {selected}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {/* <CardActions>
        {actions.map((action) => (
          <Button key={action.label} onClick={() => { action.action(item); }}>
            {action.label}
          </Button>
        ))}
      </CardActions> */}
    </Card>
  );
};

export default ItemCard;
