import React from 'react';
import {
  Box,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import {
  formatDate,
} from '@mcn-platform/models';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ReferenceTagsInput from '../TagsInput/ReferenceTagsInput';

const ActivityInput = ({ currentValue, setValue }: any) => (
  <Box sx={{ p: 2 }}>
    {(currentValue.conditionType === 'courseProgramMicrocast' || currentValue.conditionType === 'noProgress') && (
      <Box sx={{ p: 2 }}>
        <FormControlLabel
          control={(
            <Checkbox
              onChange={
                () => setValue({
                  ...currentValue,
                  allCourses: !currentValue.allCourses,
                })
              }
              checked={currentValue.allCourses}
            />
          )}
          label="All Courses"
        />
        <ReferenceTagsInput
          id="course"
          name="Courses"
          placeholder="Select Courses"
          value={currentValue.selectedCourses}
          onChange={(newValue) => {
            setValue({
              ...currentValue,
              selectedCourses: newValue,
            });
          }}
          modelName="course"
          disabled={currentValue.allCourses}
          fullWidth
        />
        <FormControlLabel
          control={(
            <Checkbox
              onChange={
                () => setValue({
                  ...currentValue,
                  allPrograms: !currentValue.allPrograms,
                })
              }
              checked={currentValue.allPrograms}
            />
          )}
          label="All Programs"
        />
        <ReferenceTagsInput
          id="program"
          name="Programs"
          placeholder="Select Programs"
          value={currentValue.selectedPrograms}
          onChange={(newValue) => {
            setValue({
              ...currentValue,
              selectedPrograms: newValue,
            });
          }}
          modelName="program"
          disabled={currentValue.allPrograms}
          fullWidth
        />
        { currentValue.activityType !== 'progress' && (
          <>
            <FormControlLabel
              control={(
                <Checkbox
                  onChange={
                    () => setValue({
                      ...currentValue,
                      allMicrocasts: !currentValue.allMicrocasts,
                    })
                  }
                  checked={currentValue.allMicrocasts}
                />
              )}
              label="All Microcasts"
            />
            <ReferenceTagsInput
              id="microcast"
              name="Microcasts"
              placeholder="Select Microcasts"
              value={currentValue.selectedMicrocasts}
              onChange={(newValue) => {
                setValue({
                  ...currentValue,
                  selectedMicrocasts: newValue,
                });
              }}
              modelName="microcast"
              disabled={currentValue.allMicrocasts}
              fullWidth
            />
          </>
        )}
      </Box>
    )}
    {(currentValue.conditionType === 'courseProgramMicrocast' && currentValue.activityType === 'progress') && (
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography>Progress Percentage:</Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-number"
              label="Value"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              value={currentValue.progressPercentage}
              onChange={(ev) => {
                setValue({
                  ...currentValue,
                  progressPercentage: ev.target.value,
                });
              }}
            />
          </Grid>
        </Grid>
      </Box>
    )}
    {((currentValue.conditionType === 'time' && currentValue.activityType === 'waitFor') || currentValue.conditionType === 'noProgress') && (
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography>Wait For:</Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-number"
              label="Value"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              value={currentValue.value}
              onChange={(ev) => {
                setValue({
                  ...currentValue,
                  value: ev.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Term</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={currentValue.waitForTerm}
                label="Age"
                onChange={(e) => {
                  setValue({
                    ...currentValue,
                    waitForTerm: e.target.value,
                  });
                }}
              >
                <MenuItem value="hour">Hours</MenuItem>
                <MenuItem value="day">Days</MenuItem>
                <MenuItem value="week">Weeks</MenuItem>
                <MenuItem value="month">Months</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    )}

    {currentValue.conditionType === 'time' && currentValue.activityType === 'calendarDate' && (
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Typography>Date:</Typography>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={currentValue.dateType || 'before'}
                label="Age"
                onChange={(e) => {
                  setValue({
                    ...currentValue,
                    dateType: e.target.value,
                  });
                }}
              >
                <MenuItem value="before">Before</MenuItem>
                <MenuItem value="after">After</MenuItem>
                <MenuItem value="range">Date Range</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          { currentValue.dateType === 'range' && (
            <>
              <Grid item xs={3}>
                <DatePicker
                  label="From"
                  value={currentValue.fromDate}
                  onChange={(v) => {
                    setValue({
                      ...currentValue,
                      fromDate: formatDate(v),
                    });
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <DatePicker
                  label="To"
                  value={currentValue.toDate}
                  onChange={(v) => {
                    setValue({
                      ...currentValue,
                      toDate: formatDate(v),
                    });
                  }}
                />
              </Grid>
            </>
          )}
          { currentValue.dateType !== 'range' && (
            <Grid item xs={3}>
              <DatePicker
                label="Date"
                value={currentValue.dateValue}
                onChange={(v) => {
                  setValue({
                    ...currentValue,
                    dateValue: formatDate(v),
                  });
                }}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    )}

    {currentValue.conditionType === 'target' && currentValue.activityType === 'memberOf' && (
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Typography>Member Of:</Typography>
          </Grid>
          <Grid item xs={6}>
            <ReferenceTagsInput
              id="membership"
              name="Memberships"
              placeholder="Select Memberships"
              value={currentValue.selectedMemberships}
              onChange={(newValue) => {
                setValue({
                  ...currentValue,
                  selectedMemberships: newValue,
                });
              }}
              modelName="membership"
              fullWidth
              disabled={false}
            />
          </Grid>
        </Grid>
      </Box>
    )}
    {currentValue.conditionType === 'membership' && (
      <Box sx={{ p: 2 }}>
        <FormControlLabel
          control={(
            <Checkbox
              onChange={
                () => setValue({
                  ...currentValue,
                  allMemberships: !currentValue.allMemberships,
                })
              }
              checked={currentValue.allMemberships}
            />
          )}
          label="All Memberships"
        />
        <ReferenceTagsInput
          id="membership"
          name="Memberships"
          placeholder="Select Memberships"
          value={currentValue.selectedMemberships}
          onChange={(newValue) => {
            setValue({
              ...currentValue,
              selectedMemberships: newValue,
            });
          }}
          modelName="membership"
          fullWidth
          disabled={currentValue.allMemberships}
        />
      </Box>
    )}
    {currentValue.conditionType === 'learningBoard' && (
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography>Not Viewed For:</Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-number"
              label="Value"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              value={currentValue.notViewedFor}
              onChange={(ev) => {
                setValue({
                  ...currentValue,
                  notViewedFor: ev.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Term</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={currentValue.waitForTerm}
                label="Age"
                onChange={(e) => {
                  setValue({
                    ...currentValue,
                    waitForTerm: e.target.value,
                  });
                }}
              >
                <MenuItem value="hour">Hours</MenuItem>
                <MenuItem value="day">Days</MenuItem>
                <MenuItem value="week">Weeks</MenuItem>
                <MenuItem value="month">Months</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    )}
  </Box>
);

export default ActivityInput;
