import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
} from '@mui/material';

import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet-async';
import { v4 } from 'uuid';
import { useNavigate, useParams } from 'react-router-dom';
import usePrompt from 'hooks/usePrompt';

import ItemEdit from 'components/item/ItemEdit';
import { IKicker, useStore } from '@mcn-platform/models';
import ButtonStack from 'components/item/ButtonStack';

const KickerEditor = ({ clone }:{ clone?: boolean }) => {
  const [kicker, setKicker] = useState<IKicker | null>();
  const store = useStore();
  const { kickerID } = useParams();
  const navigate = useNavigate();

  usePrompt({ isDirty: kicker ? (kicker.changeCount > 0) : false });

  useEffect(() => {
    if (!kickerID) {
      return;
    }
    // check if we're creating a new kicker
    if (clone) {
      if (kicker) {
        console.log('All good - already loaded');
        return;
      }

      console.log('cloning');
      store.load('kicker', kickerID).then((kickerToClone:any) => {
        const newKicker = store.clone(
          'kicker',
          kickerToClone,
        );
        setKicker(newKicker);
      });
    } else if (kickerID === 'new') {
      if (kicker) {
        console.log('All good - already loaded');
        return;
      }

      const newKicker = store.create(
        'kicker',
        v4(),
        'New Kicker',
      );
      setKicker(newKicker);
    } else {
      console.log('loading kicker', { kickerID, kicker });
      const loadedKicker = store.getOrLoad('kicker', kickerID);
      setKicker(loadedKicker);
    }
  }, [store, kickerID, kicker, clone]);

  /**
   * Takes the full kicker and saves it to the kicker stub.  Sections are serialized to JSON
   */
  const saveKicker = () => {
    if (!kicker) return;
    console.log('kicker:', kicker);
    if (clone || kickerID === 'new') {
      store.addStub('kicker', kicker).then(() => {
        navigate(`/app/kicker-editor/${kicker.id}`);
      });
    } else {
      store.update('kicker', kicker);
    }
  };

  if (!kicker) {
    return <div>Loading.</div>;
  }
  if (kicker.syncState === 'not_found') {
    return <div>Not found</div>;
  }
  if (kicker.syncState === 'error') {
    return <div>Error loading kicker</div>;
  }

  if (kicker.syncState === 'saving') {
    return <div>Saving..</div>;
  }

  if (!clone && kickerID !== 'new' && kicker.syncState !== 'loaded') {
    return <div>Loading..</div>;
  }
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    }}
    >
      <Helmet>
        <title>{kickerID === 'new' ? 'Kicker Builder' : 'Kicker Editor'}</title>
      </Helmet>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
      }}
      >
        <Box sx={{ flex: '1', overflowY: 'auto' }}>
          <Grid container sx={{ height: '100%' }}>
            <Grid item xs={12} md={12} sx={{ height: '100%', overflow: 'hidden', pr: 2 }}>
              <Card sx={{ height: '100%', overflow: 'auto' }}>
                <CardContent>
                  <ItemEdit
                    key={kicker.id}
                    item={kicker}
                    modelName="kicker"
                    handleSubmit={(fields:any) => {
                      console.log('Update kicker', fields);
                      kicker.updateValues(fields);
                    }}
                    hideTitle
                    autoSave
                    // fields={['name', 'description']}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <ButtonStack>
          <Button
            color="primary"
            variant="contained"
            onClick={saveKicker}
            disabled={kicker.changeCount === 0}
          >
            Save
          </Button>
        </ButtonStack>
      </Box>
    </Box>
  );
};

KickerEditor.defaultProps = {
  clone: false,
};

export default observer(KickerEditor);
