import React, { createRef, RefObject, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Box,
  List,
} from '@mui/material';
import { ILearningBoard, ILearningBoardSection } from '@mcn-platform/models';
import DraggableItem from './DraggableItem';
import DropTarget from './DropTarget';
import { TreeItem } from './types';

type LearningBoardTreeProps = {
  learningBoard: ILearningBoard;
  currentSection: TreeItem | null;
  setCurrentSection: (section:TreeItem | null) => void;
};
const Item = observer(({
  learningBoard,
  section,
  depth,
  currentSection,
  setCurrentSection,
  activeSectionRef,
}:{
  learningBoard: ILearningBoard,
  depth: number,
  section: TreeItem,
  currentSection: TreeItem | null,
  setCurrentSection: (newSection:TreeItem | null) => void,
  activeSectionRef: RefObject<any>,
}) => (
  <Box ref={section.id === currentSection?.id ? activeSectionRef : null}>
    <DraggableItem
      learningBoard={learningBoard}
      section={section}
      depth={depth}
      onClick={() => setCurrentSection(section)}
      currentSection={currentSection}
      setCurrentSection={setCurrentSection}
      selected={currentSection?.id === section.id}
    />
  </Box>
));

const Section = observer(({
  section,
  depth,
  index,
  learningBoard,
  currentSection,
  setCurrentSection,
  activeSectionRef,
}:{
  section: TreeItem,
  depth: number,
  index: number,
  learningBoard: ILearningBoard,
  currentSection: TreeItem | null,
  setCurrentSection: (newSection:TreeItem | null) => void,
  activeSectionRef: RefObject<any>,
}) => (
  <>
    <DropTarget index={index} depth={depth} learningBoard={learningBoard} />
    <Item
      learningBoard={learningBoard}
      section={section}
      depth={depth + 1}
      currentSection={currentSection}
      setCurrentSection={setCurrentSection}
      activeSectionRef={activeSectionRef}
    />
    <List dense>
      {section.sections.map((subSection:ILearningBoardSection, subIndex: number) => (
        <Section
          key={subSection.id}
          section={subSection}
          depth={0}
          index={subIndex}
          learningBoard={learningBoard}
          currentSection={currentSection}
          setCurrentSection={setCurrentSection}
          activeSectionRef={activeSectionRef}
        />
      ))}
      <DropTarget index={section.sections.length} depth={depth + 1} learningBoard={learningBoard} />
    </List>
  </>
));

const LearningBoardTree = ({
  learningBoard,
  currentSection,
  setCurrentSection,
}:LearningBoardTreeProps) => {
  const activeSectionRef:RefObject<any> = createRef();

  useEffect(() => {
    if (activeSectionRef && activeSectionRef.current) {
      activeSectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [activeSectionRef, currentSection]);

  return (
    <List dense>
      <Item
        learningBoard={learningBoard}
        depth={0}
        section={learningBoard}
        currentSection={currentSection}
        setCurrentSection={setCurrentSection}
        activeSectionRef={activeSectionRef}
      />

      {learningBoard.sections
      && learningBoard.sections.map((section:ILearningBoardSection, index: number) => (
        <Section
          key={section.id}
          section={section}
          depth={0}
          index={index}
          learningBoard={learningBoard}
          currentSection={currentSection}
          setCurrentSection={setCurrentSection}
          activeSectionRef={activeSectionRef}
        />
      ))}
      <DropTarget depth={1} index={learningBoard.sections.length} learningBoard={learningBoard} />
    </List>
  );
};

export default observer(LearningBoardTree);
