/* eslint-disable react/jsx-props-no-spreading */
/**
 * Takes tags[] as the initial list of chips to display.
 * Each time a chip is added or removed onChange is called.
 * The value being typed or selected in for a new chip is internal state as well.
 * TODO: tags can be selected - this is internal state that is managed.
 */
import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Autocomplete, Chip, TextField, IconButton,
} from '@mui/material';
import {
  Close as CloseIcon,
} from '@mui/icons-material';

type TagsInputProps = {
  onChange: (tags:string[]) => void;
  value: any;
  placeholder?: string;
  options: string[];
  optionLabels: string[];
  disabled: boolean;
  [other:string]: any;
};

const TagsInput = ({
  onChange,
  placeholder,
  value = [],
  options,
  optionLabels,
  disabled,
  ...other
}:TagsInputProps) => {
  // this is the text currently in the field that hasnt been turned into a chip yet
  const [freeText, setFreeText] = useState<string>('');
  // the 'value' field is the parsed array of values.

  const getLabel = (option:string) => {
    const index = options.indexOf(option);
    return optionLabels[index];
  };

  const handleDelete = (e: any, item:string) => {
    e.preventDefault();
    const newValue = [...value];
    newValue.splice(newValue.indexOf(item), 1);
    onChange(newValue);
  };

  return (
    <Autocomplete
      fullWidth
      multiple
      id="tags-outlined"
      value={value}
      inputValue={freeText}
      options={options}
      getOptionLabel={(option:any) => getLabel(option)}
      renderTags={(tagsValue: readonly string[], getTagProps) => (
        tagsValue.map((option: string, index: number) => (
          <Chip
            {...getTagProps({ index })}
            label={getLabel(option)}
            sx={{ margin: 0.5 }}
            deleteIcon={(
              <IconButton
                disableRipple
                disabled={disabled}
              >
                <CloseIcon />
              </IconButton>
            )}
            disabled={disabled}
            onDelete={(e) => handleDelete(e, option)}
          />
        )))}
      onChange={(event: any, newValue:any) => {
        onChange(newValue);
        setFreeText('');
      }}
      renderInput={(params) => (
        <TextField
          variant="outlined"
          placeholder={value.length < 3 ? placeholder : ''}
          {...params}
          onChange={(event) => {
            console.log('TextField onChange', event);
            setFreeText(event.target.value);
          }}
          disabled={disabled}
        />
      )}
      {...other}
      disabled={disabled}
    />
  );
};

TagsInput.defaultProps = {
  placeholder: '',
};

export default observer(TagsInput);
