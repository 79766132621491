import * as React from 'react';
import { SVGProps } from 'react';

export default (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3.775 8 2.442v13.783l4 1.333V3.775Zm2 .102v13.96l3.141-.449a1 1 0 0 0 .859-.99V4.46a1 1 0 0 0-1.141-.99L14 3.877ZM2.859 2.612 6 2.163v13.96l-2.859.408A1 1 0 0 1 2 15.54V3.601a1 1 0 0 1 .859-.99ZM13 20l-6-2-3.576.51A3 3 0 0 1 0 15.542V3.601A3 3 0 0 1 2.576.633L7 0l6 2 3.576-.51A3 3 0 0 1 20 4.458v11.94a3 3 0 0 1-2.576 2.969L13 20Z"
      fill="currentColor"
    />
  </svg>
);
