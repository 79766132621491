import React from 'react';
import { observer } from 'mobx-react-lite';
import { Controller } from 'react-hook-form';
import { FormControlLabel } from '@mui/material';
import ReferenceSelector from './ReferenceSelector';
import { StandardFieldProps } from './FieldTypes';

const StandardReferenceField = ({
  control,
  fieldName,
  field,
  readOnly,
}:StandardFieldProps) => {
  if (!field.references) {
    console.error('field definition requires references', field);
    return null;
  }
  const modelName = field.references;
  return (
    <Controller
      name={fieldName}
      control={control}
      render={({
        field: {
          onChange,
          onBlur,
          value,
          name,
          // ref,
        },
        fieldState: {
          // invalid,
          isTouched,
          // isDirty,
          error,
        },
      }) => {
        const selector = (
          <ReferenceSelector
            label="Select"
            modelName={modelName}
            id={fieldName}
            onChange={onChange}
            onBlur={onBlur}
            name={name}
            disabled={field.readOnly || readOnly}
            value={value}
            error={isTouched && !!error}
          />
        );
        if (field.showLabel === false) {
          return selector;
        }
        return (
          <FormControlLabel
            label={field.label}
            disabled={field.readOnly}
            labelPlacement="top"
            control={selector}
          />
        );
      }}
    />
  );
};

export default observer(StandardReferenceField);
