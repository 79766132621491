import React from 'react';
import {
  List,
  ListItemText,
  ListItemButton,
  Box,
  Typography,
} from '@mui/material';
import {
  ConditionActivities,
} from '@mcn-platform/models';

const ActivitySelector = ({ currentValue, setValue }: any) => {
  const selectedStyle = {
    color: '#1276dd',
    fontWeight: 600,
    fontSize: '0.875rem',
    backgroundColor: '#f4f6fa',
  };
  return (
    <Box sx={{ p: 2 }}>
      <Typography sx={{ fontWeight: 'bold', width: '100%' }}>Activities</Typography>
      <List
        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        {ConditionActivities.map((activity) => {
          if (activity.types.includes(currentValue.conditionType)) {
            return (
              <ListItemButton
                key={activity.key}
                onClick={() => setValue({
                  ...currentValue,
                  activityType: activity.key,
                })}
                sx={{
                  ...(activity.key === currentValue.activityType ? selectedStyle : {}),
                }}
              >
                <ListItemText primary={activity.label} />
              </ListItemButton>
            );
          }
          return null;
        })}
        {currentValue.conditionType && currentValue.conditionType === '' && (
          <Typography key={1}>Please select condition type.</Typography>
        )}
      </List>
    </Box>
  );
};

export default ActivitySelector;
