import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { v4 } from 'uuid';
import { useNavigate, useParams } from 'react-router-dom';
import usePrompt from 'hooks/usePrompt';
import { IEngagementFlow, useStore } from '@mcn-platform/models';

import ItemEdit from 'components/item/ItemEdit';
import ButtonStack from 'components/item/ButtonStack';
import ReferenceSelector from 'components/forms/ReferenceSelector';

const ExecuteFlowDialog = ({ open, setOpen }:any) => {
  const store = useStore();
  console.log('open:', open);
  const [customer, setCustomer] = useState<any>();
  const handleExecute = () => {
    console.log('hello', customer, store);
    store.executeEngagementFlow({ customerID: customer.id });
  };
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Please Select Customer
      </DialogTitle>
      <DialogContent>
        <ReferenceSelector
          label="Select Customer"
          modelName="customer"
          id="customerselect"
          onChange={(newID, newCustomer) => setCustomer(newCustomer)}
          value={customer}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} autoFocus>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleExecute}>
          Execute
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const EngagementFlowEditor = ({ clone }:{ clone?: boolean }) => {
  const [flow, setFlow] = useState<IEngagementFlow | null>();
  const [openExecuteDialog, setOpenExecuteDialog] = useState<boolean>(false);
  const store = useStore();
  const { flowID } = useParams();
  const navigate = useNavigate();

  usePrompt({ isDirty: flow ? (flow.changeCount > 0) : false });

  useEffect(() => {
    if (!flowID) {
      return;
    }
    // check if we're creating a new flow
    if (clone) {
      if (flow) {
        console.log('All good - already loaded');
        return;
      }

      console.log('cloning');
      store.load('engagementFlow', flowID).then((flowToClone:any) => {
        const newFlow = store.clone(
          'engagementFlow',
          flowToClone,
        );
        setFlow(newFlow);
      });
    } else if (flowID === 'new') {
      if (flow) {
        console.log('All good - already loaded');
        return;
      }

      const newFlow = store.create(
        'engagementFlow',
        v4(),
        'New Flow',
      );
      setFlow(newFlow);
    } else {
      const loadedFlow = store.getOrLoad('engagementFlow', flowID);
      setFlow(loadedFlow);
    }
  }, [store, flowID, flow, flow?.syncState, clone]);

  /**
   * Takes the full flow and saves it to the flow stub.  Sections are serialized to JSON
   */
  const saveFlow = () => {
    if (!flow) return;
    console.log('flow:', flow);
    if (clone || flowID === 'new') {
      store.addStub('engagementFlow', flow).then(() => {
        navigate(`/app/engagement-flow-editor/${flow.id}`);
      });
    } else {
      store.update('engagementFlow', flow);
    }
  };

  const execute = () => {
    setOpenExecuteDialog(true);
  };

  if (!flow) {
    return <div>Loading.</div>;
  }
  if (flow.syncState === 'error') {
    return <div>Error loading flow</div>;
  }

  if (flow.syncState === 'saving') {
    return <div>Saving..</div>;
  }

  if (!clone && flowID !== 'new' && flow.syncState !== 'loaded') {
    return <div>Loading..</div>;
  }
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    }}
    >
      <Helmet>
        <title>{flowID === 'new' ? 'Flow Builder' : 'Flow Editor'}</title>
      </Helmet>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
      }}
      >
        <Box sx={{ flex: '1', overflowY: 'auto' }}>
          <Grid container sx={{ height: '100%' }}>
            <Grid item xs={12} md={12} sx={{ height: '100%', overflow: 'hidden', pr: 2 }}>
              <Card sx={{ height: '100%', overflow: 'auto' }}>
                <CardContent>
                  <ItemEdit
                    key={flow.id}
                    item={flow}
                    modelName="engagementFlow"
                    handleSubmit={(fields:any) => {
                      console.log('Update flow', fields);
                      flow.updateValues(fields);
                    }}
                    hideTitle
                    autoSave
                    // fields={['name', 'description']}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <ButtonStack>
          <Button
            color="primary"
            variant="contained"
            onClick={execute}
          >
            Execute
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={saveFlow}
            disabled={flow.changeCount === 0}
          >
            Save
          </Button>
        </ButtonStack>
      </Box>
      <ExecuteFlowDialog
        open={openExecuteDialog}
        setOpen={(val:boolean) => setOpenExecuteDialog(val)}
      />
    </Box>
  );
};

EngagementFlowEditor.defaultProps = {
  clone: false,
};

export default observer(EngagementFlowEditor);
