import * as React from 'react';
import { SVGProps } from 'react';

export default (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <path
      d="M14.659 4.167 10 8.825 5.342 4.167 4.167 5.342 8.825 10l-4.658 4.658 1.175 1.175L10 11.175l4.659 4.658 1.175-1.175L11.175 10l4.659-4.658-1.175-1.175Z"
      fill="currentColor"
    />
  </svg>
);
