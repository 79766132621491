/* eslint-disable react/require-default-props */
import React from 'react';
import {
  Button, Checkbox,
  Table, TableRow, TableBody, TableHead, TableCell,
  Card,
} from '@mui/material';
import { styled, Theme } from '@mui/material/styles';

import { useNavigate } from 'react-router';
import { observer } from 'mobx-react-lite';
import { useStore } from '@mcn-platform/models';
import { ItemField } from './ItemTypes';
import ActionMenu, { Action } from './ActionMenu';
import FieldValue from './FieldValue';

type TableListProps = {
  modelName: string;
  model: any;
  actions: Action[];
  imageField: ItemField,
  titleField: ItemField,
  secondaryFields: ItemField[],
  handleSelectAll: (event:any) => void;
  handleSelectOne: (item:any) => void;
  showSelect: boolean,
  enableDeselect: boolean;
  actionsStyle?: string;
};

const HeaderCell = styled(TableCell)(({ theme }: { theme: Theme }) => ({
  fontSize: '13px',
  fontWeight: 'bold',
  px: 1,
  borderColor: theme.palette.divider,
  borderWidth: 1,
}));

const BodyCell = styled(TableCell)(({ theme }: { theme: Theme }) => ({
  p: 1,
  borderColor: theme.palette.divider,
  borderWidth: 1,
}));

const Row = observer(({
  titleField,
  secondaryFields,
  actions,
  modelName,
  item,
  showSelect,
  selected,
  disabled,
  handleSelectOne,
  enableDeselect,
  navigate,
  actionsStyle,
}:any) => {
  const store = useStore();
  const rowItem = (item.syncState === undefined || item.syncState === 'loaded') ? item : store.getOrLoad(modelName, item.id);
  const handleRowSelect = (ri) => {
    const index = actions.findIndex((a) => a.name === 'edit');
    if (index > -1) {
      actions[index].action(ri, navigate, store);
    }
  };
  // console.log('rowItem', rowItem);
  return (
    <TableRow
      selected={selected}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'action.hover',
          boxShadow: '1px 0px 10px 0px #dbdbdb',
        },
      }}
    >
      { showSelect && (
        <BodyCell
          padding="checkbox"
          key="checks"
          onClick={() => { handleRowSelect(rowItem); }}
        >
          <Checkbox
            disabled={disabled || (selected && !enableDeselect)}
            checked={selected}
            onChange={() => handleSelectOne(rowItem)}
            value="true"
          />
        </BodyCell>
      )}
      <BodyCell
        key={titleField.name}
        onClick={() => { handleRowSelect(rowItem); }}
      >
        <FieldValue field={titleField} item={item} />
      </BodyCell>
      { secondaryFields.map((field) => (
        <BodyCell
          key={field.name}
          onClick={() => { handleRowSelect(rowItem); }}
        >
          <FieldValue field={field} item={item} />
        </BodyCell>
      ))}
      { actions.length > 0 && (
        actionsStyle === 'ellipses' ? (
          <BodyCell>
            <ActionMenu
              item={item}
              actions={actions}
              selected={selected}
              showSelect={showSelect}
            />
          </BodyCell>
        ) : (
          <BodyCell>
            {actions.map((action) => (
              <Button
                key={action.label}
                onClick={() => { action.action(rowItem, navigate, store); }}
                disabled={action.disabled && action.disabled(rowItem, store)}
              >
                {action.label}
              </Button>
            ))}
          </BodyCell>
        )
      )}
    </TableRow>
  );
});

const TableList = ({
  modelName,
  model,
  handleSelectAll,
  handleSelectOne,
  actions,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  imageField,
  titleField,
  secondaryFields,
  showSelect,
  enableDeselect,
  actionsStyle,
}:TableListProps) => {
  const navigate = useNavigate();
  // console.log('TableList selectedIDs', selectedIDs);

  return (
    <Card>
      <Table>
        <TableHead>
          <TableRow>
            { showSelect && (
              <TableCell key="checks" padding="checkbox">
                <Checkbox
                  color="primary"
                  indeterminate={
                    model.selectedCount() > 0
                  }
                  onChange={handleSelectAll}
                />
              </TableCell>
            )}
            <HeaderCell sx={{ width: '25%' }}>
              {titleField.label}
            </HeaderCell>
            { secondaryFields && secondaryFields.map((field) => (
              <HeaderCell key={field.label}>
                {field.label}
              </HeaderCell>
            ))}
            { actions.length > 0 && (
              <HeaderCell>
                Actions
              </HeaderCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          { model.items.map((item) => (
            <Row
              key={item.id}
              modelName={modelName}
              item={item}
              selected={model.isSelected(item) || model.isPreSelected(item)}
              disabled={model.isDisabled(item)}
              titleField={titleField}
              secondaryFields={secondaryFields}
              actions={actions}
              showSelect={showSelect}
              handleSelectOne={handleSelectOne}
              enableDeselect={enableDeselect}
              navigate={navigate}
              actionsStyle={actionsStyle}
            />
          ))}
        </TableBody>
      </Table>
    </Card>
  );
};

export default observer(TableList);
