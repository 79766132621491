import React from 'react';
import {
  FormControlLabel,
  TextField,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { useTokens } from '@mcn-platform/models';
import { StandardFieldProps } from './FieldTypes';
import HelperText from './HelperText';
import GenerateAIDialog from './GenerateAIDialog';
import GenerateAiFormLabel from './GenerateAiFormLabel';

const promptList = [
  'Summarize the course',
  'Summarize the course with the instructor name',
  'Write a 2 paragraph summary of the course',
];

const StandardTextField = (
  {
    control,
    fieldName,
    field,
    readOnly,
  }
  :StandardFieldProps,
) => {
  const [aiDialogOpen, setAiDialogOpen] = React.useState(false);
  const tokens = useTokens();

  return (
    <Controller
      name={fieldName}
      control={control}
      render={({
        field: {
          onChange,
          onBlur,
          value,
        },
        fieldState: {
          isTouched,
          error,
        },
      }) => {
        const handleGenerateFieldCancel = () => {
          setAiDialogOpen(false);
        };
        const handleGenerateFieldConfirm = (newValue: string) => {
          setAiDialogOpen(false);
          onChange(newValue);
        };
        // eslint-disable-next-line no-underscore-dangle
        const { key } = control._formValues;
        const label = field.tokenKey ? tokens.getToken(field.tokenKey) : field.label;
        return (
          <>
            <FormControlLabel
              label={
                !readOnly && field.aiTarget ? (
                  <GenerateAiFormLabel
                    label={label}
                    onClick={() => setAiDialogOpen(true)}
                  />
                ) : label
              }
              disabled={field.readOnly}
              labelPlacement="top"
              control={(
                <TextField
                  fullWidth
                  multiline={field.multiline}
                  minRows={field.rows}
                  id={fieldName}
                  name={fieldName}
                  placeholder={field.placeholder}
                  value={value}
                  disabled={readOnly}
                  onChange={(newValue:any) => {
                    onChange(newValue);
                  }}
                  onBlur={onBlur}
                  error={isTouched && Boolean(error)}
                  inputProps={{
                    'aria-describedby': `helper-text-${fieldName}`,
                    style: {
                      resize: field.resize ? 'vertical' : 'none',
                    },
                  }}
                />
              )}
            />
            <HelperText
              name={fieldName}
              error={isTouched && error?.message}
              description={field.description}
            />
            { field?.aiTarget && (
              <GenerateAIDialog
                open={aiDialogOpen}
                title="Generate AI"
                promptList={field.promptList || promptList}
                itemType={field.aiTarget}
                itemKey={key}
                onConfirm={handleGenerateFieldConfirm}
                onCancel={handleGenerateFieldCancel}
                onClose={() => { setAiDialogOpen(false); }}
                isRichText={false}
              />
            )}
          </>
        );
      }}
    />
  );
};

StandardTextField.defaultProps = {
  placeholder: '',
  description: '',
  disabled: false,
  multiline: false,
  rows: 1,
  resize: false,
};

export default StandardTextField;
