import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Grid,
  Box,
  Button,
  Typography,
} from '@mui/material';
import { cloneDeep } from 'lodash';
import { useStore } from '@mcn-platform/models';
import PencilIcon from 'icons/PencilIcon';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/DoneOutline';
import ConditionSelector from './ConditionSelector';
import ActivitySelector from './ActivitySelector';
import ActivityInput from './ActivityInput';
import ReferenceSelector from '../ReferenceSelector';

interface FieldProps {
  currentValue: any;
  setValue;
}

// The condition component displays the rule and allows to Edit
const Condition = ({
  currentValue,
  condition,
  index,
  setValue,
}: any) => {
  const store = useStore();
  const [editMode, setEditMode] = useState<boolean>(false);
  const handleChange = (newValue: any) => {
    const cVal = cloneDeep(currentValue);
    if (cVal.conditions) {
      cVal.conditions[index] = newValue;
    } else {
      cVal.conditions = [
        { ...newValue },
        { ...newValue },
      ];
    }
    setValue(cVal);
  };
  const deleteCondition = () => {
    const cVal = cloneDeep(currentValue);
    if (cVal.conditions && cVal.conditions.length > 1) {
      cVal.conditions.splice(index, 1);
    } else {
      cVal.conditions = [];
    }
    setValue(cVal);
  };
  const getStartedActivitySummary = (startLabel:string) => {
    let summary = startLabel;
    if (condition.allCourses) {
      summary += ' all Courses';
    } else if (condition.selectedCourses?.length) {
      const courses = condition.selectedCourses.map((course) => store.getOrLoad('course', course));
      const courseNames = courses.map((course) => course.name);
      summary += ` Courses: (${courseNames.join(', ')})`;
    }
    if (condition.allPrograms) {
      summary += ' and all Programs';
    } else if (condition.selectedPrograms?.length) {
      const programs = condition.selectedPrograms.map((program) => store.getOrLoad('program', program));
      const programNames = programs.map((program) => program.name);
      summary += ` Programs: (${programNames.join(', ')})`;
    }
    if (condition.allMicrocasts) {
      summary += ' and all Microcasts';
    } else if (condition.selectedMicrocasts?.length) {
      const microcasts = condition.selectedMicrocasts.map((microcast) => store.getOrLoad('microcast', microcast));
      const microcastNames = microcasts.map((microcast) => microcast.name);
      summary += ` Microcasts: (${microcastNames.join(', ')})`;
    }

    if (summary !== 'Started ') {
      return summary;
    }
    return 'No condition added';
  };

  const getWaitForSummary = () => {
    if (condition.value && condition.waitForTerm) {
      return `Wait for ${condition.value} ${condition.waitForTerm}s`;
    }
    return 'No condition added';
  };

  const getCalendarDateSummary = () => {
    if (condition.dateType) {
      if (condition.dateType === 'before' && condition.dateValue) {
        return `Before ${condition.dateValue}`;
      }
      if (condition.dateType === 'after' && condition.dateValue) {
        return `After ${condition.dateValue}`;
      }
      if (condition.dateType === 'range' && condition.fromDate && condition.toDate) {
        return `Between ${condition.fromDate} and ${condition.toDate}`;
      }
    }
    return 'No condition added';
  };

  const getMembershipConditionSummary = () => {
    if (condition.activityType === 'purchasedMembership') {
      return 'All purchased memberships';
    }
    return 'No condition added';
  };

  const getMemberOfSummary = () => {
    if (condition.selectedMemberships?.length) {
      const memberships = condition.selectedMemberships.map((membership) => store.getOrLoad('membership', membership));
      const membershipNames = memberships.map((membership) => membership.name);
      return `Member of: (${membershipNames.join(', ')})`;
    }
    return 'No condition added';
  };

  const getConditionSummary = () => {
    switch (condition?.conditionType) {
      case 'courseProgramMicrocast':
        if (condition.activityType === 'started') {
          return getStartedActivitySummary('Started');
        }
        if (condition.activityType === 'notStarted') {
          return getStartedActivitySummary('Not started');
        }
        if (condition.activityType === 'finished') {
          return getStartedActivitySummary('Finished');
        }
        if (condition.activityType === 'notFinished') {
          return getStartedActivitySummary('Not Finished');
        }
        return 'No condition added.';
      case 'time':
        if (condition.activityType === 'waitFor') {
          return getWaitForSummary();
        }
        if (condition.activityType === 'calendarDate') {
          return getCalendarDateSummary();
        }
        return 'No condition added.';
      case 'target':
        if (condition.activityType === 'memberOf') {
          return getMemberOfSummary();
        }
        return 'No condition added.';
      case 'membership':
        return getMembershipConditionSummary();
      default:
        return 'No condition added.';
    }
  };
  console.log('condition:', condition);
  return (
    <Grid
      container
      sx={{
        border: '1px solid',
        borderColor: '#e0e0eb',
        borderRadius: '8px',
        my: 2,
      }}
    >
      {!editMode ? (
        <Box sx={{ p: 2, width: '100%' }}>
          <Typography sx={{ display: 'inline-block' }}>{getConditionSummary()}</Typography>
          <Box sx={{ float: 'right', cursor: 'pointer' }}><DeleteIcon onClick={() => deleteCondition()} /></Box>
          <Box sx={{ float: 'right', cursor: 'pointer' }}><PencilIcon onClick={() => setEditMode(true)} /></Box>
        </Box>
      ) : (
        <>
          <Grid item xs={6}>
            <ConditionSelector currentValue={condition} setValue={handleChange} />
          </Grid>
          <Grid item xs={6}>
            <ActivitySelector currentValue={condition} setValue={handleChange} />
          </Grid>
          <Grid item xs={12}>
            <ActivityInput currentValue={condition} setValue={handleChange} />
            <Box sx={{ float: 'right', p: 2 }}><CheckIcon sx={{ cursor: 'pointer' }} onClick={() => setEditMode(false)} /></Box>
          </Grid>
        </>
      )}
    </Grid>
  );
};

const Conditions = ({ currentValue, setValue }: FieldProps) => {
  const [addResult, setAddResult] = useState<boolean>(!!currentValue.result);
  const addNewCondition = () => {
    const cVal = cloneDeep(currentValue);
    if (cVal.conditions) {
      cVal.conditions.push({
        value: 1,
      });
    } else {
      cVal.conditions = [
        { value: 1 },
      ];
    }
    setValue(cVal);
  };
  const onResultChange = (newValue: any) => {
    const cVal = cloneDeep(currentValue);
    cVal.result = newValue;
    setValue(cVal);
  };

  return (
    <Box>
      {currentValue.conditions?.length ? currentValue.conditions.map((condition, index) => (
        <Condition
          currentValue={currentValue}
          condition={condition}
          index={index}
          setValue={setValue}
        />
      )) : (
        <Condition
          currentValue={currentValue}
          condition={{}}
          index={0}
          setValue={setValue}
        />
      )}
      <Button
        color="primary"
        variant="contained"
        onClick={addNewCondition}
        fullWidth
        sx={{
          my: 2,
          width: '100%',
        }}
      >
        Add New Condition
      </Button>
      { addResult && (
        <ReferenceSelector
          label="Result Email"
          modelName="emailTemplate"
          id="email"
          onChange={onResultChange}
          value={currentValue.result}
        />
      )}
      { !addResult && (
        <Button
          color="primary"
          variant="contained"
          onClick={() => setAddResult(true)}
          fullWidth
          sx={{
            my: 2,
            width: '100%',
          }}
        >
          Add Results
        </Button>
      )}
    </Box>
  );
};

export default observer(Conditions);
