import React, { useState } from 'react';
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Divider,
  IconButton,
  Paper,
  Popper,
} from '@mui/material';
import ActionsIcon from '@mui/icons-material/MoreVert';

import {
  ILearningBoard,
  navStore,
} from '@mcn-platform/models';
import { TreeItem } from './LearningBoardTree/types';

type ActionsButtonProps = {
  learningBoard: ILearningBoard;
  section: TreeItem; // actions apply to this section
  setCurrentSection: (section:TreeItem | null) => void;
  currentSection: TreeItem | null ; // this section is displayed in edit area
};

/**
 * @param section section to add the new element to
 * @returns JSX to be displayed in tree
 */
const ActionsButton = ({
  learningBoard,
  section,
  currentSection,
  setCurrentSection,
}:ActionsButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // console.log('anchorEl', anchorEl);
  const open = Boolean(anchorEl);
  const id = open ? 'add-element' : undefined;
  // console.log('section:', section);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    console.log('event', event);
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleDelete = () => {
    navStore.showConfirmDialog(
      'Confirm Delete',
      `Are you sure you want to delete ${section.name}?`,
      () => {
        learningBoard.removeSection(section);
        if (section === currentSection) {
          setCurrentSection(null);
        }
      },
      'Delete',
    );
  };

  const closePopup = () => {
    setAnchorEl(null);
  };

  const handleClickAway = () => {
    closePopup();
  };

  return (
    <>
      <IconButton aria-describedby={id} onClick={handleClick}>
        <ActionsIcon />
      </IconButton>
      <Popper id={id} anchorEl={anchorEl} open={open} placement="bottom-start">
        <ClickAwayListener onClickAway={handleClickAway}>
          <Paper>
            <ButtonGroup orientation="vertical">
              {/* learning board doesnt have a type */}
              {(typeof section.type === 'undefined') && (
                <>
                  <Button
                    onClick={() => {
                      const newSection = section.addCategory('New Portal Category/Group');
                      setCurrentSection(newSection);
                      closePopup();
                    }}
                  >
                    Add Portal Category/Group
                  </Button>
                  <Button
                    onClick={() => {
                      const newSection = section.addCustom('New Learning Board Group');
                      setCurrentSection(newSection);
                      closePopup();
                    }}
                  >
                    Add Learning Board Group
                  </Button>
                </>
              )}
              {section.canDelete() && (
                <>
                  <Divider />
                  <Button color="warning" onClick={handleDelete}>
                    Delete
                  </Button>
                </>
              )}
            </ButtonGroup>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default ActionsButton;
