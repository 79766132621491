import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
} from '@mui/material';
import Tabs from 'components/Tabs';
// import {
//   DataGrid,
//   GridColumns,
// } from '@mui/x-data-grid';
import { v4 } from 'uuid';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import usePrompt from 'hooks/usePrompt';

import {
  ITopic,
  useStore,
} from '@mcn-platform/models';
import { DiscussionGroup } from '@mcn-platform/portal-lib';
import ItemEdit from 'components/item/ItemEdit';
import EditLayout from './EditLayout';

// const ListingTab = ({
//   type,
//   topicID,
// }:{
//   type: string | undefined,
//   topicID: any,
// }) => {
//   const store = useStore();
//   const [rows, setRows] = useState([] as any[]);
//   console.log(topicID);
//   useEffect(() => {
//     switch (type) {
//       case 'course':
//         setRows(store.getCachedList('course', {
//           topic: { eq: topicID },
//         }).getItems());
//       break;
//       case 'program':
//         setRows(store.getCachedList('program', {
//           topic: { eq: topicID },
//         }).getItems());
//       break;
//       case 'microcast':
//         setRows(store.getCachedList('microcast', {
//           topic: { eq: topicID },
//         }).getItems());
//       break;
//       default:
//         setRows([]);
//       break;
//     }
//   }, [type, topicID, store]);

//   const columns: GridColumns = [
//     {
//       field: type === 'program' ? 'program.name' : 'course.name',
//       headerName: 'Item Name',
//       width: 250,
//       valueGetter: ({ row }) => row.name,
//     },
//   ];
//   return (
//     <Box>
//       <DataGrid
//         autoHeight
//         pageSize={10}
//         rows={rows}
//         columns={columns}
//         editMode="row"
//         hideFooterSelectedRowCount
//       />
//     </Box>
//   );
// };

const DiscussionEditor = () => {
  const [topic, setTopic] = useState<ITopic | null>();
  const store = useStore();
  const { key, tab } = useParams();
  const navigate = useNavigate();

  usePrompt({
    isDirty: topic ? (topic.changeCount > 0) : false,
  });
  useEffect(() => {
    if (!key) {
      return;
    }
    // check if we're creating a new kicker
    if (key === 'new') {
      if (topic) {
        console.log('All good - already loaded');
        return;
      }

      const newTopic = store.create(
        'discussionTopic',
        v4(),
        'New Topic',
      );
      setTopic(newTopic);
    } else {
      const loadedTopic = store.getOrLoad('discussionTopic', key);
      setTopic(loadedTopic);
    }
  }, [store, key, topic, topic?.syncState]);

  const setSelectedTab = (newTabKey:string) => {
    navigate(`/app/discussion/${key}/${newTabKey}`);
  };
  /**
   * Takes the full kicker and saves it to the kicker stub.  Sections are serialized to JSON
   */
  const saveTopic = () => {
    if (!topic) return;
    console.log('topic:', topic);
    if (key === 'new') {
      store.addStub('discussionTopic', topic).then(() => {
        navigate(`/app/discussion/${topic.id}`);
      });
    } else {
      store.update('discussionTopic', topic);
    }
  };

  if (!topic) {
    return <div>Loading.</div>;
  }
  if (topic.syncState === 'error') {
    return <div>Error loading Topic</div>;
  }

  if (topic.syncState === 'saving') {
    return <div>Saving..</div>;
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    }}
    >
      <Helmet>
        <title>{key === 'new' ? 'New Topic' : 'Edit Topic'}</title>
      </Helmet>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
      }}
      >
        <Tabs
          tabs={[{
            key: 'detail',
            label: 'Details',
            content: (
              <EditLayout
                title={key === 'new' ? 'New Topic' : 'Edit Topic'}
                buttonStack={(
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={saveTopic}
                    disabled={topic.changeCount === 0}
                  >
                    Save
                  </Button>
                )}
              >
                <ItemEdit
                  key={topic.id}
                  item={topic}
                  modelName="discussionTopic"
                  handleSubmit={(fields:any) => {
                    console.log('Update kicker', fields);
                    topic.updateValues(fields);
                  }}
                  hideTitle
                  autoSave
                  // fields={['name', 'description']}
                />
              </EditLayout>
            ),
          },
          {
            key: 'posts',
            label: 'Posts',
            content: <DiscussionGroup topic={topic} startingAdminMode />,
          },
          // {
          //   key: 'courses',
          //   label: 'Courses',
          //   content: <ListingTab type="course" topicID={key} />,
          // },
          // {
          //   key: 'program',
          //   label: 'Programs',
          //   content: <ListingTab type="program" topicID={key} />,
          // },
          // {
          //   key: 'microcast',
          //   label: 'Microcast',
          //   content: <ListingTab type="microcast" topicID={key} />,
          // },
          ]}
          selected={tab || 'detail'}
          setSelected={setSelectedTab}
        />
      </Box>
    </Box>
  );
};

export default observer(DiscussionEditor);
