import React from 'react';
import { Dialog, DialogContent } from '@mui/material';
import { AssetImage, AssetVideoPlayer, capitalizeFirstLetter } from '@mcn-platform/portal-lib';
import placeholderImage from 'assets/images/placeholder400.jpg';
import BootstrapDialogTitle from './BootstrapDialogTitle';

const PreviewDialog = ({
  open, item, handleClose,
}: any) => (
  <Dialog
    open={open}
    onClose={handleClose}
    fullWidth
    PaperProps={{ sx: {} }}
  >
    <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
      {capitalizeFirstLetter(item?.name)}
    </BootstrapDialogTitle>
    <DialogContent sx={{ overflowY: 'hidden' }}>
      {item?.mimeType.startsWith('image/')
        && (
          <AssetImage
            asset={item}
            customStyle={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
            fallbackSrc={placeholderImage}
            size="thumb400w"
          />
        )}
      {item?.mimeType.startsWith('video/')
        && (
          <AssetVideoPlayer
            asset={item}
            showControls
            aspectRatio="16:9"
            playerStyle={{ objectFit: 'fill' }}
          />
        )}
    </DialogContent>
  </Dialog>
);

export default PreviewDialog;
