import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import usePrompt from 'hooks/usePrompt';
import { Control } from 'react-hook-form';
import {
  IAdminStore,
  ICustomer,
  navStore,
  passwordValidator,
  useStore,
} from '@mcn-platform/models';
import * as Yup from 'yup';
import ButtonStack from 'components/item/ButtonStack';
import CustomerTracking from 'components/customer/CustomerTracking/CustomerTracking';
import DocumentsList from 'components/customer/DocumentsList';
import { FieldType } from 'components/forms/FieldTypes';
import ItemEdit from 'components/item/ItemEdit';
import LearningBoardEditor from 'components/learningBoard/LearningBoardEditor';
import MeetingRegistrationList from 'components/customer/MeetingRegistrationList';
import MembersListField from 'components/membership/MembersListField';
import QuestionnaireList from 'components/customer/QuestionnaireList';
import StandardTextField from 'components/forms/StandardTextField';
import Tabs from 'components/Tabs';

const MembersTab = ({
  id,
  name,
  email,
}:{
  id: string | undefined,
  name: string,
  email: string,
}) => {
  const store = useStore() as IAdminStore;
  return (
    <MembersListField
      organizationID={store.currentOrganization?.id}
      customerID={id}
      customerName={name}
      customerEmail={email}
    />
  );
};

const TemporaryPasswordField = (
  control: Control,
  fieldName: string,
  field:FieldType,
  readOnly:boolean,
  // itemType:string,
) => {
  // const store = useStore() as IAdminStore;
  // eslint-disable-next-line no-underscore-dangle, react/destructuring-assignment
  const { id } = control._formValues;

  // Only show temporary password for new customers
  if (id !== 'new') {
    return null; // <div>Not fur you</div>;
  }

  return (
    <StandardTextField
      control={control}
      fieldName={fieldName}
      field={field}
      readOnly={readOnly}
    />
  );
};

const CustomerEditor = () => {
  const store = useStore() as IAdminStore;
  const [customer, setCustomer] = useState<ICustomer>();
  const { key, tab } = useParams();
  const navigate = useNavigate();

  usePrompt({
      isDirty: customer ? (customer.changeCount > 0) : false,
  });
  const validator = Yup.object().shape({
    password: passwordValidator(),
  });

  useEffect(() => {
    if (!key) {
      return;
    }

    // check if we're creating a new customer
    if (key === 'new') {
      const newCustomer = store.create(
        'customer',
        'new',
        'New Customer',
        {
          organization: store.currentOrganization,
        },
      );
      setCustomer(newCustomer);
    } else {
      const stub = store.getOrLoad('customer', key);
      console.log('syncstub: ', stub.syncState, { ...stub });
      setCustomer(stub);
    }
  }, [store, key, customer?.syncState]);

  /**
   * Takes the full customer and saves it to the customer stub.  Sections are serialized to JSON
   */
  const saveCustomer = async () => {
    if (!customer) return;
    try {
      if (key === 'new') {
        // only set the password for new customers - throws erorr if not valid
        const password = customer.temporaryPassword;
        await validator.validate({ password });

        navStore.showMessageDialog('Creating Customer', 'Creating customer');
        await store.createCustomer(customer).then((newCustomer) => {
          console.log('saved');
          navigate(`/app/customer/${newCustomer.id}`);
          navStore.hideMessageDialog();
          navStore.showConfirmDialog(
            'New Customer',
            `New customer has been created with email ${customer.email}
            and password ${customer.temporaryPassword}
            `,
            () => navStore.hideConfirmDialog(),
            'OK',
            undefined,
            '',
          );
        });
      } else {
        await store.update('customer', customer);
        await store.refreshCustomerStats(customer.id);
        const results = store.saveAll();
        // const results = learningBoards.map((learningBoard) => {
        //   if (learningBoard.syncState === 'not_found') {
        //     return store.add('learningBoard', learningBoard);
        //   }
        //   return store.update('learningBoard', learningBoard);
        // });
        console.log('LearningBoard results', results);
      }
    } catch (e:any) {
      console.log('error', e);
      navStore.hideMessageDialog();
      if (e instanceof Yup.ValidationError) {
        navStore.errorDialog.showError('Password Requirements Not Met', e.message);
      } else {
        navStore.errorDialog.showError('Error Creating Customer', e.message);
      }
    }
  };

  const setSelectedTab = (newTabKey:string) => {
    navigate(`/app/customer/${key}/${newTabKey}`);
  };

  if (!customer) {
    return <div>Loading.</div>;
  }
  if (customer.syncState === 'error') {
    return <div>Error loading customer</div>;
  }

  if (customer.syncState === 'saving') {
    return <div>Saving..</div>;
  }

  if (key !== 'new' && customer.syncState !== 'loaded') {
    return <div>Loading..</div>;
  }

  const customerID = customer?.id;
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    }}
    >
      <Helmet>
        <title>{key === 'new' ? 'New Customer' : 'Edit Customer'}</title>
      </Helmet>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        minHeight: '100%',
      }}
      >
        <Tabs
          tabs={[{
            key: 'detail',
            label: 'Details',
            content: (
              <>
                <Box sx={{ flex: '1', overflowY: 'auto' }}>
                  <ItemEdit
                    item={customer}
                    modelName="customer"
                    handleSubmit={(newValues) => {
                      console.log('Update customer', newValues);
                      customer.updateValues(newValues);
                    }}
                    hideTitle
                    autoSave
                    overrides={{
                      temporaryPassword: TemporaryPasswordField,
                    }}
                  />
                </Box>
                <Box sx={{ flex: 0 }}>
                  <ButtonStack>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={saveCustomer}
                      disabled={customer.changeCount === 0}
                    >
                      Save
                    </Button>
                  </ButtonStack>
                </Box>
              </>
            ),
          },
          {
            key: 'memberships',
            label: 'Memberships',
            content: <MembersTab id={customerID} name={customer?.name} email={customer?.email} />,
          },
          {
            key: 'purchases',
            label: 'Purchases',
            content: <Box />,
          },
          {
            key: 'customerTracking',
            label: 'Customer Tracking',
            content: <CustomerTracking customer={customer} />,
          },
          {
            key: 'learningBoards',
            label: 'Learning Boards',
            content: <LearningBoardEditor
              customerID={customerID}
              portalID={store.currentPortal?.id}
            />,
          },
          {
            key: 'documents',
            label: 'Documents',
            content: <DocumentsList
              customer={customer}
              portal={store.currentPortal}
              organization={store.currentOrganization}
            />,
          },
          {
            key: 'questionnaires',
            label: 'Questionnaires',
            content: <QuestionnaireList
              customer={customer}
              portal={store.currentPortal}
              organization={store.currentOrganization}
            />,
          },
          {
            key: 'meetings',
            label: 'Meetings',
            content: <MeetingRegistrationList
              customer={customer}
              portal={store.currentPortal}
              organization={store.currentOrganization}
            />,
          },
          ]}
          selected={tab || 'detail'}
          setSelected={setSelectedTab}
        />
      </Box>
    </Box>
  );
};

export default observer(CustomerEditor);
