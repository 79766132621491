import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Controller } from 'react-hook-form';

import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Stack,
  Typography,
} from '@mui/material';
import { useStore } from '@mcn-platform/models';

import ItemList from 'components/item/ItemList';
import SelectionModel from 'components/item/SelectionModel';
import SortableItemList from 'components/item/sortableItemList/SortableItemList';
import { StandardFieldProps } from './FieldTypes';
import ItemPickerDialog from './ItemPickerDialog';

const getSelectedKeys = (modelName: string, items:any[]):Record<string, string[]> => {
  if (modelName === 'item') {
    return items.reduce(
      (selectedKeys, item) => {
        selectedKeys[item.type].push(item[item.type]);
        return selectedKeys;
      },
      {
        asset: [],
        course: [],
        document: [],
        meeting: [],
        microcast: [],
        program: [],
      },
    );
  }
  const selectedKeys = {};
  selectedKeys[modelName] = items;
  return selectedKeys;
};

const StandardArrayField = ({
  control,
  fieldName,
  field,
}:StandardFieldProps) => {
  const modelName = field.references || '';
  const [pickerOpen, setPickerOpen] = useState<boolean>(false);
  const store = useStore();

  return (
    <Controller
      name={fieldName}
      control={control}
      render={({
        field: {
          onChange,
          // onBlur,
          value,
          // name,
          // ref,
        },
        // fieldState: {
        //   // invalid,
        //   isTouched,
        //   // isDirty,
        //   error,
        // },
      }) => {
        const selectedKeysByModel = getSelectedKeys(modelName, value);
        console.log('selectedKeysByModel', selectedKeysByModel);

        /*
         * sets the value of the field to be the new items
         * completely replaces the old value
         */
        const updateItems = (newValue) => {
          onChange(newValue);
        };

        /*
         * sets the value of the field to be the new items
         * completely replaces the old value
         */
        const addItems = (newItems) => {
          const newValue = [...value, ...newItems];
          onChange(newValue);
        };

        const handleOpenSelectDialog = () => {
          setPickerOpen(true);
        };

        const handleConfirmSelectDialog = (
          newItems:any[],
        ) => {
          addItems(newItems);
          setPickerOpen(false);
        };

        const handleCancelSelectDialog = () => {
          setPickerOpen(false);
        };
        return (
          <FormControl fullWidth>
            <FormLabel className="WysiwygEditor-formlabel" htmlFor={fieldName}>
              <Typography color="inherit" fontWeight="inherit" component="div">
                {field.label}
              </Typography>
            </FormLabel>
            <Box>
              {
                field.sortable ? (
                  <SortableItemList
                    modelName={modelName}
                    items={value}
                    updateItems={(newItems) => updateItems(newItems)}
                  />
                ) : (
                  <ItemList
                    modelName={modelName}
                    model={new SelectionModel(
                      false,
                      value?.map((key) => (store.getOrLoad(modelName, key))),
                      [],
                      [],
                      [],
                    )}
                    listStyle="list"
                    defaultAction={() => {}}
                    actions={[]}
                    imageField={{ name: '', label: 'Preview', showMore: false }}
                    titleField={{ name: 'name', label: 'Name', showMore: false }}
                    secondaryFields={[
                      // { name: 'description', label: 'Description' },
                    ]}
                    showSelect={false}
                    enableDeselect={false}
                  />
                )
              }
            </Box>
            { (!field.readOnly) && (
              <Stack direction="row" spacing={3}>
                <Button variant="outlined" onClick={handleOpenSelectDialog}>
                  Update Items
                </Button>
                {/* <Button variant="outlined" onClick={handleRemoveDialog}>Remove</Button> */}
              </Stack>
            )}
            <ItemPickerDialog
              open={pickerOpen}
              modelName={modelName}
              preSelectedKeysByModel={selectedKeysByModel}
              onCancel={handleCancelSelectDialog}
              onConfirm={handleConfirmSelectDialog}
              scope={field.scope || 'organization'}
            />
          </FormControl>
        );
      }}
    />
  );
};

export default observer(StandardArrayField);
