import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import ItemNavigator from 'components/item/ItemNavigator';
import { observer } from 'mobx-react-lite';

const SelectAssetDialog = ({
  open, item, assetType, handleChange, handleClose, handleCancel,
}: any) => {
  const [selectedKeys, setSelectedKeys] = useState(item ? [item.key] : []);
  // console.log('Asset type', assetType);

  let titleLabelType;

  switch (assetType) {
    case 'video':
      titleLabelType = 'Video';
      break;
  
    default:
      titleLabelType = 'Asset';
      break;
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        width: '100%',
        maxWidth: '100%',
      }}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: '100%',
          height: '100%',
          margin: 8,
        },
      }}
    >
    <DialogTitle>{`Select ${titleLabelType}`}</DialogTitle>
      <DialogContent>
        <ItemNavigator
          modelName="asset"
          selectedKeys={item ? [item.key] : []}
          // listStyle="list"
          updateSelectedItems={setSelectedKeys}
          defaultAction={handleChange}
          filter={assetType !== 'any' ? { type: { eq: assetType } } : undefined}
          multiSelect={false}
          showSelect
          enableDeselect
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleCancel}>Cancel</Button>
        <Button variant="outlined" onClick={handleClose} disabled={selectedKeys.length !== 1}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
};

export default observer(SelectAssetDialog);
