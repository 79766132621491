/* eslint-disable no-plusplus */
import React from 'react';
import { useDrag } from 'react-dnd';
import { observer } from 'mobx-react-lite';
import {
  Box,
  ListItemButton,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
} from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ComponentIcon from '@mui/icons-material/Microwave';
import { ILearningBoard } from '@mcn-platform/models';
import { ItemTypes } from 'publisher/editor/DragConstants';
import ActionsButton from '../ActionsButton';
import { TreeItem } from './types';

type DraggableItemProps = {
  learningBoard: ILearningBoard;
  section: TreeItem;
  depth: number;
  currentSection: TreeItem | null;
  setCurrentSection: (newSection:TreeItem | null) => void;
  onClick: any;
  selected: boolean;
};
const DraggableItem = ({
  learningBoard,
  section,
  depth,
  currentSection,
  setCurrentSection,
  onClick,
  selected,
}:DraggableItemProps) => {
  const [{ isDragging }, dragRef, previewRef] = useDrag({
    type: ItemTypes.LEARNING_BOARD_SECTION,
    item: section,
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <Box
      sx={{
        opacity: isDragging ? 0.5 : 1,
        backgroundColor: selected ? '#f4f6fa' : 'inherit',
        color: selected ? '#1276dd' : '#777E91',
      }}
      ref={previewRef}
    >
      <ListItem
        disableGutters
        dense
        sx={{ pl: depth * 4, marginBottom: 0 }}
        secondaryAction={(
          <ActionsButton
            learningBoard={learningBoard}
            section={section}
            currentSection={currentSection}
            setCurrentSection={setCurrentSection}
          />
        )}
      >
        <ListItemIcon ref={dragRef} sx={{ cursor: 'grab' }}>
          <DragIndicatorIcon />
          <ComponentIcon />
        </ListItemIcon>
        <ListItemButton onClick={onClick}>
          <ListItemText primary={<Typography sx={{ fontSize: '0.875rem', fontWeight: '600' }}>{section.name}</Typography>}>
            {/* {path}
            <br /> */}
          </ListItemText>
        </ListItemButton>
      </ListItem>
    </Box>
  );
};

export default observer(DraggableItem);
