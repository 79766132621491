import * as React from 'react';
import { SVGProps } from 'react';
import { Avatar } from '@mui/material';
// import CheckIcon from '@mui/icons-material/Check';

export const CheckCirclePrimary = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <circle cx={10} cy={10} r={9.5} fill="currentColor" stroke="currentColor" />
    <path
      d="m6 10 2.667 3L14 7"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CheckCircleSecondary = (props: SVGProps<SVGSVGElement>) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Avatar
    sx={{
      backgroundColor: 'transparent',
      border: (theme) => `2.5px solid ${theme.palette.primary.light}`,
      // eslint-disable-next-line react/destructuring-assignment
      width: props.width,
      // eslint-disable-next-line react/destructuring-assignment
      height: props.height,
      padding: (theme) => theme.spacing(0.75),
      color: (theme) => theme.palette.success.main,
    }}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      enableBackground="new 0 0 24 24"
      height="1em"
      id="Layer_1"
      viewBox="0 0 24 24"
      width="1em"
      xmlSpace="preserve"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M21.652,3.211c-0.293-0.295-0.77-0.295-1.061,0L9.41,14.34  c-0.293,0.297-0.771,0.297-1.062,0L3.449,9.351C3.304,9.203,3.114,9.13,2.923,9.129C2.73,9.128,2.534,9.201,2.387,9.351  l-2.165,1.946C0.078,11.445,0,11.63,0,11.823c0,0.194,0.078,0.397,0.223,0.544l4.94,5.184c0.292,0.296,0.771,0.776,1.062,1.07  l2.124,2.141c0.292,0.293,0.769,0.293,1.062,0l14.366-14.34c0.293-0.294,0.293-0.777,0-1.071L21.652,3.211z"
        fillRule="evenodd"
        fill="currentColor"
      />
    </svg>
  </Avatar>
);
