import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import usePrompt from 'hooks/usePrompt';
import { observer } from 'mobx-react-lite';
import { Button } from '@mui/material';
import { useStore } from '@mcn-platform/models';
import ItemEdit from 'components/item/ItemEdit';
import ButtonStack from 'components/item/ButtonStack';
import { Control, Controller } from 'react-hook-form';
import TokensTable from 'components/portalAdmin/TokensTable';
import { FieldType } from 'components/forms/FieldTypes';

const TokensField = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  control, fieldName, field, readOnly, itemType,
}:{
  control: Control,
  fieldName: string,
  field:FieldType,
  readOnly: boolean,
  itemType:string,
}) => (
  <Controller
    name={fieldName}
    control={control}
    render={({
      field: {
        onChange,
        value,
      },
    }) => (
      <TokensTable tokens={value} save={onChange} readOnly={readOnly} />
    )}
  />
);

const TokensType = (
  control: Control,
  fieldName: string,
  field:FieldType,
  readOnly: boolean,
  itemType:string,
) => (
  <TokensField
    control={control}
    fieldName={fieldName}
    field={field}
    readOnly={readOnly}
    itemType={itemType}
  />
);

const overrides = { tokens: TokensType };

const PortalTokens = () => {
  const store = useStore();
  const { currentPortal: portal } = store;
  usePrompt({ isDirty: portal ? (portal.changeCount > 0) : false });
  /**
   * Takes the full course and saves it to the course stub.  Sections are serialized to JSON
   */
  const savePortal = useCallback(() => {
    if (!portal) return Promise.reject(Error('No portal'));
    return store.update('portal', portal);
  }, [store, portal]);

  if (!portal) {
    return <div>Loading</div>;
  }

  if (portal.syncState !== 'loaded') {
    return <div>Loading..</div>;
  }

  return (
    <>
      <Helmet>
        <title>Portal Tokens</title>
      </Helmet>
      <ItemEdit
        key={portal.id}
        modelName="portal"
        item={portal}
        // organize via groups instead
        formConfig={[
          'tokens',
        ]}
        overrides={overrides}
        handleSubmit={savePortal}
        hideTitle
        autoSave
      />
      <ButtonStack>
        <Button
          color="primary"
          variant="contained"
          onClick={savePortal}
          disabled={portal.changeCount === 0}
        >
          Save
        </Button>
        {/* <Box>
          (Change Count &nbsp;
          {course.changeCount}
          )
        </Box> */}
      </ButtonStack>
    </>
  );
};

export default observer(PortalTokens);
