import React, { useState, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  styled,
  Theme,
  useMediaQuery,
} from '@mui/material';
import { useStore } from '@mcn-platform/models';
import {
  BlockInput,
  GlobalConfirmDialog,
  GlobalErrorDialog,
  GlobalMessageDialog,
  MoveToTop,
} from '@mcn-platform/portal-lib';

import GlobalDndContext from 'components/GlobalDndContext';
import AdminAppBar from './AdminAppBar';
import AdminSideBar from './AdminSideBar';
import LoadGuard from './LoadGuard';

const drawerWidth = 298;

const AdminLayoutRoot = ({ children }:{ children:ReactNode }) => (
  <Box sx={{
    backgroundColor: 'palette.background.default',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  }}
  >
    {children}
  </Box>
);

const AdminLayoutWrapper = styled('main', { shouldForwardProp: (prop) => prop !== 'roomForSideBar' })<{
  open: boolean, roomForSideBar: boolean;
}>(({ theme, open, roomForSideBar }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'auto', // 'hidden',
  padding: `0 ${theme.spacing(3)}`,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: 0,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: roomForSideBar ? drawerWidth : 0,
  }),
}));

const AdminLayoutContainer = ({ children }:{ children:ReactNode }) => (
  <Box sx={{
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  }}
  >
    {children}
  </Box>
);

const AdminLayoutContent = ({ children }:{ children:ReactNode }) => (
  <Box sx={{
    flex: '1',
    height: '100%',
    overflow: 'auto',
  }}
  >
    {children}
  </Box>
);

const AdminLayout = (
  { status, errorMessage }:{ status:string, errorMessage: string },
) => {
  // const location = useLocation();
  const [pageTitle, setPageTitle] = useState('' as string);
  const [sideBarOpen, setSideBarOpen] = useState<boolean>(true);

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const overlaySideBar = !isDesktop;

  //   const pageTitle = NAVBAR_TITLES.find((el) => el.page === location.pathname)?.text || '';
  // useEffect(() => {
  //   // first two elements of path only
  //   const pathname = location.pathname.split('/').slice(0, 3).join('/');
  //   const navItem:any = entriesByPath[pathname];
  //   if (navItem) {
  //     setPageTitle(navItem.title || navItem.name);
  //   } else if (location.pathname.split('/').length > 3) {
  //     const pathname1 = location.pathname.split('/').slice(0, 4).join('/');
  //     const navItem1:any = entriesByPath[pathname1];
  //     const title = navItem1 ? navItem1.title || navItem1.name : '';
  //     setPageTitle(title);
  //   } else {
  //     setPageTitle('');
  //   }
  // }, [location.pathname]);

  if (status !== 'ready' && status !== 'error') {
    return <LoadGuard />;
  }
  if (status === 'error') {
    return <LoadGuard message={errorMessage} showLogout />;
  }

  return (
    <AdminLayoutRoot>
      <MoveToTop />
      <GlobalDndContext>
        <Helmet onChangeClientState={(newState) => setPageTitle(newState.title)} />
        <AdminAppBar
          title={pageTitle}
          drawerWidth={drawerWidth}
          sidebarOpen={sideBarOpen}
          setSidebarOpen={setSideBarOpen}
        />
        <AdminSideBar
          drawerWidth={drawerWidth}
          overlay={overlaySideBar}
          sidebarOpen={sideBarOpen}
          setSidebarOpen={setSideBarOpen}
        />
        <AdminLayoutWrapper open={sideBarOpen} roomForSideBar={sideBarOpen && !overlaySideBar}>
          <AdminLayoutContainer>
            <AdminLayoutContent>
              <Outlet />
              <BlockInput />
              <GlobalConfirmDialog />
              <GlobalErrorDialog />
              <GlobalMessageDialog />
            </AdminLayoutContent>
          </AdminLayoutContainer>
        </AdminLayoutWrapper>
      </GlobalDndContext>
    </AdminLayoutRoot>
  );
};

const AdminLoadGuard = () => {
  const { status, errorMessage } = useStore();
  return <AdminLayout status={status} errorMessage={errorMessage || ''} />;
};

export default observer(AdminLoadGuard); // AdminLayout;
