import React from 'react';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Box,
} from '@mui/material';
import { useStore, navStore } from '@mcn-platform/models';
import { WysiwygEditor, deserializeMarkdown } from '@mcn-platform/portal-lib';

interface GenerateAIDialogType {
  open: boolean;
  onClose: () => void;
  title: string;
  promptList: any[],
  itemType: string,
  itemKey: string,
  onConfirm: (newValue: any) => void;
  onCancel: () => void;
  isRichText: boolean;
}

const GenerateAIDialog = ({
  open,
  onClose,
  title,
  promptList,
  itemType,
  itemKey,
  onConfirm,
  onCancel,
  isRichText,
}: GenerateAIDialogType) => {
  const [prompt, setPrompt] = React.useState(promptList[0]);
  const [result, setResult] = React.useState<string | any>();
  const [wysiwygID, setWysiwygID] = React.useState<string>('generate_ai');
  const store = useStore();

  const updatedValue = (slateValue:any) => {
    console.log('slate value', slateValue);
    // console.log('rawText value', rawText);
    const newValue = {
      slate: slateValue,
      html: null,
    };
    console.log('New rich text value in AI Dialog', newValue);
    setResult(newValue.slate);
  };

  const handleConfirm = async () => {
    onConfirm(result);
  };

  const handleCancel = async () => {
    onCancel();
  };

  const handleGenerate = async () => {
    // console.log('Result: ', createdData);
    navStore.showMessageDialog(
      'Generating AI',
      'Please wait while generating content',
    );
    const generatedAIResult = await store.generateField(
      itemType,
      itemKey,
      prompt,
    );
    if (generatedAIResult) {
      // const value = processor.parse(generatedAIResult);
      // console.log('marked text', value);
      // updatedValue([value]);
      if (isRichText) {
        const markdownToPlate = deserializeMarkdown(generatedAIResult);
        console.log('markdownToPlate', markdownToPlate);
        setResult(markdownToPlate);
        setWysiwygID(`generate_ai.${Date.now()}`);
      } else {
        setResult(generatedAIResult);
      }
      navStore.hideMessageDialog();
    } else {
      navStore.hideMessageDialog();
      navStore.showConfirmDialog(
        'Generating AI',
        'Error generating content',
        () => navStore.hideMessageDialog(),
        'Ok',
      );
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={2}>
          <Stack direction="row" spacing={2}>
            <Autocomplete
              freeSolo
              fullWidth
              id="promptAutocomplete"
              options={promptList}
              onChange={(event:any) => {
                console.log('event', event);
                setPrompt(promptList[event.target.value]);
              }}
              value={promptList[0]}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  placeholder="Enter or modify Prompt"
                  value={prompt}
                  onChange={(event) => setPrompt(event.target.value)}
                />
              )}
            />
            <Button
              variant="outlined"
              onClick={handleGenerate}
            >
              Generate
            </Button>
          </Stack>
          {isRichText ? (
            <Box
              sx={[{
                width: '100%',
                border: '1px solid',
                borderColor: '#e0e0eb',
                borderRadius: 1,
                padding: 2,
              },
              {
                '&:hover': {
                  borderColor: '#111111',
                },
              },
              {
                '&:focus': {
                  border: '2px solid #1276dd',
                },
              },
              ]}
            >
              <WysiwygEditor
                key={wysiwygID}
                id={wysiwygID}
                value={result}
                setValue={(newValue:any) => { updatedValue(newValue); }}
              />
            </Box>
          ) : (
            <TextField
              value={result}
              placeholder="Generated content"
              fullWidth
              multiline
              rows={16}
              onChange={(event) => setResult(event.target.value)}
            />
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleConfirm} disabled={!result} color="primary" variant="outlined">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GenerateAIDialog;
