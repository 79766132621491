import React from 'react';
import { observer } from 'mobx-react-lite';
import {
  FormControlLabel,
  InputAdornment, OutlinedInput,
  TextField,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { StandardFieldProps } from './FieldTypes';
import HelperText from './HelperText';

const StandardNumberField = ({
  control,
  fieldName,
  field,
  readOnly,
}:StandardFieldProps) => (
  <Controller
    name={fieldName}
    control={control}
    render={({
      field: {
        onChange,
        onBlur,
        value,
        // name,
        // ref,
      },
      fieldState: {
        // invalid,
        isTouched,
        // isDirty,
        error,
      },
    }) => (
      <>
        <FormControlLabel
          label={field.label}
          disabled={field.readOnly}
          labelPlacement="top"
          sx={{ alignItems: 'flex-start' }}
          control={fieldName === 'discountPercent' ? (
            <OutlinedInput
              type="number"
              id={fieldName}
              name={fieldName}
              value={value || ''}
              onChange={onChange}
              onBlur={onBlur}
              disabled={field.readOnly || readOnly}
              error={isTouched && Boolean(error)}
              //   helperText={meta.touched && meta.error}
              startAdornment={(
                <InputAdornment position="start">%</InputAdornment>
              )}
            />
          ) : (
            <TextField
              type="number"
              id={fieldName}
              name={fieldName}
              value={value === null || value === undefined ? '' : value}
              onChange={(ev) => onChange(parseFloat(ev.target.value))}
              onBlur={onBlur}
              disabled={field.readOnly || readOnly}
              error={isTouched && Boolean(error)}
              helperText={isTouched && error?.message}
            />
          )}
        />
        <HelperText
          name={fieldName}
          error={isTouched && error?.message}
          description={field.description}
        />
      </>
    )}
  />
);

StandardNumberField.defaultProps = {
  // placeholder: '',
  description: '',
  disabled: false,
};

export default observer(StandardNumberField);
