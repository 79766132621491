import React from 'react';

import {
  DataGrid,
  GridColumns,
  GridRowModel,
} from '@mui/x-data-grid';

import {
  defaultTokens,
} from '@mcn-platform/models';
import { Box } from '@mui/material';

const tokensMeta = [
  {
    key: 'programName',
    name: 'Program Name',
    description: 'What to call programs within the portal.',
    groups: 'General',
  },
  {
    key: 'courseName',
    name: 'Course Name',
    description: 'What to call courses within the portal.',
    groups: 'General',
  },
  {
    key: 'documentName',
    name: 'Document Name',
    description: 'What to call documents within the portal.',
    groups: 'General',
  },
  {
    key: 'instructorName',
    name: 'Instructor Name',
    description: 'What to call instructors within the portal.',
    groups: 'General',
  },
  {
    key: 'learningBoardName',
    name: 'Learning Board Name',
    description: 'What to call the learning board within the portal.',
    groups: 'General',
  },
  {
    key: 'lessonName',
    name: 'Lesson Name',
    description: 'What to call lessons within the portal.',
    groups: 'General',
  },
  {
    key: 'meetingName',
    name: 'Meeting Name',
    description: 'What to call meetings within the portal.',
    groups: 'General',
  },
  {
    key: 'microcastName',
    name: 'Microcast Name',
    description: 'What to call microcasts within the portal.',
    groups: 'General',
  },
  {
    key: 'myLearningBoardLabel',
    name: 'My Learning Board Label',
    description: 'Name of tab in My Learnings page that links to the Learning Board.',
    groups: 'General',
  },
  {
    key: 'myDocumentsLabel',
    name: 'My Documents Label',
    description: 'Name of tab in My Learnings page that links to the My Documents page.',
    groups: 'General',
  },
  {
    key: 'startLabel',
    name: 'Start Label',
    description: 'Text to show on Start Microcast/Course/Program button.',
    groups: 'Buttons',
  },
  {
    key: 'continueLabel',
    name: 'Continue Label',
    description: 'Text to show on Continue Microcast/Course/Program button.',
    groups: 'Buttons',
  },
  {
    key: 'retakeLabel',
    name: 'Retake Label',
    description: 'Text to show on Retake Microcast/Course/Program button.',
    groups: 'Buttons',
  },
  {
    key: 'registerLabel',
    name: 'Register For Meeting Label',
    description: 'Text to show to register for a meeting.',
    groups: 'Buttons',
  },
  {
    key: 'acceptInviteLabel',
    name: 'Accept Invite To Meeting Label',
    description: 'Text to show to when customer has been invited but not yet registered for a meeting.',
    groups: 'Buttons',
  },
  {
    key: 'joinMeetingLabel',
    name: 'Join Now Label',
    description: 'Text to show to when customer can join the meeting.',
    groups: 'Buttons',
  },
  {
    key: 'registeredLabel',
    name: 'Registration Completed For Meeting Label',
    description: 'Text to show when customer has registered for the meeting.',
    groups: 'Buttons',
  },
  {
    key: 'viewRecordingLabel',
    name: 'View Meeting Recording Label',
    description: 'Text to show to view a previously recorded meeting.',
    groups: 'Buttons',
  },
  {
    key: 'programOutlineLabel',
    name: 'Program Outline Label',
    description: '',
    groups: 'Landing Page',
  },
  {
    key: 'programAboutLabel',
    name: 'Program About Label',
    description: '',
    groups: 'Landing Page',
  },
  {
    key: 'programDescriptionLabel',
    name: 'Program Description Label',
    description: '',
    groups: 'Delivery Page',
  },
  {
    key: 'instructorsTabLabel',
    name: 'Instructors Tab Label',
    description: 'Label to show on Instructor tab.  Use `hide` to not show instructor tab.',
    groups: 'Delivery Page',
  },
  {
    key: 'prevLabel',
    name: 'Previous Label',
    description: 'Text to show on the Previous button on the Course Delivery Page.',
    groups: 'Delivery Page',
  },
  {
    key: 'nextLabel',
    name: 'Next Label',
    description: 'Text to show on the Next button on the Course Delivery Page.',
    groups: 'Delivery Page',
  },
  {
    key: 'startQuizButtonLabel',
    name: 'Start Quiz Button Label',
    description: 'Text to show on the Start Quiz button on the Course Delivery Page.',
    groups: 'Delivery Page',
  },
  {
    key: 'progressLabel',
    name: 'Progress Label',
    description: 'Label to show the progress status on the Course Delivery Page.',
    groups: 'Delivery Page',
  },
  {
    key: 'notesLabel',
    name: 'Notes Label',
    description: 'Label to show Notes section on the Course Delivery Page.',
    groups: 'Delivery Page',
  },
  {
    key: 'addNoteButtonLabel',
    name: 'Add Note Button Label',
    description: 'Text to show on the Add Note button on the Course Delivery Page.',
    groups: 'Delivery Page',
  },
  {
    key: 'audioButtonLabel',
    name: 'Audio Button Label',
    description: 'Text to show on the Audio button.',
    groups: 'Delivery Page',
  },
  {
    key: 'articleButtonLabel',
    name: 'Article Button Label',
    description: 'Text to show on the Article button.',
    groups: 'Delivery Page',
  },
  {
    key: 'blockButtonLabel',
    name: 'Block Button Label',
    description: 'Text to show on the Block button.',
    groups: 'Delivery Page',
  },
  {
    key: 'certificationButtonLabel',
    name: 'Certification Button Label',
    description: 'Text to show on the Certification button.',
    groups: 'Delivery Page',
  },
  {
    key: 'courseButtonLabel',
    name: 'Course Button Label',
    description: 'Text to show on the Course button.',
    groups: 'Delivery Page',
  },
  {
    key: 'downloadButtonLabel',
    name: 'Download Button Label',
    description: 'Text to show on the Download button.',
    groups: 'Delivery Page',
  },
  {
    key: 'knowledgeCheckButtonLabel',
    name: 'Knowledge Check Button Label',
    description: 'Text to show on the Knowledge Check button.',
    groups: 'Delivery Page',
  },
  {
    key: 'microcastButtonLabel',
    name: 'Microcast Button Label',
    description: 'Text to show on the Microcast button.',
    groups: 'Delivery Page',
  },
  {
    key: 'pdfButtonLabel',
    name: 'PDF Button Label',
    description: 'Text to show on the PDF button.',
    groups: 'Delivery Page',
  },
  {
    key: 'quizButtonLabel',
    name: 'Quiz Button Label',
    description: 'Text to show on the Quiz button.',
    groups: 'Delivery Page',
  },
  {
    key: 'scormButtonLabel',
    name: 'Scorm Button Label',
    description: 'Text to show on the Scorm button.',
    groups: 'Delivery Page',
  },
  {
    key: 'videoButtonLabel',
    name: 'Video Button Label',
    description: 'Text to show on the Video button.',
    groups: 'Delivery Page',
  },
  {
    key: 'typeformButtonLabel',
    name: 'Typeform Button Label',
    description: 'Text to show on the Typeform button.',
    groups: 'Delivery Page',
  },
  {
    key: 'learningObjectivesLabel',
    name: 'Learning Objectives Label',
    description: '',
    groups: 'Landing Page',
  },
  {
    key: 'viewLessonLabel',
    name: 'View Lesson Label',
    description: 'Label used on Lesson Carousel and other locations that link to a lesson.',
    groups: 'Landing Page',
  },
  {
    key: 'discussionGroupLabel',
    name: 'Discussion Group Label',
    description: 'Label used for Discussion Group tab.',
    groups: 'Landing Page',
  },
  {
    key: 'myLearningsLabel',
    name: 'My Learnings Label',
    description: '',
    groups: 'Navigation',
  },
  {
    key: 'myMeetingsLabel',
    name: 'My Meetings Label',
    description: '',
    groups: 'Navigation',
  },
  {
    key: 'courseDescriptionLabel',
    name: 'Course Description Label',
    description: '',
    groups: 'Delivery Page',
  },
  {
    key: 'courseOutlineLabel',
    name: 'Course Outline Label',
    description: '',
    groups: 'Delivery Page',
  },
  {
    key: 'courseOverviewLabel',
    name: 'Course Overview Label',
    description: '',
    groups: 'Delivery Page',
  },
  {
    key: 'courseAboutLabel',
    name: 'Course About Label',
    description: '',
    groups: 'Delivery Page',
  },
  {
    key: 'courseMaterialsLabel',
    name: 'Course Materials Label',
    description: '',
    groups: 'Delivery Page',
  },
  {
    key: 'transcriptLabel',
    name: 'Transcript Label',
    description: '',
    groups: 'Delivery Page',
  },
  {
    key: 'richText1Label',
    name: 'Rich Text 1 Label',
    description: 'User facing label',
    groups: 'Delivery Page',
  },
  {
    key: 'richText2Label',
    name: 'Rich Text 2 Label',
    description: 'User facing label',
    groups: 'Delivery Page',
  },
  {
    key: 'richText3Label',
    name: 'Rich Text 3 Label',
    description: 'User facing label',
    groups: 'Delivery Page',
  },
  {
    key: 'richText4Label',
    name: 'Rich Text 4 Label',
    description: 'User facing label',
    groups: 'Delivery Page',
  },
  {
    key: 'richText5Label',
    name: 'Rich Text 5 Label',
    description: 'User facing label',
    groups: 'Delivery Page',
  },
  {
    key: 'richText6Label',
    name: 'Rich Text 6 Label',
    description: 'User facing label',
    groups: 'Delivery Page',
  },
  {
    key: 'richText7Label',
    name: 'Rich Text 7 Label',
    description: 'User facing label',
    groups: 'Delivery Page',
  },
  {
    key: 'richText8Label',
    name: 'Rich Text 8 Label',
    description: 'User facing label',
    groups: 'Delivery Page',
  },
  {
    key: 'richText9Label',
    name: 'Rich Text 9 Label',
    description: 'User facing label',
    groups: 'Delivery Page',
  },
  {
    key: 'richText10Label',
    name: 'Rich Text 10 Label',
    description: 'User facing label',
    groups: 'Delivery Page',
  },
  {
    key: 'kickerNameLabel',
    name: 'Kicker Name Label',
    description: 'User facing label',
    groups: 'Delivery Page',
  },
  {
    key: 'kickerEndAtLabel',
    name: 'Kicker End At Label',
    description: 'End At is the date after which the kicker should no longer be used.',
    groups: 'Delivery Page',
  },
  {
    key: 'kickerRichText1Label',
    name: 'Kicker Rich Text 1 Label',
    description: 'User facing label',
    groups: 'Delivery Page',
  },
  {
    key: 'kickerRichText2Label',
    name: 'Kicker Rich Text 2 Label',
    description: 'User facing label',
    groups: 'Delivery Page',
  },
  {
    key: 'kickerRichText3Label',
    name: 'Kicker Rich Text 3 Label',
    description: 'User facing label',
    groups: 'Delivery Page',
  },
  {
    key: 'kickerRichText4Label',
    name: 'Kicker Rich Text 4 Label',
    description: 'User facing label',
    groups: 'Delivery Page',
  },
  {
    key: 'kickerRichText5Label',
    name: 'Kicker Rich Text 5 Label',
    description: 'User facing label',
    groups: 'Delivery Page',
  },
  {
    key: 'kickerText1Label',
    name: 'Kicker Text 1 Label',
    description: 'User facing label',
    groups: 'Delivery Page',
  },
  {
    key: 'kickerText2Label',
    name: 'Kicker Text 2 Label',
    description: 'User facing label',
    groups: 'Delivery Page',
  },
  {
    key: 'kickerText3Label',
    name: 'Kicker Text 3 Label',
    description: 'User facing label',
    groups: 'Delivery Page',
  },
  {
    key: 'kickerText4Label',
    name: 'Kicker Text 4 Label',
    description: 'User facing label',
    groups: 'Delivery Page',
  },
  {
    key: 'kickerText5Label',
    name: 'Kicker Text 5 Label',
    description: 'User facing label',
    groups: 'Delivery Page',
  },
  {
    key: 'customerNameLabel',
    name: 'Customer Name Label',
    description: 'User facing label',
    groups: 'Customer',
  },
  {
    key: 'customerPhoneNumberLabel',
    name: 'Customer Phone Number Label',
    description: 'User facing label',
    groups: 'Customer',
  },
  {
    key: 'customerText1Label',
    name: 'Customer Text 1 Label',
    description: 'User facing label',
    groups: 'Customer',
  },
  {
    key: 'customerText2Label',
    name: 'Customer Text 2 Label',
    description: 'User facing label',
    groups: 'Customer',
  },
  {
    key: 'customerText3Label',
    name: 'Customer Text 3 Label',
    description: 'User facing label',
    groups: 'Customer',
  },
  {
    key: 'customerText4Label',
    name: 'Customer Text 4 Label',
    description: 'User facing label',
    groups: 'Customer',
  },
  {
    key: 'customerText5Label',
    name: 'Customer Text 5 Label',
    description: 'User facing label',
    groups: 'Customer',
  },
];

const columns: GridColumns = [
  // { field: 'id', headerName: 'Code', width: 130 },
  {
    field: 'id',
    headerName: 'Key',
    width: 200,
    editable: false,
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 200,
    editable: false,
  },
  {
    field: 'default',
    headerName: 'Default',
    flex: 1,
    editable: false,
  },
  {
    field: 'value',
    headerName: 'Override Value',
    flex: 1,
    editable: true,
  },
];

const buildTable = (tokens) => (tokensMeta.map((tokenDef) => ({
  ...tokenDef,
  id: tokenDef.key,
  name: tokenDef.name,
  default: defaultTokens[tokenDef.key],
  value: tokens ? tokens[tokenDef.key] : '',
})));

const TokensTable = ({
  tokens,
  save,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  readOnly,
} : {
  tokens: Record<string, string>,
  save: (newTokens:Record<string, string>) => void,
  readOnly: boolean,
}) => {
  const [rows, setRows] = React.useState(buildTable(tokens));

  const processRowUpdate = (newRow: GridRowModel) => {
    const newTokens = { ...tokens || {} };
    if (newRow.value) {
      newTokens[newRow.id] = newRow.value;
    } else {
      delete newTokens[newRow.id];
    }
    save(newTokens);
    return newRow; // || {};
  };

  const handleProcessRowUpdateError = (error:any) => {
    console.log('Error on update', error);
  };

  return (
    <Box>
      <DataGrid
        autoHeight
        pageSize={20}
        rows={rows}
        columns={columns}
        editMode="row"
        componentsProps={{
          toolbar: { setRows, save },
        }}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        processRowUpdate={processRowUpdate}
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>
  );
};

export default TokensTable;
