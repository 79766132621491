import React from 'react';
import { Box, Stack, Switch } from '@mui/material';
import ReferenceTagsInput from 'components/forms/TagsInput/ReferenceTagsInput';

export type ItemFilterProps = {
  allItems: boolean,
  setAllItems: (newValue:boolean) => void,
  courseFilter: string[],
  setCourseFilter: (ids: string[]) => void,
  microcastFilter: string[],
  setMicrocastFilter: (ids: string[]) => void,
  programFilter: string[]
  setProgramFilter: (ids: string[]) => void,
  showCourseFilter?: boolean,
  showProgramFilter?: boolean,
  showMicrocastFilter?: boolean,
};

const ItemFilter = ({
  allItems,
  setAllItems,
  courseFilter,
  setCourseFilter,
  programFilter,
  setProgramFilter,
  microcastFilter,
  setMicrocastFilter,
  showCourseFilter,
  showProgramFilter,
  showMicrocastFilter,
}:ItemFilterProps) => (
  <Stack direction="column" spacing={1}>
    <Stack direction="row" alignItems="center">
      <Switch checked={allItems} onChange={() => setAllItems(!allItems)} />
      <Box>All Items</Box>
    </Stack>
    {showCourseFilter && (
      <ReferenceTagsInput
        modelName="course"
        size="small"
        placeholder="Courses..."
        value={courseFilter}
        onChange={setCourseFilter}
        disabled={allItems}
      />
    )}
    {showMicrocastFilter && (
      <ReferenceTagsInput
        modelName="microcast"
        size="small"
        placeholder="Microcasts..."
        value={microcastFilter}
        onChange={setMicrocastFilter}
        disabled={allItems}
      />
    )}
    {showProgramFilter && (
      <ReferenceTagsInput
        modelName="program"
        size="small"
        placeholder="Programs..."
        value={programFilter}
        onChange={setProgramFilter}
        disabled={allItems}
      />
    )}
  </Stack>
);

ItemFilter.defaultProps = {
  showCourseFilter: true,
  showMicrocastFilter: true,
  showProgramFilter: true,
};

export default ItemFilter;
