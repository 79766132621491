import { Box, Divider, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import React from 'react';

/**
 * Helper component for doing tabs
 */
type TabDefinition = {
  key: string;
  label: string;
  content: React.ReactElement;
};

const a11yProps = (index: number) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

const Tabs = ({
  tabs,
  selected,
  setSelected,
}: {
  tabs: TabDefinition[],
  selected: string,
  setSelected: (selectedTab:string) => void,
}) => {
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelected(tabs[parseInt(newValue, 10)].key);
  };

  const tabIndex = tabs.findIndex((tab) => (tab.key === selected)) || '0';
  const tabValue = tabIndex === -1 ? '0' : tabIndex.toString();

  return (
    <TabContext value={tabValue}>
      <Box sx={{ width: '100%' }}>
        <TabList onChange={handleTabChange}>
          {tabs.map((tab: TabDefinition, index: number) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <Tab
              key={tab.key}
              label={tab.label}
              value={index.toString()}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...a11yProps(index)}
            />
          ))}
        </TabList>
      </Box>
      <Divider />
      {tabs.map((tab: TabDefinition, index: number) => (
        <TabPanel
          key={tab.key}
          value={index.toString()}
          sx={{ height: '100%' }}
        >
          {tab.content}
        </TabPanel>
      ))}
    </TabContext>
  );
};

export default Tabs;
