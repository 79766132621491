import React from 'react';
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
  OutlinedInput,
  InputAdornment,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Controller } from 'react-hook-form';
import { StandardFieldProps } from './FieldTypes';

const StandardCertificateCriteriaCourse = ({
  control,
  fieldName,
  field,
  readOnly,
}:StandardFieldProps) => (
  <Controller
    name={fieldName}
    control={control}
    render={({
      field: {
        onChange,
        onBlur,
        value,
      },
    }) => {
      // console.log('AJ CriteriaValue: ', value);
      const updateNewValue = (newValue) => {
        onChange(newValue);
      };
      const updateActive = (checked) => {
        if (value) {
          value.active = checked;
        } else {
          updateNewValue({
            active: checked,
          });
        }
        updateNewValue(value);
      };
      const updatePercentage = (e) => {
        if (value) {
          value.percentage = e.target.value;
        } else {
          updateNewValue({
            percentage: e.target.value,
          });
        }
        updateNewValue(value);
      };
      const disabled = Boolean(readOnly || field.readOnly);
      return (
        <FormControl
          fullWidth
          disabled={disabled}
        >
          <FormLabel id={`label-${fieldName}`}>{field.label}</FormLabel>
          <FormControlLabel
            id={`${fieldName}`}
            name={fieldName}
            label=""
            onBlur={onBlur}
            labelPlacement="end"
            aria-labelledby={`${fieldName}`}
            disabled={disabled}
            control={(
              <>
                <Checkbox
                  checked={!!value?.active}
                  onChange={(event) => {
                    updateActive(event.target.checked);
                  }}
                  onBlur={onBlur}
                  disabled={disabled}
                />
                <FormLabel disabled={disabled}>
                  &nbsp;Complete&nbsp;
                </FormLabel>
                <OutlinedInput
                  type="number"
                  id={`oinput-${fieldName}`}
                  name={fieldName}
                  value={value?.percentage || ''}
                  onChange={updatePercentage}
                  onBlur={onBlur}
                  disabled={disabled}
                  endAdornment={(
                    <InputAdornment position="start">%</InputAdornment>
                  )}
                />
                <FormLabel disabled={disabled}>
                  &nbsp;of the course or program.&nbsp;
                </FormLabel>
              </>
            )}
          />
        </FormControl>
      );
    }}
  />
  );
StandardCertificateCriteriaCourse.defaultProps = {
  placeholder: '',
  description: '',
  disabled: false,
};

export default observer(StandardCertificateCriteriaCourse);
