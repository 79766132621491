import React from 'react';
import { AssetTypes } from '@mcn-platform/models';
import { StandardFieldProps } from './FieldTypes';
import AccessGroupsTagsField from './AccessGroupsTagsField';
import AssetReferenceField from './AssetReferenceField';
import StandardArrayField from './StandardArrayField';
import StandardBorderStyleField from './StandardBorderStyleField';
import StandardBulletPointsField from './StandardBulletPointsField';
import StandardCheckboxField from './StandardCheckboxField';
import StandardColorField from './StandardColorField';
import StandardColorSetField from './StandardColorSetField';
import StandardCurrencyField from './StandardCurrencyField';
import StandardDateField from './StandardDateField';
import StandardDateTimeField from './StandardDateTimeField';
import StandardDurationField from './StandardDurationField';
import StandardFontStyleField from './StandardFontStyleField';
import StandardNumberField from './StandardNumberField';
import StandardChipOptionsField from './StandardChipOptionsField';
import StandardRadioButtonsField from './StandardRadioButtonsField';
import StandardReferenceField from './StandardReferenceField';
import StandardRichTextField from './StandardRichTextField';
import StandardSelectField from './StandardSelectField';
import StandardTagsField from './StandardTagsField';
import StandardReferenceChipsField from './StandardReferenceChipsField';
import StandardTextField from './StandardTextField';
import PortalCategoryField from './PortalCategoryField';
import EngagementFlowField from './EngagementFlowFields/EngagementFlowField';
import StandardCertificateCriteriaCourse from './StandardCertificateCriteriaCourse';
import StandardPhoneNumberField from './StandardPhoneNumberField';

const StandardField = ({
  control,
  fieldName,
  field,
  readOnly,
}:StandardFieldProps) => {
  if (field.type === 'reference') {
    if (field.references === 'asset') {
      // its either the field's assetType definition,
      // the type of the item, or any in that order
      const assetType:AssetTypes = field.assetType || 'any';
      // console.log('reference item', item);
      // console.log(`field name: ${fieldName}`);
      // console.log('field value:', item ? item[fieldName] : undefined);
      return (
        <AssetReferenceField
          control={control}
          fieldName={fieldName}
          field={field}
          assetType={assetType}
          readOnly={readOnly}
        />
      );
    }
    if (field.references === 'category') {
      // its the portal category list
      return (
        <PortalCategoryField
          control={control}
          fieldName={fieldName}
          field={field}
          readOnly={readOnly}
        />
      );
    }
    return (
      <StandardReferenceField
        control={control}
        fieldName={fieldName}
        field={field}
        readOnly={readOnly}
      />
    );
  }
  if (field.type === 'json') {
    if (field.subtype === 'engagementRules') {
      return (
        <EngagementFlowField
          control={control}
          field={field}
          fieldName={fieldName}
          // readOnly={readOnly}
        />
      );
    }
  }
  if (field.type === 'boolean') {
    if (field.enum) {
      return (
        <StandardRadioButtonsField
          control={control}
          fieldName={fieldName}
          field={field}
          readOnly={readOnly}
        />
      );
    }
    return (
      <StandardCheckboxField
        control={control}
        fieldName={fieldName}
        field={field}
        readOnly={readOnly}
      />
    );
  }
  if (field.type === 'options') {
    return (
      <StandardChipOptionsField
        control={control}
        fieldName={fieldName}
        field={field}
        readOnly={readOnly}
      />
    );
  }
  if (field.type === 'date') {
    return (
      <StandardDateField
        control={control}
        fieldName={fieldName}
        field={field}
        readOnly={readOnly}
      />
    );
  }
  if (field.type === 'timestamp') {
    return (
      <StandardDateTimeField
        control={control}
        fieldName={fieldName}
        field={field}
        readOnly={readOnly}
      />
    );
  }
  if (field.type === 'richtext') {
    return (
      <StandardRichTextField
        control={control}
        fieldName={fieldName}
        field={field}
        readOnly={readOnly}
      />
    );
  }
  if (field.type === 'array' || field.type === 'referenceArray') {
    if (field.subtype === 'chips') {
      return (
        <StandardReferenceChipsField
          control={control}
          fieldName={fieldName}
          field={field}
          readOnly={readOnly}
        />
      );
    }
    return (
      <StandardArrayField
        control={control}
        fieldName={fieldName}
        field={field}
        readOnly={readOnly}
      />
    );
  }
  if (field.type === 'number') {
    return (
      <StandardNumberField
        control={control}
        fieldName={fieldName}
        field={field}
        readOnly={readOnly}
      />
    );
  }
  if (field.type === 'currency') {
    return (
      <StandardCurrencyField
        control={control}
        fieldName={fieldName}
        field={field}
        readOnly={readOnly}
      />
    );
  }
  if (field.type === 'accessGroups') {
    return (
      <AccessGroupsTagsField
        control={control}
        fieldName={fieldName}
        field={field}
        readOnly={readOnly}
      />
    );
  }
  if (field.type === 'string') {
    if (field?.subtype === 'chips') {
      return (
        <StandardTagsField
          control={control}
          field={field}
          fieldName={fieldName}
          readOnly={readOnly}
          // options={field.enumLabels}
        />
      );
    }
    if (field?.subtype === 'phoneNumber') {
      return (
        <StandardPhoneNumberField
          control={control}
          field={field}
          fieldName={fieldName}
          readOnly={readOnly}
          // options={field.enumLabels}
        />
      );
    }
    if (field?.subtype === 'duration') {
      return (
        <StandardDurationField
          control={control}
          fieldName={fieldName}
          field={field}
          readOnly={readOnly}
        />
      );
    }
    if (field?.subtype === 'bulletPoints') {
      return (
        <StandardBulletPointsField
          control={control}
          fieldName={fieldName}
          field={field}
          readOnly={readOnly}
        />
      );
    }
    if (field?.subtype === 'color') {
      return (
        <StandardColorField
          control={control}
          fieldName={fieldName}
          field={field}
          readOnly={readOnly}
        />
      );
    }
    if (field?.subtype === 'colorSet') {
      return (
        <StandardColorSetField
          control={control}
          fieldName={fieldName}
          field={field}
          readOnly={readOnly}
        />
      );
    }
    if (field?.subtype === 'fontStyle') {
      return (
        <StandardFontStyleField
          control={control}
          fieldName={fieldName}
          field={field}
          readOnly={readOnly}
        />
      );
    }
    if (field?.subtype === 'borderStyle') {
      return (
        <StandardBorderStyleField
          control={control}
          fieldName={fieldName}
          field={field}
          readOnly={readOnly}
        />
      );
    }
  }
  if (field.enum) {
    return (
      <StandardSelectField
        control={control}
        fieldName={fieldName}
        field={field}
        readOnly={readOnly}
      />
    );
  }
  if (field.type === 'string' && (!field.subtype || field.subtype === 'email')) {
    return (
      <StandardTextField
        control={control}
        fieldName={fieldName}
        field={field}
        readOnly={readOnly}
      />
    );
  }
  if (field.subtype === 'courseCertificateCriteria') {
    return (
      <StandardCertificateCriteriaCourse
        control={control}
        fieldName={fieldName}
        field={field}
        readOnly={readOnly}
      />
    );
  }
  return null;
};

export default StandardField;
