import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet-async';
import { v4 } from 'uuid';
import { useNavigate, useParams } from 'react-router-dom';
import usePrompt from 'hooks/usePrompt';

import ItemEdit from 'components/item/ItemEdit';
import { IEmailTemplate, navStore, useStore } from '@mcn-platform/models';
import ButtonStack from 'components/item/ButtonStack';

const variables = [
  {
    variable: 'customer.name',
    description: 'Customer name',
  },
  {
    variable: 'customer.email',
    description: 'Customer email',
  },
  {
    variable: 'customer.temporaryPassword',
    description: 'Temporary password',
  },
  {
    variable: 'course.name',
    description: 'Course name',
  },
  {
    variable: 'course.link',
    description: 'Course link',
  },
];

const VariableDescription = ({ variable, description }:any) => (
  <Typography variant="body1">
    <b>
      {'${'}
      {variable}
      {'}'}
    </b>
    {' - '}
    {description}
  </Typography>
);

const ResultDialog = ({
  open,
  handleClose,
  fromAddress,
  toAddress,
  subject,
  body,
}:any) => (
  <Dialog
    open={open}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      Test Email Result
    </DialogTitle>
    <DialogContent>
      <Stack direction="column">
        <Typography variant="body1">
          The email has been sent.  A preview of the email is also shown below.
        </Typography>
        <Box>
          From:
          {fromAddress}
        </Box>
        <Box>
          To:
          {toAddress}
        </Box>
        <Box>
          Subject:
          {subject}
        </Box>
        <Box sx={{ border: '1px solid grey' }}>
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: body }}
          />
        </Box>
      </Stack>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

const EmailTemplateEditor = ({ clone }:{ clone?: boolean }) => {
  const [emailTemplate, setEmailTemplate] = useState<IEmailTemplate | null>();
  const [testEmailAddress, setTestEmailAddress] = useState<string>('test@microcasting.com');
  const [resultDialogOpen, setResultDialogOpen] = useState<boolean>(false);
  const [resultData, setResultData] = useState<any>(undefined);
  const store = useStore();
  const { emailTemplateID } = useParams();
  const navigate = useNavigate();

  usePrompt({ isDirty: emailTemplate ? (emailTemplate.changeCount > 0) : false });

  useEffect(() => {
    if (!emailTemplateID) {
      return;
    }
    // check if we're creating a new emailTemplate
    if (clone) {
      if (emailTemplate) {
        console.log('All good - already loaded');
        return;
      }

      console.log('cloning');
      store.load('emailTemplate', emailTemplateID).then((emailTemplateToClone:any) => {
        const newEmailTemplate = store.clone(
          'emailTemplate',
          emailTemplateToClone,
        );
        setEmailTemplate(newEmailTemplate);
      });
    } else if (emailTemplateID === 'new') {
      if (emailTemplate) {
        console.log('All good - already loaded');
        return;
      }

      const newEmailTemplate = store.create(
        'emailTemplate',
        v4(),
        'New emailTemplate',
      );
      setEmailTemplate(newEmailTemplate);
    } else {
      const loadedEmailTemplate = store.getOrLoad('emailTemplate', emailTemplateID);
      setEmailTemplate(loadedEmailTemplate);
    }
  }, [store, emailTemplateID, emailTemplate, emailTemplate?.syncState, clone]);

  useEffect(() => {
    setTestEmailAddress(store?.currentUser?.email);
  }, [store, store?.currentUser]);

  /**
   * Takes the full flow and saves it to the flow stub.  Sections are serialized to JSON
   */
  const saveEmailTemplate = () => {
    if (!emailTemplate) return;
    console.log('emailTemplate:', emailTemplate);
    if (clone || emailTemplateID === 'new') {
      store.addStub('emailTemplate', emailTemplate).then(() => {
        navigate(`/app/email-template-editor/${emailTemplate.id}`);
      });
    } else {
      store.update('emailTemplate', emailTemplate);
    }
  };

  const sendTestEmail = async () => {
    if (!emailTemplate) {
      return;
    }

    navStore.showMessageDialog(
      'Sending Test Email',
      'Sending the test email. Please wait.',
    );
    const sendResult = await store.sendTestEmail(
      emailTemplate.subject,
      emailTemplate.body,
      emailTemplate.from,
      testEmailAddress,
      emailTemplate.bcc,
    );
    setResultData(sendResult);
    navStore.hideMessageDialog();
    setResultDialogOpen(true);
  };

  if (!emailTemplate) {
    return <div>Loading.</div>;
  }
  if (emailTemplate.syncState === 'error') {
    return <div>Error loading flow</div>;
  }

  if (emailTemplate.syncState === 'saving') {
    return <div>Saving..</div>;
  }

  if (!clone && emailTemplateID !== 'new' && emailTemplate.syncState !== 'loaded') {
    return <div>Loading..</div>;
  }
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    }}
    >
      <Helmet>
        <title>Email Template Editor</title>
      </Helmet>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
      }}
      >
        <Box sx={{ flex: '1', overflowY: 'auto' }}>
          <Grid container sx={{ height: '100%' }}>
            <Grid item xs={12} md={12} sx={{ height: '100%', overflow: 'hidden', pr: 2 }}>
              <Card sx={{ height: '100%', overflow: 'auto' }}>
                <CardContent>
                  <ItemEdit
                    key={emailTemplate.id}
                    item={emailTemplate}
                    modelName="emailTemplate"
                    handleSubmit={(fields:any) => {
                      console.log('Update emailTemplate', fields);
                      emailTemplate.updateValues(fields);
                    }}
                    hideTitle
                    autoSave
                    // fields={['name', 'description']}
                  />
                  <FormControlLabel
                    label="Test Email Address"
                    labelPlacement="top"
                    sx={{ mx: 2 }}
                    control={(
                      <TextField
                        value={testEmailAddress}
                        placeholder="Enter test email address"
                        fullWidth
                        size="small"
                        onChange={(event) => setTestEmailAddress(event.target.value)}
                      />
                    )}
                  />
                  <Stack
                    sx={{ m: 2 }}
                  >
                    <Typography variant="h5">
                      Available Variables
                    </Typography>
                    {
                      variables.map((variableDescription) => (
                        <VariableDescription
                          key={variableDescription.variable}
                          variable={variableDescription.variable}
                          description={variableDescription.description}
                        />
                      ))
                    }
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <ButtonStack>
          <Button
            color="primary"
            variant="outlined"
            onClick={sendTestEmail}
          >
            Send Test Email
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={saveEmailTemplate}
            disabled={emailTemplate.changeCount === 0}
          >
            Save
          </Button>
        </ButtonStack>
      </Box>
      <ResultDialog
        open={resultDialogOpen}
        handleClose={() => setResultDialogOpen(false)}
        fromAddress={emailTemplate.from}
        toAddress={testEmailAddress}
        subject={resultData?.subject}
        body={resultData?.body}
      />
    </Box>
  );
};

EmailTemplateEditor.defaultProps = {
  clone: false,
};

export default observer(EmailTemplateEditor);
