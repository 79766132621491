import React from 'react';
import {
  Box,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ReferenceTagsInput from 'components/forms/TagsInput/ReferenceTagsInput';
import { SortType } from '../types';
import FilterAccordion from '../FilterAccordion';
import ItemFilter, { ItemFilterProps } from '../ItemFilter';

const DateRangePicker = ({
  afterDate, setAfterDate,
  beforeDate, setBeforeDate,
}:any) => (
  <Stack direction="row" spacing={2} alignItems="center">
    <span>FROM</span>
    <DatePicker
      value={afterDate}
      onChange={setAfterDate}
    />
    <span>TO</span>
    <DatePicker
      value={beforeDate}
      onChange={setBeforeDate}
    />
  </Stack>
);

const sortFieldOptions:SortType[] = [
  {
    id: 'lastActiveDate',
    label: 'Accessed Date',
  },
  {
    id: 'completeDate',
    label: 'Completion Date',
  },
  {
    id: 'progress',
    label: 'progress',
  },
];

export const certificationStatusOptions:SortType[] = [
  {
    id: 'notStarted',
    label: 'No Progress',
  },
  {
    id: 'inProgress',
    label: 'In Progress',
  },
  {
    id: 'failed',
    label: 'Failed',
  },
  {
    id: 'completed',
    label: 'Passed',
  },
  {
    id: 'all',
    label: 'All',
  },
];

const sortDirectionOptions:SortType[] = [
  {
    id: 'asc',
    label: 'Ascending',
  },
  {
    id: 'desc',
    label: 'Descending',
  },
];

type CertificationProgressFiltersProps = ItemFilterProps & {
  allCertifications: boolean,
  setAllCertifications: (newValue:boolean) => void,
  certificationFilter: string[],
  setCertificationFilter: (ids: string[]) => void,
  complianceStatus: string,
  setComplianceStatus: (sort:string) => void,
  accessedAfter: any,
  setAccessedAfter: (newDate:any) => void,
  accessedBefore: any,
  setAccessedBefore: (newDate:any) => void,
  completedAfter: any,
  setCompletedAfter: (newDate:any) => void,
  completedBefore: any,
  setCompletedBefore: (newDate:any) => void,
  lastActiveAfter: any,
  setLastActiveAfter: (newDate:any) => void,
  lastActiveBefore: any,
  setLastActiveBefore: (newDate:any) => void,
  progressMin: number,
  setProgressMin: (newNumber:number) => void,
  progressMax: number,
  setProgressMax: (newNumber:number) => void,
  sortField: string,
  setSortField: (sort:string) => void,
  sortDirection: string,
  setSortDirection: (direction:string) => void,
};

const CertificationsProgressFilters = ({
  allItems,
  setAllItems,
  courseFilter,
  setCourseFilter,
  microcastFilter,
  setMicrocastFilter,
  programFilter,
  setProgramFilter,
  allCertifications,
  setAllCertifications,
  certificationFilter,
  setCertificationFilter,
  complianceStatus,
  setComplianceStatus,
  accessedAfter,
  setAccessedAfter,
  accessedBefore,
  setAccessedBefore,
  completedAfter,
  setCompletedAfter,
  completedBefore,
  setCompletedBefore,
  lastActiveAfter,
  setLastActiveAfter,
  lastActiveBefore,
  setLastActiveBefore,
  progressMin,
  setProgressMin,
  progressMax,
  setProgressMax,
  sortField,
  setSortField,
  sortDirection,
  setSortDirection,
}: CertificationProgressFiltersProps) => (
  <FilterAccordion>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={3} sx={{ textAlign: 'right' }}>
        <FormLabel>Item Filter</FormLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <ItemFilter
          allItems={allItems}
          setAllItems={setAllItems}
          courseFilter={courseFilter}
          setCourseFilter={setCourseFilter}
          microcastFilter={microcastFilter}
          setMicrocastFilter={setMicrocastFilter}
          programFilter={programFilter}
          setProgramFilter={setProgramFilter}
          showMicrocastFilter={false}
        />
      </Grid>
      <Grid item xs={12} sm={3} sx={{ textAlign: 'right' }}>
        <FormLabel>Certification Filter</FormLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <Stack direction="column" spacing={1}>
          <Stack direction="row" alignItems="center">
            <Switch
              checked={allCertifications}
              onChange={() => setAllCertifications(!allCertifications)}
            />
            <Box>All Certifications</Box>
          </Stack>
          <ReferenceTagsInput
            modelName="certification"
            size="small"
            placeholder="Certifications..."
            value={certificationFilter}
            onChange={setCertificationFilter}
            disabled={allCertifications}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} sm={3} sx={{ textAlign: 'right' }}>
        <FormLabel>Compliance Status</FormLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Box>Field</Box>
          <Select
            size="small"
            onChange={(event) => {
              setComplianceStatus(event.target.value);
            }}
            value={complianceStatus}
            sx={{ width: 300 }}
          >
            {certificationStatusOptions.map((option) => (
              <MenuItem value={option.id} key={option.id}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={3} sx={{ textAlign: 'right' }}>
        <FormLabel>Assigned / Accessed</FormLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <DateRangePicker
          afterDate={accessedAfter}
          setAfterDate={setAccessedAfter}
          beforeDate={accessedBefore}
          setBeforeDate={setAccessedBefore}
        />
      </Grid>
      <Grid item xs={12} sm={3} sx={{ textAlign: 'right' }}>
        <FormLabel>Completed</FormLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <DateRangePicker
          afterDate={completedAfter}
          setAfterDate={setCompletedAfter}
          beforeDate={completedBefore}
          setBeforeDate={setCompletedBefore}
        />
      </Grid>
      <Grid item xs={12} sm={3} sx={{ textAlign: 'right' }}>
        <FormLabel>Last Active</FormLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <DateRangePicker
          afterDate={lastActiveAfter}
          setAfterDate={setLastActiveAfter}
          beforeDate={lastActiveBefore}
          setBeforeDate={setLastActiveBefore}
        />
      </Grid>
      <Grid item xs={12} sm={3} sx={{ textAlign: 'right' }}>
        <FormLabel>Progress</FormLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Box>FROM</Box>
          <TextField
            size="small"
            value={progressMin}
            onChange={(event) => setProgressMin(parseInt(event.target.value, 10))}
            type="number"
            InputProps={{
              inputProps: { max: 100, min: 0 },
            }}
          />
          <Box>TO</Box>
          <TextField
            size="small"
            value={progressMax}
            onChange={(event) => setProgressMax(parseInt(event.target.value, 10))}
            type="number"
            InputProps={{
              inputProps: { max: 100, min: 0 },
            }}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} sm={3} sx={{ textAlign: 'right' }}>
        <FormLabel>Sort</FormLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Box>Field</Box>
          <Select
            size="small"
            onChange={(event) => {
              setSortField(event.target.value);
            }}
            value={sortField}
            sx={{ width: 300 }}
          >
            {sortFieldOptions.map((option) => (
              <MenuItem value={option.id} key={option.id}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          <Box>Direction</Box>
          <Select
            size="small"
            value={sortDirection}
            onChange={(event) => {
              setSortDirection(event.target.value);
            }}
            sx={{ width: 300 }}
          >
            {sortDirectionOptions.map((option) => (
              <MenuItem value={option.id} key={option.id}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </Stack>
      </Grid>
    </Grid>
  </FilterAccordion>
);

export default CertificationsProgressFilters;
