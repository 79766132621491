const fontWeights = {
  fontWeightThin: 100,
  fontWeightExtraLight: 200,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightSemiBold: 600,
  fontWeightBold: 700,
  fontWeightExtraBold: 800,
  fontWeightBlack: 900,
};

export default {
  fontFamily: [
    'Montserrat',
    '-apple-system',
    'BlinkMacSystemFont',
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
  ].join(','),
  ...fontWeights,
  h1: {
    fontWeight: 500,
    fontSize: 35,
    letterSpacing: '-0.24px',
  },
  h2: {
    fontWeight: fontWeights.fontWeightBold,
    fontSize: '2rem', // 32px
    letterSpacing: '0.00735em',
    lineHeight: '38.98px',
  },
  h3: {
    fontWeight: 500,
    fontSize: 24,
    letterSpacing: '-0.06px',
  },
  h5: {
    fontWeight: 500,
    fontSize: 16,
    letterSpacing: '-0.05px',
  },
  // maq customized //
  h4: {
    fontWeight: fontWeights.fontWeightBold,
    fontSize: '1.125rem', // 18px
    letterSpacing: '0.00735em',
  },
  h6: {
    fontWeight: fontWeights.fontWeightSemiBold,
    fontSize: '0.875rem', // 14px
    letterSpacing: '0.0075em',
  },
  subtitle1: {
    fontWeight: fontWeights.fontWeightRegular, // taken from default
    fontSize: '0.875rem', // 14px
    letterSpacing: '0.00938em',
  },
  body1: {
    fontWeight: fontWeights.fontWeightMedium,
    fontSize: '0.875rem', // 14px
    letterSpacing: '0.00938em',
  },
  body2: {
    fontWeight: fontWeights.fontWeightRegular,
    fontSize: '0.75rem', // 12px
    letterSpacing: '0.01071em',
  },
  button: {
    fontWeight: fontWeights.fontWeightSemiBold,
    fontSize: '0.75rem', // 12px
    letterSpacing: '0.01071em',
  },
  // // // // // // //

  overline: {
    fontWeight: 500,
  },
};
