import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Paper,
  Stack,
  Typography,
  Tooltip,
  IconButton,
} from '@mui/material';
import { AssetImage } from '@mcn-platform/portal-lib';
import { observer } from 'mobx-react-lite';
import {
  Delete as DeleteIcon,
  Replay as ReloadIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import {
  AssetTypes, IAsset, MimeTypeMap, formatDuration, navStore, useStore,
} from '@mcn-platform/models';
import ProgressLinear from 'components/progressLinear/ProgressLinear';
import placeholderImage from 'assets/images/placeholder400.jpg';
import PreviewDialog from './PreviewDialog';
import SelectAssetDialog from './SelectAssetDialog';

type AssetPickerDialogProps = {
  value: IAsset | undefined;
  name:string;
  canUpload: boolean;
  showSelectedImage?: boolean;
  assetType?: AssetTypes;
  onChange: (newValue:any) => void;
  disabled?: boolean,
  TranscriptDialog?: any,
};

enum PickerState {
  Display = 'Display', // normal view, not selecting or uploading
  PreviewModal = 'DisplayModal', // modal view, not selecting or uploading
  Transcript = 'Transcript', // model to view/edit transcript
  Choosing = 'Choosing', // selecting asset from dialog window
  Confirm = 'Confirm',
  Uploading = 'Uploading',
}

const UploadButton = ({
  name,
  handleFileSelected,
  acceptType,
  repository,
  disabled,
  // setRepository,
}:any) => (
  <Stack direction="row" width="100%" spacing={1}>
    <Tooltip arrow placement="top" title={repository ? `Upload to ${repository?.name}` : 'Please select repository.'}>
      <label htmlFor={`${name}-outlined-button-file`}>
        <input
          id={`${name}-outlined-button-file`}
          name={`${name}-outlined-button-file`}
          // accept={MimeTypeMap[assetType]}
          accept={acceptType}
          style={{ display: 'none' }}
          type="file"
          onChange={handleFileSelected}
          disabled={disabled || !repository}
        />
        <Button
          component="span"
          variant="outlined"
          disabled={disabled || !repository}
        >
          Upload
        </Button>
      </label>
    </Tooltip>
  </Stack>
);

const AssetPickerDialog = ({
  value,
  name,
  canUpload = false,
  showSelectedImage,
  onChange,
  assetType = 'any',
  disabled = false,
  TranscriptDialog = undefined,
}:AssetPickerDialogProps) => {
  const store = useStore();
  const [repository, setRepository] = useState<any>(store.currentRepository);
  const [asset, setAsset] = useState<IAsset | null>(null);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [pickerState, setPickerState] = useState<string>(PickerState.Display);
  const [selectedFile, setSelectedFile] = useState<File>();

  // this is tha value selected in the picker - it becomes the selected asset if/when user confirms
  const [currentSelection, setCurrentSelection] = useState<any>();

  const handleSetRepository = (newValue:any) => {
    if (typeof newValue === 'string') {
      setRepository(store.libraries.get(newValue));
    } else {
      setRepository(newValue);
    }
  };

  const reloadAsset = () => {
    if (!asset) {
      return;
    }
    store.getOrLoad('asset', asset.id, 10);
  };

  // console.log('Value is', value);
  useEffect(() => {
    if (value) {
      if (typeof value === 'string') {
        setAsset(store.getOrLoad('asset', value));
      } else {
        setAsset(store.getOrLoad('asset', value.id));
      }
    } else {
      setAsset(null);
    }
  }, [store, value]);
  // const asset = store.getOrLoad('asset', value?.id);

  /**
   * Updates the selectedAsset state and also updates Formik form state.
   * @param newValue Asset model
   */
  const updateSelectedAsset = (newValue) => {
    setAsset(newValue);
    onChange(newValue);
  };

  const handleChange = (newValue:any) => {
    setAsset(newValue);
    setCurrentSelection(newValue);
  };

  const handlePreviewModalOpen = () => {
    setPickerState(PickerState.PreviewModal);
  };
  const handlePreviewModalClose = () => {
    setPickerState(PickerState.Display);
  };

  const handleTranscriptModalOpen = () => {
    setPickerState(PickerState.Transcript);
  };

  const navigate = useNavigate();
  const handleEditAsset = () => {
    navigate(`/app/asset/${asset?.id}`);
  };

  const handleTranscriptModalClose = () => {
    setPickerState(PickerState.Display);
  };

  const handleOpenSelectDialog = () => {
    setCurrentSelection(asset);
    setPickerState(PickerState.Choosing);
  };
  const handleCloseSelectDialog = () => {
    // setUserUpdated(true);
    updateSelectedAsset(currentSelection);
    setPickerState(PickerState.Display);
  };

  const monitorUploadProgress = (progressPercent:number) => {
    const progress = Math.round(progressPercent);
    setUploadProgress(progress);
    if (progressPercent === 100) {
      setPickerState(PickerState.Display);
    }
  };

  const handleCancelSelectDialog = () => {
    setPickerState(PickerState.Display);
  };

  const handleFileSelected = (event:any) => {
    if (event.target.files.length === 0) {
      console.log('No file selected');
      return;
    }
    // setUserUpdated(true);
    setUploadProgress(0);
    setSelectedFile(event.target.files[0]);
    setPickerState(PickerState.Uploading);
    store.upload(
      event.target.files[0],
      repository,
      updateSelectedAsset,
      monitorUploadProgress,
    ).then((newAsset) => {
      setAsset(newAsset);
    });
  };

  const deleteAsset = () => {
    navStore.showConfirmDialog(
      'Delete asset',
      'Are you sure you want to delete this asset?',
      () => {
        updateSelectedAsset(null);
        setAsset(null);
      },
      'Delete',
      undefined,
      'Cancel',
    );
  };

  return (
    <Box sx={{ mt: 0.625 }}>
      {(pickerState === PickerState.Display || pickerState === PickerState.PreviewModal) && (
        <Stack spacing={1}>
          {showSelectedImage && asset
            && ((asset.type === 'image' || asset.type === 'video') ? (
              <>
                <Stack direction="row" spacing={1}>
                  <Box sx={{ minWidth: '200px' }}>
                    <Box
                      sx={{
                        position: 'relative',
                        /* Aspect ratio for image is 4:3 */
                        width: '200px',
                        height: 150,
                      }}
                      onClick={handlePreviewModalOpen}
                    >
                      <AssetImage
                        avatar={false}
                        avatarVariant="rounded"
                        asset={asset}
                        customStyle={{
                          width: '100%',
                          height: '100%',
                          cursor: 'zoom-in',
                        }}
                        fallbackSrc={placeholderImage}
                        size={asset?.type === 'video' ? 'videoThumb' : 'thumb400w'}
                      />
                      <Paper
                        elevation={0}
                        sx={{
                          width: '100%',
                          position: 'absolute',
                          bottom: 0,
                          px: 0.75,
                          py: 0.5,
                          borderRadius: 0,
                          borderBottomLeftRadius: 5,
                          borderBottomRightRadius: 5,
                          bgcolor: (theme) => theme.palette.backgroundTertiary,
                        }}
                      >
                        <Typography component="div" variant="caption" fontWeight="fontWeightBold" sx={{ color: 'common.white' }}>
                          {asset?.name}
                        </Typography>
                      </Paper>
                    </Box>
                  </Box>
                  <Box>
                    <Stack direction="column" alignSelf="flex-start" alignItems="flex-start">
                      <IconButton onClick={deleteAsset} disabled={disabled}>
                        <DeleteIcon />
                      </IconButton>
                      {asset.type === 'video' && asset.status === 'processed' && (
                        <Typography
                          component="div"
                          color="textSecondary"
                          display="inline"
                          sx={{ pl: 1 }}
                          variant="body2"
                        >
                          <Stack direction="row">
                            <Box
                              sx={{
                                fontWeight: 300,
                              }}
                            >
                              Length
                              {': '}
                            </Box>
                            {formatDuration(asset.length)}
                          </Stack>
                        </Typography>
                      )}
                      {asset.type === 'video' && asset.status === 'processed' && (
                        <Button
                          size="small"
                          variant="outlined"
                          disabled={disabled || !TranscriptDialog}
                          onClick={handleTranscriptModalOpen}
                        >
                          View/Edit Transcript
                        </Button>
                      )}
                      {!disabled && (<Button size="small" variant="outlined" onClick={handleEditAsset} sx={{ marginTop: '5px' }}>Edit Asset</Button>)}
                    </Stack>
                  </Box>
                </Stack>
                <Stack>
                  {!disabled && asset.status !== 'processed' && (
                    <Button size="small" variant="outlined" onClick={reloadAsset} startIcon={<ReloadIcon />}>
                      Processing - Refresh Status
                    </Button>
                  )}
                </Stack>
              </>
            )
              : (
                <Box>
                  <Typography
                    component="div"
                    variant="caption"
                    fontWeight="fontWeightBold"
                    sx={{
                      px: 2,
                      py: 0.5,
                      borderRadius: 1,
                      bgcolor: (theme) => theme.palette.backgroundTertiary,
                      color: 'common.white',
                      display: 'inline-block',
                    }}
                  >
                    {asset?.name}
                  </Typography>
                </Box>
              ))}
          <Stack direction="row" spacing={3}>
            <Tooltip arrow placement="left" title="Select from assets.">
              <Button
                variant="outlined"
                sx={{ height: 'auto' }}
                onClick={handleOpenSelectDialog}
                disabled={disabled}
              >
                Select
              </Button>
            </Tooltip>
            {canUpload && (
              <UploadButton
                name={name}
                handleFileSelected={handleFileSelected}
                acceptType={MimeTypeMap[assetType]}
                repository={repository}
                setRepository={handleSetRepository}
                disabled={disabled}
              />
            )}
          </Stack>
        </Stack>
      )}
      <PreviewDialog
        open={pickerState === PickerState.PreviewModal}
        item={asset}
        handleClose={handlePreviewModalClose}
      />
      { TranscriptDialog && (
        <TranscriptDialog
          open={pickerState === PickerState.Transcript}
          item={asset}
          handleClose={handleTranscriptModalClose}
        />
      )}
      {pickerState === PickerState.Uploading && (
        <Box sx={{ width: '100%' }}>
          <Box sx={{ pt: 1, mr: 2.5 }}>
            <ProgressLinear value={uploadProgress} showTitle={false} />
          </Box>
          <Typography variant="h6" fontWeight="fontWeightSemiBold" sx={{ pt: 1 }}>
            Uploading:&nbsp;
            <Typography component="span" variant="h6" fontWeight="fontWeightMedium">
              {selectedFile?.name}
            </Typography>
          </Typography>
        </Box>
      )}
      <SelectAssetDialog
        open={pickerState === PickerState.Choosing}
        item={asset}
        assetType={assetType}
        handleChange={handleChange}
        handleClose={handleCloseSelectDialog}
        handleCancel={handleCancelSelectDialog}
      />
    </Box>
  );
};

AssetPickerDialog.defaultProps = {
  assetType: 'any',
  showSelectedImage: true,
  disabled: false,
  TranscriptDialog: undefined,
};

export default observer(AssetPickerDialog);
