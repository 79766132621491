import React, { FunctionComponent, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import {
  Box,
  Container, Dialog,
  Typography,
} from '@mui/material';

import {
  navStore,
  Schema,
  useStore,
} from '@mcn-platform/models';

import { ItemField } from 'components/item/ItemTypes';
import ItemEdit from 'components/item/ItemEdit';
import ItemNavigator from 'components/item/ItemNavigator';

export interface ItemPageProps {
  modelName: string;
  imageField?: ItemField;
  titleField?: ItemField;
  secondaryFields?: ItemField[];
  // specify custom edit function
  pageHead?: (item:any, store: any) => React.ReactElement | undefined;
  EditScreen?: FunctionComponent<React.PropsWithChildren<any>>;
  // list fields that should be editable - not used if EditScreen specified
  editFields?: string[];
  additionalActions?: any[];
  availableListStyles?: any[],
  newItemHandler?: (
    setIsNew:any,
    setItem:any,
    setOpen:any,
    filterName?: string,
  ) => void | undefined,
  handleNewLabel?: string,
  listBarActions?: any,
  actionsStyle?: 'menu' | 'ellipses',
  // hard coded filter that limits what the user sees
  filter?: any;
  filterName?: string;
  // use selectable filters
  filters?: string[],
  readOnly?: boolean,
}

const ItemPage = ({
  modelName,
  pageHead = undefined,
  EditScreen,
  imageField = undefined,
  titleField = undefined,
  secondaryFields = undefined,
  editFields = undefined,
  additionalActions = [],
  filter = {},
  filterName = undefined,
  availableListStyles = [],
  // can pass in the handler, undefined in which case default is used,
  // or null in which case adding items is disabled.
  newItemHandler = undefined,
  handleNewLabel = undefined,
  listBarActions = undefined,
  actionsStyle = 'menu',
  filters = ['searchText'],
  readOnly = false,
}:ItemPageProps) => {
  const store = useStore();
  const params = useParams();
  const [open, setOpen] = useState<boolean>(false);
  const [isNew, setIsNew] = useState<boolean>(false);
  const [item, setItem] = useState<any>({});

  const handleNew = () => {
    if (newItemHandler) {
      newItemHandler(setIsNew, setItem, setOpen, filterName);
    } else {
      const newItem:any = Schema.getEmptyInstance(
        modelName,
        store.currentOrganization,
      );

      setIsNew(true);
      setItem(newItem);
      setOpen(true);
    }
  };

  const handleEdit = (editItem:any) => {
    console.log('Editing item', editItem);
    setIsNew(false);
    setItem(editItem);
    setOpen(true);
  };

  const handleDelete = (deleteItem:any) => {
    console.log('Delete item', deleteItem);
    navStore.showConfirmDialog(
      'Confirm Delete',
      `Are you sure you want to delete ${deleteItem.name || modelName}?`,
      () => store.remove(modelName, deleteItem),
      'Delete',
      undefined,
      'Cancel',
    );
  };

  const handleSubmit = (newValues:any) => {
    if (isNew) {
      store.add(modelName, newValues);
    } else {
      item.updateValues(newValues);
      store.update(modelName, item);
    }
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const actions = [
    { name: 'edit', label: 'Edit', action: handleEdit },
    { name: 'delete', label: 'Delete', action: handleDelete },
    ...additionalActions,
  ];

  useEffect(() => {
    if (params.isNewPortal && params.isNewPortal === 'new') {
      handleNew();
    }
  // Revisit how we are handling new items and then take care of this eslint error
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {Schema.getCapped(Schema.getPluralLabel(modelName))}
        </title>
      </Helmet>
      <Box>
        <Container maxWidth={false} disableGutters>
          <Box>
            {pageHead ? pageHead(item, store) : (
              <Typography
                color="textSecondary"
                gutterBottom
                variant="body2"
                component="div"
              >
                {Schema.getModel(modelName).description}
              </Typography>
            )}
            <ItemNavigator
              modelName={modelName}
              defaultAction={actions[0].action}
              actions={actions}
              imageField={imageField}
              titleField={titleField}
              // params={params}
              secondaryFields={secondaryFields}
              filter={filter}
              handleNew={newItemHandler === null ? undefined : handleNew}
              handleNewLabel={handleNewLabel}
              listBarActions={listBarActions}
              showSelect={false}
              multiSelect={false}
              enableDeselect={false}
              actionsStyle={actionsStyle}
              availableListStyles={availableListStyles}
              filters={filters}
              readOnly={readOnly}
            />
          </Box>
        </Container>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          scroll="paper"
        >
          { EditScreen ? (
            <EditScreen item={item} setOpen={setOpen} />
          ) : (
            <ItemEdit
              modelName={modelName}
              item={item}
              formConfig={editFields}
              handleSubmit={handleSubmit}
            />
          )}
        </Dialog>
      </Box>
    </>
  );
};

ItemPage.defaultProps = {
  pageHead: undefined,
  EditScreen: undefined,
  imageField: undefined,
  titleField: undefined,
  secondaryFields: undefined,
  editFields: undefined,
  additionalActions: [],
  listBarActions: [],
  filter: {},
  filterName: undefined,
  availableListStyles: ['list', 'grid'],
  newItemHandler: undefined,
  handleNewLabel: undefined,
  actionsStyle: 'menu',
  filters: ['searchText'],
  readOnly: false,
};

export default observer(ItemPage);
