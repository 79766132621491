import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Box,
  Typography,
  Select,
  MenuItem,
  Grid,
  Skeleton,
} from '@mui/material';
import Tabs from 'components/Tabs';
import { ICachedList, useStore } from '@mcn-platform/models';
import { observer } from 'mobx-react-lite';
import CustomerTrackingData from './CustomerTrackingData';

const itemTypes = [
  { modelName: 'all', label: 'All' },
  { modelName: 'course', label: 'Courses' },
  { modelName: 'program', label: 'Programs' },
  { modelName: 'microcast', label: 'Microcasts' },
];

const DateFormatter = ({ value }: any) => (value ? new Date(value).toDateString() : '');

const CustomerTracking = ({ customer }:any) => {
  const store = useStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const trackingTab = searchParams.get('tracking');
  const [selectedItemType, setSelectedItemType] = useState('all');
  const [progressList, setProgressList] = useState<ICachedList | undefined>(undefined);
  const selectTrackingTab = (newTab:string) => {
    setSearchParams({
      tracking: newTab,
    });
  };

  const {
    createdAt,
    name,
    email,
  } = customer;

  const handleItemChange = (e: any) => {
    setSelectedItemType(e.target.value as string);
  };

  useEffect(() => {
    const loadItems = async () => {
      const newList = store.getCachedList('itemProgress', {
        customerID: { eq: customer.id },
        ...selectedItemType !== 'all' && { [`${selectedItemType}ID`]: { exists: true } },
        ...trackingTab === 'itemCompleted' && { progress: { eq: 100 } },
        ...trackingTab === 'itemStarted' && { startedDate: { exists: true } },
        ...trackingTab === 'certifications' && { certifications: { exists: true } },
      });
      newList.setRequestedCount(1000);
      // store.fetchCachedList(progressList);
      setProgressList(newList);
      console.log('we got', newList?.items?.length);
    };
    setProgressList(undefined);
    loadItems();
  }, [
    selectedItemType,
    store,
    customer?.id,
    trackingTab,
  ]);

  return (
    <Box>
      <Grid container sx={{ p: 1 }}>
        <Grid item xs={12} md={6}>
          <Typography variant="h4">
            { `Customer Analytics for: ${name === '' ? email : name}` }
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h4">
            { `Account activation date: ${DateFormatter(createdAt)}` }
          </Typography>
        </Grid>
      </Grid>
      <Grid container sx={{ p: 1 }}>
        <Grid item xs={12} md={6}>
          <Typography variant="h4">
            Item Analytics
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Select
            id="model-type"
            value={selectedItemType}
            onChange={handleItemChange}
            sx={{ minWidth: '150px', height: '30px' }}
          >
            {itemTypes.map((i) => (
              <MenuItem key={i.modelName} value={i.modelName}>{i.label}</MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      {progressList?.syncState === 'loaded' ? (
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          minHeight: '100%',
        }}
        >
          <Tabs
            tabs={[{
              key: 'itemStarted',
              label: 'Items Started',
              content: <CustomerTrackingData progressList={progressList} customer={customer} />,
            },
            {
              key: 'itemCompleted',
              label: 'Items Completed',
              content: <CustomerTrackingData progressList={progressList} customer={customer} />,
            },
            {
              key: 'itemPurchased',
              label: 'Item Purchased',
              content: <CustomerTrackingData progressList={progressList} customer={customer} />,
            },
            {
              key: 'certifications',
              label: 'Certifications',
              content: (
                <CustomerTrackingData
                  progressList={progressList}
                  isCertification
                  customer={customer}
                />
              ),
            },
            ]}
            selected={trackingTab || 'itemStarted'}
            setSelected={selectTrackingTab}
          />
        </Box>
      ) : (
        <Skeleton variant="rectangular" height={400} />
      )}
    </Box>
  );
};

export default observer(CustomerTracking);
