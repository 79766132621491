/* eslint-disable react/require-default-props */
import React from 'react';
import {
  Box, CircularProgress,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import GridList from './GridList';
import TableList from './TableList';
import { ItemField } from './ItemTypes';

interface ItemListProps {
  modelName: string;
  model: any;
  // modelName: string;
  listStyle: 'list' | 'grid';
  // fields: string[];
  defaultAction: ((item:any, navigate: any) => void) | undefined;
  actions: any[];
  imageField: ItemField;
  titleField: ItemField;
  secondaryFields: ItemField[];
  updateSelectedItems?: (items:string[]) => void;
  showSelect: boolean;
  enableDeselect: boolean;
  actionsStyle?: string;
  block?: boolean;
}

const LoadModal = ({ loading, children }:{ loading: boolean, children: any }) => (
  <Box
    sx={{
      position: 'relative',
    }}
  >
    {loading && (
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          background: '#00000022',
        }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress
          color="inherit"
        />
      </Box>
    )}
    {children}
  </Box>
);

export const ItemList = ({
  modelName,
  model,
  listStyle,
  // fields,
  defaultAction,
  actions,
  imageField,
  titleField,
  secondaryFields,
  updateSelectedItems = () => {},
  showSelect,
  enableDeselect,
  actionsStyle,
  block = false,
}:ItemListProps) => {
  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      model.selectAll();
    } else {
      model.deselectAll();
    }
    updateSelectedItems(model.selectedKeys);
  };

  const handleSelectOne = (item:any) => {
    model.toggleItem(item);
    updateSelectedItems(model.selectedKeys);
    if (defaultAction) {
      defaultAction(item, null);
    }
  };

  if (!model.items || model.items.length === 0) {
    return (
      <Box>No items</Box>
    );
  }

  return (
    <LoadModal loading={block}>
      { listStyle === 'list' && (
        <TableList
          modelName={modelName}
          model={model}
          handleSelectAll={handleSelectAll}
          handleSelectOne={handleSelectOne}
          actions={actions}
          imageField={imageField}
          titleField={titleField}
          secondaryFields={secondaryFields}
          showSelect={showSelect}
          enableDeselect={enableDeselect}
          actionsStyle={actionsStyle}
        />
      )}
      {listStyle === 'grid' && (
        <GridList
          model={model}
          defaultAction={showSelect ? handleSelectOne : defaultAction}
          actions={actions}
          imageField={imageField}
          titleField={titleField}
          secondaryFields={secondaryFields}
          showSelect={showSelect}
        />
      )}
    </LoadModal>
  );
};
ItemList.defaultProps = {
  updateSelectedItems: (items:string[]) => {
    console.log('updated list', items);
  },
  block: false,
};
export default observer(ItemList);
