import React from 'react';
import {
  FormControlLabel,
  MenuItem, Select,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Controller } from 'react-hook-form';
import { StandardFieldProps } from './FieldTypes';
import HelperText from './HelperText';

const StandardSelectField = ({
  control,
  fieldName,
  field,
  readOnly,
}:StandardFieldProps) => {
  if (!field.enum
    || field.enum.length === 0
    || !field.enumLabels
    || field.enum.length !== field.enumLabels.length) {
    console.error('field definition enum or enumLabels missing.');
    return null;
  }
  return (
    <Controller
      name={fieldName}
      control={control}
      render={({
        field: {
          onChange,
          // onBlur,
          value,
          // name,
          // ref,
        },
        fieldState: {
          isTouched,
          error,
        },
      }) => (
        <>
          <FormControlLabel
            label={field.label}
            disabled={field.readOnly || readOnly}
            labelPlacement="top"
            sx={{ alignItems: 'flex-start' }}
            control={(
              // <FormLabel id={`label-${fieldName}`}>{field.label}</FormLabel>
              <Select
                id={fieldName}
                onChange={onChange}
                value={value || field.default || ''}
                name={fieldName}
                fullWidth
              >
                {field.enum?.map((option:string, index:number) => (
                  <MenuItem
                    key={option}
                    value={option}
                  >
                    { /* @ts-expect-error can't be undefined because checking for that above */ }
                    {field.enumLabels[index]}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          <HelperText
            name={fieldName}
            error={isTouched && error?.message}
            description={field.description}
          />
        </>
      )}
    />
  );
};

export default observer(StandardSelectField);
