import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { v4 } from 'uuid';
import { useNavigate, useParams } from 'react-router-dom';
import usePrompt from 'hooks/usePrompt';

import { useStore } from '@mcn-platform/models';

import ItemEdit from 'components/item/ItemEdit';
import EditLayout from './EditLayout';

const OrganizationEditor = () => {
  const store = useStore();
  const [organization, setOrganization] = useState<any>();
  const { organizationID } = useParams();
  const navigate = useNavigate();

  usePrompt({ isDirty: organization ? (organization.changeCount > 0) : false });

  useEffect(() => {
    if (!organizationID) {
      return;
    }
    // check if we're creating a new organization
    if (organizationID === 'new') {
      const newOrganization = store.create(
        'organization',
        v4(),
        'New Organization',
      );
      setOrganization(newOrganization);
    } else {
      const stub = store.getOrLoad('organization', organizationID);
      console.log('syncstub: ', stub.syncState, { ...stub });
      setOrganization(stub);
    }
  }, [store, organizationID, organization?.syncState]);

  /**
   * Takes the full organization and saves it to the organization stub.
   */
  const saveOrganization = () => {
    if (!organization) return;

    if (organizationID === 'new') {
      store.addStub('organization', organization)
        .then(() => {
          console.log('saved organization');
          return store.add(
            'userOrganization',
            {
              id: v4(),
              organization,
              name: store.currentUser.email,
              user: store.currentUser,
              superUser: true,
            },
          );
        })
        .then(() => {
          console.log('saved userOrganization');
          const id = v4();
          return store.add(
            'repository',
            {
              id,
              organization,
              name: 'Default Repository',
              description: 'Default repository used for all uploaded content unless another repository is created and selected.',
              bucket: `mcn-repo-${store.amplifyEnvironment}-${organization.id.slice(0, 8)}-${id.slice(0, 8)}`,
              status: 'new',
            },
          );
          // TODO: provision the bucket via lambda.
        })
        .then(() => {
          console.log('saved repository created');
          navigate(`/app/organization/${organization.id}`);
        });
    } else {
      store.update('organization', organization);
    }
  };

  if (!organization) {
    return <div>Loading.</div>;
  }
  if (organization.syncState === 'error') {
    return <div>Error loading organization</div>;
  }

  if (organization.syncState === 'saving') {
    return <div>Saving..</div>;
  }

  if (organizationID !== 'new' && organization.syncState !== 'loaded') {
    return <div>Loading..</div>;
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    }}
    >
      <EditLayout
        title={organizationID === 'new' ? 'New Organization' : 'Edit Organization'}
        buttonStack={(
          <Button
            color="primary"
            variant="contained"
            onClick={saveOrganization}
            disabled={organization.changeCount === 0}
          >
            Save
          </Button>
        )}
      >
        <ItemEdit
          item={organization}
          modelName="organization"
          handleSubmit={(newValues) => {
            console.log('Update organization', newValues);
            organization.updateValues(newValues);
          }}
          hideTitle
          autoSave
        />
      </EditLayout>
    </Box>
  );
};

export default observer(OrganizationEditor);
