import React from 'react';

import {
  Button,
  Stack,
  Box,
} from '@mui/material';
import OpenAiIcon from '../../icons/OpenAiIcon';

const GenerateAiFormLabel = ({
  label,
  onClick,
}:any) => (
  <Stack direction="row" alignContent="flex-end" justifyContent="space-between">
    <Box sx={{ display: 'flex', alignItems: 'end' }}>
      {label}
    </Box>
    <Button
      onClick={onClick}
      color="primary"
      variant="outlined"
      size="small"
      endIcon={<OpenAiIcon />}
      sx={{
        paddingY: '2px',
      }}
    >
      Generate AI
    </Button>
  </Stack>
);

export default GenerateAiFormLabel;
