import { splitSeparator } from 'constants/general';
import React, { useState, useCallback, useEffect } from 'react';
import { v4 } from 'uuid';
import { List, Button, Box } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';

import { arrayMove } from '@mcn-platform/models';
import BulletItemInput from './BulletItemInput';

type BulletItemsProps = {
  value: string
  setValue: (value: any) => void
  placeholder: string
  disabled: boolean
};
type ItemType = {
  id: string
  text: string
};

const BulletPoints = ({
  value,
  setValue,
  placeholder,
  disabled,
}: BulletItemsProps) => {
  // creating array of objects with unique id's so drag&drop will work properly
  const initialValue = value?.split(splitSeparator).map((text: string) => ({ id: v4(), text }));
  const [items, setItems] = useState(initialValue as Array<ItemType>);

  // '-2' for neutral state of item
  // '-1' is when adding new item
  const [selectedID, setSelectedID] = useState('-2' as string);

  const getTextFromItems = (itemsList: ItemType[]) => (
    [...itemsList].reduce((done, curr) => (done ? `${done}${splitSeparator}${curr.text}` : curr.text), '')
  );

  const handleAdd = (text: string) => {
    const newItems = [...items, { id: v4(), text }];
    setItems(newItems);
    setValue(getTextFromItems(newItems));

    // neither editing nor adding item
    setSelectedID('-2');
  };

  const handleEdit = (text: string) => {
    const updatedItems = items.map(
      (item: ItemType) => ((item.id === selectedID) ? ({ id: item.id, text }) : item),
    );
    setItems(updatedItems);
    setValue(getTextFromItems(updatedItems));

    // neither editing nor adding item
    setSelectedID('-2');
  };
  const handleDelete = (id: string) => {
    const updatedItems = items.filter((item: ItemType) => item.id !== id);
    setItems(updatedItems);
    setValue(getTextFromItems(updatedItems));
  };
  const handleCancel = () => setSelectedID('-2');

  const moveItem = useCallback((dragIndex: number, hoverIndex: number) => {
    const updatedItems = arrayMove([...items], dragIndex, hoverIndex);
    setItems(updatedItems);
    setValue(getTextFromItems(updatedItems));
  }, [items, setValue]);

  useEffect(() => {
    // If the bullet list is empty, then set the first item as selected
    // So that the field is already open and user doesnt have to click on Pen(edit) icon
    // Refer MCN-167
    if (items.length === 1 && items[0].text === '') {
      setSelectedID(items[0].id);
    }
  }, [items]);

  const renderBulletPoint = (item: ItemType, index: number, bulletDisabled: boolean) => (
    <BulletItemInput
      key={item.id}
      id={item.id}
      text={item.text}
      index={index}
      placeholder={placeholder}
      disabled={bulletDisabled}
      selectedID={selectedID}
      setSelectedID={setSelectedID}
      onAdd={handleAdd}
      onEdit={handleEdit}
      onDelete={handleDelete}
      onCancel={handleCancel}
      moveItem={moveItem}
    />
  );

  return (
    <Box
      sx={[{
        width: '100%',
        border: '1px solid',
        borderColor: '#e0e0eb',
        borderRadius: 1,
        padding: 2,
      },
      {
        '&:hover': {
          borderColor: '#111111',
        },
      },
      {
        '&:focus': {
          border: '2px solid #1276dd',
        },
      },
      ]}
    >
      <List sx={{ width: '100%' }}>
        {items.map((item: ItemType, index: number) => (
          renderBulletPoint(item, index, disabled)
        ))}
        {/* when adding new point (input field) */}
        {(selectedID === '-1') && renderBulletPoint({ id: selectedID, text: '' }, -1, false)}
      </List>
      {(selectedID === '-2' || (selectedID !== '-2' && selectedID !== '-1')) && (
        <Box sx={{ textAlign: 'center' }}>
          <Button
            variant="text"
            disabled={disabled || (selectedID !== '-2' && selectedID !== '-1')}
            onClick={() => setSelectedID('-1')}
            startIcon={<AddBoxIcon />}
          >
            Add
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default BulletPoints;
