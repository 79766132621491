import React from 'react';
import {
  FormControlLabel,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { MuiTelInput } from 'mui-tel-input';

import { StandardFieldProps } from './FieldTypes';
import HelperText from './HelperText';

// const MuiTelInput = loadable(() => import('mui-tel-input'));

const StandardPhoneNumberField = ({
  control,
  fieldName,
  field,
  readOnly,
}:StandardFieldProps) => (
  <Controller
    name={fieldName}
    control={control}
    render={({
      field: {
        onChange,
        onBlur,
        value,
      },
      fieldState: {
        isTouched,
        error,
      },
    // eslint-disable-next-line arrow-body-style
    }) => (
      <>
        <FormControlLabel
          label={field.label}
          disabled={field.readOnly}
          labelPlacement="top"
          control={(
            <MuiTelInput
              fullWidth
              id={fieldName}
              name={fieldName}
              placeholder={field.placeholder}
              value={value}
              disabled={readOnly}
              // onChange={onChange}
              onChange={(newValue:any) => {
                // console.log(`setting ${fieldName} to new value`, newValue);
                onChange(newValue);
              }}
              onBlur={onBlur}
              // disabled={disabled}
              error={isTouched && Boolean(error)}
              inputProps={{
                'aria-describedby': `helper-text-${fieldName}`,
              }}
              defaultCountry="US"
              disableFormatting
            />
          )}
        />
        <HelperText
          name={fieldName}
          error={isTouched && error?.message}
          description={field.description}
        />
      </>
    )}
  />
);

StandardPhoneNumberField.defaultProps = {
  placeholder: '',
  description: '',
  disabled: false,
};

export default StandardPhoneNumberField;
