import React from 'react';
import {
  Button,
  Popper,
  getContrastRatio,
} from '@mui/material';
import { replaceStylesheetGlobals, useStore } from '@mcn-platform/models';
import { ColorPickerControl } from '@mcn-platform/portal-lib';
import { ControlProps } from './ControlProps';

export type ColorControlProps = ControlProps & {
  showGlobalColors?: boolean;
  rounded?: 'none' | 'all' | 'left' | 'right',
};

const labelMap = {
  color_background: 'Background',
  color_font: 'Main Font',
  color_font_inverse: 'Inverse Font',
  color_font_secondary: 'Secondary Font',
  color_primary: 'Primary',
  color_primary_hover: 'Primary (Hover)',
  color_primary_disabled: 'Primary (Disabled)',
  color_secondary: 'Secondary',
  color_secondary_hover: 'Secondary (Hover)',
  color_secondary_disabled: 'Secondary (Disabled)',
  color_tertiary: 'Tertiary',
  color_tertiary_hover: 'Tertiary (Hover)',
  color_tertiary_disabled: 'Tertiary (Disabled)',
};

const ColorControl = ({
  label,
  value,
  onChange,
  disabled,
  showGlobalColors = true,
  rounded = 'all',
}: ColorControlProps) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const store = useStore();
  const { stylesheet } = store.currentPortal;
  const open = Boolean(anchorEl);
  const id = open ? `popper-${label}` : undefined;

  const onClickMethod = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const onCloseMethod = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const resolvedColor = replaceStylesheetGlobals(stylesheet, value);
  return (
    <>
      <Button
        onClick={onClickMethod}
        size="small"
        variant="contained"
        sx={{
          height: 1,
          width: 1,
          borderTopLeftRadius: ['all', 'left'].includes(rounded) ? 8 : 0,
          borderTopRightRadius: ['all', 'right'].includes(rounded) ? 8 : 0,
          borderBottomLeftRadius: ['all', 'left'].includes(rounded) ? 8 : 0,
          borderBottomRightRadius: ['all', 'right'].includes(rounded) ? 8 : 0,
          '&:hover': {
            background: resolvedColor,
            color: getContrastRatio(resolvedColor || '#000', '#fff') > 4.5 ? '#ccc' : '#444',
          },
          backgroundColor: resolvedColor,
          color: getContrastRatio(resolvedColor || '#000', '#fff') > 4.5 ? '#fff' : '#111',
        }}
        disabled={disabled}
      >
        {labelMap[value] || (value && value.toString()) || 'Select Color'}
      </Button>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="top-start"
        style={{
          zIndex: 10,
          boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 0px 1px, rgba(0, 0, 0, 0.15) 0px 8px 16px',
          borderRadius: '8px',
        }}
      >
        <ColorPickerControl
          // label={label}
          value={value}
          onChange={onChange}
          onCloseMethod={onCloseMethod}
          showGlobalColors={showGlobalColors}
        />
      </Popper>
    </>
  );
};
ColorControl.defaultProps = {
  showGlobalColors: true,
  rounded: 'all',
};

export default ColorControl;
