/* eslint-disable no-unneeded-ternary */
import React from 'react';
import {
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

interface PaginationProps {
  currentPage: number;
  hasNext: boolean;
  hasPrevious: boolean;
  handleNext: () => void;
  handlePrevious: () => void;
}

const Pagination = ({
  currentPage,
  hasNext,
  hasPrevious,
  handleNext = () => {},
  handlePrevious = () => {},
}:PaginationProps) => (
  <Stack direction="row" spacing={0} alignItems="center">
    <IconButton disabled={!hasPrevious} onClick={handlePrevious}>
      <ArrowBackIosIcon />
    </IconButton>
    <Typography variant="h5">
      Page
      {' '}
      {currentPage}
    </Typography>
    <IconButton disabled={!hasNext} onClick={handleNext}>
      <ArrowForwardIosIcon />
    </IconButton>
  </Stack>
);

export default Pagination;
