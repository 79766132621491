import { observer } from 'mobx-react-lite';

const ItemNameFormatter = observer(({ item }: any) => {
  if (item.course) {
    return item.course.name;
  }
  if (item.microcast) {
    return item.microcast.name;
  }
  if (item.program) {
    return item.program.name;
  }
  return '-';
});

export default ItemNameFormatter;
