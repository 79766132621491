import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from '@mui/material';
import ItemList from 'components/item/ItemList';
import { ICachedList, navStore, useStore } from '@mcn-platform/models';
import ReferenceSelector from 'components/forms/ReferenceSelector';
import { v4 } from 'uuid';
import { useNavigate } from 'react-router';
import SelectionModel from 'components/item/SelectionModel';

const NewMemberDialog = ({
  open,
  handleNewMembership,
  handleCancelDialog,
  customerID,
  customerName,
  customerEmail,
}:any) => {
  const [membership, setMembership] = useState(null);
  return (
    <Dialog open={open}>
      <DialogContent>
        <DialogTitle>
          Add Membership for
          {' '}
          {customerName || customerEmail || customerID}
        </DialogTitle>
        {/* {portal?.name} */}
        {/* @TODO - filter by portal */}
        <ReferenceSelector
          label="Membership"
          modelName="membership"
          modelScope="portal"
          id={`membership-${customerID}`}
          onChange={(newMembership:any) => {
            console.log('membership selected', membership);
            setMembership(newMembership);
          }}
          value={membership}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelDialog}>Cancel</Button>
        <Button
          disabled={!membership}
          onClick={() => { handleNewMembership(membership); }}
        >
          Add Membership
        </Button>
      </DialogActions>
    </Dialog>
  );
};

/**
 * This is a special case field because it doesn't actually effect any data
 * on the form (ie the the Customer).  Instead it provides a reference
 * to the many to many connection to the Membership table via the Member table.
 * @param param0
 * @returns
 */

const MembersListField = ({
  organizationID,
  customerID,
  customerName,
  customerEmail,
}:any) => {
  const [membershipList, setMembershipList] = useState<ICachedList | undefined>(undefined);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const key = `${organizationID}~${customerID}`;
  const store = useStore();
  const { currentPortal } = store;

  const loadList = useCallback(() => {
    const list = store.getCachedList('member', {
      organizationID: { eq: organizationID },
      or: [
        { customerID: { eq: customerID } },
        { customerID: { eq: key } },
      ],
      // status: { eq: 'active' },
    });
    setMembershipList(list);
    list.setRequestedCount(100);
  }, [organizationID, customerID, key, store]);

  // const membershipList = store.getCachedList('member', {
  //   organizationID: { eq: organizationID },
  //   customerID: { eq: customerID },
  //   // status: { eq: 'active' },
  // });
  useEffect(() => {
    console.log('refresh');
    loadList();
  }, [
    organizationID, customerID, store, loadList,
  ]);

  const handleNewMembership = async (membershipID) => {
    setDialogOpen(false);
    navStore.showMessageDialog(
      'Creating Membership',
      'Membership is being created for the customer. Please wait.',
    );
    try {
      if (!membershipID) {
        console.log('No membershipID, skipping membersip create');
      }
      const membership = await store.getOrLoad('membership', membershipID);
      if (membership) {
        console.log('Create new membership', membership);
        const newMember = await store.stub(
          'member',
          v4(),
          customerEmail,
          {
            customerID,
            membershipID: membership.id,
            portalID: membership.portal.id,
            status: 'ACTIVE',
          },
        );
        console.log('New member', newMember);
        await store.addStub('member', newMember);
        await store.refreshCustomerStats(customerID);
        loadList();
        navStore.hideMessageDialog();
      }
    } catch (e) {
      navStore.showMessageDialog('Error', 'Error creating the membership.');
    }
  };

  console.log('membershipList', membershipList);

  const viewMember = (member:any) => {
    navigate(`/app/member-editor/${member.id}`);
  };

  const model = new SelectionModel(
    false,
    membershipList?.items || [],
    [],
    [],
    [],
  );

  // const items = get(formik.values, propName);
  return (
    <FormControlLabel
      label="Memberships"
      labelPlacement="top"
      onClick={(e) => {
        e.preventDefault();
      }}
      control={(
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }} />
          <ItemList
            modelName="membership"
            model={model}
            // initialselectedIDs={initialselectedIDs}
            listStyle="list"
            // defaultAction={defaultAction}
            // actions={actions}
            // imageField={imageField}
            // titleField={titleField}
            defaultAction={viewMember}
            actions={[
              {
                name: 'view',
                label: 'View',
                action: viewMember,
              },
            ]}
            imageField={{ name: 'image', label: 'Poster' }}
            titleField={{ name: 'membership.name', label: 'Membership' }}
            secondaryFields={[
              // { name: 'rating', label: 'Rating' },
              { name: 'portal.name', label: 'Portal' },
              { name: 'name', label: 'Email' },
              { name: 'status', label: 'Status' },
            ]}
            showSelect={false}
            enableDeselect={false}
          />

          <Button onClick={() => setDialogOpen(true)}>Add Membership</Button>
          <NewMemberDialog
            open={dialogOpen}
            customerID={customerID}
            customerName={customerName}
            customerEmail={customerEmail}
            handleCancelDialog={() => setDialogOpen(false)}
            handleNewMembership={(membership) => handleNewMembership(membership)}
          />
        </Box>
    )}
    />
  );
};

export default MembersListField;
