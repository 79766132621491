import React from 'react';
import '@aws-amplify/ui-react/styles.css';
import 'react-multi-carousel/lib/styles.css';
import 'react-h5-audio-player/lib/styles.css';
import { Box } from '@mui/material';

/**
 * Should be the one place css and theme based styling is applied so it can be used
 * consistently in SSR and in designer
 *
 * There are two copies of this - one in Admin App and one in Portal App because
 * it wasn't clear how to export tge css from Portal Lib repository.
 *
 * @param param0
 * @returns
 */
const PortalCss = ({ children }:{ children: React.ReactNode }) => (
  <Box sx={{
    height: '100%',
    '--amplify-components-authenticator-state-inactive-background-color': '#bdbdbd',
  }}
  >
    {children}
  </Box>
);

export default PortalCss;
