import * as React from 'react';
import { SVGProps } from 'react';

export default (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 18V9h6v9H5Zm4-7v5H7v-5h2ZM13 18V9h6v9h-6Zm4-7v5h-2v-5h2Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 18H5a1 1 0 1 0 0 2h14a1 1 0 1 0 0-2ZM5 16a3 3 0 1 0 0 6h14a3 3 0 1 0 0-6H5ZM20 8v-.768a1 1 0 0 0-.713-.958l-7-2.1a1 1 0 0 0-.574 0l-7 2.1A1 1 0 0 0 4 7.232V8a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1ZM4.138 4.36A3 3 0 0 0 2 7.232V8a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3v-.768a3 3 0 0 0-2.138-2.873l-7-2.1a3 3 0 0 0-1.724 0l-7 2.1Z"
      fill="currentColor"
    />
  </svg>
);
