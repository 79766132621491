import React from 'react';
import {
  FormControl, FormLabel, FormControlLabel, Checkbox,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Controller } from 'react-hook-form';
import { StandardFieldProps } from './FieldTypes';

const StandardCheckboxField = ({
  control,
  fieldName,
  field,
  readOnly,
}:StandardFieldProps) => (
  <Controller
    name={fieldName}
    control={control}
    render={({
      field: {
        onChange,
        onBlur,
        value,
      },
      fieldState: {
        // invalid,
        isTouched,
        // isDirty,
        error,
      },
    }) => (
      <FormControl disabled={field.readOnly}>
        {(field.showLabel || field.showLabel === undefined) && (
          <FormLabel id={`label-${fieldName}`}>{field.label}</FormLabel>
        )}
        <FormControlLabel
          label={field.description || field.placeholder || ''}
          onBlur={onBlur}
          labelPlacement="end"
          aria-labelledby={`label-${fieldName}`}
          control={(
            <>
              <Checkbox
                id={fieldName}
                name={fieldName}
                checked={value}
                onChange={(event) => {
                  onChange(event.target.checked);
                }}
                onBlur={onBlur}
                disabled={field.readOnly || readOnly}
              />
              {isTouched && !!error && (
                <span>{error.message}</span>
              )}
            </>
          )}
        />
      </FormControl>
    )}
  />
);
StandardCheckboxField.defaultProps = {
  placeholder: '',
  description: '',
  disabled: false,
};

export default observer(StandardCheckboxField);
