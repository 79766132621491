import * as React from 'react';
import { styled, Theme } from '@mui/material/styles';
import { Box, Select, MenuItem } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DoneIcon from '@mui/icons-material/Done';
import { observer } from 'mobx-react-lite';

const StyledSelect = styled(Select)(({ theme }: { theme: Theme }) => ({
  width: 170,
  height: 34,
  border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: '22px',
  fontSize: theme.typography.caption.fontSize,
  lineHeight: 2,
  fontWeight: theme.typography.fontWeightBold,
  fontStretch: 'normal',
  fontStyle: 'normal',
  letterSpacing: 'normal',
  '&:hover': {},
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&:focus-visible': {
    border: 'none',
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.text.secondary,
  },
  '& .MuiSelect-select': {
    paddingLeft: '44px',
    '& .MuiPaper-root': {
      background: theme.palette.primary.main,
    },
  },
}));

const CustomSelect = ({
  option, options, setOption,
  startIcon, iconStyle, customStyle,
  id = 'select-item',
  idField = 'id', labelField = 'name',
}: any) => {
  const selectedOption = options.find((el) => (el === option));

  return (
    <Box sx={{ position: 'relative', width: customStyle.width }}>
      <Box
        sx={{
          position: 'absolute',
          top: '5px',
          left: '15px',
        }}
      >
        {startIcon || (
          <DoneIcon
            fontSize="small"
            sx={{ color: 'text.secondary', ...iconStyle }}
          />
        )}
      </Box>
      <StyledSelect
        labelId={`${id}-label`}
        id={id}
        IconComponent={KeyboardArrowDownIcon}
        value={selectedOption || ''}
        MenuProps={{
          sx: {
            '& .MuiPaper-root': {
              background: (theme) => theme.palette.background.paper,
              '&>ul': {
                '&>li': {
                  color: (theme) => theme.palette.text.primary,
                },
              },
            },
          },
        }}
        // @ts-expect-error - revisit
        onChange={(e: SelectChangeEvent) => (
          setOption(options.find(((o:any) => o === e.target.value)))
        )}
        sx={{ ...customStyle }}
      >
        {options.map((o: any) => <MenuItem key={o[idField]} value={o}>{o[labelField]}</MenuItem>)}
      </StyledSelect>
    </Box>
  );
};

CustomSelect.defaultProps = {
  startIcon: null,
  iconStyle: {},
  customStyle: {},
  idField: 'id',
  labelField: 'name',
};

export default observer(CustomSelect);
