import React from 'react';
import { Button, Tooltip, Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const CopyLinkButton = ({
  label,
  url,
  copiedMessage = CopyLinkButton.defaultProps.copiedMessage,
}:{
  label: string,
  url: string,
  copiedMessage?: string
}) => {
  const [open, setOpen] = React.useState(false);
  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    navigator.clipboard.writeText(url);
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 3000);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Box>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={copiedMessage}
        >
          <Button
            variant="outlined"
            onClick={handleTooltipOpen}
          >
            {label}
          </Button>
        </Tooltip>
      </Box>
    </ClickAwayListener>
  );
};

CopyLinkButton.defaultProps = {
  copiedMessage: 'Copied to clipboard',
};

export default observer(CopyLinkButton);
