import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { observer } from 'mobx-react-lite';

import CircleIcon from 'icons/CircleIcon';
import { CheckCirclePrimary } from 'icons/CheckCircleIcon';
import { Controller } from 'react-hook-form';
import { StandardFieldProps } from './FieldTypes';

const StandardRadioButtons = (
  {
    control,
    fieldName,
    field,
    readOnly,
  }: StandardFieldProps,
) => (
  <Controller
    name={fieldName}
    control={control}
    render={({
      field: {
        onChange,
        onBlur,
        value,
        // name,
        // ref,
      },
      // fieldState: {
      //   // invalid,
      //   isTouched,
      //   // isDirty,
      //   error,
      // },
    }) => (
      <FormControl
        id={`label-${fieldName}`}
        disabled={field.readOnly}
      >
        <FormLabel id={`label-${fieldName}`}>{field.label}</FormLabel>
        <RadioGroup
          aria-labelledby={`label-${fieldName}`}
          name={fieldName}
          value={value}
          onChange={(ev) => {
            const newValue = ev.target.value;
            if (field.type === 'boolean') {
              onChange(newValue === 'true');
            } else {
              onChange(newValue);
            }
          }}
          onBlur={onBlur}
        >
          {/* @ts-expect-error put guard in */}
          {field.enumLabels.map((enumLabel: string, index: number) => (
            <FormControlLabel
              key={enumLabel}
              label={enumLabel}
              // @ts-expect-error put guard in
              value={field.enum[index]}
              disabled={readOnly}
              control={(
                <Radio
                  icon={<CircleIcon />}
                  checkedIcon={<CheckCirclePrimary />}
                />
              )}
            />
          ))}
        </RadioGroup>
      </FormControl>
    )}
  />
);

StandardRadioButtons.defaultProps = {
  disabled: false,
};

export default observer(StandardRadioButtons);
