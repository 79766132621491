import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { ICachedList, Schema, useStore } from '@mcn-platform/models';

interface ReferenceSelectorProps {
  id: string;
  name?: string;
  label?: string;
  modelName: string
  modelScope?: 'organization' | 'portal' | 'portalLibraries'
  value?: any;
  onChange: (newId:string, newReference:any) => void;
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean;
  onBlur?: () => void;
  fullWidth?: boolean;
  size?: 'small' | 'medium';
  error?: boolean;
}

const getPortalLibraryFilter = (store) => {
  const { currentPortal } = store;
  const libraryFilter = {
    or: currentPortal.getLibraryIDs().map((id:string) => ({ libraries: { matchPhrase: id } })),
  };
  return libraryFilter;
};

const getPortalFilter = (store) => {
  const { currentPortal } = store;
  const portalFilter:{
    and: any[]
  } = {
    and: [],
  };
  if (currentPortal) {
    portalFilter.and.push({
      or: [
        {
          portalID: {
           eq: currentPortal.id,
          },
        },
        {
          portalID: {
           eq: currentPortal.key,
          },
        },
      ],
    });
  }
  return portalFilter;
};

const getFilter = (modelScope: any, store: any) => {
  switch (modelScope) {
    case 'portal':
      return { filter: getPortalFilter(store), crossOrganization: true };
    case 'portalLibraries':
      return { filter: getPortalLibraryFilter(store), crossOrganization: true };
    default:
      return { filter: undefined, crossOrganization: false };
  }
};

const ReferenceSelector = ({
  id,
  value, // this should be the MST OBJECT - not the ID
  modelName,
  modelScope,
  label,
  onChange,
  disabled,
  onBlur,
  fullWidth,
  size,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  name,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  error,
}:ReferenceSelectorProps) => {
  const store = useStore();
  const [items, setItems] = useState<any[] | undefined>();
  const [cachedList, setCachedList] = useState<ICachedList | undefined>();
  useEffect(() => {
    const { filter, crossOrganization } = getFilter(modelScope, store);
    const newCachedList = store.getCachedList(
      modelName,
      filter,
      Schema.getSortValues(modelName),
      crossOrganization,
    );
    newCachedList.setRequestedCount(1000);
    setCachedList(newCachedList);
  }, [store, modelName, modelScope]);

  useEffect(() => {
    const loadedItems = [{ key: null, name: 'none' }, ...(cachedList?.items ? cachedList.items : [])];
    console.log('Set items', loadedItems);
    setItems(loadedItems);
  }, [cachedList, cachedList?.totalCount, cachedList?.syncState]);

  // converts string key to the MST object if needed
  const getValue = (val:any) => {
    if (!val) {
      return null;
    }
    if (typeof val === 'string') {
      return store.getOrLoad(modelName, val);
    }
    return store.getOrLoad(modelName, val.key);
  };

  if (!items) {
    return (<span>Loading...</span>);
  }

  return (
    <Autocomplete
      fullWidth={fullWidth}
      id={id}
      // name={fieldName}
      value={getValue(value)} // this should be the MST OBJECT - not the ID
      onChange={(e, newValue) => {
        onChange(newValue.key, newValue);
      }}
      onBlur={onBlur}
      disabled={disabled}
      renderInput={(params) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <TextField {...params} label={label || Schema.getLabel(modelName)} name={id} size={size} sx={{ minWidth: '200px' }} />
      )}
      options={items}
      isOptionEqualToValue={(option, val) => (val ? val.key === option.key : items[0])}
      getOptionLabel={(option) => (option ? option.name : 'none')}
      renderOption={(props, option) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <li {...props} key={option.key}>
          {option.name}
        </li>
      )}
    />
  );
};

ReferenceSelector.defaultProps = {
  value: null,
  onBlur: () => {},
  fullWidth: true,
  size: 'medium',
  disabled: false,
  modelScope: 'organization',
  label: undefined, // will default to label from schema
  name: undefined,
  error: false,
};

export default observer(ReferenceSelector);
