/* eslint-disable no-plusplus */
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useDrag } from 'react-dnd';
import {
  Box,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import ProgramIcon from 'icons/MapIcon';
import {
  Article as DocumentIcon,
  Delete as DeleteIcon,
  DragIndicator as DragIndicatorIcon,
  Groups as MeetingIcon,
  School as CourseIcon,
  SchoolOutlined as MicrocastIcon,
  TextSnippet as AssetIcon,
} from '@mui/icons-material';
import { useStore } from '@mcn-platform/models';

type DraggableCourseItemProps = {
  item: any;
  handleDelete: (item: any) => void;
  dndType: string;
};
const DraggableItemItem = ({
  item, handleDelete, dndType,
}:DraggableCourseItemProps) => {
  const [{ isDragging }, dragRef, previewRef] = useDrag({
    type: dndType,
    item,
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const store = useStore();
  const rowItem = store.getOrLoad(item.type, item[item.type]);

  return (
    <Box
      sx={{
        opacity: isDragging ? 0.5 : 1,
        backgroundColor: 'inherit',
      }}
      ref={previewRef}
    >
      <ListItem
        disableGutters
        sx={{ marginBottom: 0 }}
        secondaryAction={(
          <DeleteIcon onClick={() => handleDelete(item)} />
        )}
      >
        <ListItemIcon ref={dragRef} sx={{ cursor: 'grab' }}>
          <DragIndicatorIcon />
          { item?.type === 'asset' && <AssetIcon /> }
          { item?.type === 'course' && <CourseIcon /> }
          { item?.type === 'document' && <DocumentIcon /> }
          { item?.type === 'meeting' && <MeetingIcon /> }
          { item?.type === 'microcast' && <MicrocastIcon /> }
          { item?.type === 'program' && <ProgramIcon /> }
        </ListItemIcon>
        <ListItemText>
          {rowItem.name}
        </ListItemText>
      </ListItem>
    </Box>
  );
};

export default observer(DraggableItemItem);
