import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box } from '@mui/material';
import { ICachedList, useStore } from '@mcn-platform/models';
import { ItemField } from './ItemTypes';
import { ItemList } from './ItemList';
import SelectionModel from './SelectionModel';
import LibrarySelectionModel from './LibrarySelectionModel';

interface PaginatedItemListProps {
  modelName: string
  cachedList: ICachedList | undefined;
  page: number
  itemsPerPage: number
  selectedKeys?: string[]
  preSelectedKeys?: string[]
  listStyle: 'list' | 'grid'
  defaultAction: ((item:any, navigate: any) => void) | undefined
  actions?: any[]
  imageField?: ItemField | undefined
  titleField?: ItemField | undefined
  // params?: any,
  secondaryFields?: ItemField[]
  updateSelectedItems?: (items: string[]) => void
  augmentData?: (items: any[], preSelectedKeys?: string[], disabledKeys?:string[]) => Promise<
    { items: any[], preSelectedKeys: string[], disabledKeys:string[] }
  >
  multiSelect: boolean
  showSelect: boolean
  enableDeselect: boolean
  actionsStyle?: 'ellipses' | 'menu'
  useLibraryModel?: boolean
  libraryID?: string
}

const PaginatedItemListDefaults = {
  selectedKeys: [],
  preSelectedKeys: [],
  updateSelectedItems: (items: string[]) => {
    console.log('updated list', items);
  },
  augmentData: async (
    items: any[],
    preSelectedKeys: string[] = [],
    disabledKeys: string[] = [],
  ) => (Promise.resolve(
    { items, preSelectedKeys, disabledKeys },
  )),
  actions: [],
  imageField: { name: '', label: 'Preview', showMore: false },
  titleField: { name: 'name', label: 'Name', showMore: false },
  secondaryFields: [
    { name: 'description', label: 'Description', showMore: true },
  ],
  actionsStyle: 'menu',
  useLibraryModel: false,
  libraryID: undefined,
};

const PaginatedItemList = ({
  modelName,
  cachedList,
  itemsPerPage,
  page,
  selectedKeys = [],
  preSelectedKeys = [],
  listStyle,
  defaultAction,
  actions = PaginatedItemListDefaults.actions,
  imageField = PaginatedItemListDefaults.imageField,
  titleField = PaginatedItemListDefaults.titleField,
  secondaryFields = PaginatedItemListDefaults.secondaryFields,
  updateSelectedItems = PaginatedItemListDefaults.updateSelectedItems,
  augmentData = PaginatedItemListDefaults.augmentData,
  multiSelect,
  showSelect,
  enableDeselect,
  actionsStyle,
  useLibraryModel,
  libraryID,
}: PaginatedItemListProps) => {
  const [model, setModel] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const store = useStore();
  const library = libraryID ? store.getOrLoad('library', libraryID) : undefined;

  useEffect(() => {
    if (!cachedList) {
      return;
    }
    const fetchData = async () => {
      const previousRequestedCount = cachedList.requestedCount;
      cachedList.setRequestedCount((page + 1) * itemsPerPage);
      setLoading(true);
      await store.fetchCachedList(cachedList);
      const pageItems = cachedList.getPage(page, itemsPerPage);
      if (!model || cachedList.requestedCount !== previousRequestedCount) {
        const { items, preSelectedKeys: preSelectedKeysFinal, disabledKeys } = pageItems
          ? await augmentData(pageItems, preSelectedKeys, preSelectedKeys)
          : { items: pageItems, preSelectedKeys, disabledKeys: preSelectedKeys };
        const newModel = useLibraryModel
          ? new LibrarySelectionModel(items, store, modelName, library)
          : new SelectionModel(
            multiSelect,
            items,
            preSelectedKeysFinal,
            disabledKeys,
            selectedKeys,
          );
        setModel(newModel);
      }
      setLoading(false);
    };
    fetchData();
  }, [
    store,
    cachedList,
    augmentData,
    itemsPerPage,
    library,
    model,
    modelName,
    multiSelect,
    page,
    selectedKeys,
    preSelectedKeys,
    useLibraryModel,
  ]);

  console.log('PaginatedItemList', model);
  if (!model) {
    return (
      <Box>Loading…</Box>
    );
  }

  return (
    <ItemList
      modelName={modelName}
      model={model}
      listStyle={listStyle}
      defaultAction={defaultAction}
      actions={actions}
      imageField={imageField}
      titleField={titleField}
      secondaryFields={secondaryFields}
      updateSelectedItems={updateSelectedItems}
      showSelect={showSelect}
      enableDeselect={enableDeselect}
      actionsStyle={actionsStyle}
      block={loading}
    />
  );
};
PaginatedItemList.defaultProps = PaginatedItemListDefaults;

export default observer(PaginatedItemList);
