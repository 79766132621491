import React from 'react';
import {
  FormControl,
  FormControlLabel,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { StandardFieldProps } from './FieldTypes';
import BulletPoints from './bulletItemsInput/BulletItems';
import HelperText from './HelperText';

const StandardBulletsPointsField = ({
  control,
  fieldName,
  field,
  readOnly,
}:StandardFieldProps) => (
  <Controller
    name={fieldName}
    control={control}
    render={({
      field: {
        onChange,
        // onBlur,
        value,
        // name,
        // ref,
      },
      fieldState: {
        // invalid,
        isTouched,
        // isDirty,
        error,
      },
    }) => (
      <FormControl fullWidth>
        <FormControlLabel
          label={field.label || 'No Label'}
          disabled={field.readOnly || readOnly}
          labelPlacement="top"
          // eslint-disable-next-line react/jsx-no-useless-fragment
          control={(
            <BulletPoints
              value={value}
              setValue={onChange}
              placeholder={field.placeholder || ''}
              disabled={Boolean(field.readOnly || readOnly)}
            />
          )}
        />
        <HelperText
          name={fieldName}
          error={isTouched && error?.message}
          description={field.description}
        />
      </FormControl>
    )}
  />
);

StandardBulletsPointsField.defaultProps = {
  description: undefined,
};

export default StandardBulletsPointsField;
