import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
} from '@mui/material';

import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet-async';
import { v4 } from 'uuid';
import { useNavigate, useParams } from 'react-router-dom';
import usePrompt from 'hooks/usePrompt';

import ItemEdit from 'components/item/ItemEdit';
import { IRepository, useStore } from '@mcn-platform/models';
import ButtonStack from 'components/item/ButtonStack';

const RepositoryEditor = ({ clone }:{ clone?: boolean }) => {
  const [repository, setRepository] = useState<IRepository | null>();
  const store = useStore();
  const { repositoryID } = useParams();
  const navigate = useNavigate();

  usePrompt({ isDirty: repository ? (repository.changeCount > 0) : false });

  useEffect(() => {
    if (!repositoryID) {
      return;
    }
    // check if we're creating a new repository
    if (clone) {
      if (repository) {
        console.log('All good - already loaded');
        return;
      }

      console.log('cloning');
      store.load('repository', repositoryID).then((repositoryToClone:any) => {
        const newRepository = store.clone(
          'repository',
          repositoryToClone,
        );
        setRepository(newRepository);
      });
    } else if (repositoryID === 'new') {
      if (repository) {
        console.log('All good - already loaded');
        return;
      }

      const newRepository = store.create(
        'repository',
        v4(),
        'New Repository',
      );
      setRepository(newRepository);
    } else {
      console.log('loading repository', { repositoryID, repository });
      const loadedRepository = store.getOrLoad('repository', repositoryID);
      setRepository(loadedRepository);
    }
  }, [store, repositoryID, repository, clone]);

  /**
   * Takes the full repository and saves it to the repository stub.  Sections are serialized to JSON
   */
  const saveRepository = () => {
    if (!repository) return;
    console.log('repository:', repository);
    if (clone || repositoryID === 'new') {
      store.addStub('repository', repository).then(() => {
        navigate(`/app/repository-editor/${repository.id}`);
      });
    } else {
      store.update('repository', repository);
    }
  };

  if (!repository) {
    return <div>Loading.</div>;
  }
  if (repository.syncState === 'not_found') {
    return <div>Not found</div>;
  }
  if (repository.syncState === 'error') {
    return <div>Error loading repository</div>;
  }

  if (repository.syncState === 'saving') {
    return <div>Saving..</div>;
  }

  if (!clone && repositoryID !== 'new' && repository.syncState !== 'loaded') {
    return <div>Loading..</div>;
  }
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    }}
    >
      <Helmet>
        <title>{repositoryID === 'new' ? 'Repository Builder' : 'Repository Editor'}</title>
      </Helmet>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
      }}
      >
        <Box sx={{ flex: '1', overflowY: 'auto' }}>
          <Grid container sx={{ height: '100%' }}>
            <Grid item xs={12} md={12} sx={{ height: '100%', overflow: 'hidden', pr: 2 }}>
              <Card sx={{ height: '100%', overflow: 'auto' }}>
                <CardContent>
                  <ItemEdit
                    key={repository.id}
                    item={repository}
                    modelName="repository"
                    handleSubmit={(fields:any) => {
                      console.log('Update repository', fields);
                      repository.updateValues(fields);
                    }}
                    hideTitle
                    autoSave
                    // fields={['name', 'description']}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <ButtonStack>
          <Button
            color="primary"
            variant="contained"
            onClick={saveRepository}
            disabled={repository.changeCount === 0}
          >
            Save
          </Button>
        </ButtonStack>
      </Box>
    </Box>
  );
};

RepositoryEditor.defaultProps = {
  clone: false,
};

export default observer(RepositoryEditor);
