import React from 'react';
import {
  Box,
  Button,
  Dialog, DialogContent,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useStore } from '@mcn-platform/models';
import { AssetVideoPlayer } from '@mcn-platform/portal-lib';
import ItemEdit from 'components/item/ItemEdit';
import ButtonStack from 'components/item/ButtonStack';
import BootstrapDialogTitle from './BootstrapDialogTitle';

const TranscriptDialog = ({
  open, item, handleClose,
}: any) => {
  const store = useStore();
  const saveItem = () => {
    store.update('asset', item);
  };
  if (!open) {
    return null;
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      PaperProps={{ sx: {} }}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        Transcript for
        {' '}
        {item?.name}
      </BootstrapDialogTitle>
      <DialogContent>
        <Box sx={{ width: '400px' }}>
          {item?.mimeType.startsWith('video/')
            && (
              <AssetVideoPlayer
                asset={item}
                showControls
                aspectRatio="16:9"
                playerStyle={{ objectFit: 'fill' }}
              />
            )}
        </Box>
        <ItemEdit
          key={item?.id}
          item={item}
          modelName="asset"
          handleSubmit={() => {}}
          hideTitle
          autoSave
          formConfig={['transcript']}
        />
        <ButtonStack>
          <Button
            color="primary"
            variant="contained"
            onClick={saveItem}
            disabled={item.changeCount === 0}
          >
            Save
          </Button>
        </ButtonStack>
      </DialogContent>
    </Dialog>
  );
};

export default observer(TranscriptDialog);
