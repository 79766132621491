import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
} from '@mui/material';

import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet-async';
import { v4 } from 'uuid';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { IMicrocast, useStore } from '@mcn-platform/models';
import usePrompt from 'hooks/usePrompt';

import ItemEdit from 'components/item/ItemEdit';
import ButtonStack from 'components/item/ButtonStack';

const MicrocastEditor = ({ clone }:{ clone?: boolean }) => {
  const [microcast, setMicrocast] = useState<IMicrocast | null>();
  const store = useStore();
  const { currentOrganization, currentPortal } = useStore();
  const { microcastID } = useParams();
  const navigate = useNavigate();

  usePrompt({ isDirty: microcast ? (microcast.changeCount > 0) : false });

  useEffect(() => {
    if (!microcastID) {
      return;
    }
    // check if we're creating a new microcast
    if (clone) {
      if (microcast) {
        console.log('All good - already loaded');
        return;
      }

      console.log('cloning');
      store.load('microcast', microcastID).then((microcastToClone:any) => {
        const newMicrocast = store.clone(
          'microcast',
          microcastToClone,
        );
        setMicrocast(newMicrocast);
      });
    } else if (microcastID === 'new') {
      if (microcast) {
        console.log('All good - already loaded');
        return;
      }

      const newMicrocast = store.create(
        'microcast',
        v4(),
        'New Microcast',
        {
          sections: [],
          type: 'container',
          libraries: store.currentPortal.defaultLibrary ? [store.currentPortal.defaultLibrary] : [],
          organization: store.currentOrganization,
        },
      );
      setMicrocast(newMicrocast);
    } else {
      const loadedMicrocast = store.getOrLoad('microcast', microcastID);
      setMicrocast(loadedMicrocast);
    }
  }, [store, microcastID, microcast, microcast?.syncState, clone]);

  /**
   * Takes the full microcast and saves it to the microcast stub.  Sections are serialized to JSON
   */
  const saveMicrocast = () => {
    if (!microcast) return;
    console.log('microcast:', microcast);
    if (clone || microcastID === 'new') {
      store.addStub('microcast', microcast).then(() => {
        console.log('saved');
        navigate(`/app/microcast-editor/${microcast.id}`);
      });
    } else {
      store.update('microcast', microcast);
    }
  };

  if (!microcast) {
    return <div>Loading.</div>;
  }
  if (microcast.syncState === 'error') {
    return <div>Error loading microcast</div>;
  }

  if (microcast.syncState === 'saving') {
    return <div>Saving..</div>;
  }

  if (!clone && microcastID !== 'new' && microcast.syncState !== 'loaded') {
    return <div>Loading..</div>;
  }

  // console.log('Microcast editor:', microcastID, getSnapshot(microcast));
  const readOnly = (microcast?.organization?.id !== currentOrganization?.id);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    }}
    >
      <Helmet>
        <title>{microcastID === 'new' ? 'Microcast Builder' : 'Microcast Editor'}</title>
      </Helmet>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
      }}
      >
        <Box sx={{ flex: '1', overflowY: 'auto' }}>
          <Grid container sx={{ height: '100%' }}>
            <Grid item xs={12} md={12} sx={{ height: '100%', overflow: 'hidden', pr: 2 }}>
              <Card sx={{ height: '100%', overflow: 'auto' }}>
                <CardContent>
                  <ItemEdit
                    key={microcast.id}
                    item={microcast}
                    modelName="microcast"
                    handleSubmit={(fields:any) => {
                      console.log('Update microcast', fields);
                      microcast.updateValues(fields);
                    }}
                    hideTitle
                    autoSave
                    readOnly={readOnly}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <ButtonStack>
          <Link to={`/portal/${currentOrganization?.id}/${currentPortal?.id}/microcast/${microcast?.slug || microcast?.key}`} target="_blank">
            <Button
              variant="outlined"
            >
              View
            </Button>
          </Link>
          <Button
            color="primary"
            variant="contained"
            onClick={saveMicrocast}
            disabled={microcast.changeCount === 0}
          >
            Save
          </Button>
        </ButtonStack>
      </Box>
    </Box>
  );
};

MicrocastEditor.defaultProps = {
  clone: false,
};

export default observer(MicrocastEditor);
