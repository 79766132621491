import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControlLabel,
  Stack,
  TextField,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';

import {
  IAdminStore,
  IUserOrganization,
  navStore,
  useStore,
} from '@mcn-platform/models';

const UserEditor = () => {
  const store = useStore() as IAdminStore;
  const [userOrganization, setUserOrganization] = useState<IUserOrganization>();
  const [email, setEmail] = useState<string>('');

  const { userOrgID } = useParams();
  const navigate = useNavigate();

  // usePrompt('You have unsaved changes.
  // Are you sure you want to leave?', userOrganization ?
  // (userOrganization.changeCount > 0 && userOrgID !== 'new') : false);

  useEffect(() => {
    if (!userOrgID) {
      return;
    }

    // check if we're creating a new userOrganization
    if (userOrgID === 'new') {
      // don't need to
    } else {
      const stub = store.getOrLoad('userOrganization', userOrgID);
      setUserOrganization(stub);
    }
  }, [store, userOrgID, userOrganization?.syncState]);

  /**
   * Takes the full userOrganization and saves it to the userOrganization stub.
   */
  // const saveUserOrganization = () => {
  //   if (!userOrganization) return;

  //   if (userOrgID === 'new') {
  //     // navStore.showMessageDialog('Creating UserOrganization', 'Creating userOrganization');
  //   } else {
  //     store.update('userOrganization', userOrganization);
  //   }
  // };

  const submitNew = () => {
    navStore.showMessageDialog('Inviting User', 'Creating invite...');
    store.addUserToOrganization(email).then((newUserOrg) => {
      console.log('created new userOrg');
      navStore.hideMessageDialog();
      navStore.showConfirmDialog(
        'Success',
        'User has been invited.  If this is a new user they will receive an email with a temporary password.',
        () => navStore.hideConfirmDialog(),
        'OK',
      );
      navigate(`../userOrg/${newUserOrg.id}`);
    }).catch((reason) => {
      console.log('Error inviting user', reason);
      navStore.hideMessageDialog();
      navStore.showConfirmDialog(
        'Error',
        'There was a problem inviting the user to the organization.',
        () => navStore.hideConfirmDialog(),
        'OK',
      );
    });
    return false;
  };

  if (userOrgID === 'new') {
    return (
      <Stack direction="column" spacing={2}>
        <Helmet>
          <title>Add User to Organization</title>
        </Helmet>
        <FormControlLabel
          label="Email"
          labelPlacement="top"
          control={(
            <TextField
              fullWidth
              id="email"
              name="email"
              placeholder="Enter email"
              value={email}
              onChange={(event:any) => {
                console.log('setting email to new value', event);
                setEmail(event.target.value); // onChange(newValue);
              }}
            />
          )}
        />
        <Box>
          <Button variant="contained" onClick={submitNew}>Invite User</Button>
        </Box>
      </Stack>
    );
  }

  if (!userOrganization) {
    return (
      <div>Loading.</div>
    );
  }
  if (userOrganization.syncState === 'error') {
    return <div>Error loading user</div>;
  }

  if (userOrganization.syncState === 'saving') {
    return <div>Saving..</div>;
  }

  if (userOrgID !== 'new' && userOrganization.syncState !== 'loaded') {
    return <div>Loading..</div>;
  }

  return (
    <Stack direction="column" spacing={2}>
      <Helmet>
        <title>User Information</title>
      </Helmet>
      <FormControlLabel
        label="Email"
        labelPlacement="top"
        control={(
          <TextField
            fullWidth
            id="email"
            name="email"
            value={userOrganization.user.email}
            disabled
          />
        )}
      />
      <FormControlLabel
        label="Name"
        labelPlacement="top"
        control={(
          <TextField
            fullWidth
            id="name"
            name="name"
            value={userOrganization.user.name}
            disabled
          />
        )}
      />
      {/* <Box>
        <Button variant="contained" onClick={submitNew}>Invite User</Button>
      </Box> */}
    </Stack>
  );
};

export default observer(UserEditor);
