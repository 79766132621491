import React, { useState } from 'react';
import {
  InputLabel,
  //  List,
  // ListItem,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import { ICategory, IPortal } from '@mcn-platform/models';
import { observer } from 'mobx-react-lite';

const categoryItems = (
  category:any,
  depth:number,
  activeCategoryID:string,
  onChange:any,
):any[] => {
  if (!category.subCategories) {
    return [];
  }
  return category.subCategories.reduce((catList:any, subCat:any) => {
    catList.push(
      <MenuItem
        key={subCat.id}
        value={subCat.id}
        onClick={() => {
          onChange(subCat);
        }}
        sx={{ paddingLeft: depth + 2 }}
        selected={subCat.id === activeCategoryID}
      >
        <ListItemText>
          {subCat.name}
        </ListItemText>
      </MenuItem>,
    );
    catList.push(...categoryItems(subCat, depth + 1, activeCategoryID, onChange));
    return catList;
  }, []);
};

const CategoryPickerControl = ({
  portal,
  label,
  activeCategory,
  onChange,
  disabled,
}:{
  portal: IPortal,
  label: string,
  activeCategory: ICategory,
  onChange: (newValue:ICategory) => void,
  disabled?: boolean,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const setValue = (newValue:any) => {
    onChange(newValue);
    setOpen(false);
  };

  const categories = [
    <ListSubheader key="categories">Categories</ListSubheader>,
    ...categoryItems(portal.categories[0], 0, activeCategory, setValue),
    <ListSubheader key="groups">Groups</ListSubheader>,
    ...categoryItems(portal.categories[1], 0, activeCategory, setValue),
    <ListSubheader key="dynamic">Dynamic</ListSubheader>,
    ...categoryItems(
      {
        name: 'Dynamic',
        subCategories: [{
          name: 'Page Context',
          id: '@categoryId',
        }],
      },
      0,
      activeCategory,
      setValue,
    ),
  ];
  console.log('ActiveCategory', activeCategory);
  return (
    <Stack>
      <InputLabel id="category-select-label">{label}</InputLabel>
      <Select
        size="small"
        labelId="category-select-label"
        id="category-select"
        value={activeCategory}
        // label={label}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        disabled={disabled}
      >
        {categories}
      </Select>
    </Stack>
  );
};

CategoryPickerControl.defaultProps = {
  disabled: false,
};

export default observer(CategoryPickerControl);
