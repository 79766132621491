import * as React from 'react';
import { SVGProps } from 'react';

export default (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1000 1000"
    xmlSpace="preserve"
    width="1em"
    height="1em"
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  >
    <path fill="currentColor" d="M811.9 24.6s-32.8-32.8-65.7 0L75.8 695l-17.1 17.1 186.7 186.8 27.4 27.4 15.1 15.1 32.7-32.7 523.9-523.9 32.7-32.7 98.2-98.2c32.8-32.8 0-65.7 0-65.7L811.9 24.6zM287.7 875.7 124.2 712.2l523.9-524 163.5 163.5-523.9 524zm556.7-556.6L680.9 155.6l98.2-98.2 163.5 163.5-98.2 98.2z" />
    <path fill="currentColor" d="m73.3 926.6 34.5-165.3-49.7-49.7L10 990l277.8-48.7-15.1-15.1-27.3-27.4z" />
  </svg>
);
