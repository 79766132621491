import React from 'react';

import AssetPickerDialog from 'components/asset/AssetPickerDialog';
import { FormControl, FormLabel, Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Controller } from 'react-hook-form';
import { AssetTypes } from '@mcn-platform/models';
import TranscriptDialog from 'components/asset/TrancriptDialog';
import { StandardFieldProps } from './FieldTypes';
import HelperText from './HelperText';

interface AssetReferenceFieldProps extends StandardFieldProps {
  assetType: AssetTypes;
}

const AssetReferenceField = ({
  control,
  fieldName,
  field,
  assetType,
  readOnly,
}:AssetReferenceFieldProps):any => (
  <Controller
    name={fieldName}
    control={control}
    render={({
      field: {
        onChange,
        value,
      },
      fieldState: {
        isTouched,
        error,
      },
    }) => (
      <FormControl disabled={field.readOnly} fullWidth>
        <FormLabel id={`label-${fieldName}`}>{field.label}</FormLabel>
        <Box
          sx={[{
            width: '100%',
            border: '1px solid',
            borderColor: '#e0e0eb',
            borderRadius: 1,
            padding: 2,
          },
          {
            '&:hover': {
              borderColor: '#111111',
            },
          },
          {
            '&:focus': {
              border: '2px solid #1276dd',
            },
          },
          ]}
        >
          <AssetPickerDialog
            value={value}
            name={fieldName}
            canUpload
            onChange={onChange}
            disabled={readOnly}
            assetType={assetType || field.assetType}
            TranscriptDialog={TranscriptDialog}
          />
          <HelperText
            name={fieldName}
            error={isTouched && error?.message}
            description={field.description}
          />
        </Box>
      </FormControl>
    )}
  />
);

export default observer(AssetReferenceField);
