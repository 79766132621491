import { IAdminStore, ILibrary } from '@mcn-platform/models';

/**
 * This selection model sets and checks if the items are in the specified library.
 */
class LibrarySelectionModel {
  items:any[];

  library:ILibrary;

  store:IAdminStore;

  modelName: string;

  constructor(items:any[], store:IAdminStore, modelName:string, library:ILibrary) {
    this.items = items;
    this.library = library;
    this.store = store;
    this.modelName = modelName;
  }

  isSelected(item:any) {
    return item.libraries.includes(this.library);
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
  isDisabled(item:any) {
    return false;
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
  isPreSelected(item:any) {
    return false;
  }

  toggleItem(item:any) {
    if (this.isSelected(item)) {
      // remove library
      item.removeLibrary(this.library);
    } else {
      // add library
      item.addLibrary(this.library);
    }

    // save item
    this.store.update(this.modelName, item, false);
  }

  // eslint-disable-next-line class-methods-use-this
  selectAll() {
    // @todo - fix this so it just deselects the current page's items
    // this.selectedIDs = this.items.map((item:any) => item.id);
    // return this.selectedIDs;
  }

  // eslint-disable-next-line class-methods-use-this
  deselectAll() {
    // @todo - fix this so it just deselects the current page's items
    // this.selectedIDs = [];
    // return this.selectedIDs;
  }

  // eslint-disable-next-line class-methods-use-this
  selectedCount() {
    return 0;
  }
}

export default LibrarySelectionModel;
