// eslint-disable-next-line import/prefer-default-export
export const ItemTypes = {
  CATEGORY: 'Category',
  COMPONENT: 'Component',
  COURSE: 'Course',
  COURSE_SECTION: 'CourseSection',
  LEARNING_BOARD_SECTION: 'LearningBoardSection',
  WIDGET: 'Widget',
  PRODUCT: 'Item',
  LINK: 'Link',
  ITEM: 'Item',
  TAB: 'Tab',
};
