import React from 'react';
import { observer } from 'mobx-react-lite';
import { Typography } from '@mui/material';
import { ILearningBoardSection } from '@mcn-platform/models';
import ItemEdit from 'components/item/ItemEdit';
import CategoryDetail from './CategoryDetail';
import CustomDetail from './CustomDetail';
import { TreeItem } from './LearningBoardTree/types';

const fields = ['name'];

const updateSection = (section:TreeItem, formData:any) => {
  console.log('Update section', formData);
  formData.name = formData.category.name;
  section.updateFields(fields, formData);
};

type SectionDetailProps = {
  section: TreeItem;
};

const SectionDetail = ({ section }:SectionDetailProps) => {
  console.log('In Section Detail : ', section);
  if (section.type === 'category') {
    return <CategoryDetail section={section as ILearningBoardSection} />;
  }
  if (section.type === 'custom') {
    return <CustomDetail section={section as ILearningBoardSection} />;
  }
  return (
    <>
      <Typography variant="h4">
        Learning Board
      </Typography>
      <ItemEdit
        item={section}
        modelName="learningBoard"
        handleSubmit={(newValues) => updateSection(section, newValues)}
        formConfig={fields}
        hideTitle
        autoSave
      />
    </>
  );
};

export default observer(SectionDetail);
