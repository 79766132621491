import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
} from '@mui/material';

import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet-async';
import { v4 } from 'uuid';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { IDocument, useStore } from '@mcn-platform/models';
import usePrompt from 'hooks/usePrompt';

import ItemEdit from 'components/item/ItemEdit';
import ButtonStack from 'components/item/ButtonStack';
import DocumentPageLinkButton from 'components/item/DocumentPageLinkButton';

const DocumentEditor = ({ clone }:{ clone?: boolean }) => {
  const [document, setDocument] = useState<IDocument | null>();
  const store = useStore();
  const { currentOrganization, currentPortal } = useStore();
  const { key } = useParams();
  const navigate = useNavigate();

  usePrompt({ isDirty: document ? (document.changeCount > 0) : false });

  useEffect(() => {
    if (!key) {
      return;
    }
    // check if we're creating a new document
    if (clone) {
      if (document) {
        console.log('All good - already loaded');
        return;
      }

      console.log('cloning');
      store.load('document', key).then((documentToClone:any) => {
        const newDocument = store.clone(
          'document',
          documentToClone,
        );
        setDocument(newDocument);
      });
    } else if (key === 'new') {
      if (document) {
        console.log('All good - already loaded');
        return;
      }
      const newDocument = store.create(
        'document',
        v4(),
        'New Document',
        {
          libraries: store.currentPortal.defaultLibrary ? [store.currentPortal.defaultLibrary] : [],
          organization: store.currentOrganization,
          publishedAt: new Date(),
        },
      );
      setDocument(newDocument);
    } else {
      const loadedDocument = store.getOrLoad('document', key);
      setDocument(loadedDocument);
    }
  }, [store, key, document, document?.syncState, clone]);

  /**
   * Takes the full document and saves it to the document stub.  Sections are serialized to JSON
   */
  const saveDocument = () => {
    if (!document) return;
    console.log('document:', document);
    if (clone || key === 'new') {
      store.addStub('document', document).then(() => {
        console.log('saved');
        navigate(`/app/document-editor/${document.id}`);
      });
    } else {
      store.update('document', document);
    }
  };

  if (!document) {
    return <div>Loading.</div>;
  }
  if (document.syncState === 'error') {
    return <div>Error loading document</div>;
  }

  if (document.syncState === 'saving') {
    return <div>Saving..</div>;
  }

  if (!clone && key !== 'new' && document.syncState !== 'loaded') {
    return <div>Loading..</div>;
  }

  // console.log('Document editor:', key, getSnapshot(document));
  const readOnly = (document?.organization?.id !== currentOrganization?.id);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    }}
    >
      <Helmet>
        <title>
          Document Editor
        </title>
      </Helmet>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
      }}
      >
        <Box sx={{ flex: '1', overflowY: 'auto' }}>
          <Grid container sx={{ height: '100%' }}>
            <Grid item xs={12} md={12} sx={{ height: '100%', overflow: 'hidden', pr: 2 }}>
              <Card sx={{ height: '100%', overflow: 'auto' }}>
                <CardContent>
                  <ItemEdit
                    key={document.id}
                    item={document}
                    modelName="document"
                    handleSubmit={(fields:any) => {
                      console.log('Update document', fields);
                      document.updateValues(fields);
                    }}
                    hideTitle
                    autoSave
                    readOnly={readOnly}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <ButtonStack>
          <DocumentPageLinkButton document={document} />
          <Link to={`/portal/${currentOrganization?.id}/${currentPortal?.id}/document/${document?.slug || document?.id}`} target="_blank">
            <Button
              variant="outlined"
            >
              View
            </Button>
          </Link>
          <Button
            color="primary"
            variant="contained"
            onClick={saveDocument}
            disabled={document.changeCount === 0}
          >
            Save
          </Button>
        </ButtonStack>
      </Box>
    </Box>
  );
};

DocumentEditor.defaultProps = {
  clone: false,
};

export default observer(DocumentEditor);
