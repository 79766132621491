import React, { createRef, RefObject, useEffect } from 'react';
import { List } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { moveItem, useStore } from '@mcn-platform/models';
import DropTarget from './DropTarget';
import DraggableItem from './DraggableItem';
import DraggableItemItem from './DraggableItemItem';

type SortableItemListProps = {
  modelName: string;
  items: any[];
  updateItems: (newItems: any[]) => void;
};

const getItem = (store, modelName, itemOrID) => {
  if (typeof itemOrID === 'string') {
    return store.getOrLoad(modelName, itemOrID);
  }
  const item = itemOrID;
  return (item.syncState === undefined || item.syncState === 'loaded')
    ? item
    : store.getOrLoad(modelName, item.id);
  // return store.getOrLoad(modelName, itemOrID.id);
};

const SortableItem = observer(({
  modelName,
  item,
  index,
  handleDrop,
  handleDelete,
}:{
  modelName: string,
  item: any,
  index: number,
  handleDrop: any,
  handleDelete: any,
}) => {
  const store = useStore();
  const rowItem = getItem(store, modelName, item);

  return (
    <>
      { modelName === 'item' ? (
        <DraggableItemItem
          item={rowItem}
          handleDelete={handleDelete}
          dndType={modelName}
        />
      ) : (
        <DraggableItem
          item={rowItem}
          handleDelete={handleDelete}
          dndType={modelName}
        />
      )}
      <DropTarget
        index={index + 1}
        handleDrop={handleDrop}
        dndType={modelName}
      />
    </>
  );
});

const SortableItemList = ({
  modelName,
  items,
  updateItems,
}:SortableItemListProps) => {
  const activeCourseRef:RefObject<Element> = createRef();
  useEffect(() => {
    if (activeCourseRef && activeCourseRef.current) {
      activeCourseRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [activeCourseRef, items]);

  const handleDrop = (item:any, newIndex:number) => {
    const currentIndex = items.indexOf(item);
    const adjustedNewIndex = newIndex > currentIndex ? newIndex - 1 : newIndex;
    const tempArray = items.slice();
    moveItem(tempArray, currentIndex, adjustedNewIndex);
    updateItems(tempArray);
  };

  const handleDelete = (item:any) => {
    const deleteIndex = items.indexOf(item);
    const tempArray = items.slice();
    tempArray.splice(deleteIndex, 1);
    updateItems(tempArray);
  };

  return (
    <List>
      <DropTarget
        index={0}
        handleDrop={handleDrop}
        dndType={modelName}
      />
      {items && items.map((item:any, index:number) => (
        <SortableItem
          key={item.id}
          modelName={modelName}
          item={item}
          index={index}
          handleDrop={handleDrop}
          handleDelete={handleDelete}
        />
      ))}
    </List>
  );
};

export default observer(SortableItemList);
