import { useEffect, useState } from 'react';
import { isEqual } from 'lodash';

const AutoUpdate = ({ handleSubmit, initialValues, currentValues }:any) => {
  // const formik = useFormikContext();
  const [lastSaved, setLastSaved] = useState<any>(initialValues);

  // console.log('initialValues', JSON.stringify(formik.initialValues));
  // console.log('       values', JSON.stringify(formik.values));
  // console.log('    lastSaved', JSON.stringify(lastSaved));

  useEffect(() => {
    if (!isEqual(currentValues, lastSaved)) {
      try {
        handleSubmit();
      } catch (error) {
        console.log('Failed to autosave', error);
      }
      setLastSaved(currentValues);
    }
  }, [handleSubmit, currentValues, lastSaved]);

  return null;
};

export default AutoUpdate;
