/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Stack,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';

import {
  IAdminStore,
  IAsset,
  navStore, useStore,
} from '@mcn-platform/models';

import usePrompt from 'hooks/usePrompt';
import ItemEdit from 'components/item/ItemEdit';
import ButtonStack from 'components/item/ButtonStack';
import { Control, Controller } from 'react-hook-form';
import { FieldType } from 'components/forms/FieldTypes';
// import AssetReferenceField from 'components/forms/AssetReferenceField';
import UploadFile from 'components/asset/UploadFile';
// import AssetTypeField from 'components/asset/AssetTypeField';

const processAccessChange = (
  id:string,
  store:IAdminStore,
  title:string,
  message:string,
  buttonLabel:string,
  newLevel:string,
  onChange: (newLevel:string) => void,
) => {
  navStore.showConfirmDialog(
    title,
    message,
    async () => {
      navStore.hideConfirmDialog();
      navStore.showMessageDialog('Make Asset Private', 'Processing');
      const newAsset = await store.setAccessLevel(id, newLevel);
      console.log('Updated asset', newAsset);
      onChange(newLevel);
      navStore.hideMessageDialog();
    },
    buttonLabel,
    undefined,
    'Cancel',
  );
};

const AccessField = (
  control: Control,
  fieldName: string,
  field: FieldType,
  readOnly: boolean,
  // itemType:string,
) => {
  const store = useStore();
  // eslint-disable-next-line no-underscore-dangle, react/destructuring-assignment
  const { id } = control._formValues;
  const asset = store.getOrLoad('asset', id);
  return (
    <Controller
      name={fieldName}
      control={control}
      render={({
        field: {
          onChange,
          // onBlur,
          value,
        },
      }:any) => {
        const setAccessLevel = (newLevel:string) => {
          if (newLevel === 'public') {
            processAccessChange(
              id,
              store,
              'Make Asset Public',
              'Are you sure you want to make this asset public?  Anyone with a link to the asset will be able to access/download it.',
              'Make Public',
              newLevel,
              onChange,
            );
          }
          if (newLevel === 'normal') {
            processAccessChange(
              id,
              store,
              'Make Asset Private',
              'Are you sure you want to make this asset private?',
              'Make Private',
              newLevel,
              onChange,
            );
          }
        };

        return (
          <FormControl
            id={`label-${fieldName}`}
            disabled={readOnly}
          >
            <FormLabel id={`label-${fieldName}`}>{field.label}</FormLabel>
            <Stack direction="row" spacing={2} alignItems="center">
              <span>
                {value === 'normal' ? 'Restricted' : 'Public'}
              </span>
              {value === 'normal' && (
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => setAccessLevel('public')}
                  disabled={readOnly}
                >
                  Make Asset Public
                </Button>
              )}
              {value === 'public' && (
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => setAccessLevel('normal')}
                  disabled={readOnly}
                >
                  Make Asset Private
                </Button>
              )}
            </Stack>
            { value === 'public' && (
              <span>
                {`https://${asset.repository.bucket}.s3.amazonaws.com/${asset.path}/original`}
              </span>
            )}
          </FormControl>
        );
      }}
    />
  );
};

const labelMap = {
  image: 'Image',
  video: 'Video',
  pdf: 'PDF',
  any: 'Asset',
  other: 'Asset',
};

const AssetEditor = () => {
  const store = useStore();
  const [asset, setAsset] = useState<IAsset | null>();
  const { assetID, type } = useParams();
  const navigate = useNavigate();
  const assetType = type || 'any';

  usePrompt({ isDirty: asset ? (asset.changeCount > 0) : false });

  const typeLabel = labelMap[assetType] || 'Asset';

  const handleUploadComplete = (newAsset:IAsset) => {
    console.log('Asset Change', newAsset);
    setAsset(newAsset);
    navigate(`/app/asset/${newAsset.id}`);
  };

  const handleCancel = () => {
    switch (assetType) {
      case 'image':
        navigate('/app/assets/image');
        break;
      case 'video':
        navigate('/app/assets/video');
        break;
      case 'pdf':
        navigate('/app/assets/pdf');
        break;
      default:
        navigate('/app/assets/other');
    }
  };

  useEffect(() => {
    if (!assetID) {
      return;
    }

    // check if we're creating a new asset
    if (assetID === 'new') {
      // const newAsset = store.create(
      //   'asset',
      //   v4(),
      //   'New Asset',
      //   {
      //     portal: store.currentPortal,
      //   },
      // );
      // setAsset(newAsset);
    } else {
      const stub = store.getOrLoad('asset', assetID, 10);
      console.log('syncstub: ', stub.syncState, { ...stub });
      setAsset(stub);
    }
  }, [store, assetID, asset?.syncState]);

  /**
   * Takes the full asset and saves it to the asset stub.  Sections are serialized to JSON
   */
  const saveAsset = async () => {
    if (!asset) return;
    await store.update('asset', asset);
  };

  const processAsset = async () => {
    navStore.showConfirmDialog(
      'Processing Asset',
      'The asset is being processed. Click OK to have it process in background.',
      () => navStore.hideConfirmDialog(),
      'OK',
      undefined,
      '',
    );
    try {
      const updatedAsset = await store.processAsset(asset);
      console.log('Updated asset', updatedAsset);
      // setAsset(updatedAsset);
    } catch (e) {
      console.log('Error processing asset', e);
    }
    navStore.hideConfirmDialog();
  };

  if (asset?.syncState === 'error') {
    return <div>Error loading asset</div>;
  }

  if (asset?.syncState === 'saving') {
    return <div>Saving..</div>;
  }

  if (assetID !== 'new' && asset?.syncState !== 'loaded') {
    return <div>Loading..</div>;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Helmet>
        <title>
          {assetID === 'new' ? 'New' : 'Edit'}
          {' '}
          {typeLabel}
        </title>
      </Helmet>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        minHeight: '100%',
      }}
      >
        <Box sx={{ flex: '1', overflowY: 'auto' }}>
          { assetID === 'new' ? (
            <UploadFile
              repository={store.currentRepository}
              onUploadComplete={handleUploadComplete}
              onCancel={handleCancel}
              assetType={assetType}
            />
          ) : (
            <ItemEdit
              item={asset}
              modelName="asset"
              handleSubmit={(newValues:any) => {
                console.log('Update asset', newValues);
                asset?.updateValues(newValues);
              }}
              overrides={{
                access: AccessField,
              }}
              hideTitle
              autoSave
            />
          )}
        </Box>
        <ButtonStack>
          <Button
            variant="outlined"
            onClick={processAsset}
          >
            Process Asset
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={saveAsset}
            disabled={asset?.changeCount === 0}
          >
            Save
          </Button>
        </ButtonStack>
      </Box>
    </Box>
  );
};

export default observer(AssetEditor);
