import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
} from '@mui/material';
import { TabContext, TabList } from '@mui/lab';
import { v4 } from 'uuid';
import { difference } from 'lodash';

import { IPortal, pluralize, useStore } from '@mcn-platform/models';
import { capitalizeFirstLetter } from '@mcn-platform/portal-lib';

import ItemNavigator from 'components/item/ItemNavigator';

const ItemPickerDialog = ({
  open,
  modelName,
  /* Map with modelName as key and array of selected keys as value.
   * Items have been selected but not yet confirmed.
   */
  preSelectedKeysByModel = ItemPickerDialog.defaultProps.preSelectedKeysByModel,
  onCancel,
  onConfirm,
  scope,
}:{
  open:boolean,
  modelName:string,
  preSelectedKeysByModel?:Record<string, string[]>,
  onConfirm: (newValue:any) => void,
  onCancel: () => void,
  scope?: 'organization' | 'portal',
}) => {
  const [selectedTab, setSelectedTab] = useState<any>('course');
  const [selectedModelName, setSelectedModelName] = useState<string>(
    modelName === 'item' ? 'course' : modelName,
  );

  /* Map with modelName as key and array of selected keys as value.
   * Items have been selected but not yet confirmed.
   */
  const [newKeysByModel, setNewKeysByModel] = useState<Record<string, string[]>>({});

  const store = useStore();

  const getLibraryFilter = (portal:IPortal) => {
    // filter search based on libraries assigned to portal
    const libraryFilter = {
      or: portal.getLibraryIDs().map((id:string) => ({ libraries: { matchPhrase: id } })),
    };
    console.log('libraryFilter', libraryFilter);
    return libraryFilter;
  };

  const updateFromSelectedItems = (selectedKeys:string[]) => {
    if (modelName === 'item') {
      const newValue = {
        ...newKeysByModel,
        [selectedModelName]: selectedKeys,
      };
      console.log('newValue', newValue);
      setNewKeysByModel(newValue);
    } else {
      const newValue = {};
      newValue[modelName] = selectedKeys;
      setNewKeysByModel(newValue);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
    setSelectedModelName(newValue);
  };

  const handleOk = () => {
    if (modelName === 'item') {
      // need to recreate items for existing entries first
      const newItems = ['asset', 'course', 'document', 'meeting', 'microcast', 'program'].map(
        (model) => {
          const list = preSelectedKeysByModel[model].slice();
          // just the new models
          const newKeys = difference(newKeysByModel[model], list);
          return newKeys.map(
            (newKey:any) => {
              const key = v4();
              const data = {
                key,
                id: key,
                type: model,
              };
              data[model] = newKey;
              return data;
            },
          );
        },
      );
      // combine into one list - existing then all the new models
      onConfirm(newItems.flat());
    } else {
      onConfirm(
        newKeysByModel[modelName],
      );
    }
    setNewKeysByModel({});
  };

  const handleCancel = () => {
    setNewKeysByModel({});
    onCancel();
  };

  return (
    <Dialog
      open={open}
      // onClose={handleCloseSelectDialog}
      PaperProps={{
        sx: {
          width: '90%',
          maxWidth: '90%',
          height: '80%',
          margin: 8,
        },
      }}
    >
      <DialogContent>
        { modelName === 'item' ? (
          <TabContext value={selectedTab}>
            <Box sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
            >
              <DialogTitle>
                <TabList onChange={handleTabChange}>
                  <Tab key="course" label="Courses" value="course" />
                  <Tab key="program" label="Programs" value="program" />
                  <Tab key="microcast" label="Microcasts" value="microcast" />
                  <Tab key="document" label="Documents" value="document" />
                  <Tab key="meeting" label="Meetings" value="meeting" />
                  <Tab key="asset" label="Assets" value="asset" />
                </TabList>
              </DialogTitle>
            </Box>
          </TabContext>
        ) : (
          <DialogTitle>{`Select ${capitalizeFirstLetter(pluralize(modelName))}`}</DialogTitle>
        ) }
        <ItemNavigator
          key={selectedModelName}
          modelName={selectedModelName}
          selectedKeys={newKeysByModel[selectedModelName]}
          preSelectedKeys={preSelectedKeysByModel[selectedModelName]}
          // listStyle="list"
          updateSelectedItems={
            (newKeys:string[]) => updateFromSelectedItems(newKeys)
          }
          multiSelect
          showSelect
          enableDeselect
          // only show items that are connected to the current portal
          // might need to make this depend on where the field is being used, but
          // right now picker always wants to be library-scoped except
          // for assets where its org-scoped
          filter={['item', 'course', 'document', 'meeting', 'microcast', 'program'].includes(selectedModelName) ? getLibraryFilter(store.currentPortal) : undefined}
          filters={[
            ...(
              ['item', 'course', 'document', 'meeting', 'microcast', 'program'].includes(selectedModelName)
              ? [scope === 'portal' ? 'portalLibraries' : 'combinedLibrary']
              : []
            ),
            'searchText',
          ]}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          onClick={handleOk}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ItemPickerDialog.defaultProps = {
  preSelectedKeysByModel: {},
  scope: 'organization',
};

export default ItemPickerDialog;
