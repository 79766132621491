import { colors } from '@mui/material';
import {
  createTheme,
} from '@mui/material/styles';
import palette from '../shared/palette';
import shadows from './shadows';
import typography from './typography';
import type {} from '@mui/lab/themeAugmentation';

const paletteSettings = {
  ...palette, // default custom palette
  primary: {
    main: '#1276dd',
    contrastText: colors.common.white,
  },
  secondary: {
    main: '#c4380f',
    contrastText: colors.common.white,
  },
  text: {
    primary: '#111111', // temp (check if there any other color)
    secondary: '#777E91',
  },
  background: {
    default: '#edf0f5',
    paper: colors.common.white,
  },
  error: {
    main: '#eb4c55',
  },
  info: {
    main: '#9ce2ff',
    light: '#acd3e3',
    dark: '#0d6bc2',
  },
  success: {
    main: '#a3cc06',
    //   light: '',
    dark: '#25a408',
  },
  divider: '#e4e7ed',
  action: {
    ...createTheme().palette.action,
    hover: '#f4f6fa',
    selected: '#e8f3ff',
  },
  subNav: {
    main: '#0f316c',
    topColor: undefined,
    bottomColor: undefined,
    text: {
      primary: '#ffffff',
      secondary: '#acd3e3',
    },
  },
  courseBar: {
    main: '#007bad',
    text: {
      primary: '#ffffff',
      secondary: '#acd3e3',
    },
  },
};

const scrollBarStyle = {
  '&::-webkit-scrollbar': {
    width: '0.4em',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: paletteSettings.primary.main,
  },
};

const adminTheme = createTheme({
  palette: { ...paletteSettings },
  shape: { borderRadius: 8 },
  shadows,
  typography,
  components: {
  // MuiAppBar: {
  //   styleOverrides: {
  //     root: {
  //       backgroundColor: '#222',
  //     },
  //   },
  // },
    MuiCard: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: { borderRadius: 8 },
      },
    },
    MuiTabs: {
      defaultProps: {
        scrollButtons: 'auto',
        variant: 'scrollable',
        // scrollButtons: true,
        //   indicatorColor: 'primary',
      },
      styleOverrides: {
        root: {
          borderRadius: 0,
          '& .MuiTabs-indicator': {
            height: 2,
            borderRadius: '1px',
            backgroundColor: 'primary.dark',
          },
          '.MuiTabs-scrollButtons.Mui-disabled': {
            opacity: 0.3,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minWidth: 40,
          paddingX: 1.125,
          textTransform: 'none',
          '&.Mui-selected': {
            fontWeight: typography.fontWeightBold,
            color: paletteSettings.common.black,
          },
        },
      },
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          px: 0,
          width: '100%',
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'text', color: 'primary' },
          style: {
            color: paletteSettings.primary.main,
          },
        },
        {
          props: { variant: 'text', color: 'secondary' },
          style: {
            color: paletteSettings.secondary.main,
          },
        },
      ],
      defaultProps: {
        // disableRipple: true, // No more ripple!
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          ...typography.button,
          minWidth: 120,
          height: '100%',
          borderRadius: 8,
          padding: '13px 27px',
          textAlign: 'left',
          textTransform: 'none',
        },
      },
    },
    MuiToggleButton: {
      variants: [
        {
          props: { color: 'primary' },
          style: {
            color: paletteSettings.primary.main,
          },
        },
        {
          props: { color: 'secondary' },
          style: {
            color: paletteSettings.secondary.main,
          },
        },
      ],
      defaultProps: {
        // disableRipple: true, // No more ripple!
        // disableElevation: true,
      },
      styleOverrides: {
        root: {
          ...typography.button,
          minWidth: 120,
          height: '100%',
          borderRadius: 8,
          padding: '13px 27px',
          textAlign: 'left',
          textTransform: 'none',
        },
      },

    },
    MuiTextField: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'outlined' && {
            '.MuiIconButton-root': {
              p: 0,
              mx: 0.5,
              '&:hover': {
                color: paletteSettings.primary.main,
              },
            },
          }),
        }),
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          ...typography.h6,
          color: paletteSettings.common.black,
          mb: 0.375,
        },
      },
    },
    MuiFormControlLabel: {
      variants: [
        {
          props: { labelPlacement: 'top' },
          style: {
            '.MuiFormControlLabel-label': {
              // show label on top left corner
              width: '100%',
              textAlign: 'left',
              mb: 8, // 8px
              //   pt: 3, // 24px
              ...typography.h6,
            },
          },
        },
        {
          props: { labelPlacement: 'end' },
          style: {
            '.MuiFormControlLabel-label': {
              textAlign: 'left',
              ...typography.body2,
              fontWeight: typography.fontWeightRegular,
              marginLeft: 0.375,
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          width: '100%',
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          // style overrides for rich text editor field
          // editor label style
          '& .WysiwygEditor-formlabel': {
            mb: 0.625,
            '& .MuiTypography-root': {
              my: 'auto',
            },
            '& .MuiIconButton-root': {
              p: 0.5,
            },
          },
          // editor toolbar style
          '& .MuiCollapse-wrapper': {
            '& .MuiCollapse-wrapperInner': {
              '& .WysiwygEditor-toolbar': {
                m: 0,
                px: '8px',
                py: 0,
                border: 'none',
                borderRadius: 8,
              },
            },
          },
          // editor input style
          '& .WysiwygEditor-Input': {
            height: '400px', // want to revisit this - should be possible to customize height easily
            border: '1px solid #e0e0eb',
            borderRadius: 8,
            padding: '0 !important',
            // color: palette.common.black,
            overflowY: 'auto',
            ...scrollBarStyle,
            // ...typography.h6,
            '&:hover': {
              border: `1px solid ${paletteSettings.common.black}`,
            },
            '&:focus': {
              border: `2px solid ${paletteSettings.primary.main}`,
            },
            '&::placeholder': {
              color: paletteSettings.secondary.main,
              //   ...typography.h6,
            },
          },
        },
      },
    },
    MuiFormHelperText: {
      variants: [
        {
          props: { error: true },
          style: {
            mt: 1.375,
            ml: 0,
            display: 'flex',
            '& .MuiTypography-root': {
              color: paletteSettings.secondary.main,
            },
            '& .MuiSvgIcon-root': {
              my: 'auto',
              color: paletteSettings.error.main,
            },
          },
        },
      ],
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiOutlinedInput: {
      variants: [
        {
          props: { multiline: true },
          style: {
            textarea: {
              ...scrollBarStyle,
            },
          },
        },
      ],
      styleOverrides: {
        input: {
          color: palette.common.black,
          ...typography.h6,
          '& fieldset': {
            border: '1px solid #e0e0eb',
          },
          '&:hover fieldset': {},
          '&.Mui-focused fieldset': {
            border: `2px solid ${paletteSettings.primary.main}`,
          },
          '&::placeholder': {
            color: paletteSettings.secondary.main,
            ...typography.h6,
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {},
      },
    },
    // MuiListItemIcon: {
    //   styleOverrides: {
    //     root: {
    //       minWidth: 40,
    //     },
    //   },
    // },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#e8f3ff',
            borderRadius: 8,
          },
          '&.Mui-selected': {
            backgroundColor: '#f4f6fa',
            borderRadius: 8,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '6px',
          backgroundColor: paletteSettings.secondary.main,
          '& .MuiButtonBase-root': {
            padding: 0,
          },
        },
        label: {
          color: paletteSettings.common.white,
        },
      },
    },
    MuiImageList: {
      defaultProps: {
        gap: 4,
        cols: undefined, // manually setting in styleOverrides
        variant: 'masonry',
      },
      // styleOverrides: {
      //   root: {
      //     p: 0.5,
      //     columnCount: {
      //       xs: '1 !important',
      //       sm: '2 !important',
      //       md: '4 !important',
      //       lg: '6 !important',
      //       xl: '8 !important',
      //     },
      //   },
      // },
    },
    MuiImageListItem: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'masonry' && {
            width: '100%',
            height: '100%',
            p: 0.5,
            mt: 0.25,
            borderRadius: 8,
            '&:hover': {
              cursor: 'pointer',
              boxShadow: shadows[1],
              backgroundColor: 'action.hover',
            },
            '& img': {
              borderRadius: 8,
            },
          }),
        }),
      },
    },
    MuiImageListItemBar: {
      defaultProps: {
        position: 'below',
        actionPosition: 'right',
      },
      styleOverrides: {
        root: { p: 1, pb: 0.5 },
        titleWrap: { p: 0 },
        title: {
          ...typography.body1,
          pr: 1,
          width: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        actionIcon: {
          pr: 1,
          my: 'auto',
          width: 20,
        },
      },
    },
  },
});

export default adminTheme;
