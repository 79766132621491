import React from 'react';
import { FormControl, FormLabel } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Controller } from 'react-hook-form';
import { useStore } from '@mcn-platform/models';
import CategoryPickerControl from 'publisher/editor/controls/CategoryPickerControl';
import { StandardFieldProps } from './FieldTypes';
import HelperText from './HelperText';

const PortalCategoryField = ({
  control,
  fieldName,
  field,
  readOnly,
}:StandardFieldProps):any => {
  const store = useStore();

  return (
    <Controller
      name={fieldName}
      control={control}
      render={({
        field: {
          onChange,
          value,
        },
        fieldState: {
          isTouched,
          error,
        },
      }) => {
        const { currentPortal } = store;
        if (!currentPortal) {
          return (<div />);
        }
        console.log('Form value', value);
        return (
          <FormControl disabled={field.readOnly} fullWidth>
            <FormLabel id={`label-${fieldName}`}>{field.label}</FormLabel>
            <CategoryPickerControl
              portal={currentPortal}
              label=""
              onChange={(newValue) => {
                console.log('New Category Picker Value', newValue);
                onChange(newValue?.id);
              }}
              disabled={readOnly}
              activeCategory={value}
            />
            <HelperText
              name={fieldName}
              error={isTouched && error?.message}
              description={field.description}
            />
          </FormControl>
        );
      }}
    />
  );
};

export default observer(PortalCategoryField);
