import React from 'react';
import {
  FormLabel,
  Grid,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { StandardFieldProps } from './FieldTypes';
import StandardNumberField from './StandardNumberField';
import StandardSelectField from './StandardSelectField';

/**
 * Handle Color Sets (normal, hover, disabled) - usually for buttons
 * The keys are fieldName + .color, .hover.color and .disabled.color
 * @param param
 * @returns
 */
const StandardFontStyleField = ({
  control,
  fieldName,
  field,
  readOnly,
}:StandardFieldProps) => (
  <Controller
    name={`${fieldName}`}
    control={control}
    render={() => (
      <>
        <FormLabel>
          {field.label}
        </FormLabel>
        <Grid
          container
          spacing={1}
          padding={1}
        >
          <Grid item xs={3}>
            <StandardNumberField
              control={control}
              fieldName={fieldName.replace('.fontStyle', '.fontSize')}
              readOnly={readOnly}
              field={{
                type: 'number', // mapping values used in stylesheet to standard field types
                label: 'Size',
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <StandardSelectField
              control={control}
              fieldName={fieldName.replace('.fontStyle', '.fontWeight')}
              field={{
                label: 'Weight',
                type: 'string',
                default: 'normal',
                enum: ['normal', 'bold', 'lighter', 'bolder',
              '100', '200', '300', '400', '500', '600', '700', '800', '900'],
                enumLabels: ['Normal', 'Bold', 'Lighter', 'Bolder',
              '100', '200', '300', '400', '500', '600', '700', '800', '900'],
              }}
              readOnly={readOnly}
            />
          </Grid>
          <Grid item xs={5}>
            <StandardSelectField
              control={control}
              fieldName={fieldName.replace('.fontStyle', '.textTransform')}
              field={{
                label: 'Transform',
                type: 'string',
                default: 'none',
                enum: ['none', 'capitalize', 'uppercase', 'lowercase'],
                enumLabels: ['None', 'Capitalize', 'Uppercase', 'Lowercase'],
              }}
              readOnly={readOnly}
            />
          </Grid>
        </Grid>
      </>
    )}
  />
);

StandardFontStyleField.defaultProps = {
  description: undefined,
};

export default StandardFontStyleField;
